import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AgentConversationRead } from './schemas'
import { ContentViewerContent, ContentViewerReferenceData, ContentViewerType } from '@/content-viewer/store/slice'
import { buildContentViewerContentForAgentReference } from '@/content-viewer/util/build-content-viewer-content'
import { ArtifactVersionRead } from '@/artifacts/schemas'

/**
 * Wrapper around AgentConversationRead (from the backend)
 * with additional UI-related fields.
 * Potentially move into a types.ts file?
 */
export type AgentConversation = AgentConversationRead & {
  lastRefresh: number | null
  isLoading: boolean
  loadError: ConversationLoadError | null
}

export type ConversationLoadError = {
  code: string
  title: string
  message: string
}

export type AgentConversationsState = {
  conversations: Partial<{ [conversationId: string]: AgentConversation }>
  activeContentViewer: Partial<{ [conversationId: string]: ContentViewerContent }>
  initialLoadComplete: boolean
  lastRefresh: number | null
  loading: boolean
  error: boolean
}

const initialState: AgentConversationsState = {
  conversations: {},
  activeContentViewer: {},
  initialLoadComplete: false,
  lastRefresh: null,
  loading: false,
  error: false,
}

/**
 * Agent Conversations Slice
 * Holds new agent conversations.
 */
export const agentConversationsSlice = createSlice({
  name: 'agentConversationsState',
  initialState,
  reducers: {
    // ============== Listener Middleware Triggers ============== >
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refreshConversationList: (_state, _action: PayloadAction<{ forceRefresh: boolean }>) => {
      // Handled by listener middleware
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    generateConversationTitle: (_state, _action: PayloadAction<{ conversationId: string }>) => {
      // Handled by listener middleware
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchConversationData: (_state, _action: PayloadAction<{ conversationId: string; forceRefresh: boolean }>) => {
      // Handled by listener middleware
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    generateConversationFollowUpQuestions: (_state, _action: PayloadAction<{ conversationId: string }>) => {
      // Handled by listener middleware
    },

    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Set loading state for conversation list
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    // Set error state for conversation list
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload
    },

    // Set initial load complete for conversation list
    setInitialLoadComplete: (state, action: PayloadAction<boolean>) => {
      state.initialLoadComplete = action.payload
    },

    // Set last refresh for conversation list
    setLastRefresh: (state, action: PayloadAction<number | null>) => {
      state.lastRefresh = action.payload
    },

    /**
     * Upsert a conversation.
     * Creates a new `AgentConversation` if it doesn't exist.
     * Updates an existing `AgentConversation` with the new `AgentConversationRead` fields
     * from the payload if it does exist.
     */
    upsertConversation: (state, action: PayloadAction<AgentConversationRead>) => {
      const conversationRead = action.payload

      const existingConversation = state.conversations[conversationRead.id]

      if (!existingConversation) {
        // If the conversation doesn't exist, initialize it with default values
        const newConversation: AgentConversation = {
          ...conversationRead,
          lastRefresh: null,
          isLoading: false,
          loadError: null,
        }

        state.conversations[conversationRead.id] = newConversation
      } else {
        // If the updated_at date is the same, skip the update
        if (existingConversation.updated_at === conversationRead.updated_at) {
          return
        }

        // Update only the AgentConversationRead fields
        state.conversations[conversationRead.id] = {
          ...existingConversation,
          ...conversationRead,
        }
      }
    },

    // Delete a conversation
    deleteConversation: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload
      delete state.conversations[conversationId]
    },

    // Set loading state for a conversation
    setConversationLoading: (state, action: PayloadAction<{ conversationId: string; isLoading: boolean }>) => {
      const { conversationId, isLoading } = action.payload
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      conversation.isLoading = isLoading
    },

    // Set error state for a conversation
    setConversationError: (state, action: PayloadAction<{ conversationId: string; loadingError: ConversationLoadError | null }>) => {
      const { conversationId, loadingError } = action.payload
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      conversation.loadError = loadingError
    },

    // Set the last refresh time for a conversation
    setConversationLastRefresh: (state, action: PayloadAction<{ conversationId: string; lastRefresh: number | null }>) => {
      const { conversationId, lastRefresh } = action.payload
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      conversation.lastRefresh = lastRefresh
    },

    // Set the active content viewer (artifact version) for a conversation
    setConversationActiveContentViewerArtifactVersion: (state, action: PayloadAction<{ conversationId: string; artifactVersion: ArtifactVersionRead }>) => {
      const { conversationId, artifactVersion } = action.payload

      // Make sure the conversation exists
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      const contentViewerContent: ContentViewerContent = {
        type: ContentViewerType.ARTIFACT,
        data: artifactVersion,
      }

      state.activeContentViewer[conversationId] = contentViewerContent
    },

    // Set the active content viewer (reference) for a conversation
    setConversationActiveContentViewerReference: (state, action: PayloadAction<{ conversationId: string; referenceData: ContentViewerReferenceData }>) => {
      const { conversationId, referenceData } = action.payload

      // Make sure the conversation exists
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      const contentViewerContent = buildContentViewerContentForAgentReference(referenceData)

      state.activeContentViewer[conversationId] = contentViewerContent
    },

    // Remove the active content viewer for a conversation
    RemoveConversationActiveContentViewer: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload
      delete state.activeContentViewer[conversationId]
    },
  },
})

// Actions
export const AgentConversationsActions = agentConversationsSlice.actions

export default agentConversationsSlice.reducer
