import { kAgentCitationRegex } from '@/util/rehype-custom-plugins/rehype-agent-citations'
import { ClientAgentReference } from '@/agent/references/store/schemas'
import AgentResponseReferencesPrintableListItem from './AgentResponseReferencesPrintableListItem'

type AgentResponseReferencesPrintableSectionProps = {
  responseMessage: string
  turnUniqueCitationIds: string[]
  references: ClientAgentReference[]
}

/**
 * Agent Response References Printable Section
 *
 * A printable version of the AgentResponseReferencesSection/AgentResponseReferencesGroup component.
 * This is used to display the references section in the printable version of the agent response event bubble.
 */
export default function AgentResponseReferencesPrintableSection(props: AgentResponseReferencesPrintableSectionProps) {
  const { responseMessage, turnUniqueCitationIds, references } = props

  // Identify the citation_ids in the responseMessage
  const matches = responseMessage.match(kAgentCitationRegex)

  // Convert to a list of strings
  const matchedCitationIds = matches ? matches.map((match) => match.slice(1, -1)) : []

  // If no matches, return null
  if (matchedCitationIds.length === 0) {
    return null
  }

  // Filter the turnUniqueCitationIds to only include the citation_ids in the responseMessage
  const usedCitationIds = turnUniqueCitationIds.filter((citation_id) => matchedCitationIds.some((match) => match === citation_id))
  const unusedCitationIds = turnUniqueCitationIds.filter((citation_id) => !usedCitationIds.includes(citation_id))

  const totalCitationIds = usedCitationIds.length + unusedCitationIds.length

  // Helper function to render reference list items
  const renderReferenceItems = (citationIds: string[]) =>
    citationIds
      .map((citationId) => {
        // Find the reference by the citation_id
        const reference = references.find((ref) => ref.reference.citation_id === citationId)
        if (!reference) return null

        // Create the label for the citation using the index of the citationId in turnUniqueCitationIds (citationId is used if not found)
        const index = turnUniqueCitationIds.indexOf(citationId)
        const label = index === -1 ? citationId : index + 1
        return <AgentResponseReferencesPrintableListItem key={citationId} citationLabel={label} reference={reference} />
      })
      .filter((item): item is JSX.Element => item !== null)

  return (
    <div>
      {totalCitationIds > 0 && (
        <div>
          <hr />
          <h3>Cited References</h3>
          {renderReferenceItems(usedCitationIds)}
          {unusedCitationIds.length > 0 && (
            <>
              <hr />
              <h3>Related References</h3>
              {renderReferenceItems(unusedCitationIds)}
            </>
          )}
          <hr />
        </div>
      )}
    </div>
  )
}
