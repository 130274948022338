import { store } from '@/store/store'

import { paxtonAppApi } from '@/store/apis/paxton-app-api'
import { nullifyData as booleanNullify } from '@/store/slices/boolean-builder-stream-response.slice'
import { nullifyData as auditNullify } from '@/store/slices/document-audit-stream-response.slice'
import { nullifyData as chatUxNullify } from '@/chat-common/store/chat-v2-ux.slice'
import { nullifyData as subscriptionNullify } from '@/routes/dashboard/subscribe/store/subscription.slice'
import { ChatV2Actions } from '@/chat-common/store/chat-v2.slice'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import { ArtifactsActions } from '@/artifacts/store/slice'
import { AgentResponseMetadataActions } from '@/agent/response_metadata/store/slice'
import { AgentReferencesActions } from '@/agent/references/store/slice'
import { AgentEventsActions } from '@/agent/events/store/slice'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'

import * as Sentry from '@sentry/browser'
/**
 * Clear State Data
 * Handles clearing of various slices of global state
 */
export async function clearReduxStateData() {
  // Legacy features
  store.dispatch(auditNullify())
  store.dispatch(booleanNullify())

  // Chat UX
  store.dispatch(chatUxNullify())

  // Chat Conversations
  store.dispatch(ChatV2Actions.nullifyData())

  // Subscription
  store.dispatch(subscriptionNullify())

  // Suggested questions
  store.dispatch(paxtonAppApi.util.resetApiState())

  // Agent Conversations
  try {
    store.dispatch(AgentConversationsActions.nullifyData())
    store.dispatch(ArtifactsActions.nullifyData())
    store.dispatch(AgentResponseMetadataActions.nullifyData())
    store.dispatch(AgentReferencesActions.nullifyData())
    store.dispatch(AgentEventsActions.nullifyData())
    store.dispatch(AgentConversationFormActions.nullifyData())
  } catch (error) {
    console.warn('Error clearing agent state data:', error)
    Sentry.captureException(error, {
      extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
    })
  }
}
