import * as Dialog from '@radix-ui/react-dialog'
import { Drafting } from '@/assets/icons'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { FilesDriveDialogType, uiStateSlice } from '@/store/slices/ui-state.slice'
import { FilesDriveActions } from '../../store/files-drive.slice'
import { z } from 'zod'
import { useState } from 'react'

const validationSchema = z
  .string()
  .regex(/^(?![ .])(?!.*[ .]$)[A-Za-z0-9_.\- ]{1,255}$/, 'Invalid filename. Must be 1-255 characters and cannot start or end with a space or dot')

export default function RenameItemDialog() {
  const [error, setError] = useState<string | null>(null)
  const [proposedItemName, setProposedItemName] = useState<string>('')

  const dispatch = useAppDispatch()

  const isDialogVisible = useAppSelector((state) => state.uiState.filesDriveDialogVisible)
  const activeDialogType = useAppSelector((state) => state.uiState.filesDriveDialogType)
  const activeItem = useAppSelector((state) => state.filesDrive.activeItem)

  const isRenamingItems = useAppSelector((state) => state.filesDrive.isRenamingItems)

  const showRenameDialog = isDialogVisible && activeDialogType === FilesDriveDialogType.RENAME

  /**
   * Handles file extension when renaming a file
   *
   * @param newName - New filename with optional extension
   * @returns Filename with proper extension
   * @throws Error if original extension is invalid
   */
  const handleFileExtension = (newName: string) => {
    if (!activeItem) return ''

    const hasExtension = /\.\w+$/.test(newName)
    // Extension is a property of FileNode but not FolderNode
    const originalExtension = activeItem && 'extension' in activeItem ? activeItem.extension : ''

    //Error out in case we can't find a original file extension
    if (originalExtension.length <= 1) throw new Error('Unable to find a original file extension')

    // Prevent user from changing the extension of the file
    if (hasExtension) {
      const newNameWithExtension = newName.split('.').pop()
      if (newNameWithExtension !== originalExtension) {
        setError('Invalid filename. Cannot change the extension of the file.')
        return ''
      }
    }

    // If the new name doesn't have an extension, add the original extension
    const newNameWithExtension = hasExtension ? newName : newName + `.${originalExtension}`

    return newNameWithExtension
  }

  /**
   * Handles input change for the rename item dialog
   * Set error if the filename is invalid
   * @param e - React change event
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.currentTarget.value
    setProposedItemName(inputName)

    const result = validationSchema.safeParse(inputName)

    if (!result.success) {
      setError('Invalid filename.')
    } else {
      setError(null)
    }
  }

  /**
   * Handles form submission for the rename item dialog
   * @param e - React form event with item name input
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const inputNameWithExtension = handleFileExtension(proposedItemName)

    if (inputNameWithExtension !== '') {
      dispatch(FilesDriveActions.renameItem({ proposedItemName: inputNameWithExtension, itemId: activeItem?.id ?? '' }))
    }
  }

  /**
   * Handles closing the rename item dialog
   */
  const handleCloseDialog = () => {
    dispatch(FilesDriveActions.clearActiveFile())
    dispatch(uiStateSlice.actions.setFilesDriveDialogVisible(false))
    dispatch(uiStateSlice.actions.setFilesDriveDialogType(null))
    setError(null)
  }

  return (
    <Dialog.Root open={showRenameDialog}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-brand-neutral-50 p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="m-0 text-[17px] font-medium">
            <span className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
              <Drafting className="h-10 w-10 p-2 scale-75 rounded-full text-brand-700 bg-brand-100" />
              Rename File
            </span>
          </Dialog.Title>
          <Dialog.Description className="mt-[10px] mb-5 text-[15px] leading-normal">
            <span className="text-brand-neutral-800 my-5">
              <strong>Please note:</strong> renaming this file will not update past chats. Previous chats will still reference the old file name.
            </span>
          </Dialog.Description>
          <form onSubmit={(e) => handleSubmit(e)}>
            <fieldset className="mb-[15px] flex items-center gap-5">
              <input
                id="newName"
                autoFocus={true}
                name="newName"
                className="block w-full rounded-md border-0 p-2 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 focus:ring-2 focus:ring-inset focus:ring-brand-700 sm:text-sm sm:leading-6"
                placeholder="New name"
                defaultValue={`${activeItem?.name}`}
                onChange={(e) => handleInputChange(e)}
              />
            </fieldset>
            {error && <p className={'text-sm text-red-700'}>{error}</p>}
            <div className="mt-[25px] gap-3 flex justify-end">
              <Dialog.Close asChild>
                <button
                  type="button"
                  className={`bg-brand-neutral-50 text-brand-neutral-900 ring-brand-neutral-300 hover:bg-brand-neutral-100 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset sm:w-auto h-auto`}
                  onClick={handleCloseDialog}
                >
                  Cancel
                </button>
              </Dialog.Close>
              <Dialog.Close asChild>
                <button
                  type="submit"
                  className={`bg-brand-500 text-white hover:bg-brand-400 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm sm:w-auto h-auto ${
                    error !== null || isRenamingItems ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={error !== null || isRenamingItems}
                >
                  {isRenamingItems ? 'Saving...' : 'Save'}
                  <ButtonLoader loading={isRenamingItems ?? false} />
                </button>
              </Dialog.Close>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
