import { z } from 'zod'

/**
 * Represents the type of data being sent over the stream
 * Matches the MBMessageType in the backend
 */
export enum WS2ReceivedDataType {
  CONVERSATION_EVENT = 'CONVERSATION_EVENT',
  CONVERSATION_EVENT_CHUNK = 'CONVERSATION_EVENT_CHUNK',
  REFERENCE = 'REFERENCE',
  STATUS = 'STATUS',
  ERROR = 'ERROR',
  CLOSE_DONE = 'CLOSE_DONE',
  CLOSE_ERROR_APP = 'CLOSE_ERROR_APP',
  CLOSE_ERROR_OTHER = 'CLOSE_ERROR_OTHER',
  CLOSE_STOP = 'CLOSE_STOP',
  ARTIFACT_VERSION_UPDATED = 'ARTIFACT_VERSION_UPDATED',
}

// Zod schema for a stream payload
export const WS2ReceivedDataSchema = z.object({
  type: z.nativeEnum(WS2ReceivedDataType).describe('The type of message being sent'),
  payload: z.any().describe('The payload data could be anything.'),
})

/**
 * Every message received over the stream will have a type and data.
 * @param {WS2ReceivedData} type - The type of message being sent
 * @param {string} payload - Stringified JSON
 */
export type WS2ReceivedData = z.infer<typeof WS2ReceivedDataSchema>

/**
 * Represents the type of data being sent over the stream
 */
export enum WS2SendPayloadType {
  CONVERSATION_MESSAGE = 'CONVERSATION_MESSAGE',
  CONVERSATION_STOP = 'CONVERSATION_STOP',
}

// Zod schema for a the initial connect and send
export const WS2ConnectAndSendPayloadSchema = z.object({
  conversationId: z.string().describe('The conversation ID'),
  type: z.nativeEnum(WS2SendPayloadType).describe('The type of message being sent'),
  payload: z.any().describe('The payload data (do not stringify yet).'),
})
export type WS2ConnectAndSendPayload = z.infer<typeof WS2ConnectAndSendPayloadSchema>

// Zod schema for subsequent messages sent
export const WS2SendMessagePayloadSchema = z.object({
  type: z.nativeEnum(WS2SendPayloadType).describe('The type of message being sent'),
  payload: z.any().describe('The payload data (do not stringify yet).'),
})
export type WS2SendMessagePayload = z.infer<typeof WS2SendMessagePayloadSchema>
