import { ContentViewerType, ContentViewerContent, ContentViewerReferenceData } from '@/content-viewer/store/slice'

/**
 * Builds a ContentViewerContent object for an agent reference.
 *
 * @param reference - The ClientAgentReference to build the content for.
 * @returns The ContentViewerContent object.
 */
export function buildContentViewerContentForAgentReference(reference: ContentViewerReferenceData): ContentViewerContent {
  const isPDF = reference.reference.metadata.file_path.endsWith('.pdf')

  const content: ContentViewerContent = {
    type: isPDF ? ContentViewerType.AGENT_REFERENCE_PDF : ContentViewerType.AGENT_REFERENCE_MD,
    data: reference,
  }

  return content
}
