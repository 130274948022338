import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import RenderReactPdfViewer from '@/components/react-pdf-viewer/render-react-pdf-viewer'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { ClientAgentReference } from '@/agent/references/store/schemas'
import ContentViewerHeader from './common/ContentViewerHeader'
import { agentReferenceFootnoteNumber, agentReferenceMetadataToTitle } from '@/agent/references/util/agent-reference-utils'
import { ContentViewerReferenceData } from '../store/slice'
import { PaxtonHighlightArea } from '@/components/react-pdf-viewer/schema/paxton-highlight-area'

type ContentViewerPDFProps = {
  data: ContentViewerReferenceData
  highlights: PaxtonHighlightArea[]
  startingPage?: number
}

/**
 * Content Viewer PDF - The Newer PDF Viewer for the Agent References
 *
 * This is a data specific wrapper for the generic RenderReactPdfViewer component.
 *
 * @param props
 * @returns {JSX.Element}
 */
export default function ContentViewerPDF(props: ContentViewerPDFProps): JSX.Element {
  const { data, highlights, startingPage } = props
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)

  // Infer values
  const reference: ClientAgentReference = data.reference
  const titlePrefix = agentReferenceFootnoteNumber(reference) ?? ''
  const title = `[${titlePrefix}] ${agentReferenceMetadataToTitle(reference)}`
  const conversationId = reference.conversation_id

  // Local state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pdfFile, setPdfFile] = useState<File | null>(null)

  // Fetch thePDF whenever the reference changes
  useEffect(() => {
    // Fetch the PDF file from GCS
    async function fetchPdfFromGcs() {
      // Reset state
      setLoading(true)
      setError(false)
      setPdfFile(null)

      // Get the path from the reference
      const path = reference.metadata.file_path

      // Load the PDF file from Firebase storage
      try {
        const storageRef = ref(storage, path)
        const blob: Blob = await getBlob(storageRef)
        const blobType = blob.type

        // Make sure it's a PDF file
        if (blobType !== 'application/pdf') {
          Sentry.captureException(new Error('PDF Reference Blob is not application/pdf'), { extra: { referenceMetadata: JSON.stringify(reference.metadata) } })
          setError(true)
          return
        }

        // Set the PDF file
        setPdfFile(blob as File)
      } catch (e) {
        console.error(e)
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    // Call the function to load and set the HTML content
    fetchPdfFromGcs()
  }, [reference, storage])

  // Render loading
  if (loading) {
    return (
      <div className={'p-2'}>
        <CircularProgressContinuousSized size={12} thickness={7} />
      </div>
    )
  }

  // Render error
  if (error || !pdfFile || !reference) {
    return <div className={'p-2'}>Error loading PDF</div>
  }

  return (
    <div className="bg-brand-neutral-50 flex flex-col h-full w-full overflow-hidden">
      <ContentViewerHeader loading={loading} title={title} conversationId={conversationId} />
      <RenderReactPdfViewer pdfFile={pdfFile} highlightAreas={highlights} startingPage={startingPage} />
    </div>
  )
}
