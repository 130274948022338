import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { kErrorDisplayingResponseMessage } from '@/constants/constants-strings'
import ErrorMessageBubble from '../ErrorMessageBubble'
import EventTextMarkdown from '../EventTextMarkdown'
import AgentPromptAssistPopover from './AgentPromptAssistPopover'

type PromptAssistEventBubbleProps = {
  conversationId: string
  eventId: string
}

/**
 * Prompt Assist Event Bubble
 *
 * A component to display events of type AGENT_RESPONSE_PROMPT_ASSIST.
 */
export default function PromptAssistEventBubble(props: PromptAssistEventBubbleProps) {
  const { conversationId, eventId } = props

  const event = useAppSelector((state: RootState) => state.agentEventsState.events[conversationId]?.[eventId])

  if (!event) {
    console.error('Event not found')
    return <ErrorMessageBubble message={kErrorDisplayingResponseMessage} />
  }

  return (
    <div className="py-2 px-4 rounded-lg text-base whitespace-pre-wrap compatible-word-break bg-brand-50 border border-brand-200">
      <div className="my-2">
        <AgentPromptAssistPopover />
      </div>
      <div className="px-2 text-sm leading-6 mb-2 text-brand-800">
        <div id="chat-response-bubble">
          <EventTextMarkdown conversationId={conversationId} eventId={eventId} />
        </div>
      </div>
    </div>
  )
}
