import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { kGetUserOrgData } from '@/constants/constants-api-paths'
import { z } from 'zod'

export enum OrganizationSubscriptionStatus {
  PAID = 'paid',
  TRIALING = 'trialing',
  CANCELED = 'canceled',
}

// Zod schema for OrgData
export const OrgDataSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  seats: z.number(),
  has_access: z.boolean(),
  subscription_status: z.nativeEnum(OrganizationSubscriptionStatus),
})
export type OrgData = z.infer<typeof OrgDataSchema>

// Get Org Info for the current user
export async function getOrgData(): Promise<OrgData | null> {
  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    // Construct request
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + kGetUserOrgData
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Report non-404 errors to sentry
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: data,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })

      return null
    }

    // Validate response complies with the schema
    const result = OrgDataSchema.safeParse(data.organization)
    if (!result.success) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`OrgDataSchema validation failed`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: data,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })

      return null
    }

    // Return the organization data
    return result.data
  } catch (e) {
    console.error(e)

    // Capture to sentry
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })

    return null
  }
}
