import { RootState } from '@/store/store'
import { ArtifactVersionRead } from '../schemas'

/**
 * Select: Artifact by ID
 *
 * Returns the artifact with the given id, or null if it does not exist.
 */
export const selectArtifactById = (state: RootState, props: { artifactVersionId: string | undefined | null }): ArtifactVersionRead | null => {
  const { artifactVersionId } = props
  if (!artifactVersionId) return null

  return state.artifactsState.artifactsVersions[artifactVersionId] ?? null
}
