import { createListenerMiddleware, ListenerEffectAPI } from '@reduxjs/toolkit'
import { FilesDriveActions } from './files-drive.slice'
import { fetchAllItemsByFolder, fetchFileById, fetchRootFolderId } from '../repository/fetch-drive-items'
import { DriveType, FileNode, FilesDriveResponse, FileStatus, FolderDriveResponse, FolderNode, NodeType } from '../schemas/files-drive-schema'
import { AppDispatch, RootState } from '@/store/store'
import * as Sentry from '@sentry/browser'
import { deleteFilesByIds, deleteFoldersByIds } from '../repository/delete-drive-items'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { setFilesDriveDialogType, setFilesDriveDialogVisible } from '@/store/slices/ui-state.slice'
import { postNewFolder } from '../repository/post-new-folder'
import { putItemName } from '../repository/put-item-name'

const filesDriveListenerMiddleware = createListenerMiddleware()

filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.uploadFiles,
  effect: async (action, listenerApi) => {
    const { files_ids } = action.payload

    const maxTries = 3
    let tries = 0

    const state = listenerApi.getState() as RootState
    const { files, currentFolderId } = state.filesDrive

    if (files_ids.length === 0) {
      console.warn('No files to upload')
      return
    }

    // Check if all uploaded files are in the state
    const allFilesUploaded = files_ids.every((id) => files.some((file) => file.id === id))

    if (allFilesUploaded) {
      console.warn('All files uploaded')
      return
    }

    // Only fetch again if files have changed
    // if (JSON.stringify(files) !== JSON.stringify(currentFiles)) {
    //   console.warn('Files state changed, retrying fetch...')
    // }

    while (!allFilesUploaded && tries < maxTries) {
      console.warn('Not all files in state yet, retrying...')
      // Increase the delay time between retries so the fetch has time to be completed.
      await delay(3000)
      listenerApi.dispatch(FilesDriveActions.fetchItems({ folderId: currentFolderId ?? 'root' }))
      tries++

      // Re-check after fetching
      const updatedState = listenerApi.getState() as RootState
      if (files_ids.every((id) => updatedState.filesDrive.files.some((file) => file.id === id))) {
        listenerApi.dispatch(FilesDriveActions.setLoading(false))
        return
      }
    }

    if (tries >= maxTries) {
      console.warn('Max tries reached for checking uploaded files')
    }
  },
})

/**
 * Listener middleware for fetching and processing folders items.
 *
 * This listener handles fetching and processing folder items when triggered by the fetchItems action.
 * It manages the entire folder loading workflow including:
 *
 * 1. Validating loading state to prevent duplicate fetches
 * 2. Determining and fetching root folder ID if needed
 * 3. Loading all items (files and folders) within the target folder
 * 4. Processing and transforming the response data
 * 5. Updating the UI state with the processed data
 * 6. Managing loading states and error handling
 *
 * @param {Object} action - The action object containing the payload
 * @param {string} action.payload.folderId - The ID of the folder to fetch items from. Empty string '' indicates root folder.
 */
filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.fetchItems,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState

    const { driveType } = state.filesDrive
    const { folderId } = action.payload

    if (state.filesDrive.loading) {
      console.warn('Fetch Items action ignored because loading is already in progress.')
      return
    }

    listenerApi.dispatch(FilesDriveActions.setLoading(true))

    // Get root folder id if folderId is empty/undefined
    const currentFolderId = folderId === '' || folderId === undefined ? await fetchRootFolderId(driveType) : folderId

    if (!currentFolderId) {
      console.error('Failed to fetch folder metadata')
      return
    }

    try {
      const data = await fetchAllItemsByFolder(currentFolderId)

      const processedData = {
        folders: (data.sub_folders?.map((f: FolderDriveResponse) => ({ ...f, type: NodeType.FOLDER })) as FolderNode[]).sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        files: (data.files?.map((f: FilesDriveResponse) => ({ ...f, type: NodeType.FILE })) as FileNode[]).sort((a, b) =>
          a.created_at.localeCompare(b.created_at)
        ),
        folder: {
          id: data.id,
          user_id: data.user_id,
          name: data.name,
          folder_path: data.folder_path,
          folder_id: data.folder_id,
          full_visual_path: data.full_visual_path,
          type: NodeType.FOLDER,
        } as FolderNode,
      }

      performPostFetchOperations(listenerApi as unknown as ListenerEffectAPI<unknown, AppDispatch, unknown>, processedData, currentFolderId)
    } catch (error) {
      listenerApi.dispatch(FilesDriveActions.setError(typeof error === 'string' ? error : 'Failed to fetch folder items'))
      listenerApi.dispatch(FilesDriveActions.setLoading(false))
    }
  },
})

/**
 * Listener middleware for polling file processing status.
 *
 * This listener monitors files that are being processed after upload. It:
 * 1. Starts polling when setPollingFilesActive is dispatched with isActive=true
 * 2. Checks status of processing files every 3 seconds
 * 3. Updates file statuses in store as processing completes
 * 4. Stops polling when either:
 *    - All files complete processing (success or failure)
 *    - Max polling time (5 minutes) is reached
 *    - setPollingFilesActive is dispatched with isActive=false
 * 5. Handles errors by retrying after 15 seconds
 *
 * @param {Object} action - The action object containing isActive flag
 * @param {ListenerApi} listenerApi - Redux listener API for dispatching actions
 */
filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.setPollingFiles,
  effect: async (_action, listenerApi) => {
    const startTime = Date.now()

    // TODO: Consider making these configurable via environment variables
    const maxPollingTime = 5 * 60 * 1000 // 5 minutes
    const pollingIntervalTime = 3000 // 3 seconds
    const retryWaitTime = 15000 // 15 seconds

    const state = listenerApi.getState() as RootState

    if (state.filesDrive.isPollingFiles) {
      console.warn('Polling is already active')
      return
    }

    const executePoll = async () => {
      const state = listenerApi.getState() as RootState
      const { isPollingFiles } = state.filesDrive

      while (isPollingFiles) {
        const duration = Date.now() - startTime
        const state = listenerApi.getState() as RootState

        if (duration > maxPollingTime) {
          Sentry.captureException(new Error('Max polling time reached for file processing'), {
            extra: {
              onLine: navigator.onLine,
              cookieEnabled: navigator.cookieEnabled,
              pollingMinutes: duration / 60000,
            },
          })
          console.warn(`Stopping polling due to max duration.`)
          listenerApi.dispatch(FilesDriveActions.setPollingFiles({ isActive: false }))
          return
        }

        const processingFiles = state.filesDrive.files.filter((f) => f.status === FileStatus.PROCESSING)

        if (processingFiles.length === 0) {
          listenerApi.dispatch(FilesDriveActions.setPollingFiles({ isActive: false }))
          return
        }

        for (const processingFile of processingFiles) {
          try {
            await delay(pollingIntervalTime)

            const file = await fetchFileById(processingFile.id)

            if (!file) {
              console.error(`No files found for file ID: ${processingFile.id}`)
              listenerApi.dispatch(FilesDriveActions.updateFileStatus({ fileId: processingFile.id, status: FileStatus.FAILED }))
            }

            if (file.status !== FileStatus.PROCESSING) {
              listenerApi.dispatch(FilesDriveActions.updateFileStatus({ fileId: file.id, status: file.status }))
            }
          } catch (e) {
            console.warn(`Error polling file ID: ${processingFile.id}`, e)
            await delay(retryWaitTime)
          }
        }
      }
    }
    await executePoll()
  },
})

/**
 * Listener middleware for removing files and folders.
 *
 * Handles batch deletion of files and folders by dispatching delete actions and managing UI state.
 * Uses Redux best practices like:
 * - Avoiding direct state mutations
 * - Handling async operations with proper error boundaries
 * - Using action creators for state updates
 * - Managing loading states
 *
 * @param {Object} action - The delete action
 * @param {string[]} action.payload.fileIds - File IDs to delete
 * @param {string[]} action.payload.folderIds - Folder IDs to delete
 */
filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.removeItems,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState
    const { isDeletingItems, currentFolderId, driveType } = state.filesDrive

    // Early return if already deleting
    if (isDeletingItems) {
      console.warn('Delete operation already in progress')
      return
    }

    const { fileIds, folderIds } = action.payload

    // Validate input
    if (fileIds.length === 0 && folderIds.length === 0) {
      listenerApi.dispatch(
        openGlobalToast({
          type: GlobalToastType.ERROR,
          message: 'No items selected to delete',
        })
      )
    }

    try {
      // Start deleting items
      listenerApi.dispatch(FilesDriveActions.setDeletingItems(true))

      // Transform this into a promise.all
      if (fileIds.length > 0) {
        await deleteFilesByIds(fileIds)
      }
      if (folderIds.length > 0) {
        await deleteFoldersByIds(folderIds)
      }
      listenerApi.dispatch(
        openGlobalToast({
          type: GlobalToastType.SUCCESS,
          message: 'Items deleted successfully',
        })
      )
    } catch (error) {
      Sentry.captureException(error)
      listenerApi.dispatch(
        openGlobalToast({
          type: GlobalToastType.ERROR,
          message: 'Failed to delete items',
        })
      )
      throw error
    } finally {
      // Perform cleanup operations
      performFileOperationCleanup(listenerApi as unknown as ListenerEffectAPI<unknown, AppDispatch, unknown>, currentFolderId, driveType)
      // Reset deleting items active state
      listenerApi.dispatch(FilesDriveActions.setDeletingItems(false))
    }
  },
})

/**
 * Redux listener middleware for creating new folders in the file drive.
 *
 * This listener:
 * 1. Sets the creating folder loading state
 * 2. Makes an API call to create the folder with the given name and parent
 * 3. Shows success/error toasts based on the API response
 * 4. Refreshes the folder items and resets UI state on completion
 *
 * @param {Object} action - The action payload
 * @param {string} action.payload.folderName - Name for the new folder
 * @param {string} action.payload.parentFolderId - ID of parent folder to create in
 */
filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.createFolder,
  effect: async (action, listenerApi) => {
    const { folderName, parentFolderId } = action.payload

    const state = listenerApi.getState() as RootState
    const { currentFolderId, driveType } = state.filesDrive

    listenerApi.dispatch(FilesDriveActions.setCreatingFolder(true))

    try {
      const response = await postNewFolder({ folderName, parentFolderId })

      // Confirm request was successful
      if (!response) {
        listenerApi.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Failed to create folder' }))
        return
      }
      listenerApi.dispatch(
        openGlobalToast({
          type: GlobalToastType.SUCCESS,
          message: 'Folder created successfully',
        })
      )
    } catch (error) {
      listenerApi.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Failed to create folder' }))
    } finally {
      // Perform cleanup operations
      performFileOperationCleanup(listenerApi as unknown as ListenerEffectAPI<unknown, AppDispatch, unknown>, currentFolderId, driveType)
      // Reset creating folder active state
      listenerApi.dispatch(FilesDriveActions.setCreatingFolder(false))
    }
  },
})

/**
 * Redux listener middleware for renaming files and folders in the file drive.
 *
 * This listener:
 * 1. Sets the renaming loading state
 * 2. Makes an API call to update the item name
 * 3. Shows success/error toasts based on the API response
 * 4. Refreshes the folder items and resets UI state on completion
 *
 * @param {Object} action - The action payload
 * @param {string} action.payload.itemNewName - New name for the item
 * @param {string} action.payload.itemId - ID of item to rename
 */
filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.renameItem,
  effect: async (action, listenerApi) => {
    const { itemId, proposedItemName } = action.payload
    //define the current folder id
    const state = listenerApi.getState() as RootState
    const { currentFolderId, driveType } = state.filesDrive

    listenerApi.dispatch(FilesDriveActions.setRenamingItems(true))

    try {
      const response = await putItemName({ itemName: proposedItemName, itemId })

      // Confirm request was successful
      if (!response) {
        listenerApi.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Failed to rename item' }))
        return
      }

      listenerApi.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: 'Item renamed successfully' }))
    } catch (error) {
      listenerApi.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Failed to rename item' }))
    } finally {
      // Perform cleanup operations
      performFileOperationCleanup(listenerApi as unknown as ListenerEffectAPI<unknown, AppDispatch, unknown>, currentFolderId, driveType)
      // Reset renaming items active state
      listenerApi.dispatch(FilesDriveActions.setRenamingItems(false))
    }
  },
})

export default filesDriveListenerMiddleware

/**
 * Helper functions for managing file drive operations and state
 */

/**
 * Creates a promise that resolves after the specified delay.
 * Used for polling operations.
 */
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Updates Redux store state after fetching folder data.
 * Handles setting folder items, current folder state, and loading states.
 *
 * @param listenerApi - The RTK listener API for dispatching actions
 * @param processedData - The processed folder data containing files and folders
 * @param folderId - The ID of the current folder
 */
const performPostFetchOperations = (
  listenerApi: ListenerEffectAPI<unknown, AppDispatch, unknown>,
  processedData: {
    folders: FolderNode[]
    files: FileNode[]
    folder: FolderNode
  },
  folderId: string
): void => {
  // Update store state with all folder data in a batch
  listenerApi.dispatch(
    FilesDriveActions.setFolderItems({
      folders: processedData.folders,
      files: processedData.files,
    })
  )
  listenerApi.dispatch(FilesDriveActions.setCurrentFolderId({ folderId }))
  listenerApi.dispatch(FilesDriveActions.setCurrentFolder({ folder: processedData.folder }))
  listenerApi.dispatch(FilesDriveActions.setPollingFiles({ isActive: true }))
  listenerApi.dispatch(FilesDriveActions.setLoading(false))
}

/**
 * Performs cleanup operations after file/folder operations complete.
 * Resets UI state and refreshes folder items.
 *
 * @param listenerApi - The RTK listener API for dispatching actions
 * @param currentFolderId - The current folder ID to refresh
 * @param driveType - The type of drive (user or organization)
 * @param organizationId - Optional organization ID for org drives
 */
const performFileOperationCleanup = (
  listenerApi: ListenerEffectAPI<unknown, AppDispatch, unknown>,
  currentFolderId: string | null,
  driveType: DriveType,
  organizationId?: string
): void => {
  // Close dialog and clear dialog UI State
  listenerApi.dispatch(setFilesDriveDialogVisible(false))
  listenerApi.dispatch(setFilesDriveDialogType(null))

  // Clear active item
  listenerApi.dispatch(FilesDriveActions.clearActiveFile())

  // Clear selected items
  listenerApi.dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files_ids: [], folders_ids: [] }))

  // Fetch items again to update the UI
  if (driveType === DriveType.ORG && organizationId) {
    listenerApi.dispatch(
      FilesDriveActions.fetchOrgItems({
        organizationId: organizationId,
      })
    )
  } else {
    listenerApi.dispatch(
      FilesDriveActions.fetchItems({
        folderId: currentFolderId ?? 'root',
      })
    )
  }
}
