import { useAppDispatch } from '@/store/store-hooks'
import { useNavigate } from 'react-router-dom'
import { ChatV2Actions, ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import postCreateChat from '../fetch/post-create-chat'
import { ConversationHeader } from '@/chat-common/conversation_list/schema'

/**
 * Create New Chat Hook
 * Hook to create a new chat conversation
 * @returns {createNewChat} function to create a new chat
 */
export function useCreateNewChat() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  /**
   * Create New Chat
   *
   * Handles:
   * 1. Request new chat from server
   * 2. Construct the new chat object with default values
   * 2. Inserting the new chat into the state
   *
   * @param chatFeature
   * @param navigateOnSuccess
   * @param onSuccess
   * @param onError
   * @returns The created conversation header
   */
  const createNewChat = async (
    chatFeature: ChatV2Feature,
    navigateOnSuccess: boolean,
    onSuccess?: () => void,
    onError?: (e: any) => void
  ): Promise<ConversationHeader | undefined> => {
    try {
      // Ask the server for a new conversation
      const response: ConversationHeader = await postCreateChat(chatFeature)

      // Insert it into state
      dispatch(ChatV2Actions.upsertConversationFromHeader(response))

      // Refresh the conversation list
      dispatch(ChatV2Actions.refreshConversations({ forceRefresh: true }))

      // Call the onSuccess callback
      if (onSuccess) onSuccess()

      // If the navigateOnSuccess flag is set, navigate to the new chat
      if (navigateOnSuccess) navigate(`/dashboard/chat/${response.feature}/${response.id}`)
      
      return response
    } catch (e) {
      // Call the onError callback
      if (onError) onError(e)
      return undefined
    }
  }

  return { createNewChat }
}
