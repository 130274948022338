import React from 'react'
import { getBrandName } from '@/util/enterprise'
import { ClientAgentEventRead } from '@/agent/events/store/schemas'
import { ArtifactVersionRead, ArtifactVersionStatusType } from '@/artifacts/schemas'
import { artifactNameFallback } from '@/constants/constants-artifact'
import { ClientAgentReference } from '@/agent/references/store/schemas'
import EventTextMarkdownPrintable from './EventTextMarkdownPrintable'
import { CitationIdHighlightCount } from '@/agent/references/store/slice'
import AgentResponseReferencesPrintableSection from './AgentResponseReferencesPrintableSection'

interface AgentResponseEventPrintableBubbleProps {
  event: ClientAgentEventRead
  artifactVersion?: ArtifactVersionRead | null
  references: ClientAgentReference[]
  turnUniqueCitationAndHighlightCount: CitationIdHighlightCount
}

/**
 * Agent Response Event Printable Bubble
 *
 * Renders the event as a printable bubble, including:
 * - A header with the brand name.
 * - The event's text rendered as Markdown.
 * - A references section.
 */
const AgentResponseEventPrintableBubble: React.FC<AgentResponseEventPrintableBubbleProps> = (props: AgentResponseEventPrintableBubbleProps) => {
  const { event, artifactVersion, turnUniqueCitationAndHighlightCount, references } = props

  const turnUniqueCitationIds = Object.keys(turnUniqueCitationAndHighlightCount)

  const messageHeader = getBrandName()

  const getArtifactStatusText = (artifactVersion: ArtifactVersionRead) => {
    switch (artifactVersion.status) {
      case ArtifactVersionStatusType.GENERATING:
        return 'Generating...'
      case ArtifactVersionStatusType.COMPLETED:
        return artifactVersion.name || artifactNameFallback
      case ArtifactVersionStatusType.ERROR:
        return 'Failed to generate draft.'
      case ArtifactVersionStatusType.CANCELLED:
        return 'Draft generation canceled.'
      default:
        return ''
    }
  }
  // If the event is missing or the value is empty, don't render the bubble
  if (!event || !event.value.trim()) {
    return null
  }

  return (
    <div>
      {/* Header */}
      <h2>{messageHeader}</h2>

      {/* Event text rendered as printable Markdown */}
      <EventTextMarkdownPrintable event={event} turnUniqueCitationAndHighlightCount={turnUniqueCitationAndHighlightCount} />

      {/* Artifact representation if the event included artifact generation */}
      {artifactVersion && (
        <>
          <p>
            <i>Artifact: {getArtifactStatusText(artifactVersion)}</i>
          </p>
          {artifactVersion.status === ArtifactVersionStatusType.COMPLETED && (
            <p>Your draft is ready for review and may be accessed via the artifact above. Is there anything you would like clarified or modified?</p>
          )}
        </>
      )}

      {/* References Section */}
      <AgentResponseReferencesPrintableSection responseMessage={event.value} turnUniqueCitationIds={turnUniqueCitationIds} references={references} />
    </div>
  )
}

export default AgentResponseEventPrintableBubble
