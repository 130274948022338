import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { Source } from '@mui/icons-material'
import DraftingChatDialog from '../source-dialogs/drafting-chat-dialog'
import { useState } from 'react'
import { ChatV2QueryMetadataDrafting } from '@/chat-common/schemas/chat-query-metadata-schema'
import { AlertErrorCircle } from '@/assets/icons'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { brandedAIFriendlyName } from '@/util/enterprise'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'

type ChatControlsDraftingChatProps = {
  conversationId: string
}

export default function ChatControlsDraftingChat(props: ChatControlsDraftingChatProps) {
  const { conversationId } = props
  const { trackEvent } = useAnalytics()

  // Local state for source window status
  const [sourceWindowOpen, setSourceWindowOpen] = useState<boolean>(false)

  // Source information from the conversation
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataDrafting | null

  // Count the number of source fields present except for the key "feature"
  const keys = Object.keys(currentSource ?? {}).filter(
    (key) => key !== 'feature' && key !== 'selected_files' && key !== 'focus_mode' // irrelevant keys showing up in this metadata
  ) as (keyof ChatV2QueryMetadataDrafting)[]

  // Count the number of source fields present that are not empty strings
  let sourceFieldsCount = 0
  if (currentSource != null) {
    keys.forEach((key) => {
      const value = currentSource[key]
      if (typeof value === 'string' && value.trim().length > 0) {
        sourceFieldsCount++
      }
    })
  }

  return (
    <>
      <div className="mb-0 pb-0">
        <div className="rounded-lg bg-yellow-50 p-4 my-4 grid grid-cols-[45px_auto] items-center">
          <div className={'flex h-7 w-7 text-yellow-700 bg-yellow-50 rounded-lg p-1 ring-4 ring-white items-center justify-center'}>
            <AlertErrorCircle />
          </div>
          <span className={'text-xs text-yellow-900 text-opacity-70'}>
            This tool does not have access to external research materials.
            <br />
            Utilize {brandedAIFriendlyName}'s research tools for information on laws, regulations, court cases, etc.
          </span>
        </div>
      </div>
      <DraftingChatDialog conversationId={conversationId} open={sourceWindowOpen} onClose={(value) => setSourceWindowOpen(value)} />
      <button
        onClick={() => {
          trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.drafting })
          setSourceWindowOpen(true)
        }}
        className="w-auto rounded-md text-sm px-0 font-semibold text-brand-500 hover:underline"
      >
        Configure additional document settings (optional) <Source fontSize="small" />
      </button>
      <div className={'mb-2'}>
        <p className="text-sm text-brand-neutral-500">
          {sourceFieldsCount > 0 ? `(${sourceFieldsCount} setting${sourceFieldsCount > 1 ? 's' : ''} present)` : ''}
        </p>
      </div>
    </>
  )
}
