import { FileNode, FileStatus } from '../schemas/files-drive-schema'
import { DynamicStatusIconFromFile } from '@/chat-common/components/source-dialogs/utils/file-status'
import FileActionsMenu from './FileActionsMenu'
import { FilesDriveDialogType } from '@/store/slices/ui-state.slice'
import { formatDate, formatSize } from '../helpers/formatNumbers'
import { FilesDriveActions } from '../store/files-drive.slice'
import { useAppDispatch } from '@/store/store-hooks'

interface FileListItemProps {
  file: FileNode
  isSelected: (itemId: string) => boolean
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDialogOpen: (dialogType: FilesDriveDialogType) => void
  onDownload: (file: FileNode) => Promise<void>
}

export default function FileListItem({
  file,
  isSelected,
  onSelect,
  onDialogOpen,
  onDownload,
}: FileListItemProps) {
  const { status, id, name, error, size, created_at } = file

  const dispatch = useAppDispatch()

  // Set the active file in the Redux store for the FileActionsMenu actions.
  const handleFileAction = (file: FileNode) => {
    dispatch(FilesDriveActions.setActiveFile(file))
  }

  const isDisabled = status === FileStatus.PROCESSING

  return (
    <div className="group border-t-[1px] py-[6px] grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden hover:bg-brand-100">
      <div className={'px-2 grid grid-cols-[22px_30px_auto] items-center'}>
        <input
          type="checkbox"
          checked={isSelected(id)}
          onChange={onSelect}
          value={id}
          disabled={isDisabled}
          className={'w-4 h-4 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm disabled:opacity-50 disabled:cursor-not-allowed'}
          onClick={(e) => e.stopPropagation()}
        />
        <div>{DynamicStatusIconFromFile(status)}</div>
        <div className={'text-sm overflow-hidden flex items-center'}>
          <p className={'flex-grow'}>
            {name}
            {error ? <span className={'text-red-700 ml-1'}>Error Processing File</span> : ''}
          </p>
          <FileActionsMenu
            isDisabled={isDisabled}
            onOpen={() => handleFileAction(file)}
            onRenameClick={() => onDialogOpen(FilesDriveDialogType.RENAME)}
            onDownloadClick={() => onDownload(file)}
            onDeleteClick={() => onDialogOpen(FilesDriveDialogType.DELETE)}
          />
        </div>
      </div>
      <p className={'text-sm px-2 hidden sm:block'}>{formatDate(created_at)}</p>
      <p className={'text-sm px-2 hidden md:block'}>{formatSize(size)}</p>
    </div>
  )
} 