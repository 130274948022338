import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectLegacyConversationVisibleReferenceExists } from '@/chat-common/store/chat-v2.selectors'
import LegacyReferenceViewer from '@/chat-common/components/reference-viewer/reference-viewer'
import { ContentViewerType } from '../store/slice'
import { constructArtifactFilePath } from '@/artifacts/util/construct-artifact-file-path'
import { kGcsArtifactsBucketRef, kGcsUserMarkdownFilesBucketRef } from '@/constants/constants-gcs'
import { agentReferenceFootnoteNumber, agentReferenceMetadataToTitle } from '@/agent/references/util/agent-reference-utils'
import ContentViewerMarkdown from './content-viewer-markdown'
import { artifactNameFallback } from '@/constants/constants-artifact'
import downloadArtifactButtonFunction from '../util/download-button-func-artifact'
import ContentViewerPDF from './content-viewer-pdf'
import { AgentReferenceType } from '@/agent/references/store/schemas'

type ContentViewerConstructorProps = {
  conversationId: string
}

/**
 * Content Viewer Constructor
 *
 * Determines the mode of the content viewer (ContentViewerType),
 * and returns the correct layout and rendering components.
 */
export default function ContentViewerConstructor(props: ContentViewerConstructorProps) {
  const { conversationId } = props

  // Legacy chat visible reference
  const legacyReferenceIsVisible = useAppSelector((state: RootState) => selectLegacyConversationVisibleReferenceExists(state, { chatId: conversationId }))

  // Agent conversation content viewer containing an artifact or reference
  const contentViewer = useAppSelector((state: RootState) => state.contentViewerState)
  const contentViewerContent = contentViewer.content

  if (!contentViewerContent && !legacyReferenceIsVisible) {
    return null
  }

  // If it's a legacy chat with a visible reference, show the legacy reference viewer
  if (legacyReferenceIsVisible) {
    return <LegacyReferenceViewer conversationId={conversationId} />
  }

  // If it's an agent conversation with a content viewer, show the correct viewer based on the mode
  if (contentViewerContent) {
    switch (contentViewerContent.type) {
      case ContentViewerType.ARTIFACT: {
        const data = contentViewerContent.data
        const filePath = constructArtifactFilePath(data.id)
        const title = data.name || artifactNameFallback

        // Create a wrapper function that captures the artifact ID and conforms to the expected interface
        const download = (onLoading: (isLoading: boolean) => void, onError: (error: Error) => void) => {
          return downloadArtifactButtonFunction(data.id, onLoading, onError)
        }

        return (
          <ContentViewerMarkdown
            conversationId={conversationId}
            filePath={filePath}
            title={title}
            gcsBucketName={kGcsArtifactsBucketRef}
            downloadButtonFunction={{ download }}
          />
        )
      }

      case ContentViewerType.AGENT_REFERENCE_MD: {
        const data = contentViewerContent.data
        const filePath = data.reference.metadata.file_path

        const citationHighlightIndex = data.citationHighlightIndex
        const highlights = citationHighlightIndex === undefined ? undefined : data.reference.highlighted_citations[citationHighlightIndex]?.sentences

        const titlePrefix = agentReferenceFootnoteNumber(data.reference) ?? ''
        const title = `[${titlePrefix}] ${agentReferenceMetadataToTitle(data.reference)}`

        return (
          <ContentViewerMarkdown
            conversationId={conversationId}
            filePath={filePath}
            title={title}
            highlights={highlights}
            gcsBucketName={kGcsUserMarkdownFilesBucketRef}
          />
        )
      }

      case ContentViewerType.AGENT_REFERENCE_PDF: {
        const data = contentViewerContent.data

        // Default starting page to 0
        let startingPage = 0
        if (data.reference.type === AgentReferenceType.FILE_UPLOAD_CHUNK) {
          startingPage = data.reference.metadata.page_nums[0] ?? 0
        }

        const citationHighlightIndex = data.citationHighlightIndex
        const highlights = citationHighlightIndex === undefined ? [] : data.reference.highlighted_citations[citationHighlightIndex]?.highlights ?? []

        return <ContentViewerPDF data={data} highlights={highlights} startingPage={startingPage} />
      }
      default:
        return null
    }
  }

  return null
}
