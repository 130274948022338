import { AgentConversationsActions } from '@/agent/conversations/store/slice'

import { agentReferenceMetadataToTitle } from '@/agent/references/util/agent-reference-utils'
import { selectIdentifierMatchesActiveContentViewer } from '@/content-viewer/store/selectors'
import { ContentViewerActions, ContentViewerReferenceData } from '@/content-viewer/store/slice'

import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import AgentResponseReferencesListItemHighlightButton from './AgentResponseReferencesListItemHighlightButton'
import AgentResponseReferencesListItemPages from './AgentResponseReferencesListItemPages'

type AgentResponseReferencesListItemProps = {
  citation_id: string
  citationIdIndex: number
  citationIdHighlightCount: number
}

export default function AgentResponseReferencesListItem(props: AgentResponseReferencesListItemProps) {
  const { citation_id, citationIdIndex, citationIdHighlightCount } = props
  const dispatch = useAppDispatch()

  // Watch whether this is the active reference
  const isActive = useAppSelector((state: RootState) => selectIdentifierMatchesActiveContentViewer(state, { id: citation_id }))

  // Get this reference
  const reference = useAppSelector((state: RootState) => state.agentReferencesState.references[citation_id])
  if (!reference) {
    return null
  }

  // Get the conversation id from the reference
  const conversationId = reference.conversation_id

  // Handle viewing the reference with no specific highlight
  const handleViewReference = (citationHighlightIndex?: number) => {
    // Construct the ContentViewerReferenceData needed by the content viewer
    const referenceData: ContentViewerReferenceData = { reference, citationHighlightIndex }

    // Set the content viewer state with the reference
    dispatch(ContentViewerActions.setAgentReference({ referenceData }))

    // Set the active content viewer for the conversation
    dispatch(AgentConversationsActions.setConversationActiveContentViewerReference({ conversationId, referenceData }))
  }

  // Create the label
  // If not found, use the citation_id
  // If found, add 1 to the index to make it human readable
  const footnoteNumber = citationIdIndex + 1

  const title = agentReferenceMetadataToTitle(reference)

  return (
    <div
      className={`flex flex-col space-y-1 text-left w-full text-xs p-2 mb-2 rounded-md ${
        isActive ? 'bg-brand-400 bg-opacity-20' : 'hover:bg-brand-neutral-100'
      }`}
    >
      <button className={'text-left group'} onClick={() => handleViewReference()}>
        <div className="compatible-word-break font-bold text-black group-hover:text-brand-500 cursor-pointer">
          [{footnoteNumber}]&nbsp;{title}
        </div>
        <div className="text-brand-neutral-600">
          <AgentResponseReferencesListItemPages reference={reference} />
        </div>
      </button>
      {/* Render Highlight Links */}
      {citationIdHighlightCount > 0 && (
        <div className={'flex gap-x-1 text-brand-neutral-700'}>
          <div className={'whitespace-nowrap'}>Highlights: </div>
          <div className={'flex flex-wrap gap-2'}>
            {Array.from({ length: citationIdHighlightCount }).map((_, highlightIndex) => (
              <AgentResponseReferencesListItemHighlightButton
                key={highlightIndex}
                citation_id={citation_id}
                citationHighlightIndex={highlightIndex}
                footnoteNumber={footnoteNumber}
                onClick={handleViewReference}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
