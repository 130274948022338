import { FileNode } from '../schemas/files-drive-schema'
import FileListItem from './FileListItem'
import { FilesDriveActions } from '../store/files-drive.slice'
import { useAppDispatch } from '@/store/store-hooks'
import { FilesDriveDialogType } from '@/store/slices/ui-state.slice'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

type FileListProps = {
  files: FileNode[]
  selectedFiles: string[]
  handleDialogOpen: (dialogType: FilesDriveDialogType) => void
  handleFileDownload: (file: FileNode) => Promise<void>
  isItemSelected: (itemId: string) => boolean
  loading: boolean
}

export default function FilesList({ files, handleDialogOpen, handleFileDownload, isItemSelected, loading }: FileListProps) {

  const dispatch = useAppDispatch()

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const { value: itemId } = e.currentTarget // value = id of the item. 

    dispatch(FilesDriveActions.setSelectedFiles({
      files_ids: [itemId]
    }))
  }

  return (
    <>
      {files.map((file) => (
        <FileListItem
          key={file.id}
          file={file}
          isSelected={isItemSelected}
          onSelect={handleSelectFile}
          onDialogOpen={handleDialogOpen}
          onDownload={handleFileDownload}
        />
      ))}
      {/* Loading state - early return if loading */}
      {loading && (
        <div className="flex items-center justify-center h-full py-4">
          <CircularProgressContinuousSized size={20} thickness={5} />
        </div>
      )}
    </>
  )
}
