import { z } from 'zod'

/**
 * Core enums for file drive system
 */
export enum NodeType {
  FILE = 'file',
  FOLDER = 'folder',
}

export enum FileStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
  ERROR = 'error',
  COMPLETE = 'complete',
  // OCR = 'ocr', To be implemented in the BE
  //TODO: remove when new file drive is deployed - Check if we need for backwards compatibility
}

export interface ProcessedFolderData {
  folders: FolderNode[]
  files: FileNode[]
  folder: FolderNode
}

/**
 * Enum for drive types
 */
export enum DriveType {
  USER = 'my-drive',
  ORG = 'org-drive',
}

/**
 * Schema definitions for files and folders
 */
export const DriveFileSchema = z.object({
  id: z.string().uuid(),
  user_id: z.string(),
  name: z.string(),
  folder_path: z.string(),
  folder_id: z.string().uuid().nullable(),
  source_location: z.string(),
  markdown_location: z.string(),
  size: z.number(),
  status: z.nativeEnum(FileStatus),
  extension: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  events: z.any().nullable(),
  parse_file_id: z.any().nullable(),
  parse_file_sentences_id: z.any().nullable(),
  error: z.any().nullable(),
  type: z.literal(NodeType.FILE).optional(),
})

export const DriveFolderSchema: z.ZodType<{
  id: string
  user_id: string
  name: string
  folder_path: string
  folder_id: string | null
  full_visual_path: string
  type?: NodeType.FOLDER
  files?: z.infer<typeof DriveFileSchema>[]
  sub_folders?: z.infer<typeof DriveFolderSchema>[]
}> = z.lazy(() =>
  z.object({
    id: z.string().uuid(),
    user_id: z.string(),
    name: z.string(),
    folder_path: z.string(),
    folder_id: z.string().uuid().nullable(),
    full_visual_path: z.string(),
    type: z.literal(NodeType.FOLDER).optional(),
    files: z.array(DriveFileSchema).optional(),
    sub_folders: z.array(DriveFolderSchema).optional(),
  })
)

/**
 * Type definitions
 */
export type FilesDriveResponse = z.infer<typeof DriveFileSchema>
export type FolderDriveResponse = z.infer<typeof DriveFolderSchema>

// Type added after payload data is validated
export type FolderNode = z.infer<typeof DriveFolderSchema> & { type: NodeType.FOLDER }
export type FileNode = z.infer<typeof DriveFileSchema> & { type: NodeType.FILE }

/**
 * Type for item nodes - either folder or file
 */
export type ItemNode = FolderNode | FileNode
