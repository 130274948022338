import { useAppDispatch } from '@/store/store-hooks'
import { useNavigate } from 'react-router-dom'
import postCreateAgentChat from '../fetch/post-create-agent-chat'
import { AgentConversationRead } from '@/agent/conversations/store/schemas'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'

/**
 * Create New Chat Hook
 * Hook to create a new chat conversation
 * @returns {createNewAgentChat} function to create a new chat
 */
export function useCreateNewAgentChat() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  /**
   * Create New Chat
   *
   * Handles:
   * 1. Request new chat from server
   * 2. Construct the new chat object with default values
   * 2. Inserting the new chat into the state
   *
   * @param navigateOnSuccess
   * @param onSuccess
   * @param onError
   */
  const createNewAgentChat = async (navigateOnSuccess: boolean, onSuccess?: () => void, onError?: (e: any) => void) => {
    try {
      // Ask the server for a new conversation
      const response: AgentConversationRead = await postCreateAgentChat()

      // Insert it into state
      dispatch(AgentConversationsActions.upsertConversation(response))

      // Call the onSuccess callback
      if (onSuccess) onSuccess()

      // If the navigateOnSuccess flag is set, navigate to the new chat
      if (navigateOnSuccess) navigate(`/dashboard/chat/assistant/${response.id}`)

      return response
    } catch (e) {
      // Call the onError callback
      if (onError) onError(e)
    }
  }

  return { createNewAgentChat }
}
