/**
 * Highlight Markdown HTML String
 *
 * Edits the HTML string to apply highlighting to the specified sentences.
 *
 * @param htmlString - The HTML string to highlight.
 * @param markdownSentencesToHighlight - The sentences to highlight from the original markdown.
 * @returns
 */

import htmlTokenSequenceHighlighter from '@/util/html-processing/html-token-sequence-highlighter'
import { convertMarkdownToHtml } from './markdown-to-html'

export default async function highlightMarkdownHtmlString(htmlString: string, markdownSentencesToHighlight: string[]): Promise<string> {
  // For each sentence to highlight
  // - Process the markdown into HTML in the same manner as the original markdown
  const htmlHighlights = await Promise.all(markdownSentencesToHighlight.map((sentence) => convertMarkdownToHtml(sentence)))

  // Keep for debug logging of the sentences.
  // for (const sentence of markdownSentencesToHighlight) {
  //   const sentenceHtml = convertMarkdownToHtml(sentence)
  //   console.log(`Converted md: ${sentence}\nto html: ${sentenceHtml}`)
  // }

  const highlightedHtml = htmlTokenSequenceHighlighter(htmlString, htmlHighlights)
  return highlightedHtml
}
