import { RootState } from '@/store/store'
import { AgentEventType, ClientAgentEventRead } from './schemas'
import { createSelector, createSelectorCreator, lruMemoize } from '@reduxjs/toolkit'
import { AgentConversationEventsRecord, AgentEventFollowUpQuestions } from './slice'
import { selectConversationWebSocketConnected } from '@/websocket_v2/ws2.selectors'

/**
 * Conversation Event Ids List Equality
 * Purpose: Avoids re-rendering every time the Record of Agent Events is updated
 *          by comparing the previous and next lists of event ids.
 *
 * What triggers a re-render
 * - If the length of the previous and next lists are different (event added or removed)
 * - If the keys (event ids) in the previous and next lists are different
 */
const conversationEventIdsListEquality = (prevState: AgentConversationEventsRecord, nextState: AgentConversationEventsRecord): boolean => {
  // If either is null, they are not equal
  if (!prevState || !nextState) return false

  // Convert each to a list of event id keys
  const prevKeys = Object.keys(prevState)
  const nextKeys = Object.keys(nextState)

  // If the lengths are different, they are not equal
  if (prevKeys.length !== nextKeys.length) return false

  // Sort both key arrays
  prevKeys.sort()
  nextKeys.sort()

  // Check if each key is identical in sorted order
  for (let i = 0; i < prevKeys.length; i++) {
    if (prevKeys[i] !== nextKeys[i]) return false
  }

  // If we reach here, we have the exact same set of keys
  return true
}

/**
 * Selector Creator
 * A custom selector creator that uses the custom equality operator
 */
const createConversationEventIdsListSelector = createSelectorCreator(lruMemoize, conversationEventIdsListEquality)

/**
 * Select: Agent Event by ID
 *
 * Returns the agent event with the given id, or null if it does not exist.
 */
export const selectAgentEventById = (state: RootState, props: { conversationId: string; eventId: string }): ClientAgentEventRead | null => {
  const { conversationId, eventId } = props

  return state.agentEventsState.events[conversationId]?.[eventId] ?? null
}

/**
 * Select: Agent Conversation Has Events
 *
 * Returns true if the agent conversation has at least one event.
 */
export const selectAgentConversationHasEvents = (state: RootState, props: { conversationId: string }): boolean => {
  const { conversationId } = props

  const events = state.agentEventsState.events[conversationId]
  if (!events) return false

  return Object.keys(events).length > 0
}

/**
 * Select: List Of Sorted Event IDs
 *
 * @returns {string[]} List of event IDs in ascending order of timestamp
 */
export const selectSortedConversationEventIds = createConversationEventIdsListSelector(
  (state: RootState, props: { conversationId: string }) => selectEventsForConversation(state, props),
  (eventsRecord) => {
    if (!eventsRecord) return []

    // Convert the Record of events to an array (filter required for TS)
    const events = Object.values(eventsRecord).filter(isDefinedAgentEventWithId)

    // Sort the events by timestamp in ascending order (newest at bottom)
    events.sort((a, b) => {
      if (a && b) {
        return a.created_at - b.created_at
      }
      return 0
    })

    // Return the list of event ids
    return events?.map((event) => event.id) ?? []
  },
  {
    memoizeOptions: {
      equalityCheck: conversationEventIdsListEquality,
    },
  }
)

/**
 * Select: Most Recent Event ID
 *
 * @returns {string} returns the most recent event id
 */
export const selectMostRecentConversationEventId = createConversationEventIdsListSelector(
  (state: RootState, props: { conversationId: string }) => selectEventsForConversation(state, props),
  (eventsRecord) => {
    if (!eventsRecord) return null

    // Convert the Record of events to an array (filter required for TS)
    const events = Object.values(eventsRecord).filter((event) => !!event)

    // Sort the events by timestamp in ascending order (newest at bottom)
    events.sort((a, b) => {
      if (a && b) {
        return a.created_at - b.created_at
      }
      return 0
    })

    // Return the most recent event id
    return events[events.length - 1]?.id ?? null
  },
  {
    memoizeOptions: {
      equalityCheck: conversationEventIdsListEquality,
    },
  }
)

/**
 * A stable empty events record.
 *
 * This constant is used to provide a consistent empty object reference whenever there are no events
 * available for a given conversation. By using this predefined reference instead of returning a new
 * object (`{}`) each time, we:
 *
 * - Prevent unnecessary re-renders and recalculations in selectors that depend on conversation events.
 * - Maintain memoization in `createSelector`, ensuring selectors only recompute when the actual state changes.
 *
 * Any selector that needs to return an empty events record should use this constant
 * instead of creating a new `{}` to ensure stability across renders.
 */
const EMPTY_EVENTS: Partial<Record<string, ClientAgentEventRead>> = {}

/**
 * Selector: All Events for a Conversation
 *
 * Retrieves all events for a given conversation.
 * If there are no events, it returns a stable empty object reference.
 */
export const selectEventsForConversation = (state: RootState, props: { conversationId: string }): Partial<Record<string, ClientAgentEventRead>> => {
  return state.agentEventsState.events[props.conversationId] ?? EMPTY_EVENTS
}

/**
 * Select: Show Conversation Loading Bubble
 *
 * Determines whether to show a loading bubble for a conversation.
 * Returns true if:
 * - The conversation has an open WebSocket connection
 * - The most recent event is
 *   - a user query, or
 *   - an agent response with an empty value
 */
export const selectShowEventLoadingBubble = createSelector(
  [
    // 1) Is the WebSocket connected?
    (state: RootState, props: { conversationId: string }) => selectConversationWebSocketConnected(state, { conversationId: props.conversationId }),

    // 2) The id of the most recent event in the conversation
    (state: RootState, props: { conversationId: string }) => selectMostRecentConversationEventId(state, { conversationId: props.conversationId }),

    // 3) The events record for the conversation
    (state: RootState, props: { conversationId: string }) => selectEventsForConversation(state, { conversationId: props.conversationId }),
  ],
  (isConnected, mostRecentEventId, eventsRecord) => {
    // If no connection or no events
    if (!isConnected || !mostRecentEventId) return false

    // Get the most recent event
    const event = eventsRecord[mostRecentEventId]
    if (!event) return false

    // Most recent event is a user query
    if (event.type === AgentEventType.USER_QUERY) return true

    // Most recent event is an agent response with empty value
    if (event.type === AgentEventType.AGENT_RESPONSE && event.value.trim() === '') {
      return true
    }

    return false
  }
)

function isDefinedAgentEventWithId(event: Partial<ClientAgentEventRead> | null | undefined): event is ClientAgentEventRead {
  return !!event && event.id != null
}

/**
 * Select: Agent Event Follow Up Questions
 *
 * Retrieves the follow up questions state for an agent event, or null if it does not exist.
 */
export const selectAgentEventFollowUpQuestions = (state: RootState, props: { conversationId: string; eventId: string }): AgentEventFollowUpQuestions | null => {
  return state.agentEventsState.followUpQuestions[props.conversationId]?.[props.eventId] ?? null
}

/**
 * Select: Sorted Conversation Events
 *
 * Retrieves all conversation events for a given conversation and returns them
 * sorted by their creation timestamp in ascending order (oldest first).
 */
export const selectSortedConversationEvents = createSelector(
  // Get the events record for the conversation
  (state: RootState, props: { conversationId: string }) => selectEventsForConversation(state, { conversationId: props.conversationId }),
  (eventsRecord): ClientAgentEventRead[] => {
    if (!eventsRecord) return []

    // Convert the record of events into an array and filter out any undefined/null events.
    const events = Object.values(eventsRecord).filter(isDefinedAgentEventWithId)

    // Sort the events by creation timestamp (ascending order).
    events.sort((a, b) => a.created_at - b.created_at)

    return events
  }
)
