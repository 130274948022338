import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useState } from 'react'

export type OrgAdminConfirmationDialogProps = {
  title: string
  message: string
  visible: boolean
  successMessage?: string
  onConfirm: () => Promise<void>
  closeDialog: () => void
  refreshData: () => void
}

export default function OrgAdminConfirmationDialog(props: OrgAdminConfirmationDialogProps) {
  const { title, message, visible, successMessage, onConfirm, closeDialog, refreshData } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px]">
        <h2 className="text-lg font-semibold">{title}</h2>
        <p className="mt-2 text-sm whitespace-pre-wrap">{message}</p>
        {error && <p className={'mt-2 text-sm text-red-700'}>{error}</p>}
        {showSuccess && <p className={'mt-2 text-sm text-brand-700 font-bold'}>{successMessage}</p>}
        <div className={'h-5'} />
        {showSuccess && (
          <div className={'flex gap-x-4 justify-end'}>
            <button
              className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
              onClick={async () => {
                closeDialog()
              }}
            >
              <div>Close</div>
              {loading && <CircularProgressContinuousSized size={12} thickness={7} hexColor={'#ffffff'} />}
            </button>
          </div>
        )}
        {!showSuccess && (
          <div className={'flex gap-x-4 justify-end'}>
            <button
              className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-100"
              onClick={closeDialog}
            >
              Cancel
            </button>
            <button
              className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
              onClick={async () => {
                setLoading(true)
                setError(null)
                try {
                  await onConfirm()

                  // Show success message or close dialog once complete
                  if (successMessage) {
                    setShowSuccess(true)
                  } else {
                    closeDialog()
                  }
                } catch (error: any) {
                  if (error.status === 404) {
                    setError('404: This resource may have already been deleted.')
                  } else if (error.status === 410) {
                    setError('This resource may have already been completed.')

                    // Introduce an artificial delay
                    await new Promise((resolve) => setTimeout(resolve, 1000))

                    await refreshData()
                  } else {
                    setError('There was an error processing your request.')
                  }
                } finally {
                  setLoading(false)
                }
              }}
            >
              <div>Confirm</div>
              {loading && <CircularProgressContinuousSized size={12} thickness={7} hexColor={'#ffffff'} />}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
