import { AgentReferenceType, ClientAgentReference, ReferenceMetadataFileUploadChunkRead } from '@/agent/references/store/schemas'

type AgentResponseReferencesListItemPagesProps = {
  reference: ClientAgentReference
}

export default function AgentResponseReferencesListItemPages(props: AgentResponseReferencesListItemPagesProps) {
  const { reference } = props

  // Only renders for FILE_UPLOAD_CHUNK references
  if (reference.type !== AgentReferenceType.FILE_UPLOAD_CHUNK) {
    return null
  }

  const metadata = reference.metadata as ReferenceMetadataFileUploadChunkRead
  const page_nums: number[] = metadata.page_nums
  const page_index_from_one = page_nums.map((page_num) => page_num + 1)

  switch (page_index_from_one.length) {
    case 0:
      return null

    case 1:
      return <div>Page {page_index_from_one[0]}</div>

    default:
      return <div>Pages {page_index_from_one.join(', ')}</div>
  }
}
