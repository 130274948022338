import { selectAgentConversationExists } from '@/agent/conversations/store/selectors'
import { putResponseFeedback } from '@/agent/response_metadata/fetch/put-response-feedback'
import putMessageFeedback from '@/chat-common/fetch/put-message-feedback'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { RootState } from '@/store/store'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

type MessageFeedbackDialogProps = {
  conversationId: string
  eventId?: string
  messageId?: string
  positive: boolean
  visible: boolean
  onClose: () => void
}

/**
 * The MessageFeedbackDialog handles fetching and displaying of the Stripe portal Link.
 * This is to decouple the logic from the buttons that may activate this UI.
 */
export default function MessageFeedbackDialog(props: MessageFeedbackDialogProps) {
  const { conversationId, eventId, messageId, positive, visible, onClose } = props

  const isAgentConversation = useSelector((state: RootState) => selectAgentConversationExists(state, { conversationId }))

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [text, setText] = useState<string>('')

  // Ref for the textarea to enable autofocus
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  // Autofocus the textarea when the dialog becomes visible
  useEffect(() => {
    if (visible && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [visible])

  // Handle submit
  async function handleSubmit() {
    if (!loading) {
      try {
        setError(null)
        setLoading(true)

        // Thumb
        const thumb: FeedbackThumb = positive ? FeedbackThumb.UP : FeedbackThumb.DOWN

        // Handle feedback submission for agent conversations
        if (isAgentConversation) {
          if (!eventId) {
            throw Error('Event ID is required to submit feedback for agent conversations.')
          }
          console.log('Handling feedback submission for event: ', eventId)
          putResponseFeedback({ eventId: eventId, thumb: thumb, thumbText: text })
        } else {
          // Handle feedback submission for legacy conversations
          if (!messageId) {
            throw Error('Message ID is required to submit feedback for legacy conversations.')
          }
          console.log('Handling feedback submission for message: ', messageId)
          putMessageFeedback({ messageId: messageId, thumb: thumb, thumb_text: text })
        }

        // Perform a 1 second delay
        await new Promise((resolve) => setTimeout(resolve, 1000))

        onClose()
      } catch (error) {
        console.error(error)
        setError('There was an error submitting your feedback. Please try again.')
      } finally {
        setLoading(false)
      }
    }
  }

  // Do not show if not visible
  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-2xl sm:min-w-[600px]">
        <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
          {positive ? (
            <HandThumbUpIcon className="h-10 p-2 rounded-full text-green-700 bg-green-100" />
          ) : (
            <HandThumbDownIcon className="h-10 p-2 rounded-full text-red-700 bg-red-100" />
          )}{' '}
          Provide feedback
        </h2>
        <textarea
          ref={textareaRef}
          className={`w-full h-28 rounded-md`}
          placeholder={`${positive ? 'What did you like about the response?' : 'What was the issue with the response? How could it be improved?'}`}
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>

        <div className={'h-4'} />
        <div className={'flex gap-x-4 justify-end items-center'}>
          <button
            className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
            onClick={onClose}
          >
            Cancel
          </button>

          {/* Loading complete, real link */}

          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
            onClick={handleSubmit}
          >
            <div className={'flex items-center gap-x-1'}>
              <div>Submit feedback</div>
              <ButtonLoader loading={loading} />
            </div>
          </button>
        </div>
        {error && <div className={'mt-4 text-center text-red-700 text-sm'}>{error}</div>}
      </div>
    </div>
  )
}
