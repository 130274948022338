import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { HelpCircle } from '@/assets/icons'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { useHubspotConversations } from '@/context/hubspot-conversations-provider'
import { kSegmentTrackOpenSupportChatClicked } from '@/constants/constants-segment'
import DashboardSidebarGroup from './components/dashboard-sidebar-group'
import { DashboardSidebarItemType } from './components/dashboard-sidebar-item'

type SidebarItemSupportProps = {
  setSidebarOpen: (open: boolean) => void
}

export default function SidebarItemSupport({ setSidebarOpen }: SidebarItemSupportProps) {
  const { toggleWidget } = useHubspotConversations()

  // Auth Context
  const authContext = useContext(AuthContext)
  const { user, userAccountData } = authContext
  const userIsAnon = user?.isAnonymous
  const brand = userAccountData?.brand ?? BrandEnum.NONE

  // Support options for anonymous users
  const supportOptions: DashboardSidebarItemType[] = [
    {
      name: 'Chat with support',
      href: '#',
      icon: HelpCircle,
      current: false,
      allowAnon: true,
      onClick: () => {
        // Track
        analytics.track(kSegmentTrackOpenSupportChatClicked)
        toggleWidget()
        setSidebarOpen(false)
      },
      excludedBrands: [BrandEnum.HAIKU],
    },
  ].filter((item) => !item.excludedBrands?.includes(brand)) as DashboardSidebarItemType[]

  // Only render for anonymous users
  if (!userIsAnon || supportOptions.length === 0) {
    return null
  }

  return <DashboardSidebarGroup items={supportOptions} />
}
