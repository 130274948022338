import { selectIdentifierMatchesActiveContentViewer } from '@/content-viewer/store/selectors'

import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'

export type AgentResponseReferencesListItemHighlightButtonProps = {
  citation_id: string
  citationHighlightIndex: number
  footnoteNumber: number
  onClick: (citationHighlightIndex?: number) => void
}

export default function AgentResponseReferencesListItemHighlightButton(props: AgentResponseReferencesListItemHighlightButtonProps) {
  const { citation_id, citationHighlightIndex, footnoteNumber, onClick } = props

  const isActive = useAppSelector((state: RootState) =>
    selectIdentifierMatchesActiveContentViewer(state, { id: citation_id, highlightIndex: citationHighlightIndex })
  )

  return (
    <button
      className={`hover:text-brand-500 whitespace-nowrap ${isActive ? 'text-brand-600 bg-brand-400 bg-opacity-20 rounded-md' : ''}`}
      onClick={() => onClick(citationHighlightIndex)}
    >
      {citationHighlightIndex == 0 ? `[${footnoteNumber}]` : `[${footnoteNumber}.${citationHighlightIndex}]`}
    </button>
  )
}
