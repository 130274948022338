import { nanoid } from 'nanoid'
import { FilesDriveResponse } from '../schemas/files-drive-schema'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import * as Sentry from '@sentry/browser'
import { kFolderDriveApiPath } from '@/constants/constants-api-paths'
import { ApiError } from '@/util/errors/api-error'

// Types
type CreateFolderParams = {
  folderName: string
  parentFolderId: string
}

/**
 * Creates a new folder in the user's file drive.
 *
 * @param {CreateFolderParams} params - Parameters for creating the folder
 * @param {string} params.folderName - Name of the folder to create
 * @param {string} params.parentFolderId - ID of the parent folder to create under
 * @returns {Promise<FilesDriveResponse>} The created folder data
 * @throws {Error} If user is not authenticated or folder creation fails
 *
 * @example
 * ```typescript
 * const newFolder = await postNewFolder({
 *   folderName: "Documents",
 *   parentFolderId: "root"
 * })
 * ```
 */
export async function postNewFolder({ folderName, parentFolderId }: CreateFolderParams): Promise<FilesDriveResponse> {
  const sentry_transaction_id = nanoid()

  try {
    // Get auth token
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) {
      throw new Error(kErrorCodeAuthError)
    }

    // Setup request
    const apiUrl = kPaxtonAppApiBaseUrl() + kFolderDriveApiPath
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Transaction-Id': sentry_transaction_id,
      },
      body: JSON.stringify({
        name: folderName,
        parent_folder_id: parentFolderId,
      }),
    }

    // Make request
    const response = await fetch(apiUrl, requestOptions)
    // Parse and return response
    const data = await response.json()

    if (response.status !== 200 || !response.ok) {
      const error = new ApiError(response.status, 'Failed to create folder')

      // Log detailed error info
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: {
            requestUrl: apiUrl,
            requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            folderName,
          },
        })
      })
      throw new ApiError(response.status, `Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }

    return data
  } catch (error) {
    // Log any uncaught errors
    if (!(error instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: {
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
    }
    throw error
  }
}
