
import { useEffect } from 'react'
import { FilesDriveActions } from '../store/files-drive.slice'
import FilesDriveList from '../components/FilesDriveList'
import FileDriveNavigation from '../components/FileDriveNavigation'
import { useParams } from 'react-router-dom'
import { FileDriveDropZone } from '../components/FileDriveDropZone'
import { FileOverwriteModalProvider } from '../../files/file-overwrite-confirmation/file-overwrite-modal-context'
import { RootState } from '@/store/store'
import { FolderNode, DriveType } from '../schemas/files-drive-schema'
import { useAppSelector, useAppDispatch } from '@/store/store-hooks'
import { FilesDriveDialogType } from '@/store/slices/ui-state.slice'
import CreateFolder from '../components/dialogs/CreateFolder'
import ConfirmationDelete from '../components/dialogs/ConfirmationDelete'
import FilesDriveToolbar from '../components/FilesDriveToolbar'
import { uiStateSlice } from '@/store/slices/ui-state.slice'
import RenameItemDialog from '../components/dialogs/RenameItem'

function FilesDrivePage() {
  const { folderId: currentFolderId = '' } = useParams<{ folderId: string }>()
  const { driveName = DriveType.USER } = useParams<{ driveName: string }>()

  const dispatch = useAppDispatch()
  // Get the current folder meta data
  const currentFolder = useAppSelector((state: RootState) => state.filesDrive.currentFolder) as FolderNode | null

  const isRootFolder = currentFolderId === '' || currentFolderId === undefined

  const isDialogOpen = useAppSelector((state: RootState) => state.uiState.filesDriveDialogVisible)

  const loading = useAppSelector((state: RootState) => state.filesDrive.loading)

  const { files_ids: selectedFiles, folders_ids: selectedFolders } = useAppSelector((state: RootState) => state.filesDrive.selectedFilesAndFolders)

  const selectedFilesAndFoldersTotal = selectedFiles.length + selectedFolders.length

  useEffect(() => {
    dispatch(FilesDriveActions.setCurrentFolderId({ folderId: currentFolderId }))
  })

  useEffect(() => {
    // Set the current folder id
    dispatch(FilesDriveActions.setCurrentFolderId({ folderId: currentFolderId }))
    // Set the drive type
    dispatch(FilesDriveActions.setDriveType(driveName === DriveType.ORG ? DriveType.ORG : DriveType.USER))
    // Fetch the items
    dispatch(FilesDriveActions.fetchItems({ folderId: currentFolderId }))
  }, [dispatch, currentFolderId, driveName])


  const handleDialogOpen = (dialogType: FilesDriveDialogType) => {
    dispatch(uiStateSlice.actions.setFilesDriveDialogVisible(!isDialogOpen))
    dispatch(uiStateSlice.actions.setFilesDriveDialogType(isDialogOpen ? null : dialogType))
  }

  function clearAll() {
    dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files_ids: [], folders_ids: [] }))
  }

  return (
    <div className={'flex-grow flex flex-col py-4 lg:pl-0 px-4 mt-12 lg:mt-0'}>
      <ConfirmationDelete />
      <RenameItemDialog />
      <CreateFolder />


      <div className="bg-brand-neutral-50 shadow rounded-lg">
        <div className="px-4 sm:p-6 text-sm text-brand-500">New File Driver - Under Development</div>
        <FileOverwriteModalProvider>
          <FileDriveDropZone currentFolderId={currentFolderId} currentFolder={currentFolder} driveType={driveName === DriveType.ORG ? DriveType.ORG : DriveType.USER} />

          <FileDriveNavigation isRootFolder={isRootFolder} handleDialogOpen={handleDialogOpen} driveName={driveName} />
          {/* Toolbar */}
          <FilesDriveToolbar handleDialogOpen={handleDialogOpen} selectedFilesAndFoldersTotal={selectedFilesAndFoldersTotal} clearSelectedItems={clearAll} />
          <FilesDriveList currentFolderId={currentFolderId} loading={loading} handleDialogOpen={handleDialogOpen} />
        </FileOverwriteModalProvider>
      </div>
    </div>
  )
}

export default FilesDrivePage