import { AgentEventType, ClientAgentEventRead } from '@/agent/events/store/schemas'
import { kErrorGeneratingResponseMessage, kResponseCanceledMessage } from '@/constants/constants-strings'
import { getBrandName } from '@/util/enterprise'

type GeneralEventPrintableBubbleProps = {
  event: ClientAgentEventRead
}

/**
 * General Event Printable Bubble
 *
 * Render events which only require a header and text as a printable bubble.
 * - A header with the brand name.
 * - The event's value (text).
 */
export default function GeneralEventPrintableBubble({ event }: GeneralEventPrintableBubbleProps) {
  const messageHeader = event.type === AgentEventType.USER_QUERY ? 'You' : getBrandName()

  let displayText
  switch (event.type) {
    case AgentEventType.STOP_REQUEST_RECEIVED:
      displayText = kResponseCanceledMessage
      break
    case AgentEventType.SERVER_ERROR:
      displayText = kErrorGeneratingResponseMessage
      break
    default:
      displayText = event.value.trim() || ''
  }

  return (
    <div>
      <h2>{messageHeader}</h2>
      <p>{displayText}</p>
    </div>
  )
}
