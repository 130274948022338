import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { kAgentCitationRegex } from '@/util/rehype-custom-plugins/rehype-agent-citations'
import { useState } from 'react'
import AgentResponseReferencesGroup from './AgentResponseReferencesGroup'

type AgentResponseReferencesSectionProps = {
  turn_id: string
  response_message: string
}

export default function AgentResponseReferencesSection(props: AgentResponseReferencesSectionProps) {
  const { turn_id, response_message } = props
  const [expandReferences, setExpandReferences] = useState(false)
  const [expandRelated, setExpandRelated] = useState(false)

  // Get the turn's unique citation_ids -> highlight count object
  const turnUniqueCitationAndHighlightCount =
    useAppSelector((state: RootState) => state.agentReferencesState.turnUniqueCitationAndHighlightCount[turn_id]) ?? {}
  const turnUniqueCitationIds = Object.keys(turnUniqueCitationAndHighlightCount)

  // Identify the citation_ids in the response_message
  const matches = response_message.match(kAgentCitationRegex)

  // Convert to a list of strings
  const matchedCitationIds = matches ? matches.map((match) => match.slice(1, -1)) : []

  // Filter the turnUniqueCitationIds to only include the citation_ids in the response_message
  const usedCitationIds = turnUniqueCitationIds.filter((citation_id) => matchedCitationIds.some((match) => match === citation_id))
  const unusedCitationIds = turnUniqueCitationIds.filter((citation_id) => !usedCitationIds.includes(citation_id))

  const citedTitle = usedCitationIds.length === 1 ? 'cited reference' : 'cited references'
  const relatedTitle = unusedCitationIds.length === 1 ? 'related reference' : 'related references'

  // Show IF:
  // - expandReferences (top level) is true AND there are unusedCitationIds
  // - usedCitationIds is empty AND there are unusedCitationIds
  //     - This is because there will be no UX to set expanded
  const showUnusedCitations = (expandReferences && unusedCitationIds.length > 0) || (usedCitationIds.length === 0 && unusedCitationIds.length > 0)

  // If there are no used or unused citations, don't render the section
  if (!turnUniqueCitationAndHighlightCount || usedCitationIds.length + unusedCitationIds.length === 0) {
    return null
  }

  return (
    <div className="mt-2">
      {usedCitationIds.length > 0 && (
        <AgentResponseReferencesGroup
          title={citedTitle}
          citationIdHighlightCount={turnUniqueCitationAndHighlightCount}
          numOfCitations={usedCitationIds.length}
          groupCitationIds={usedCitationIds}
          turnUniqueCitationIds={turnUniqueCitationIds}
          isExpanded={expandReferences}
          toggleExpand={() => setExpandReferences(!expandReferences)}
        />
      )}

      {showUnusedCitations && (
        <AgentResponseReferencesGroup
          title={relatedTitle}
          citationIdHighlightCount={turnUniqueCitationAndHighlightCount}
          numOfCitations={unusedCitationIds.length}
          groupCitationIds={unusedCitationIds}
          turnUniqueCitationIds={turnUniqueCitationIds}
          isExpanded={expandRelated}
          toggleExpand={() => setExpandRelated(!expandRelated)}
        />
      )}
    </div>
  )
}
