import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import DashboardSidebarItem, { DashboardSidebarItemType } from './dashboard-sidebar-item'

export type DashboardSidebarGroupProps = {
  items: DashboardSidebarItemType[]
  excludedBrands?: BrandEnum[]
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
}

export default function DashboardSidebarGroup({ items }: DashboardSidebarGroupProps) {
  return (
    <div className="w-full mb-2 bg-white">
      {items.map((item, i) => {
        const isFirstSubItem = i > 0 && item.subItem && !items[i - 1].subItem
        return <DashboardSidebarItem key={`${i}-${item.name}`} item={item} index={i} isFirstSubItem={isFirstSubItem} />
      })}
    </div>
  )
}
