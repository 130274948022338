import { useLocation, useNavigate } from 'react-router-dom'
import { FolderNode, ItemNode } from '../schemas/files-drive-schema'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import { NewFolderPlus } from '@/assets/icons'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { KeyboardArrowRight } from '@mui/icons-material'
import { FilesDriveDialogType } from '@/store/slices/ui-state.slice'
import { FilesDriveActions } from '../store/files-drive.slice'

interface FileDriveNavigationProps {
  isRootFolder: boolean
  handleDialogOpen: (dialogType: FilesDriveDialogType) => void
  driveName: string
}

export default function FileDriveNavigation({ isRootFolder, handleDialogOpen, driveName }: FileDriveNavigationProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // Extracting pathname for easier manipulation
  const { pathname } = location
  // Splice by five to consider `file-drive-2.0` -- remove when file-drive-2.0 is ready
  const baseDriveLocationPath = pathname.split('/').slice(0, 5).join('/')

  const driveNameLabel = driveName === 'org-drive' ? 'Shared Drive' : 'My Drive'
  const currentFolder = useAppSelector((state: RootState) => state.filesDrive.currentFolder) as FolderNode | null

  // Allow new folder button if we're at the root folder
  const allowNewFolder = isRootFolder

  const handleClickLocation = (folderId: string) => {
    // Clear selected files and folders
    dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files_ids: [], folders_ids: [] }))

    navigate(`${baseDriveLocationPath}/${folderId}`)
  }

  const renderBreadCrumbs = (item: ItemNode) => {
    const paths = driveNameLabel.concat(`/${item.folder_path}/${item.name}`).split('/').filter(Boolean)

    return paths.map((segment, index) => {
      const isLast = index === paths.length - 1
      const isRoot = index === 0
      const folderId = isRoot ? '' : item.id

      const textClasses = 'text-brand-neutral-900 text-sm'
      const clickableClasses = !isLast ? 'underline cursor-pointer' : ''
      const combinedClasses = `${textClasses} ${clickableClasses}`.trim()
      return (
        <span key={index}>
          <span className={combinedClasses} onClick={!isLast ? () => handleClickLocation(folderId) : undefined}>
            {segment}
          </span>
          {(!isLast || isRoot) && (
            <span aria-hidden={true} className="mx-1 ">
              <KeyboardArrowRight fontSize="small" />
            </span>
          )}
        </span>
      )
    })
  }

  return (
    <div className="px-4 pt-4 mb-2">
      {!currentFolder && !isRootFolder ? (
        // Show loading only if we have no folder and we're not at the root
        <div className="inline-block pl-1">
          <CircularProgressContinuousSized size={12} thickness={5} />
        </div>
      ) : isRootFolder ? (
        // Show drive label and new folder button if we're at the root
        <div className="inline-block font-semibold leading-6 text-brand-neutral-900 text-sm">
          <span aria-description="drive-name-label">{driveNameLabel}</span>
          <span aria-hidden={true} className="mx-1">
            <KeyboardArrowRight fontSize="small" />
          </span>
          {allowNewFolder && (
            <button onClick={() => handleDialogOpen(FilesDriveDialogType.CREATE_FOLDER)} className="text-brand-500 inline-flex items-center align-bottom">
              <NewFolderPlus className="pb-[2px] h-5 w-5 text-brand-500" />
              <span className="ml-1">New Folder</span>
            </button>
          )}
        </div>
      ) : (
        // Otherwise, we must have a current folder, so render its breadcrumbs
        currentFolder && <div className="inline-block font-semibold leading-6 text-brand-neutral-900 text-sm">{renderBreadCrumbs(currentFolder)}</div>
      )}
    </div>
  )
}
