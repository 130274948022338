import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { ApiError } from '@/util/errors/api-error'
import { kAgentAssistantRouterApiBasePath } from '@/constants/constants-api-paths'

type PutResponseFeedbackArgs = {
  eventId: string
  thumb: string
  thumbText: string
}

/**
 * Sends a PUT request to add a thumb rating (with associated text) to an agent response event.
 * @param args - The event ID, thumb rating, and thumb text to send in the request.
 */
export async function putResponseFeedback(args: PutResponseFeedbackArgs): Promise<void> {
  const sentry_transaction_id = nanoid()

  try {
    const auth = getAuth()
    const token = await auth.currentUser?.getIdToken()
    if (!token) {
      throw new Error("Could not retrieve the user's authentication token.")
    }

    const body = JSON.stringify({ thumb: args.thumb, thumb_text: args.thumbText })

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      body: body,
    }

    const apiUrl = `${kPaxtonAppApiBaseUrl()}${kAgentAssistantRouterApiBasePath}/response_feedback/${args.eventId}`

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle non-OK responses
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            eventId: args.eventId,
          },
        })
      })

      throw new ApiError(response.status, `Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }
  } catch (error) {
    // Skip api errors already captured above
    if (!(error instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled, eventId: args.eventId },
        })
      })
    }
    throw error
  }
}
