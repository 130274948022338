import { ChatV2MessageType, ChatV2ReferenceType } from '../../store/chat-v2.slice'
import chatV2FormatResponseString from '@/chat-common/util/chat-v2-format-response'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { setMessageFeedbackIndicator, toggleMessageExpandReferences, toggleMessageExpandRelatedReferences } from '../../store/chat-v2-ux.slice'
import BlinkingCursor from '@/components/loaders/BlinkingCursor'
import { useEffect, useState } from 'react'
import { ClipboardIcon, HandThumbDownIcon, HandThumbUpIcon, SignalSlashIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { useOnlineStatus } from '@/context/online-status-context'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import putMessageFeedback from '@/chat-common/fetch/put-message-feedback'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import getUniqueReferenceNumbersInMessage from '@/chat-common/util/chat-v2-get-references-in-message'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { getBrandName } from '@/util/enterprise'
import { downloadResponseAsDocxRemote, copyResponseToClipboard } from '@/chat-common/util/download-conversation'
import {
  selectConversationIsLoading,
  selectIsMostRecentMessage,
  selectMessageFromId,
  selectConversationTitle,
  selectVisibleReference,
  selectReferencesFromId,
} from '@/chat-common/store/chat-v2.selectors'
import ReferencesSection from '@/chat-common/components/message-bubbles/chat-response-bubble-reference-section'
import ChatResponsePromptAssistIndicator from './chat-response-prompt-assist-indicator'
import { ChatV2Message } from '@/chat-common/schemas/chat-v2.schemas'

type ChatResponseBubbleProps = {
  chatId: string
  messageId: string
  openFeedbackDialog: (message: ChatV2Message, positive: boolean) => void
  onNewMessageText: () => void
}

export default function ChatResponseBubble(props: ChatResponseBubbleProps) {
  const { chatId, messageId, openFeedbackDialog, onNewMessageText } = props
  const { trackEvent } = useAnalytics()
  const isOnline = useOnlineStatus()

  // Redux State Selectors
  const message = useAppSelector((state: RootState) =>
    selectMessageFromId(state, {
      messageId,
      chatId,
    })
  )
  const { metadata, text } = message

  const references = useAppSelector((state: RootState) =>
    selectReferencesFromId(state, {
      messageId,
      chatId,
    })
  )

  // UX Store
  const dispatch = useAppDispatch()
  const { messageExpandReferences, messageExpandRelatedReferences, messageFeedbackIndicators } = useAppSelector((state: RootState) => state.chatV2UxState)
  const expandReferences = messageExpandReferences.includes(metadata.message_id)
  const expandRelated = messageExpandRelatedReferences.includes(metadata.message_id)
  const visibleReference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId }))
  const visibleReferenceNumber = visibleReference?.reference_number ?? ''
  const conversationIsLoading = useAppSelector((state: RootState) =>
    selectConversationIsLoading(state, {
      chatId,
    })
  )
  const conversationTitle = useAppSelector((state: RootState) => selectConversationTitle(state, { chatId }))

  // Check if this message is the most recent record in the messages record
  const isMostRecent = useAppSelector((state: RootState) => selectIsMostRecentMessage(state, { chatId, messageId }))
  const isResponse = metadata.message_type === ChatV2MessageType.response
  const showCursor = isMostRecent && isResponse && conversationIsLoading

  // Get message feedback indicator
  const messageFeedbackThumb = messageFeedbackIndicators[metadata.message_id] ?? null

  // Filter out pol references as we do not want to display those to the user
  const filteredReferences = Object.keys(references).filter((key) => references[key]?.metadata?.reference_type !== ChatV2ReferenceType.pol)

  // Get used and unused references based on whether the references are found inside the message
  const referencesInMessage = getUniqueReferenceNumbersInMessage(message) // May hallucinate reference numbers - use references as source of truth

  const usedReferences = filteredReferences.filter((key) => referencesInMessage.includes(key))
  const unusedReferences = filteredReferences.filter((key) => !referencesInMessage.includes(key))

  // Blink state - blink is false until 500ms after render
  // Desired UX: solid cursor while text is streaming, blinking cursor when text streaming is paused / waiting
  const [blink, setBlink] = useState(false)
  setTimeout(() => {
    setBlink(true)
  }, 500)

  // Trim the text
  const trimmedText = text.trim()

  // Format the message text if it's a response (creates footnote links)
  const formattedText = chatV2FormatResponseString(message, references, visibleReferenceNumber)

  // Message header
  const messageHeader = getBrandName()

  // create a flag for capturing whether either of the above are true
  const isPromptAssist = metadata.is_prompt_assist

  // Handle Copy
  const handleCopyMessageText = async () => {
    await copyResponseToClipboard(message, references)
    dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: 'Text copied to clipboard', durationMs: 2000 }))
  }

  const handleDownloadAsDocx = () => {
    const fileName = conversationTitle ?? 'response-' + metadata.feature + '-' + metadata.message_id
    downloadResponseAsDocxRemote(fileName, message, references)
  }

  // Every time the text changes, call the onNewMessageText
  useEffect(() => {
    onNewMessageText()
  }, [text])

  return (
    <>
      <div className="p-2 rounded-lg text-base whitespace-pre-wrap compatible-word-break bg-white">
        <div className={`font-bold text-xl mb-1`}>{messageHeader}</div>
        <div className={`text-sm leading-6 mb-1`}>
          {trimmedText == '' && !conversationIsLoading && (
            <div className={`text-brand-neutral-600`}>
              Response may still be generating. If you refreshed while the response was being generated, it may not yet be complete. Try refreshing again in a
              few moments.
            </div>
          )}
          <div id="chat-response-bubble" className="flex flex-col">
            {formattedText}
          </div>
          {showCursor && <BlinkingCursor blink={blink} />}
        </div>
        {metadata.message_type === ChatV2MessageType.client_error && !isOnline && isMostRecent && (
          <div className={'py-2 flex items-center gap-x-1 text-sm font-normal text-red-700'}>
            <div className={'w-5 h-5'}>
              <SignalSlashIcon />
            </div>
            <div>No connection</div>
          </div>
        )}

        {/* Prompt Assist Flag */}
        {isPromptAssist && <ChatResponsePromptAssistIndicator />}

        {/* Message Action Icons */}
        {metadata.message_type === ChatV2MessageType.response && !conversationIsLoading && (
          <div className={`flex gap-x-2 items-center text-brand-neutral-500 text-xs mb-1`}>
            <button
              title="This response was helpful"
              className={`p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100 ${messageFeedbackThumb == FeedbackThumb.UP ? 'bg-brand-100' : ''}`}
              onClick={() => {
                // Submit initial feedback
                putMessageFeedback({ messageId: metadata.message_id, thumb: FeedbackThumb.UP, thumb_text: '' })

                // Open dialog for additional feedback
                openFeedbackDialog(message, true)

                // Set the feedback icon
                dispatch(setMessageFeedbackIndicator({ messageId: metadata.message_id, thumb: FeedbackThumb.UP }))
              }}
            >
              <HandThumbUpIcon className={'h-5'} />
            </button>
            <button
              title="This response was not helpful"
              className={`p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100  ${messageFeedbackThumb == FeedbackThumb.DOWN ? 'bg-brand-100' : ''}`}
              onClick={() => {
                // Submit initial feedback
                putMessageFeedback({ messageId: metadata.message_id, thumb: FeedbackThumb.DOWN, thumb_text: '' })

                // Open dialog for additional feedback
                openFeedbackDialog(message, false)

                // Set the feedback icon
                dispatch(setMessageFeedbackIndicator({ messageId: metadata.message_id, thumb: FeedbackThumb.DOWN }))
              }}
            >
              <HandThumbDownIcon className={'h-5'} />
            </button>
            <button
              title="Copy response to clipboard"
              className={'p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100'}
              onClick={() => {
                trackEvent(AnalyticsEvent.CopyChatResponse)
                handleCopyMessageText()
              }}
            >
              <ClipboardIcon className={'h-5'} />
            </button>
            <button
              title="Download response as a DOCX file"
              className={'p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100'}
              onClick={() => {
                trackEvent(AnalyticsEvent.DownloadChatResponseToDocx)
                handleDownloadAsDocx()
              }}
            >
              <DocumentArrowDownIcon className={'h-5'} />
            </button>
          </div>
        )}

        {/* Confidence Indicator - Removed in ENG-5318 */}

        {/* References */}
        {(usedReferences.length > 0 || unusedReferences.length > 0) && (
          <div className={'border-t border-brand-neutral-200 pt-2 flex flex-col'}>
            {/* Cited references */}
            {usedReferences.length > 0 && (
              <>
                <ReferencesSection
                  title="cited references"
                  references={usedReferences}
                  isExpanded={expandReferences}
                  toggleExpand={() => dispatch(toggleMessageExpandReferences({ messageId: metadata.message_id }))}
                  conversationId={chatId}
                  messageId={messageId}
                />
                {/* Related references nested under cited references (if there are any) */}
                {unusedReferences.length > 0 && expandReferences && (
                  <>
                    <hr className={'my-4 text-brand-neutral-200'} />
                    <ReferencesSection
                      title="related references"
                      references={unusedReferences}
                      isExpanded={expandRelated}
                      toggleExpand={() => dispatch(toggleMessageExpandRelatedReferences({ messageId: metadata.message_id }))}
                      conversationId={chatId}
                      messageId={messageId}
                    />
                  </>
                )}
              </>
            )}

            {/* Only display related references when there are no cited references */}
            {usedReferences.length === 0 && unusedReferences.length > 0 && (
              <ReferencesSection
                title="related references"
                references={unusedReferences}
                isExpanded={expandRelated}
                toggleExpand={() => dispatch(toggleMessageExpandRelatedReferences({ messageId: metadata.message_id }))}
                conversationId={chatId}
                messageId={messageId}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
