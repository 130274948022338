import InlineTipError from '@/components/inline-tips/InlineTipError'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import ContentViewerHeader from './common/ContentViewerHeader'
import ContentViewerHTMLContainer from './common/ContentViewerHTMLRenderer'
import useGcsMarkdownLoader from '../hooks/gcs-md-loader-hook'
import ContentViewerDownloadButton, { ContentViewerDownloadButtonProps } from './common/ContentViewerDownloadButton'

type ContentViewerMarkdownProps = {
  conversationId: string
  title: string
  filePath: string
  gcsBucketName: string
  highlights?: string[] | null
  downloadButtonFunction?: ContentViewerDownloadButtonProps
}

/**
 * Content Viewer Markdown
 *
 * The layout for the pane that displays UX components
 * for viewing markdown content.
 */
export default function ContentViewerMarkdown(props: ContentViewerMarkdownProps) {
  const { conversationId, filePath, title, downloadButtonFunction, gcsBucketName, highlights } = props

  // Fetch markdown content and convert to HTML
  const { htmlString, isLoading, hasError, retry } = useGcsMarkdownLoader({ gcsBucketName, filePath, highlights })

  return (
    <div className="bg-brand-neutral-50 flex flex-col h-full w-full overflow-hidden">
      <ContentViewerHeader conversationId={conversationId} loading={isLoading} title={title} />
      <div className="flex-grow overflow-y-auto h-full w-full">
        {!hasError && !isLoading && !htmlString && <div>Could not locate this document.</div>}
        {hasError && (
          <div className="bg-white p-4">
            <div className="mb-4 text-gray-700 text-left w-full">{InlineTipError('Failed to load artifact.')}</div>
            <button
              className={
                'flex gap-x-2 items-center rounded-md bg-brand-500 border-[1px] border-brand-500 px-2 py-1 mb-4 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
              }
              onClick={retry}
            >
              <div>Retry</div>{' '}
              <div className={'w-5 h-5'}>
                <ArrowPathIcon />
              </div>
            </button>
          </div>
        )}
        {htmlString && <ContentViewerHTMLContainer htmlString={htmlString} />}
      </div>

      {/* Hide entire lower bar if there are no buttons to render */}
      {downloadButtonFunction && (
        <div className="border-t px-4 py-4 flex items-center">
          <ContentViewerDownloadButton {...downloadButtonFunction} />
        </div>
      )}
    </div>
  )
}
