import { ClientAgentReference } from '@/agent/references/store/schemas'
import { agentReferenceMetadataToTitle } from '@/agent/references/util/agent-reference-utils'

type AgentResponseReferencesPrintableListItemProps = {
  citationLabel: string | number
  reference: ClientAgentReference
}

/**
 * Agent Response References Printable List Item
 *
 * A printable version of the AgentResponseReferencesListItem component.
 * This is used to display a single reference in the printable version of the agent response event bubble.
 */
export default function AgentResponseReferencesPrintableListItem(props: AgentResponseReferencesPrintableListItemProps) {
  const { citationLabel, reference } = props

  const title = agentReferenceMetadataToTitle(reference)

  return (
    <p>
      [{citationLabel}] {title}
    </p>
  )
}
