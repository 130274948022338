import { selectSortedConversationEventIds } from '@/agent/events/store/selectors'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import EventBubbleConstructor from '../../events/components/EventBubbleConstructor'
import ChatLoadingBubble from '@/chat-common/components/message-bubbles/chat-loading-bubble'

type EventBubbleListProps = {
  conversationId: string
}

/**
 * Event Bubble List
 *
 * A component to render a list of event bubbles representing events in a conversation.
 */
export default function EventBubbleList(props: EventBubbleListProps) {
  const { conversationId } = props

  // Get the list of event ids for this conversation
  const eventIds = useAppSelector((state: RootState) => selectSortedConversationEventIds(state, { conversationId }))

  return (
    <div className="py-2">
      {eventIds.map((eventId) => (
        <div key={`bubble-${eventId}`} className="mb-3">
          <EventBubbleConstructor conversationId={conversationId} eventId={eventId} />
        </div>
      ))}
      <ChatLoadingBubble conversationId={conversationId} />
    </div>
  )
}
