import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { setReferralDialogVisible } from '@/store/slices/ui-state.slice'
import { XClear } from '@/assets/icons'
import { ClipboardIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'
import { GiftIcon } from '@heroicons/react/24/solid'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import getReferralCode from '@/referral/fetch/post-referral-code'
import { kPaxtonAppBaseDomain } from '@/constants/constants-links'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import InlineTipError from '@/components/inline-tips/InlineTipError'

export default function ReferralDialog() {
  const { trackEvent } = useAnalytics()
  const dispatch = useAppDispatch()
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const visible = uiState.referralDialogVisible

  const [copied, setCopied] = useState(false)
  const [referralLink, setReferralLink] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    async function fetchReferralCode() {
      const referralCode = await getReferralCode()
      if (referralCode) {
        setReferralLink(`${kPaxtonAppBaseDomain()}/sign-up?referralCode=${referralCode}`)
        setError(null) // Clear any previous errors on success
      } else {
        setError('Unable to retrieve your referral link. If the problem persists, please contact support.')
      }
      setLoading(false)
    }

    if (visible) {
      fetchReferralCode()
    }
  }, [visible])

  const handleCopy = () => {
    if (!referralLink) return
    navigator.clipboard.writeText(referralLink)
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
    trackEvent(AnalyticsEvent.ReferralLinkCopied)
  }

  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[9999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={() => dispatch(setReferralDialogVisible(false))}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-7 mx-2 rounded-md shadow-lg relative z-[10000] min-w-[275px] max-w-md sm:min-w-[550px]">
        <button
          className="absolute top-2 right-2 text-brand-neutral-900 hover:text-brand-neutral-700"
          onClick={() => dispatch(setReferralDialogVisible(false))}
        >
          <XClear />
        </button>
        <div className="flex items-center mb-4 text-lg sm:text-xl font-semibold">
          <GiftIcon className="text-brand-500 h-5 mr-2" />
          <span>Get 1 Free Month on Us!</span>
        </div>
        <p className="mb-6 text-brand-neutral-600 text-sm sm:text-base">
          Invite others to join Paxton using the link below. When someone subscribes through your link and stays for 2 months, you'll receive 1 free month as a
          thank-you. Simple as that!
        </p>

        <label className="font-semibold mb-1 sm:mb-2 block text-sm sm:text-base">Share your referral link</label>

        {loading ? (
          <div className="flex justify-center items-center h-20">
            <CircularProgressContinuousSized size={40} thickness={4} />
          </div>
        ) : error ? (
          <div className="mt-3">{InlineTipError(error)}</div>
        ) : (
          <div className="flex items-center">
            <input
              type="text"
              value={referralLink}
              readOnly
              className="flex-grow p-2 mr-2 border text-xs sm:text-sm text-brand-neutral-600 border-brand-neutral-300 rounded-md focus:outline-none"
              onCopy={() => trackEvent(AnalyticsEvent.ReferralLinkCopiedFromInput)}
            />
            <button
              onClick={handleCopy}
              className="bg-brand-500 text-sm text-white px-2 py-2 rounded-md hover:bg-brand-400 flex items-center justify-center min-w-[93px]"
            >
              {copied ? <ClipboardDocumentCheckIcon className="text-white h-5 mr-1" /> : <ClipboardIcon className="text-white h-5 mr-1" />}
              <span>{copied ? 'Copied!' : 'Copy'}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
