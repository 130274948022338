import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export interface ContentViewerDownloadButtonProps {
  download: (onLoading: (isLoading: boolean) => void, onError: (error: Error) => void) => Promise<void>
}

export default function ContentViewerDownloadButton(props: ContentViewerDownloadButtonProps) {
  const { download } = props
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  return (
    <div className={'flex gap-x-2 items-center'}>
      <button
        className="flex items-center justify-center w-5 h-5 text-gray-600 hover:text-gray-800 font-semibold focus:outline-none"
        onClick={() => {
          download(
            (isLoading) => {
              setIsLoading(isLoading)
            },
            (error) => {
              setError(error)
            }
          )
        }}
        disabled={isLoading}
        aria-busy={isLoading}
      >
        {isLoading ? <CircularProgressContinuousSized size={16} thickness={4} /> : <DocumentArrowDownIcon className="h-5 w-5" />}
      </button>
      {error && <div className={'text-red-700 text-xs'}>{error.message}</div>}
    </div>
  )
}
