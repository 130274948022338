import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AgentAssistantRequestParamsSchema, AgentAssistantRequestParams, AgentFocusMode } from './schemas'

export type AgentConversationFormData = {
  requestParams: AgentAssistantRequestParams
  queryIsSubmitting: boolean
}

export type AgentConversationFormState = {
  forms: Partial<{ [conversationId: string]: AgentConversationFormData }>
}

const initialState: AgentConversationFormState = {
  forms: {},
}

// Create a new form with the given focus mode and default values
const createNewForm = (focus_mode: AgentFocusMode = AgentFocusMode.INTELLIGENT): AgentConversationFormData => ({
  requestParams: AgentAssistantRequestParamsSchema.parse({ focus_mode }), // Use schema to apply defaults
  queryIsSubmitting: false,
})

// Helper to validate the form or initialize it with default values
const validateOrInitializeForm = (state: AgentConversationFormState, conversationId: string): AgentConversationFormData => {
  const form = state.forms[conversationId]

  if (!form) {
    const newForm = createNewForm()
    state.forms[conversationId] = newForm
    return newForm
  }

  const validation = AgentAssistantRequestParamsSchema.safeParse(form.requestParams)

  if (!validation.success) {
    const newForm = createNewForm()
    newForm.queryIsSubmitting = form.queryIsSubmitting
    state.forms[conversationId] = newForm
    return newForm
  }

  return form
}

/**
 * Agent Conversations Form Slice
 */
export const agentConversationFormSlice = createSlice({
  name: 'agentConversationFormState',
  initialState,
  reducers: {
    // ============== Listener Middleware Triggers ============== >
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitForm: (_state, _action: PayloadAction<{ conversationId: string }>) => {
      // Handled by listener middleware
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendStop: (_state, _action: PayloadAction<{ conversationId: string }>) => {
      // Handled by listener middleware
    },

    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Create a new form
    createForm: (state, action: PayloadAction<{ conversationId: string; focus_mode: AgentFocusMode }>) => {
      const { conversationId, focus_mode } = action.payload
      state.forms[conversationId] = createNewForm(focus_mode)
    },

    // Set the input text
    setInputText: (state, action: PayloadAction<{ conversationId: string; text: string }>) => {
      const { conversationId, text } = action.payload
      const form = validateOrInitializeForm(state, conversationId)
      form.requestParams.input_text = text
    },

    // Set the query submitting state
    setQueryIsSubmitting: (state, action: PayloadAction<{ conversationId: string; isSubmitting: boolean }>) => {
      const { conversationId, isSubmitting } = action.payload
      const form = validateOrInitializeForm(state, conversationId)
      form.queryIsSubmitting = isSubmitting
    },

    // Set the focus mode
    setFocusMode: (state, action: PayloadAction<{ conversationId: string; focus_mode: AgentFocusMode }>) => {
      const { conversationId, focus_mode } = action.payload
      const form = validateOrInitializeForm(state, conversationId)
      form.requestParams.focus_mode = focus_mode
    },

    // Add a file to the form
    addFile: (state, action: PayloadAction<{ conversationId: string; filePath: string }>) => {
      const { conversationId, filePath } = action.payload
      const form = validateOrInitializeForm(state, conversationId)
      if (!form.requestParams.file_paths.includes(filePath)) {
        form.requestParams.file_paths.push(filePath)
      }
    },

    // Add a list of files to the form
    addFiles: (state, action: PayloadAction<{ conversationId: string; filePaths: string[] }>) => {
      const { conversationId, filePaths } = action.payload
      const form = validateOrInitializeForm(state, conversationId)
      form.requestParams.file_paths = Array.from(new Set([...form.requestParams.file_paths, ...filePaths]))
    },

    // Remove a file from the form
    removeFile: (state, action: PayloadAction<{ conversationId: string; filePath: string }>) => {
      const { conversationId, filePath } = action.payload
      const form = validateOrInitializeForm(state, conversationId)
      form.requestParams.file_paths = form.requestParams.file_paths.filter((file) => file !== filePath)
    },

    // Clear all files from the form
    clearFiles: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload
      const form = validateOrInitializeForm(state, conversationId)
      form.requestParams.file_paths = []
    },
  },
})

// Actions
export const AgentConversationFormActions = agentConversationFormSlice.actions

export default agentConversationFormSlice.reducer
