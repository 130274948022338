import { useParams } from 'react-router-dom'
import AddCircleIcon from '@/assets/icons/plus-add-circle-filled.svg?react'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { newChatButtonLabel } from '@/chat-common/components/chat-window-utils'
import { useState } from 'react'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { useCreateNewChat } from '../hooks/use-start-new-chat'
import { useCreateNewAgentChat } from '@/agent/conversations/hooks/use-new-agent-chat'
import { kFeatureFlags } from '@/constants/constants-feature-flags'
import { kGetFeatureFlagStatus } from '@/constants/constants-feature-flags'

type NewChatButtonProps = {
  onClickCallback?: () => void
  isCompact?: boolean
}

export default function NewChatButton(props: NewChatButtonProps) {
  const { isCompact } = props
  const { chatFeature } = useParams()
  const { trackEvent } = useAnalytics()
  const { createNewChat } = useCreateNewChat()
  const { createNewAgentChat } = useCreateNewAgentChat()

  // Local state
  const [createError, setCreateError] = useState<boolean>(false)
  const [clickDisabled, setClickDisabled] = useState<boolean>(false)

  const handleClick = async () => {
    // If the button is disabled, do not allow another click
    if (clickDisabled) return

    // Disable for multiple clicks
    setClickDisabled(true)

    // Clear any previous error
    setCreateError(false)

    // Validate the chat feature
    if (!Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)) {
      console.error('Invalid chat feature:', chatFeature)
      setCreateError(true)
      setClickDisabled(false)
      return
    }

    // Track the analytics event on a successful click
    trackEvent(AnalyticsEvent.NewChat, {
      feature: chatFeature as ChatV2Feature,
    })

    function onSuccess() {
      setClickDisabled(false)
    }

    function onError(e: unknown) {
      console.error('Error creating a new chat:', e)
      setCreateError(true)
      setClickDisabled(false)
    }

    // Create new assistant chat (agent or legacy assistant, depending on the feature flag)
    if (chatFeature === ChatV2Feature.assistant) {
      // Check if the agent assistant feature flag is enabled
      const agentAssistantFeatureFlag = kGetFeatureFlagStatus(kFeatureFlags.AGENT_ASSISTANT)

      if (agentAssistantFeatureFlag) {
        // Create a new agent chat
        console.log('AGENT_ASSISTANT flag is ON => creating new agent assistant chat')
        createNewAgentChat(true, onSuccess, onError)
      } else {
        // Create a legacy assistant chat
        console.log('AGENT_ASSISTANT flag is OFF => creating new legacy assistant chat')
        createNewChat(ChatV2Feature.assistant, true, onSuccess, onError)
      }
    } else {
      // Create a legacy new chat for any other feature
      createNewChat(chatFeature as ChatV2Feature, true, onSuccess, onError)
    }
  }

  return (
    <>
      <button
        onClick={handleClick}
        className={`focus:outline-none ${
          isCompact
            ? 'p-2 text-brand-500 hover:text-brand-400'
            : 'w-full rounded-md bg-brand-500 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400'
        }`}
        aria-label="Start new chat"
        title="New Chat"
      >
        {isCompact ? (
          <AddCircleIcon style={{ fontSize: '28px' }} />
        ) : (
          <span className="inline-block whitespace-nowrap">{newChatButtonLabel(chatFeature as ChatV2Feature)}</span>
        )}
      </button>
      {createError && <div className={'text-xs text-center text-red-700'}>Error creating chat, please try again.</div>}
    </>
  )
}
