import React, { useContext, useRef, useEffect } from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDown } from '@/assets/icons'
import { useForm } from 'react-hook-form'
import { AlertErrorCircle } from '@/assets/icons'
import { useCreateSurvey } from '../hooks/use-create-survey'
import { AuthContext } from '@/context/auth-context'
import { Userpilot } from 'userpilot'
import { useOnboarding } from '../hooks/use-onboarding'
import { useCreateNewChat } from '@/chat-common/new_chat/hooks/use-start-new-chat'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { brandedAIFriendlyName } from '@/util/enterprise'
import { useCreateNewAgentChat } from '@/agent/conversations/hooks/use-new-agent-chat'
import { kFeatureFlags } from '@/constants/constants-feature-flags'
import { kGetFeatureFlagStatus } from '@/constants/constants-feature-flags'

type ViewportState = {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

type FormValues = {
  firstName: string
  lastName: string
  professionalRole: string
  professionalRoleOther: string
  areaOfPractice: string
  areaOfPracticeOther: string
  firmSize: string
  rankingFirstChoice: string
  rankingFirstChoiceOther: string
  rankingSecondChoice: string
  rankingSecondChoiceOther: string
  rankingThirdChoice: string
  rankingThirdChoiceOther: string
  paxtonReferrer: string
  paxtonReferrerOther: string
  socialNetwork: string
}

type OnboardingFormProps = {
  visible: boolean
  viewport: ViewportState
}

// Add type for submission data
type SubmissionData = Omit<
  FormValues,
  | 'professionalRoleOther'
  | 'areaOfPracticeOther'
  | 'rankingFirstChoiceOther'
  | 'rankingSecondChoiceOther'
  | 'rankingThirdChoiceOther'
  | 'paxtonReferrerOther'
  | 'socialNetwork'
>

const areaOptions = [
  { id: 0, name: 'Select area of practice', disabled: true },
  { id: 1, name: 'Bankruptcy Law' },
  { id: 2, name: 'Capital Markets/Securities' },
  { id: 3, name: 'Commercial Litigation' },
  { id: 4, name: 'Consumer Protection' },
  { id: 5, name: 'Criminal Law' },
  { id: 6, name: 'Employment & Labor Law' },
  { id: 7, name: 'Family Law' },
  { id: 8, name: 'General Corporate/Outside GC' },
  { id: 9, name: 'Immigration Law' },
  { id: 10, name: 'M&A' },
  { id: 11, name: 'Personal Injury' },
  { id: 12, name: 'Privacy & Data Security' },
  { id: 13, name: 'Real Estate' },
  { id: 14, name: 'Regulatory & Compliance' },
  { id: 15, name: 'Trusts & Estates' },
  { id: 16, name: 'Other' },
]

const firmSizeOptions = [
  { id: 0, name: 'Select firm size', disabled: true },
  { id: 1, name: 'Solo practitioner' },
  { id: 2, name: '2-10 employees' },
  { id: 3, name: '11-50 employees' },
  { id: 4, name: '51-200 employees' },
  { id: 5, name: '201-500 employees' },
  { id: 6, name: '500+ employees' },
  { id: 7, name: 'N/A' },
]

const rankQuestions = [
  'Researching laws, rules, regulations, and court decisions',
  `Drafting legal documents with ${brandedAIFriendlyName}`,
  'Summarizing my documents',
  'Asking questions about my documents',
  'Analyzing contracts',
  'Taking on more client matters',
  'Reducing my cost and moving away from Thomson Reuters or Lexis Nexis',
  'Other',
]

const OnboardingWelcome: React.FC<OnboardingFormProps> = ({ visible, viewport }) => {
  const { refreshUserAccountData, user } = useContext(AuthContext)
  const { createNewChat } = useCreateNewChat()
  const { createNewAgentChat } = useCreateNewAgentChat()

  // Add ref for the area of practice section
  const areaOfPracticeRef = useRef<HTMLDivElement>(null)
  const socialMediaRef = useRef<HTMLDivElement>(null)
  const rankingsRef = useRef<HTMLDivElement>(null)
  const chatsCreatedRef = useRef(false)

  const { isMobile, isTablet, isDesktop } = viewport

  useEffect(() => {
    if (!visible || !user?.uid) return

    // Check both ref and localStorage to prevent duplicate creation
    const localStorageKey = `chats_initialized_${user.uid}`
    const chatsInitializedInStorage = localStorage.getItem(localStorageKey)
    if (chatsCreatedRef.current || chatsInitializedInStorage) return

    const createInitialChats = async () => {
      try {
        // Mark as created before API calls to prevent race conditions
        chatsCreatedRef.current = true
        localStorage.setItem(localStorageKey, 'true')

        // Determine which function to use for creating assistant chats
        const agentAssistantEnabled = kGetFeatureFlagStatus(kFeatureFlags.AGENT_ASSISTANT)
        const createAssistantChat = () => (agentAssistantEnabled ? createNewAgentChat(false) : createNewChat(ChatV2Feature.assistant, false))

        // Create all chats and wait for their resolution
        const [documentQueryChat, draftingChat, caseLawChat, aiAssistantChat1, aiAssistantChat2, combinedResearchChat] = await Promise.all([
          createNewChat(ChatV2Feature.documentquery, false),
          createNewChat(ChatV2Feature.drafting, false),
          createNewChat(ChatV2Feature.caselaw, false),
          createAssistantChat(),
          createAssistantChat(),
          createNewChat(ChatV2Feature.research, false),
        ])

        // Check if all chats were created successfully and have valid IDs
        if (!documentQueryChat?.id || !draftingChat?.id || !caseLawChat?.id || !aiAssistantChat1?.id || !aiAssistantChat2?.id || !combinedResearchChat?.id) {
          throw new Error('One or more chats failed to create with valid IDs')
        }

        // Only update Userpilot if all chats were created successfully
        Userpilot.identify(user.uid, {
          userIdFromUrl: user.uid,
          fileAnalysisChatID: documentQueryChat.id,
          draftingChatID: draftingChat.id,
          legalResearchChatID: caseLawChat.id,
          aiAssistantChatID1: aiAssistantChat1.id,
          aiAssistantChatID2: aiAssistantChat2.id,
          combinedResearchChatID: combinedResearchChat.id,
        })
        Userpilot.reload()

        // Force refresh token to update claims after backend sets the initialization status
        await user.getIdToken(true)
        await refreshUserAccountData()
      } catch (error) {
        console.error('Error creating initial chats:', error)
        // Reset flags if creation fails
        chatsCreatedRef.current = false
        localStorage.removeItem(localStorageKey)
      }
    }

    createInitialChats()
  }, [])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      professionalRole: '',
      professionalRoleOther: '',
      areaOfPractice: '',
      areaOfPracticeOther: '',
      firmSize: '',
      rankingFirstChoice: '',
      rankingFirstChoiceOther: '',
      rankingSecondChoice: '',
      rankingSecondChoiceOther: '',
      rankingThirdChoice: '',
      rankingThirdChoiceOther: '',
      paxtonReferrer: '',
      paxtonReferrerOther: '',
      socialNetwork: '',
    },
    shouldFocusError: true,
  })

  const { createSurvey, success, isSubmitting, error } = useCreateSurvey()
  const { onboarding } = useOnboarding()
  const [surveySubmissionError, setSurveySubmissionError] = useState(false)

  const professionalRole = watch('professionalRole')

  // Store each question's rank in an array. 0 means no rank chosen yet.
  const [questionRanks, setQuestionRanks] = useState<number[]>(() => rankQuestions.map(() => 0))

  // Add a state update trigger
  const [updateTrigger, setUpdateTrigger] = useState(0)

  /**
   * Handles the ranking selection/deselection logic for the survey questions.
   * This function manages both the visual state (radio buttons) and form values,
   * with special handling for required first choices and optional 2nd/3rd choices.
   *
   * Key behaviors:
   * - First choice (rank 1) is required and cannot be deselected
   * - Second and third choices can be deselected by clicking again
   * - Only one question can have each rank (1st, 2nd, or 3rd)
   * - "Other" options include additional specification text in the form values
   *
   * @param questionIndex - Index of the question in the rankQuestions array
   * @param newRank - The rank being assigned (1, 2, or 3)
   */
  const handleRankChange = (questionIndex: number, newRank: number) => {
    setQuestionRanks((prevRanks) => {
      // Special handling for deselecting 2nd and 3rd choices
      // First choice (rank 1) cannot be deselected to maintain form validity
      if (newRank !== 1 && prevRanks[questionIndex] === newRank) {
        const updatedRanks = [...prevRanks]
        updatedRanks[questionIndex] = 0 // Reset rank to unselected

        // Clear the corresponding form values when deselecting
        if (newRank === 2) {
          setValue('rankingSecondChoice', '')
          setValue('rankingSecondChoiceOther', '')
        } else if (newRank === 3) {
          setValue('rankingThirdChoice', '')
          setValue('rankingThirdChoiceOther', '')
        }

        // Force a re-render to update radio button states
        setUpdateTrigger((prev) => prev + 1)
        return updatedRanks
      }

      // Normal ranking logic for selecting a new rank
      // First, clear any existing instance of this rank (can't have two 2nd choices)
      const updatedRanks = prevRanks.map((rank) => (rank === newRank ? 0 : rank))
      updatedRanks[questionIndex] = newRank

      // Update form values based on the new ranking
      const value = rankQuestions[questionIndex]

      // Clear any previous values for this rank
      if (newRank === 1) {
        setValue('rankingFirstChoice', value, {
          shouldValidate: true,
          shouldDirty: true,
        })
        if (value !== 'Other') {
          setValue('rankingFirstChoiceOther', '')
        }
      } else if (prevRanks[questionIndex] === 1) {
        // Clear first choice if this question previously had it
        setValue('rankingFirstChoice', '', { shouldValidate: true, shouldDirty: true })
        setValue('rankingFirstChoiceOther', '')
      }

      // Similar logic for second choice
      if (newRank === 2) {
        setValue('rankingSecondChoice', value, {
          shouldDirty: true,
        })
        if (value !== 'Other') {
          setValue('rankingSecondChoiceOther', '')
        }
      } else if (prevRanks[questionIndex] === 2) {
        setValue('rankingSecondChoice', '')
        setValue('rankingSecondChoiceOther', '')
      }

      // Similar logic for third choice
      if (newRank === 3) {
        setValue('rankingThirdChoice', value, {
          shouldDirty: true,
        })
        if (value !== 'Other') {
          setValue('rankingThirdChoiceOther', '')
        }
      } else if (prevRanks[questionIndex] === 3) {
        setValue('rankingThirdChoice', '')
        setValue('rankingThirdChoiceOther', '')
      }

      // Force a re-render to ensure radio button states are updated
      setUpdateTrigger((prev) => prev + 1)
      return updatedRanks
    })
  }

  if (!visible) return null

  const onSubmit = async (data: FormValues) => {
    try {
      setSurveySubmissionError(false)

      // Combine professional role fields
      const submissionData: SubmissionData = {
        ...data,
        professionalRole: data.professionalRole === 'Other' && data.professionalRoleOther ? `Other - ${data.professionalRoleOther}` : data.professionalRole,
        areaOfPractice: data.areaOfPractice === 'Other' && data.areaOfPracticeOther ? `Other - ${data.areaOfPracticeOther}` : data.areaOfPractice,
        rankingFirstChoice:
          data.rankingFirstChoice === 'Other' && data.rankingFirstChoiceOther ? `Other - ${data.rankingFirstChoiceOther}` : data.rankingFirstChoice,
        rankingSecondChoice:
          data.rankingSecondChoice === 'Other' && data.rankingSecondChoiceOther ? `Other - ${data.rankingSecondChoiceOther}` : data.rankingSecondChoice,
        rankingThirdChoice:
          data.rankingThirdChoice === 'Other' && data.rankingThirdChoiceOther ? `Other - ${data.rankingThirdChoiceOther}` : data.rankingThirdChoice,
        paxtonReferrer:
          data.paxtonReferrer === 'Social media' && data.socialNetwork
            ? `Social media - ${data.socialNetwork}`
            : data.paxtonReferrer === 'Other' && data.paxtonReferrerOther
            ? `Other - ${data.paxtonReferrerOther}`
            : data.paxtonReferrer,
        firstName: data.firstName,
        lastName: data.lastName,
        firmSize: data.firmSize,
      }

      await createSurvey(submissionData)

      // Onboarding Initalization after survey submission
      await onboarding()

      refreshUserAccountData()

      if (isMobile || isTablet) {
        Userpilot.trigger('AjqIFuR1Bl')
      } else if (isDesktop) {
        Userpilot.trigger('checklist:P9BjXrc_Oc')
      }
    } catch (e) {
      console.error('Survey submission error:', e)
      setSurveySubmissionError(true)
    }
  }

  const onError = (errors: any) => {
    if (errors.areaOfPractice && professionalRole === 'Law firm' && areaOfPracticeRef.current) {
      areaOfPracticeRef.current.scrollIntoView({
        block: 'center',
      })
      return
    }

    if (errors.rankingFirstChoice && rankingsRef.current) {
      rankingsRef.current.scrollIntoView({
        block: 'center',
      })
      return
    }

    if (errors.paxtonReferrer && watch('paxtonReferrer').startsWith('Social media') && socialMediaRef.current) {
      socialMediaRef.current.scrollIntoView({
        block: 'center',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="space-y-0 sm:divide-y sm:pb-0 relative before:absolute before:top-0 before:left-[-24px] before:right-[-24px] sm:before:left-0 sm:before:right-0 before:h-[1px] before:bg-brand-neutral-300">
        <fieldset>
          <legend className="sr-only">By sharing a bit about yourself, we can better adapt {brandedAIFriendlyName} to suit your role and organization.</legend>
          <div className="flex flex-col sm:flex-row items-baseline gap-7 sm:gap-10 py-8 relative after:absolute after:bottom-0 after:left-[-24px] after:right-[-24px] sm:after:left-0 sm:after:right-0 after:h-[1px] after:bg-brand-neutral-300">
            <div className="w-full sm:w-[380px] sm:shrink-0">
              <p className="font-semibold leading-7 font-base text-brand-neutral-950">Tell us about yourself</p>
              <p className="text-[13px] max-w-full sm:max-w-[348px] font-normal leading-5 italic tracking-[0.15px] text-brand-neutral-600">
                By sharing a bit about yourself, we can better adapt {brandedAIFriendlyName} to suit your role and organization.
              </p>
            </div>
            <div className="w-full flex-1 flex flex-col gap-y-4 sm:gap-y-12">
              <div className="flex flex-col gap-y-4">
                <p className="text-sm font-semibold tracking[-0.15px]leading-5 text-brand-neutral-600">1. Please enter your full name</p>
                <div className="pl-4">
                  <div className="flex flex-col gap-y-[10px] min-w-full sm:flex-row gap-x-[10px]">
                    <div className="flex flex-col gap-y-2">
                      <input
                        {...register('firstName', {
                          required: 'First name is required',
                        })}
                        type="text"
                        placeholder="First Name"
                        className={`
                          flex min-w-full sm:min-w-[225px] max-w-[225px] w-full 
                          rounded-md py-2 pl-4 pr-2.5 
                          text-brand-neutral-900 shadow-sm
                          placeholder:text-brand-neutral-500
                          sm:text-sm sm:leading-5
                          focus:ring-inset
                          ${
                            errors.firstName
                              ? 'border border-red-300 focus:border-red-300 focus:border-1 focus:ring-0'
                              : 'border-0 ring-1 ring-inset ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'
                          }
                        `}
                      />
                      {errors.firstName && (
                        <div className="flex flex-row items-center gap-x-1">
                          <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                          <p className="text-red-700 text-xs font-medium">Required field</p>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <input
                        {...register('lastName', {
                          required: 'Last name is required',
                        })}
                        type="text"
                        placeholder="Last Name"
                        className={`
                          flex min-w-full sm:min-w-[225px] max-w-[225px] w-full 
                          rounded-md py-2 pl-4 pr-2.5 
                          text-brand-neutral-900 shadow-sm
                          placeholder:text-brand-neutral-500
                          sm:text-sm sm:leading-5
                          ${
                            errors.lastName
                              ? 'border border-red-300 focus:border-red-300 focus:border-1 focus:ring-0'
                              : 'border-0 ring-1 ring-inset ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'
                          }
                        `}
                      />
                      {errors.lastName && !errors.firstName && (
                        <div className="flex flex-row items-center gap-x-1">
                          <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                          <p className="text-red-700 text-xs font-medium">Required field</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <p className="text-sm font-semibold tracking[-0.15px]leading-5 text-brand-neutral-600">2. What is your professional role?</p>

                <div className="pl-4">
                  {errors.professionalRole && !watch('professionalRole').startsWith('Other') && (
                    <div className="flex flex-row items-center gap-x-1 pb-6">
                      <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                      <p className="text-red-700 text-xs font-medium">This is a required question</p>
                    </div>
                  )}
                  <div className="flex flex-col gap-y-7">
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('professionalRole', {
                            required: 'Please select your professional role',
                            onChange: (e) => {
                              if (e.target.value !== 'Law firm') {
                                setValue('areaOfPractice', '')
                                setValue('areaOfPracticeOther', '')
                              }
                              trigger('areaOfPractice')
                            },
                          })}
                          type="radio"
                          id="law-firm"
                          value="Law firm"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="law-firm" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">I work at a law firm</span>
                            <span className="flex text-brand-neutral-500 italic pl-0 lg:pl-1">(attorney, paralegal, legal assistant, etc.)</span>
                          </p>
                        </label>
                      </div>

                      {watch('professionalRole') === 'Law firm' && (
                        <div className="relative w-full h-[36px]" ref={areaOfPracticeRef}>
                          <input
                            type="hidden"
                            {...register('areaOfPractice', {
                              validate: (value) => {
                                if (watch('professionalRole') === 'Law firm' && (!value || value === 'Select area of practice')) {
                                  return 'This is a required field'
                                }
                                return true
                              },
                            })}
                          />
                          <Listbox
                            value={areaOptions.find((option) => option.name === watch('areaOfPractice')) || areaOptions[0]}
                            onChange={(option) => {
                              setValue('areaOfPractice', option.name, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              })
                              if (option.name !== 'Other') {
                                setValue('areaOfPracticeOther', '')
                              }
                            }}
                          >
                            {({ open }) => (
                              <div className="absolute w-full h-[36px] pl-7">
                                <Listbox.Button
                                  className={`
                                    max-w-[456px]
                                    relative w-full cursor-default rounded-[6px] bg-white text-left 
                                    sm:text-sm
                                    ${
                                      errors.areaOfPractice
                                        ? 'border border-red-300 focus:border-red-300 focus:border-1 focus:ring-0'
                                        : open
                                        ? 'border-2 border-brand-500'
                                        : 'border border-brand-neutral-300 focus:border-2 focus:border-brand-500'
                                    }
                                  `}
                                >
                                  <span className="block truncate py-2 pl-4 pr-2">{watch('areaOfPractice') || 'Select area of practice'}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>
                                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <Listbox.Options className="relative z-10 top-0 max-w-[456px] w-full overflow-auto rounded-md bg-white text-base ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    {areaOptions.map((option) => (
                                      <Listbox.Option key={option.id} value={option} disabled={option.disabled}>
                                        {({ active, selected, disabled }) => (
                                          <span
                                            className={`
                                              block truncate relative cursor-default select-none py-2 px-4 
                                              ${active ? 'bg-brand-neutral-100 text-brand-500' : 'text-brand-neutral-700'} 
                                              ${selected ? 'text-brand-neutral-800' : ''}
                                              ${disabled ? 'hidden' : ''}
                                            `}
                                          >
                                            {option.name}
                                          </span>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            )}
                          </Listbox>
                        </div>
                      )}
                      {errors.areaOfPractice && watch('professionalRole') === 'Law firm' && (
                        <div className="flex flex-row items-center gap-x-1 pl-7 mt-[-8px]">
                          <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                          <p className="text-red-700 text-xs font-medium">This is a required field</p>
                        </div>
                      )}
                      {watch('professionalRole') === 'Law firm' && watch('areaOfPractice') === 'Other' && (
                        <div className="pl-[68px]">
                          <div className="flex flex-row">
                            <input
                              type="text"
                              placeholder="Please specify"
                              {...register('areaOfPracticeOther', {
                                validate: (value) => {
                                  if (watch('areaOfPractice') === 'Other' && !value?.trim()) {
                                    return 'Please specify your area of practice'
                                  }
                                  return true
                                },
                              })}
                              className={`
                                max-w-[416px] w-full rounded-md border-0 py-2 pl-4 pr-2.5 
                                text-brand-neutral-900 shadow-sm ring-1 ring-inset 
                                placeholder:text-brand-neutral-500 sm:text-sm sm:leading-5
                                ${errors.areaOfPracticeOther ? 'ring-red-300 focus:ring-red-300' : 'ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'}
                              `}
                            />
                          </div>
                          {errors.areaOfPracticeOther && (
                            <div className="flex flex-row items-center gap-x-1 mt-2">
                              <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                              <p className="text-red-700 text-xs font-medium">{errors.areaOfPracticeOther.message}</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('professionalRole')}
                          type="radio"
                          id="in-house-legal-department"
                          value="In house legal department"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="in-house-legal-department" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">I am part of an in house legal department</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('professionalRole')}
                          type="radio"
                          id="non-legal"
                          value="Non-legal department"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="non-legal" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">I am part of a non-legal department</span>
                            <span className="flex text-brand-neutral-500 italic pl-0 lg:pl-1">(corporate marketing, product, HR, etc.)</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('professionalRole')}
                          type="radio"
                          id="government"
                          value="Government"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="government" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">I work for a government entity</span>
                            <span className="flex text-brand-neutral-500 italic pl-0 lg:pl-1">(AG office, DA office, public defender, office etc.)</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('professionalRole')}
                          type="radio"
                          id="non-profit"
                          value="Non-profit"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="non-profit" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">I work for a non-profit entity</span>
                            <span className="flex text-brand-neutral-500 italic pl-0 lg:pl-1">(bar association, legal aid, etc.)</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('professionalRole')}
                          type="radio"
                          id="law-student"
                          value="Law student"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="law-student" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">I am a law student</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('professionalRole', {
                            required: 'Please select your professional role',
                          })}
                          type="radio"
                          id="role-other"
                          value="Other"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="role-other" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Other</span>
                          </p>
                        </label>
                      </div>
                      {watch('professionalRole') === 'Other' && (
                        <div className="pl-8">
                          <div className="flex flex-col gap-y-2">
                            <input
                              type="text"
                              placeholder="Please specify"
                              {...register('professionalRoleOther', {
                                validate: (value) => {
                                  if (watch('professionalRole') === 'Other' && !value?.trim()) {
                                    return 'Please specify your professional role'
                                  }
                                  return true
                                },
                              })}
                              className={`
                                max-w-[456px] w-full rounded-md border-0 py-2 pl-4 pr-2.5 
                                text-brand-neutral-900 shadow-sm ring-1 ring-inset 
                                placeholder:text-brand-neutral-500 sm:text-sm sm:leading-5
                                ${errors.professionalRoleOther ? 'ring-red-300 focus:ring-red-300' : 'ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'}
                              `}
                            />
                            {errors.professionalRoleOther && (
                              <div className="flex flex-row items-center gap-x-1">
                                <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                                <p className="text-red-700 text-xs font-medium">{errors.professionalRoleOther.message}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <p className="text-sm font-semibold tracking[-0.15px]leading-5 text-brand-neutral-600">3. What is the size of your firm/ company?</p>
                <div className="pl-4">
                  {errors.firmSize && (
                    <div className="flex flex-row items-center gap-x-1 pb-6">
                      <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                      <p className="text-red-700 text-xs font-medium">This is a required question</p>
                    </div>
                  )}
                  <div className="flex flex-col gap-y-7">
                    {firmSizeOptions.slice(1).map((option) => (
                      <div key={option.id} className="flex flex-col gap-y-4">
                        <div className="flex items-start gap-x-3">
                          <input
                            {...register('firmSize', { required: 'Please select your firm size' })}
                            type="radio"
                            id={`firm-size-${option.id}`}
                            value={option.name}
                            className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                          />
                          <label htmlFor={`firm-size-${option.id}`} className="cursor-pointer text-sm leading-sm">
                            <p className="text-sm leading-sm flex flex-col lg:flex-row">
                              <span className="flex font-medium text-brand-neutral-900">{option.name}</span>
                            </p>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend className="sr-only">
            How {brandedAIFriendlyName} best assist you? This information helps {brandedAIFriendlyName} recommend relevant features and workflows.
          </legend>
          <div className="flex flex-col sm:flex-row items-baseline gap-10 py-8 relative after:absolute after:bottom-0 after:left-[-24px] after:right-[-24px] sm:after:left-0 sm:after:right-0 after:h-[1px] after:bg-brand-neutral-300">
            <div className="w-full sm:w-[380px] sm:shrink-0">
              <p className="font-semibold leading-7 font-base text-brand-neutral-950">How can {brandedAIFriendlyName} best assist you?</p>
              <p className="text-[13px] max-w-[348px] font- leading-5 italic tracking-[0.15px] text-brand-neutral-600">
                This information helps {brandedAIFriendlyName} recommend relevant features and workflows.
              </p>
            </div>
            <div className="w-full flex-1 flex flex-col gap-y-12">
              <div className="flex flex-col gap-y-4" ref={rankingsRef}>
                <p className="text-sm font-semibold tracking[-0.15px]leading-5 text-brand-neutral-600">
                  4. How do you plan on using {brandedAIFriendlyName} AI? Rank your top 3 choices.
                </p>
                {errors.rankingFirstChoice && (
                  <div className="flex flex-row items-center gap-x-1 pl-[17px]">
                    <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                    <p className="text-red-700 text-xs font-medium">This is a required question</p>
                  </div>
                )}
                <div className="flex flex-col gap-y-[14px]">
                  <div>
                    <div className="grid grid-cols-[minmax(0,1fr),57px,57px,57px] lg:grid-cols-[minmax(0,1fr),120px,120px,120px] gap-x-0">
                      <span className="flex"></span>
                      <span className="text-brand-neutral-950 leading-sm py-[10px] font-medium text-center text-sm">1st choice</span>
                      <span className="text-brand-neutral-950 leading-sm py-[10px] font-medium text-center text-sm">2nd choice</span>
                      <span className="text-brand-neutral-950 leading-sm py-[10px] font-medium text-center text-sm">3rd choice</span>
                    </div>
                    <div className={`rounded-lg border ${errors.rankingFirstChoice ? 'border-red-300' : 'border-brand-neutral-200'}`}>
                      <input
                        type="hidden"
                        {...register('rankingFirstChoice', {
                          required: true,
                          validate: (value) => value !== '',
                        })}
                      />
                      <input type="hidden" {...register('rankingSecondChoice')} />
                      <input type="hidden" {...register('rankingThirdChoice')} />
                      {rankQuestions.map((option, index) => (
                        <div key={option}>
                          <div
                            className={`grid grid-cols-[minmax(0,1fr),57px,57px,57px] lg:grid-cols-[minmax(0,1fr),120px,120px,120px] gap-x-0
                              items-start bg-white py-[14px] pl-5 pr-1 
                              ${index !== rankQuestions.length - 1 ? 'border-b border-brand-neutral-200' : ''}
                              ${index === 0 ? 'rounded-t-lg' : ''}
                              ${index === rankQuestions.length - 1 ? 'rounded-b-lg' : ''}
                            `}
                          >
                            <div className="contents">
                              <div className="flex flex-col gap-y-[14px]">
                                <span className="text-sm leading-sm font-medium text-brand-neutral-950 tracking-[-0.15px]">{option}</span>
                                {option === 'Other' && (
                                  <>
                                    {questionRanks[index] === 1 && (
                                      <div className="flex flex-col gap-y-2">
                                        <input
                                          type="text"
                                          placeholder="Please specify"
                                          {...register('rankingFirstChoiceOther', {
                                            validate: (value) => {
                                              if (watch('rankingFirstChoice') === 'Other' && !value?.trim()) {
                                                return 'Please specify your first choice'
                                              }
                                              return true
                                            },
                                          })}
                                          className={`
                                            w-[calc(100%+150px)] lg:w-full rounded-md border-0 py-2 pl-4 pr-[10px] 
                                            text-brand-neutral-900 shadow-sm ring-1 ring-inset 
                                            placeholder:text-brand-neutral-500 lg:text-sm lg:leading-5
                                            ${
                                              errors.rankingFirstChoiceOther
                                                ? 'ring-red-300 focus:ring-red-300'
                                                : 'ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'
                                            }
                                          `}
                                        />
                                        {errors.rankingFirstChoiceOther && (
                                          <div className="flex flex-row items-center gap-x-1">
                                            <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                                            <p className="text-red-700 text-xs font-medium">{errors.rankingFirstChoiceOther.message}</p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {questionRanks[index] === 2 && (
                                      <div className="flex flex-col gap-y-2">
                                        <input
                                          type="text"
                                          placeholder="Please specify"
                                          {...register('rankingSecondChoiceOther', {
                                            validate: (value) => {
                                              if (watch('rankingSecondChoice') === 'Other' && !value?.trim()) {
                                                return 'Please specify your second choice'
                                              }
                                              return true
                                            },
                                          })}
                                          className={`
                                            w-[calc(100%+150px)] lg:w-full rounded-md border-0 py-2 pl-4 pr-[10px] 
                                            text-brand-neutral-900 shadow-sm ring-1 ring-inset 
                                            placeholder:text-brand-neutral-500 lg:text-sm lg:leading-5
                                            ${
                                              errors.rankingSecondChoiceOther
                                                ? 'ring-red-300 focus:ring-red-300'
                                                : 'ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'
                                            }
                                          `}
                                        />
                                        {errors.rankingSecondChoiceOther && (
                                          <div className="flex flex-row items-center gap-x-1">
                                            <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                                            <p className="text-red-700 text-xs font-medium">{errors.rankingSecondChoiceOther.message}</p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {questionRanks[index] === 3 && (
                                      <div className="flex flex-col gap-y-2">
                                        <input
                                          type="text"
                                          placeholder="Please specify"
                                          {...register('rankingThirdChoiceOther', {
                                            validate: (value) => {
                                              if (watch('rankingThirdChoice') === 'Other' && !value?.trim()) {
                                                return 'Please specify your third choice'
                                              }
                                              return true
                                            },
                                          })}
                                          className={`
                                            w-[calc(100%+150px)] lg:w-full rounded-md border-0 py-2 pl-4 pr-[10px] 
                                            text-brand-neutral-900 shadow-sm ring-1 ring-inset 
                                            placeholder:text-brand-neutral-500 lg:text-sm lg:leading-5
                                            ${
                                              errors.rankingThirdChoiceOther
                                                ? 'ring-red-300 focus:ring-red-300'
                                                : 'ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'
                                            }
                                          `}
                                        />
                                        {errors.rankingThirdChoiceOther && (
                                          <div className="flex flex-row items-center gap-x-1">
                                            <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                                            <p className="text-red-700 text-xs font-medium">{errors.rankingThirdChoiceOther.message}</p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="flex justify-center pt-1">
                                <input
                                  key={`radio-${index}-${questionRanks[index] === 1}-${updateTrigger}`}
                                  type="radio"
                                  checked={questionRanks[index] === 1}
                                  onChange={() => handleRankChange(index, 1)}
                                  aria-label={`${option} as 1st choice`}
                                  className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%]"
                                />
                              </div>
                              <div className="flex justify-center pt-1">
                                <input
                                  key={`radio-${index}-${questionRanks[index] === 2}-${updateTrigger}`}
                                  type="radio"
                                  checked={questionRanks[index] === 2}
                                  onChange={() => handleRankChange(index, 2)}
                                  onClick={(e) => {
                                    // If already selected, prevent default and trigger deselection
                                    if (questionRanks[index] === 2) {
                                      e.preventDefault()
                                      handleRankChange(index, 2)
                                    }
                                  }}
                                  aria-label={`${option} as 2nd choice`}
                                  className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%]"
                                />
                              </div>
                              <div className="flex justify-center pt-1">
                                <input
                                  key={`radio-${index}-${questionRanks[index] === 3}-${updateTrigger}`}
                                  type="radio"
                                  checked={questionRanks[index] === 3}
                                  onChange={() => handleRankChange(index, 3)}
                                  onClick={(e) => {
                                    // If already selected, prevent default and trigger deselection
                                    if (questionRanks[index] === 3) {
                                      e.preventDefault()
                                      handleRankChange(index, 3)
                                    }
                                  }}
                                  aria-label={`${option} as 3rd choice`}
                                  className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%]"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend className="sr-only">
            How did you find {brandedAIFriendlyName}? Your feedback helps us understand how we can better reach and serve legal professionals like you.
          </legend>
          <div className="flex flex-col sm:flex-row items-baseline gap-10 py-8 relative after:absolute after:bottom-0 after:left-[-24px] after:right-[-24px] sm:after:left-0 sm:after:right-0 after:h-[1px] after:bg-brand-neutral-300">
            <div className="w-full sm:w-[380px] sm:shrink-0">
              <p className="font-semibold leading-7 font-base text-brand-neutral-950">How did you find {brandedAIFriendlyName}?</p>
              <p className="text-[13px] max-w-[348px] font- leading-5 italic tracking-[0.15px] text-brand-neutral-600">
                Your feedback helps us understand how we can better reach and serve legal professionals like you.
              </p>
            </div>
            <div className="w-full flex-1 flex flex-col gap-y-12">
              <div className="flex flex-col gap-y-4">
                <p className="text-sm font-semibold tracking[-0.15px]leading-5 text-brand-neutral-600">
                  5. How did you first hear about about {brandedAIFriendlyName}?
                </p>
                {errors.paxtonReferrer && !watch('paxtonReferrer') && (
                  <div className="flex flex-row items-center gap-x-1 pb-2 pl-[17px]">
                    <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                    <p className="text-red-700 text-xs font-medium">This is a required question</p>
                  </div>
                )}
                <div className="pl-4">
                  <div className="flex flex-col gap-y-7">
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer', {
                            required: `Please select how you heard about ${brandedAIFriendlyName}`,
                            validate: (value) => {
                              if (value === 'Social media') {
                                return 'Please select a social network'
                              }
                              return true
                            },
                          })}
                          type="radio"
                          id="search-engine"
                          value="Search engine"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="search-engine" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Search engine</span>
                            <span className="flex text-brand-neutral-500 italic pl-0 lg:pl-1">(Google, Bing)</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer')}
                          type="radio"
                          id="professional-referral"
                          value="Professional referral / colleague"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="professional-referral" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Professional referral / colleague</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer')}
                          type="radio"
                          id="legal-association"
                          value="Legal association or bar membership"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="legal-association" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Legal association or bar membership</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer')}
                          type="radio"
                          id="legal-event"
                          value="Legal industry event or conference"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="legal-event" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Legal industry event or conference</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer')}
                          type="radio"
                          id="media-coverage"
                          value="Media coverage"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="media-coverage" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Media coverage</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4 ">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer', {
                            required: `Please select how you heard about ${brandedAIFriendlyName}`,
                            validate: (value) => {
                              if (value === 'Social media' && !watch('socialNetwork')) {
                                return 'Please select a social network'
                              }
                              return true
                            },
                          })}
                          type="radio"
                          id="social-media"
                          value="Social media"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="social-media" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Social media</span>
                          </p>
                        </label>
                      </div>
                      {watch('paxtonReferrer') === 'Social media' && (
                        <div className="relative pl-7 w-full h-[36px]" ref={socialMediaRef}>
                          <input
                            type="hidden"
                            {...register('socialNetwork', {
                              validate: (value) => {
                                if (watch('paxtonReferrer') === 'Social media' && !value?.trim()) {
                                  return 'Please select a social network'
                                }
                                return true
                              },
                            })}
                          />
                          <Listbox
                            value={watch('socialNetwork') || ''}
                            onChange={(value) => {
                              setValue('socialNetwork', value, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              })
                            }}
                          >
                            {({ open }) => (
                              <div className="absolute w-full h-[36px]">
                                <Listbox.Button
                                  className={`
                                    max-w-[456px] 
                                    relative w-full cursor-default rounded-[6px] bg-white text-left sm:text-sm
                                    ${
                                      errors.socialNetwork
                                        ? 'border border-red-300 focus:border-red-300'
                                        : open
                                        ? 'border-2 border-brand-500'
                                        : 'border border-brand-neutral-300 focus:border-2 focus:border-brand-500'
                                    }
                                  `}
                                >
                                  <span className="block truncate py-2 pl-4 pr-2">{watch('socialNetwork') || 'Select network'}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>
                                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <Listbox.Options className="relative z-10 top-0 max-w-[456px] w-full overflow-auto rounded-md bg-white text-base ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    {[
                                      { id: 1, name: 'LinkedIn' },
                                      { id: 2, name: 'Meta/ Facebook' },
                                      { id: 3, name: 'X/ Twitter' },
                                      { id: 4, name: 'TikTok' },
                                    ].map((option) => (
                                      <Listbox.Option
                                        key={option.id}
                                        value={option.name}
                                        className={({ active }) => `
                                          block truncate relative cursor-default select-none py-2 px-4
                                          ${active ? 'bg-brand-neutral-100 text-brand-500' : 'text-brand-neutral-700'}
                                        `}
                                      >
                                        {option.name}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            )}
                          </Listbox>
                        </div>
                      )}
                      {watch('paxtonReferrer') === 'Social media' && !watch('socialNetwork') && errors.socialNetwork && (
                        <div className="flex flex-row items-center gap-x-1 pb-2 pl-7 mt-[-8px]">
                          <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                          <p className="text-red-700 text-xs font-medium">{errors.socialNetwork.message}</p>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer')}
                          type="radio"
                          id="law-school"
                          value="Law School"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0"
                        />
                        <label htmlFor="law-school" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Law School</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer')}
                          type="radio"
                          id="continuing-education"
                          value="Continuing legal education"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0 sm:mt-0 sm:mt-0"
                        />
                        <label htmlFor="continuing-education" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Continuing legal education</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer')}
                          type="radio"
                          id="advertisements"
                          value="Advertisements"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0 sm:mt-0"
                        />
                        <label htmlFor="advertisements" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Advertisements</span>
                          </p>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex items-start gap-x-3">
                        <input
                          {...register('paxtonReferrer', {
                            required: `Please select how you heard about ${brandedAIFriendlyName}`,
                          })}
                          type="radio"
                          id="referrer-other"
                          value="Other"
                          className="h-5 w-5 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 [&:checked]:bg-[length:120%_120%] mt-0.5 sm:mt-0 sm:mt-0"
                        />
                        <label htmlFor="referrer-other" className="cursor-pointer text-sm leading-sm">
                          <p className="text-sm leading-sm flex flex-col lg:flex-row">
                            <span className="flex font-medium text-brand-neutral-900">Other</span>
                          </p>
                        </label>
                      </div>
                      {watch('paxtonReferrer') === 'Other' && (
                        <div className="pl-8">
                          <div className="flex flex-col gap-y-2">
                            <input
                              type="text"
                              placeholder="Please specify"
                              {...register('paxtonReferrerOther', {
                                validate: (value) => {
                                  if (watch('paxtonReferrer') === 'Other' && !value?.trim()) {
                                    return `Please specify how you heard about ${brandedAIFriendlyName}`
                                  }
                                  return true
                                },
                              })}
                              className={`
                                max-w-[456px] w-full rounded-md border-0 py-2 pl-4 pr-2.5 
                                text-brand-neutral-900 shadow-sm ring-1 ring-inset 
                                placeholder:text-brand-neutral-500 sm:text-sm sm:leading-5
                                ${errors.paxtonReferrerOther ? 'ring-red-300 focus:ring-red-300' : 'ring-brand-neutral-300 focus:ring-2 focus:ring-brand-500'}
                              `}
                            />
                            {errors.paxtonReferrerOther && (
                              <div className="flex flex-row items-center gap-x-1">
                                <AlertErrorCircle className="flex h-[14px] w-[14px] text-red-700" />
                                <p className="text-red-700 text-xs font-medium">{errors.paxtonReferrerOther.message}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="flex items-center justify-end p-3 space-x-4">
        {surveySubmissionError ? (
          <p className="text-sm text-red-700 italic">
            We were unable to submit your survey. Please try again. If the issue persists, please refresh the page and try again.
          </p>
        ) : success ? (
          <p className="text-sm text-brand-500 italic">Survey submitted successfully!</p>
        ) : (
          <p className="text-sm text-brand-neutral-500 italic">Please complete all questions to submit.</p>
        )}
        <button
          type="submit"
          disabled={success || isSubmitting || error}
          className={`
            rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm 
            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500
            ${isValid && !isSubmitting && !success ? 'bg-brand-500 hover:bg-brand-400' : 'bg-brand-500/50 cursor-not-allowed'}
          `}
        >
          {isSubmitting ? 'Submitting...' : success ? 'Submitted' : 'Submit'}
        </button>
      </div>
    </form>
  )
}

export default OnboardingWelcome
