import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { TrashDelete } from '@/assets/icons'
import * as Dialog from '@radix-ui/react-dialog'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { FilesDriveDialogType, uiStateSlice } from '@/store/slices/ui-state.slice'
import { buildMessage } from '../../helpers/handleDeleteConfirmationMessages'
import { FilesDriveActions } from '../../store/files-drive.slice'

type ConfirmationDeleteProps = {
  onOpen?: () => void // Callback when dialog opens/closes - used to assign the active item when opening the dialog through the FileActionMenu
}

/**
 * Confirmation dialog for deleting files and folders.
 * Handles both single item deletion via FileActionsMenu and bulk deletion via toolbar.
 * Shows contextual messages based on selection type (single file/folder, multiple files/folders).
 */
export default function ConfirmationDelete({ onOpen }: ConfirmationDeleteProps) {
  // Active file sets represents the FileActionMenu was trigger.
  // Identifying this dialog as a Single File deletion action.
  const activeItem = useAppSelector((state) => state.filesDrive.activeItem)
  const isSingleFile = Boolean(activeItem)

  // State containing selected folders id or files ids can indicate that files were selected.
  const { folders_ids, files_ids } = useAppSelector((state) => state.filesDrive.selectedFilesAndFolders)
  const isMultipleItemsType = folders_ids.length > 0 && files_ids.length > 0

  const isDialogVisible = useAppSelector((state) => state.uiState.filesDriveDialogVisible)
  const isDialogType = useAppSelector((state) => state.uiState.filesDriveDialogType)

  // Check if the dialog is open and the type is delete
  const isDialogOpen = isDialogVisible && isDialogType === FilesDriveDialogType.DELETE

  const isDeletingItems = useAppSelector((state) => state.filesDrive.isDeletingItems)

  const dispatch = useAppDispatch()

  if (isMultipleItemsType && isSingleFile) {
    console.error(`Can't perform action on both selected and active items`)
    return
  }

  const fileCount = files_ids.length
  const folderCount = folders_ids.length
  const deleteMessage = buildMessage(fileCount, folderCount, activeItem, isSingleFile)

  const defaultTitle = isSingleFile ? 'Delete File Permanently?' : 'Delete Items Permanently?'

  const handleConfirm = async () => {
    if (isSingleFile) {
      dispatch(
        FilesDriveActions.removeItems({
          fileIds: [activeItem?.id ?? ''],
          folderIds: [],
        })
      )
    } else {
      dispatch(
        FilesDriveActions.removeItems({
          fileIds: files_ids,
          folderIds: folders_ids,
        })
      )
    }
  }

  const handleCloseDialog = () => {
    dispatch(FilesDriveActions.clearActiveFile())
    dispatch(uiStateSlice.actions.setFilesDriveDialogVisible(false))
    dispatch(uiStateSlice.actions.setFilesDriveDialogType(null))
    dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files_ids: [], folders_ids: [] }))
  }

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={onOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-brand-neutral-50 p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="m-0 text-[17px] font-medium">
            <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
              <TrashDelete className="h-10 w-10 p-2 scale-75 rounded-full text-brand-700 bg-brand-100" />
              {defaultTitle}
            </h2>
          </Dialog.Title>
          <Dialog.Description className="mt-[10px] mb-5 text-[15px] leading-normal">
            <span className="mt-2 ml-4">{deleteMessage}</span>
          </Dialog.Description>
          <div className="mt-[25px] gap-3 flex justify-end">
            <Dialog.Close asChild>
              <button
                className="bg-brand-neutral-50 text-brand-neutral-900 ring-brand-neutral-300 hover:bg-brand-neutral-100 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset sm:w-auto h-auto"
                onClick={handleCloseDialog}
              >
                Cancel
              </button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <button
                disabled={isDeletingItems}
                className="bg-brand-500 text-white hover:bg-brand-400 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm sm:w-auto h-auto disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => handleConfirm()}
              >
                <div>{isDeletingItems ? 'Deleting...' : 'Delete'}</div>
                {isDeletingItems && <CircularProgressContinuousSized size={12} thickness={7} hexColor={'#ffffff'} />}
              </button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
