import { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { FolderIcon } from '@heroicons/react/24/outline'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { FilesDriveDialogType, uiStateSlice } from '@/store/slices/ui-state.slice'
import { FilesDriveActions } from '../../store/files-drive.slice'

export default function CreateFolderDialog() {
  const [error, setError] = useState<string | null>(null)

  const dispatch = useAppDispatch()

  const isDialogVisible = useAppSelector((state) => state.uiState.filesDriveDialogVisible)
  const isDialogType = useAppSelector((state) => state.uiState.filesDriveDialogType)

  const isCreatingFolder = useAppSelector((state) => state.filesDrive.isCreatingFolder)

  // Check if the dialog is open and the type is create folder
  const isDialogOpen = isDialogVisible && isDialogType === FilesDriveDialogType.CREATE_FOLDER

  const currentFolderId = useAppSelector((state) => state.filesDrive.currentFolder?.id) ?? ''

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value.trim() === '') {
      setError('Folder name is required')
    }
    if (value.length === 0) {
      setError('Folder name is required')
    }
    if (value.length >= 255) {
      setError('Folder name is too long')
    }
    setError(null)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (e.currentTarget.folderName.value.trim() === '') {
      setError('Folder name is required')
      return
    }

    const folderName = e.currentTarget.folderName.value

    dispatch(FilesDriveActions.createFolder({
      folderName,
      parentFolderId: currentFolderId
    }))
  }

  const handleCloseDialog = () => {
    dispatch(FilesDriveActions.clearActiveFile())
    dispatch(uiStateSlice.actions.setFilesDriveDialogVisible(false))
    dispatch(uiStateSlice.actions.setFilesDriveDialogType(null))
    setError('')
  }

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={handleCloseDialog}>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[70vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-brand-neutral-50 p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none min-w-[275px] max-w-2xl sm:min-w-[600px]">
          <form onSubmit={handleSubmit}>
            <Dialog.Title className="relative transform overflow-hidden bg-brand-neutral-50 py-4">
              <div className="sm:mt-0 sm:text-left w-full">
                <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
                  <FolderIcon className="h-10 p-2 rounded-full text-brand-700 bg-brand-100" />
                  New folder
                </h2>
              </div>

            </Dialog.Title>
            <input
              className="block w-full rounded-md border-0 p-2 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 focus:ring-2 focus:ring-inset focus:ring-brand-700 sm:text-sm sm:leading-6"
              id="folderName"
              autoFocus={true}
              name="folderName"
              placeholder="Folder name"
              maxLength={255}
              onChange={handleOnChange}
            />
            {error && <p className={'text-red-700'}>{error}</p>}

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                disabled={isCreatingFolder}
                className="inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 sm:ml-3 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isCreatingFolder ? 'Creating...' : 'Create'}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-100 sm:mt-0 sm:w-auto"
                onClick={() => {
                  handleCloseDialog()
                }}
              >
                Cancel
              </button>
            </div>
          </form>

        </Dialog.Content >
      </Dialog.Portal >
    </Dialog.Root >
  )
}
