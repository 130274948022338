import ChatControlsAssistant from '@/chat-common/components/form-source-controls/assistant/chat-controls-assistant'
import AgentChatTextArea from './AgentChatTextArea'

type AgentChatFormWrapperProps = {
  conversationId: string
}

export default function AgentChatFormWrapper(props: AgentChatFormWrapperProps) {
  const { conversationId } = props

  return (
    <div className="relative w-full max-w-3xl m-auto border-t pt-2">
      <ChatControlsAssistant conversationId={conversationId} />
      <AgentChatTextArea conversationId={conversationId} />
    </div>
  )
}
