import { z } from 'zod'

/**
 * Error type enum for clear categorization
 */
export enum PaymentErrorType {
  // Level 2: Payment valid but rejected (user errors)
  USER_PAYMENT_ERROR = 'USER_PAYMENT_ERROR',

  // Level 3: System errors (API failures, etc.)
  SYSTEM_ERROR = 'SYSTEM_ERROR',
}

/**
 * Clean error classes for payment errors
 */
export class UserPaymentError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'UserPaymentError'
  }
}

export class SystemPaymentError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'SystemPaymentError'
  }
}

/**
 * Zod schema for Stripe errors.
 *
 * Based on Stripe API error documentation: https://docs.stripe.com/api/errors
 */

// Schema for object references
const objectReferenceSchema = z.object({}).passthrough()

// Main Stripe error schema
export const stripeErrorSchema = z.object({
  // Core attributes
  type: z.string().describe('One of api_error, card_error, idempotency_error, or invalid_request_error'),
  code: z.string().nullable().optional().describe('Short string indicating the error code reported'),
  param: z.string().nullable().optional().describe('Parameter related to the error'),
  message: z.string().describe('Human-readable message providing more details about the error'),
  decline_code: z.string().nullable().optional().describe("For card errors, the card issuer's reason for the decline"),
  http_status: z.number().int().nullable().optional().describe('HTTP status code'),

  // Additional attributes
  doc_url: z.string().nullable().optional().describe('URL to Stripe documentation for the error'),
  advice_code: z.string().nullable().optional().describe('Additional advice code'),
  charge: z.string().nullable().optional().describe('Related charge ID'),
  network_advice_code: z.string().nullable().optional().describe('Network advice code'),
  network_decline_code: z.string().nullable().optional().describe('Network decline code'),
  payment_method_type: z.string().nullable().optional().describe('Type of payment method'),
  request_log_url: z.string().nullable().optional().describe('URL to request logs'),

  // Object references
  payment_intent: objectReferenceSchema.nullable().optional().describe('PaymentIntent object for errors on a PaymentIntent request'),
  payment_method: objectReferenceSchema.nullable().optional().describe('PaymentMethod object'),
  setup_intent: objectReferenceSchema.nullable().optional().describe('SetupIntent object'),
  source: objectReferenceSchema.nullable().optional().describe('Source object'),

  // Custom field for user-friendly messages
  user_message: z.string().nullable().optional().describe('User-friendly message for display'),
})

// Type definition derived from the schema
export type StripeError = z.infer<typeof stripeErrorSchema>

// define a generic error type for everything else
export const genericPaymentErrorSchema = z.object({
  message: z.string(),
})

export type GenericPaymentError = z.infer<typeof genericPaymentErrorSchema>

// Schema for API responses containing a Stripe error
export const paymentErrorResponseSchema = z.object({
  error: stripeErrorSchema.or(genericPaymentErrorSchema),
})

export type PaymentErrorResponse = z.infer<typeof paymentErrorResponseSchema>
