import * as Popover from '@radix-ui/react-popover'
import { XClear, OpenNew } from '@/assets/icons'
import { ChatV2Actions } from '../store/chat-v2.slice'
import { useAppDispatch } from '@/store/store-hooks'
import { Link } from 'react-router-dom'
import { isCitatorEnabled } from './reference-viewer/reference-view-utils'
import { ChatV2Message } from '../schemas/chat-v2.schemas'

type ChatFootnotePopoverProps = {
  referenceRecordKey: string
  footnoteText: string
  content: string | React.ReactNode
  message: ChatV2Message
  highlight: boolean
  invalid?: boolean
  references: Record<string, any>
}

export default function ChatFootnotePopover(props: ChatFootnotePopoverProps) {
  const { referenceRecordKey, footnoteText, content, message, highlight, invalid, references } = props
  const dispatch = useAppDispatch()

  const reference = references[referenceRecordKey]
  const parent_id = reference?.metadata.parent_id
  const feature = message.metadata.feature

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={`${invalid ? 'text-brand-neutral-500' : 'text-brand-800'} px-1 hover:text-brand-400 ${
            highlight ? 'bg-brand-500 bg-opacity-20 rounded-md' : ''
          }`}
          aria-label={`Footnote ${footnoteText}`}
        >
          {footnoteText}
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="rounded p-5 pt-6 max-w-xs xs:max-w-screen-xs sm:max-w-screen-sm bg-brand-neutral-50 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <div className={'text-xs whitespace-pre-wrap'}>{content}</div>

          {!invalid && (
            <>
              <button
                onClick={() => {
                  dispatch(
                    ChatV2Actions.setVisibleReference({
                      conversationId: message.metadata.conversation_id,
                      reference: reference,
                    })
                  )
                }}
                className={`font-bold text-xs text-brand-800 cursor-pointer hover:underline inline-block`}
              >
                [{reference.reference_number}] View source
              </button>
              <div className={'h-1'}></div>
              {isCitatorEnabled(feature, reference) && parent_id && (
                <Link
                  to={`/dashboard/reference-viewer/caselaw/${reference?.metadata.parent_id}`}
                  target="_blank"
                  className={'flex items-end text-brand-800 font-bold text-xs cursor-pointer hover:underline'}
                >
                  <span className={''}>New tab</span> <OpenNew className={'w-4 h-4 pl-[4px] pb-[0px]'} />
                </Link>
              )}
            </>
          )}

          <Popover.Close
            className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-brand-neutral-500 absolute top-[5px] right-[5px] hover:bg-brand-neutral-100 outline-none cursor-default"
            aria-label="Close"
          >
            <XClear />
          </Popover.Close>
          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
