import { getAuth } from 'firebase/auth'
import { store } from '@/store/store'

/**
 * Constructs the filepath for an artifact version.
 *
 * @param artifactVersion - The artifact version data object.
 * @returns The constructed filepath or null if the user is not authenticated.
 */
export function constructArtifactFilePath(artifactVersionId: string): string {
  // Get the user's ID
  const user = getAuth().currentUser
  const userId = user?.uid
  if (!userId) throw new Error('User not authenticated')

  // Get the artifactVersion from state
  const artifactVersion = store.getState().artifactsState.artifactsVersions[artifactVersionId]
  if (!artifactVersion) throw new Error(`Artifact version ${artifactVersionId} not found in state`)

  // Get the artifact and version IDs
  const { artifact_id, id: version_id } = artifactVersion

  // Construct and return the filepath
  return `users/${userId}/${artifact_id}/${version_id}.md`
}
