import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import ArrowOutward from '@mui/icons-material/ArrowOutward'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { artifactNameFallback } from '@/constants/constants-artifact'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { ArtifactVersionRead, ArtifactVersionStatusType } from '@/artifacts/schemas'
import { RootState } from '@/store/store'
import { selectConversationWebSocketConnected } from '@/websocket_v2/ws2.selectors'
import { ContentViewerActions } from '@/content-viewer/store/slice'
import { selectIdentifierMatchesActiveContentViewer } from '@/content-viewer/store/selectors'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'

type ArtifactButtonProps = {
  conversationId: string
  artifactVersion: ArtifactVersionRead
  onCancelGenerating: () => void
}

/**
 * ArtifactButton
 *
 * Renders a single button that either shows a "Generating..." state, an "Error" state,
 * or a "View Artifact" button based on the artifact's status.
 */
export default function ArtifactButton(props: ArtifactButtonProps) {
  const { conversationId, artifactVersion, onCancelGenerating } = props
  const dispatch = useAppDispatch()

  // Check the conversation's websocket connection status
  const isConnected = useAppSelector((state: RootState) => selectConversationWebSocketConnected(state, { conversationId }))

  // Check if this is active in the content viewer
  const isActive = useAppSelector((state: RootState) => selectIdentifierMatchesActiveContentViewer(state, { id: artifactVersion.id }))

  // Fallback to artifact name if artifact version doesn't have a name
  const name = artifactVersion.name || artifactNameFallback

  // Handle View Artifact
  const handleViewArtifact = () => {
    // Set the content viewer state with the artifact version
    dispatch(ContentViewerActions.setArtifactVersion({ artifactVersion }))

    // Set the active content viewer for the conversation
    dispatch(AgentConversationsActions.setConversationActiveContentViewerArtifactVersion({ conversationId, artifactVersion }))
  }

  const renderButton = () => {
    switch (artifactVersion.status) {
      case ArtifactVersionStatusType.GENERATING: {
        return (
          <div className="grid grid-cols-[60px_minmax(0,1fr)] items-center min-h-[62px] w-full max-w-[420px] rounded-[5px] overflow-hidden bg-gray-50 border border-gray-300">
            <div className="bg-gray-100 flex items-center justify-center w-full h-full border-r border-gray-300">
              <CircularProgressContinuousSized size={20} thickness={4} showTrack />
            </div>

            <div className="flex justify-between flex-wrap gap-x-2 gap-y-1 p-3 items-baseline">
              <div className="text-[13px] text-left font-medium leading-tight">Generating...</div>

              {/* Can only cancel generation if the websocket connection is open. */}
              {isConnected ? (
                <button
                  onClick={onCancelGenerating}
                  aria-label="Cancel generating document"
                  className="leading-none text-[12px] text-red-700 font-normal cursor-pointer inline"
                >
                  Cancel
                </button>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
          </div>
        )
      }

      case ArtifactVersionStatusType.ERROR:
      case ArtifactVersionStatusType.CANCELLED: {
        // Hide the button if draft generation was cancelled/errored, as a corresponding event bubble will already be shown for each case
        return null
      }

      case ArtifactVersionStatusType.COMPLETED: {
        return (
          // TODO: #B2B2B2 is one of the colours in the new true gray ramp, to be added in ENG-5284
          // Replace the arbitrary hex codes with the new variable once it has been added
          <button
            onClick={handleViewArtifact}
            className={`grid grid-cols-[60px_minmax(0,1fr)] items-center min-h-[62px] w-full max-w-[420px] rounded-[5px] overflow-hidden border  ${
              isActive ? 'bg-gray-100 border-[#B2B2B2]' : 'bg-gray-50 border-gray-300'
            }`}
            aria-label={`View artifact: ${name}`}
          >
            <div
              className={`flex items-center justify-center w-full h-full border-r ${
                isActive ? 'bg-gray-200 border-[#B2B2B2] text-brand-500' : 'bg-gray-100 border-gray-300 text-gray-400'
              }`}
            >
              <TextSnippetIcon />
            </div>

            <div className="flex flex-col justify-center p-3">
              <div className="text-[13px] text-left font-medium leading-tight mb-1 truncate" title={name}>
                {name}
              </div>
              <div className="text-[12px] text-gray-500 flex items-center leading-tight">
                Open
                {isActive ? (
                  // Indicator dot when the artifact is active
                  <div className="ml-[4.5px] mt-[1px] w-[5px] h-[5px] bg-brand-500 rounded-full" />
                ) : (
                  <ArrowOutward sx={{ fontSize: 14, marginLeft: '3px', marginBottom: '1px' }} />
                )}
              </div>
            </div>
          </button>
        )
      }

      default: {
        // If a new status is added in the future, TypeScript will flag this as an error
        const _exhaustiveCheck: never = artifactVersion.status
        return _exhaustiveCheck
      }
    }
  }

  return renderButton()
}
