import postOnboarding from '../fetch/post-onboarding'

/**
 * Onboarding Hook
 * Hook to initalize the onboarding process for a user
 * @returns {onboarding} function to trigger the onboarding initialization
 */
export function useOnboarding() {
  /**
   * Onboarding
   *
   * Handles:
   * 1. Send the onboarding request to the server
   * 2. Handle success/error callbacks
   *
   * @param onSuccess Optional callback for successful copy
   * @param onError Optional callback for copy errors
   */
  const onboarding = async (onSuccess?: () => void, onError?: (e: any) => void) => {
    try {
      // Submit the onboarding request
      const response = await postOnboarding()

      // Call the onSuccess callback if provided
      if (onSuccess) onSuccess()

      return response
    } catch (e) {
      // Call the onError callback if provided
      if (onError) onError(e)
      throw e
    }
  }

  return { onboarding }
}
