import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { FileNode } from '../schemas/files-drive-schema'
import { useAppDispatch } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { FilesDriveActions } from '../store/files-drive.slice'
import { useAppSelector } from '@/store/store-hooks'
import FilesList from './FilesList'
import FolderList from './FolderList'
import Header from './Header'
import { FilesDriveDialogType } from '@/store/slices/ui-state.slice'
import { downloadDriveFile } from '../helpers/downloadFile'

interface FileDriveListProps {
  currentFolderId: string | null
  loading: boolean
  handleDialogOpen: (dialogType: FilesDriveDialogType) => void
}

export default function FileDriveList({ loading, handleDialogOpen }: FileDriveListProps) {
  // Represents the folders and files in the current folder.
  const { folders, files } = useAppSelector((state: RootState) => state.filesDrive)
  const { files_ids: selectedFiles, folders_ids: selectedFolders } = useAppSelector((state: RootState) => state.filesDrive.selectedFilesAndFolders)

  const dispatch = useAppDispatch()

  // Check if the item is selected against state.filesDrive.selectedFilesAndFolders
  const isItemSelected = (itemId: string) => selectedFiles.includes(itemId) || selectedFolders.includes(itemId)

  function selectAll() {
    dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files_ids: files.map((file) => file.id), folders_ids: folders.map((folder) => folder.id) }))
  }

  function clearAll() {
    dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files_ids: [], folders_ids: [] }))
  }

  async function handleFileDownload(file: FileNode) {
    try {
      await downloadDriveFile(file.source_location, file.name)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: file.name + ' downloaded', durationMs: 2000 }))
    } catch (e) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: file.name + ' download failed', durationMs: 2000 }))
      throw e
    }
  }

  return (

    <div className='px-2'>
      {/* Items List - Drive Table */}
      <Header selectAll={selectAll} clearAll={clearAll} />
      <FolderList folders={folders} selectedFolders={selectedFolders} isItemSelected={isItemSelected} />
      <FilesList files={files} selectedFiles={selectedFiles} isItemSelected={isItemSelected} handleDialogOpen={handleDialogOpen} handleFileDownload={handleFileDownload} loading={loading} />
    </div>

  )
} 
