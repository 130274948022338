import { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AuthContext } from '@/context/auth-context'
import { AnalyticsEvent, AnalyticsEventProperties } from '@/analytics/schema/events.schema.ts'
import { DataLayerObject } from '@/analytics/schema/tag-manager.schema.ts'
import Cookies from 'js-cookie'
import { useParams } from 'react-router-dom'

declare global {
  interface Window {
    dataLayer: Array<DataLayerObject>
  }
}

const getHubSpotTrackingCookie = (): string | undefined => {
  return Cookies.get('hubspotutk')
}

export const useAnalytics = () => {
  const { user, userAccountData } = useContext(AuthContext)
  const { chatFeature } = useParams()

  // Grab HubSpot tracking cookie
  const hubspotUtk = getHubSpotTrackingCookie()

  const trackEvent = <T extends AnalyticsEvent>(
    event: T,
    properties?: Omit<AnalyticsEventProperties[T], 'userId' | 'subscriptionStatus' | 'orgSubscriptionStatus'> & Record<string, string | number | boolean>
  ) => {
    const transaction_id = uuidv4()
    window.dataLayer = window.dataLayer || []

    const eventData: DataLayerObject = {
      event,
      userEmail: user?.email || '',
      userId: user?.uid || '',
      subscriptionStatus: userAccountData?.subscriptionStatus,
      feature: chatFeature || '',
      ...properties,
      hubspotUtk,
      transaction_id,
    }

    // If orgData exists and has subscription_status, we add it
    if (userAccountData?.orgData?.subscription_status) {
      eventData.orgSubscriptionStatus = userAccountData.orgData.subscription_status
    }

    window.dataLayer.push(eventData)
  }

  return { trackEvent }
}
