import { useAppSelector } from "@/store/store-hooks"
import { selectAllItemIds } from "../store/files-drive.selector"


type HeaderProps = {
  selectAll: () => void
  clearAll: () => void
}

export default function Header({ clearAll, selectAll }: HeaderProps) {

  const { files_ids, folders_ids } = useAppSelector(state => state.filesDrive.selectedFilesAndFolders)

  const allItemIds = useAppSelector(state => selectAllItemIds(state))

  function checkAllIsSelected(selectedFiles: string[], allItemIds: string[]) {
    const setA = new Set(selectedFiles)
    const setB = new Set(allItemIds)

    return setA.size === setB.size && [...setA].every(item => setB.has(item))
  }

  const isAllSelected = checkAllIsSelected([...folders_ids, ...files_ids], allItemIds)

  return (
    <div className={'p-2 grid grid-cols-[19px_100%_0px_0px] sm:grid-cols-[19px_auto_166px_0px] md:grid-cols-[19px_auto_175px_82px] items-end overflow-hidden'}>
      <div>
        <input
          type="checkbox"
          checked={isAllSelected}
          className={'w-4 h-4 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm'}
          onChange={() => isAllSelected ? clearAll() : selectAll()}
        />
      </div>
      <p className={'text-sm px-2'}>Name</p>
      <p className={'text-sm px-2 hidden sm:block'}>Last modified</p>
      <p className={'text-sm px-2 hidden md:block'}>File size</p>
    </div>
  )
}
