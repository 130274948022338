import { FolderNode, FileNode } from '../schemas/files-drive-schema'

export enum DeleteMessageType {
  SINGLE_ACTIVE_FILE = 'single-active-file',
  NO_SELECTION = 'no-selection',
  MIXED_SELECTION = 'mixed-selection',
  FILES_ONLY = 'files-only',
  FOLDERS_ONLY = 'folders-only',
}

function pluralize(fileCount: number, plural: string, singular: string) {
  if (fileCount === 1) {
    return singular
  }
  return plural
}

//Variables to create the confirmation message
const getDeleteMessageType = (fileCount: number, folderCount: number, activeItem: FileNode | FolderNode | null, isSingleFile: boolean): DeleteMessageType => {
  if (isSingleFile && activeItem) {
    return DeleteMessageType.SINGLE_ACTIVE_FILE
  }

  if (fileCount > 0 && folderCount > 0) {
    return DeleteMessageType.MIXED_SELECTION
  }

  if (fileCount > 0 && folderCount === 0) {
    return DeleteMessageType.FILES_ONLY
  }

  if (fileCount === 0 && folderCount > 0) {
    return DeleteMessageType.FOLDERS_ONLY
  }

  return DeleteMessageType.NO_SELECTION
}

export const buildMessage = (fileCount: number, folderCount: number, activeItem: FileNode | FolderNode | null, isSingleFile: boolean): string => {
  const messageType = getDeleteMessageType(fileCount, folderCount, activeItem, isSingleFile)
  switch (messageType) {
    case DeleteMessageType.SINGLE_ACTIVE_FILE:
      return `Are you sure you want to delete the file ${activeItem?.name}?`
    case DeleteMessageType.NO_SELECTION:
      return 'No items selected'
    case DeleteMessageType.MIXED_SELECTION:
      return `${fileCount} ${pluralize(fileCount, 'files', 'file')}, ${folderCount} ${pluralize(
        folderCount,
        'folders and their contents',
        'folder and its contents'
      )} will be deleted forever`
    case DeleteMessageType.FILES_ONLY:
      return `${fileCount} ${pluralize(fileCount, 'files', 'file')} will be deleted forever.`
    case DeleteMessageType.FOLDERS_ONLY:
      return `${folderCount} ${pluralize(folderCount, 'folders', 'folder')} and their contents will be deleted forever.`
    default:
      return 'An unexpected error occurred.'
  }
}
