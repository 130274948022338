import { ChevronUp, ChevronDown } from '@/assets/icons'
import { useRef, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { selectSidebarItemState } from '@/store/selectors/ui-state.selectors'
import { setSidebarItemOpen, SidebarItemId } from '@/store/slices/ui-state.slice'
import DashboardSidebarItem, { DashboardSidebarItemType } from './dashboard-sidebar-item'

export type DashboardSidebarCollapsibleGroupProps = {
  title: string
  items: DashboardSidebarItemType[]
  itemId: SidebarItemId
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
}

const DashboardSidebarCollapsibleGroup = (props: DashboardSidebarCollapsibleGroupProps) => {
  const { title, items, itemId, icon: Icon } = props
  const dispatch = useAppDispatch()

  // Select the open state for this specific item using the selector
  const sidebarItemState = useAppSelector(selectSidebarItemState(itemId))
  const isOpen = !!sidebarItemState?.isOpen

  const handleOpenChange = () => {
    dispatch(setSidebarItemOpen(itemId))
  }

  // Ref to access the collapsible content DOM element
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.overflow = 'hidden'
      contentRef.current.style.transition = 'height 200ms ease-out'

      // Create a ResizeObserver to monitor changes to the content's size
      const observer = new ResizeObserver(() => {
        if (isOpen && contentRef.current) {
          // Update the height to match the content's scrollHeight when it changes
          const scrollHeight = contentRef.current.scrollHeight
          contentRef.current.style.height = `${scrollHeight}px`
        }
      })

      observer.observe(contentRef.current)

      if (isOpen) {
        const scrollHeight = contentRef.current.scrollHeight
        contentRef.current.style.height = `${scrollHeight}px`
      } else {
        contentRef.current.style.height = '0px'
      }

      // Cleanup function to disconnect the observer when the component unmounts or dependencies change
      return () => {
        observer.disconnect()
      }
    }
  }, [isOpen])

  return (
    <div className="w-full mb-2 bg-white">
      {title && (
        <button
          onClick={handleOpenChange}
          className={`group flex w-full p-2 rounded-md justify-between items-center gap-2 text-[#121212] hover:bg-[#F6F3ED] mb-1`}
        >
          <div className="flex items-center gap-2">
            {Icon && <Icon className={`h-6 w-6 text-[#76736A]`} />}
            <p className="text-md font-medium leading-6">{title}</p>
          </div>
          <div className="p-0.5 rounded-full border border-[#DFD7CB] bg-[#F0EBE2] group-hover:bg-[#DFD7CB]">
            {isOpen ? <ChevronDown className="h-5 w-5 text-[#5F5C55]" /> : <ChevronUp className="h-5 w-5 text-[#5F5C55]" />}
          </div>
        </button>
      )}
      {/* Collapsible content */}
      <div ref={contentRef} className="overflow-hidden transition-height duration-200 ease-out">
        {items.map((item, i) => {
          const isFirstSubItem = i > 0 && item.subItem && !items[i - 1].subItem
          return <DashboardSidebarItem key={`${i}-${item.name}`} item={item} index={i} isFirstSubItem={isFirstSubItem} />
        })}
      </div>
    </div>
  )
}

export default DashboardSidebarCollapsibleGroup
