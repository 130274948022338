import { getStorage, ref, getBlob } from 'firebase/storage'
import { ArtifactFetchError as MarkdownFetchError } from '@/artifacts/artifact-errors'
import * as Sentry from '@sentry/react'

/**
 * Get the markdown text from the artifacts bucket in GCS
 * @param filePath - The full path in GCS
 * @returns The markdown text
 */
export async function getMarkdownFromGCS(bucket: string, filePath: string): Promise<string> {
  try {
    const storage = getStorage(undefined, bucket)

    const mdPath = filePath.endsWith('.md') ? filePath : `${filePath}.md`

    // Get blob from GCS
    const storageRef = ref(storage, mdPath)
    const blob = await getBlob(storageRef)

    // Validate blob type
    if (!['text/plain', 'text/markdown', 'text/html'].includes(blob.type)) {
      throw new MarkdownFetchError(`Invalid blob type found for artifact: ${blob.type}`)
    }

    return await blob.text()
  } catch (error) {
    const fetchError = new MarkdownFetchError(`Failed to fetch markdown from bucket ${bucket}; File: ${filePath}: ${(error as Error).message}`)
    Sentry.captureException(fetchError, {
      extra: { error: JSON.stringify(error) },
    })
    throw fetchError
  }
}
