import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgentEventType, ClientAgentEventRead } from './schemas'

export type AgentConversationEventsRecord = Partial<{ [eventId: string]: ClientAgentEventRead }>

export type AgentEventFollowUpQuestions = {
  loading: boolean
  isError: boolean
  questions: string[]
}

export type AgentEventFollowUpQuestionsRecord = Partial<{ [eventId: string]: AgentEventFollowUpQuestions }>

export type AgentEventsState = {
  events: Partial<{ [conversationId: string]: AgentConversationEventsRecord }>
  followUpQuestions: Partial<{ [conversationId: string]: AgentEventFollowUpQuestionsRecord }>
}

const initialState: AgentEventsState = {
  events: {},
  followUpQuestions: {},
}

/**
 * Agent Events Slice
 * Holds agent events representing communication between the user and the agent.
 */
export const agentEventsSlice = createSlice({
  name: 'agentEventsState',
  initialState,
  reducers: {
    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Upsert a list of agent events
    upsertEvents: (state, action: PayloadAction<{ conversationId: string; events: ClientAgentEventRead[] }>) => {
      const { conversationId, events } = action.payload

      // Create a Record of new events so they can all be added at once
      const newEventsRecord = events.reduce((acc, event) => {
        acc[event.id] = event
        return acc
      }, {} as AgentConversationEventsRecord)

      const conversation = state.events[conversationId]
      if (!conversation) {
        state.events[conversationId] = {}
      }

      // Add the new events to the conversation
      state.events[conversationId] = {
        ...state.events[conversationId],
        ...newEventsRecord,
      }
    },

    // Appent Event Chunk
    appendEventChunk: (state, action: PayloadAction<{ conversationId: string; eventId: string; chunk: string }>) => {
      const { conversationId, eventId, chunk } = action.payload

      const event = state.events[conversationId]?.[eventId]
      if (!event) return

      // Append the chunk to the event
      event.value += chunk
    },

    // Update Artifact Version ID
    updateArtifactVersionId: (state, action: PayloadAction<{ conversationId: string; eventId: string; artifactVersionId: string | null }>) => {
      const { conversationId, eventId, artifactVersionId } = action.payload

      const event = state.events[conversationId]?.[eventId]
      if (!event) {
        console.error(`Event not found: conversationId=${conversationId}, eventId=${eventId}`)
        return
      }

      // Update the artifact_version_id of the event
      event.artifact_version_id = artifactVersionId
    },

    // Insert CLIENT_ERROR_EVENT
    insertClientErrorEvent: (state, action: PayloadAction<{ conversationId: string; value: string }>) => {
      const { conversationId, value } = action.payload

      const conversation = state.events[conversationId]
      if (!conversation) {
        state.events[conversationId] = {}
      }

      const newEvent: ClientAgentEventRead = {
        id: crypto.randomUUID(),
        turn_id: crypto.randomUUID(),
        type: AgentEventType.CLIENT_ERROR_EVENT,
        value: value,
        created_at: Date.now(),
        artifact_version_id: null,
      }

      state.events[conversationId] = {
        ...state.events[conversationId],
        [newEvent.id]: newEvent,
      }
    },

    // Insert FOLLOW_UP_QUESTIONS event
    insertFollowUpQuestionsEvent: (state, action: PayloadAction<{ conversationId: string; newEventId: string }>) => {
      const { conversationId, newEventId: eventId } = action.payload

      // Validate IDs
      if (!conversationId || !eventId) {
        console.error(`Invalid IDs for follow-up questions event: conversationId=${conversationId}, eventId=${eventId}`)
        return
      }

      // Create the ClientAgentEventRead
      const newEvent: ClientAgentEventRead = {
        id: eventId,
        turn_id: crypto.randomUUID(),
        type: AgentEventType.FOLLOW_UP_QUESTIONS,
        value: '',
        created_at: Date.now(),
        artifact_version_id: null,
      }

      // Get the conversation events record, and create an empty one if it doesn't exist
      const conversationEvents: AgentConversationEventsRecord = state.events[conversationId] || {}

      // Add the new event to the conversation and update the conversation record in state
      conversationEvents[eventId] = newEvent
      state.events[conversationId] = conversationEvents

      // Get the followUpQuestions record, and create an empty one if it doesn't exist
      const followUpQuestionsRecord: AgentEventFollowUpQuestionsRecord = state.followUpQuestions[conversationId] || {}

      // Add the new followUpQuestions record to the conversation and update the followUpQuestions record in state
      followUpQuestionsRecord[eventId] = {
        loading: true,
        isError: false,
        questions: [],
      }
      state.followUpQuestions[conversationId] = followUpQuestionsRecord
    },

    updateFollowUpQuestionsState: (
      state,
      action: PayloadAction<{ conversationId: string; eventId: string; loading?: boolean; isError?: boolean; followUpQuestions?: string[] }>
    ) => {
      const { conversationId, eventId, loading, isError, followUpQuestions } = action.payload

      const followUpQuestionsState = state.followUpQuestions[conversationId]?.[eventId]
      if (!followUpQuestionsState) return

      if (typeof loading !== 'undefined') followUpQuestionsState.loading = loading
      if (typeof isError !== 'undefined') followUpQuestionsState.isError = isError
      if (followUpQuestions) followUpQuestionsState.questions = followUpQuestions
    },
  },
})

// Actions
export const AgentEventsActions = agentEventsSlice.actions

export default agentEventsSlice.reducer
