import { kAgentAssistantRouterApiBasePath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { ApiError } from '@/util/errors/api-error'
import { CreateFollowUpQuestionsResponse, CreateFollowUpQuestionsResponseSchema } from '../store/schemas'

/**
 * Generate Conversation Follow Up Questions
 *
 * Calls the backend endpoint to generate follow up questions for the latest agent response in a conversation.
 *
 * @param conversationId - The ID of the conversation.
 * @returns The follow up questions as parsed by the Zod schema.
 */
export default async function postGenerateConversationFollowUpQuestions(conversationId: string): Promise<CreateFollowUpQuestionsResponse> {
  const sentry_transaction_id = nanoid()

  try {
    // Retrieve the current user's authentication token.
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) throw new Error(kErrorCodeAuthError)

    const apiUrl = `${kPaxtonAppApiBaseUrl()}${kAgentAssistantRouterApiBasePath}/follow-up-questions/${conversationId}`

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
    }

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle non-OK responses.
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            conversationId,
          },
        })
      })

      throw new ApiError(response.status, `Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }

    // Validate and parse the response data using the Zod schema.
    const parsedData = CreateFollowUpQuestionsResponseSchema.parse(data)
    return parsedData
  } catch (error) {
    // Skip api errors already captured above
    if (!(error instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })
    }
    throw error
  }
}
