import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

/**
 * Selector that returns an array of all folder and file IDs in the file drive
 *
 * @param {RootState} state - The Redux root state
 * @returns {string[]} Array containing all folder and file IDs concatenated together
 *
 * @example
 * const allIds = useSelector(selectAllItemIds)
 * // Returns: ['folder1', 'folder2', 'file1', 'file2']
 */
export const selectAllItemIds = createSelector(
  (state: RootState) => state.filesDrive.folders,
  (state: RootState) => state.filesDrive.files,
  (folders, files) => [...folders.map((folder) => folder.id), ...files.map((file) => file.id)]
)
