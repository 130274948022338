import { Parser } from 'html-to-react'
import { Docx2HtmlCommentTree, Docx2HtmlCommentType, Docx2HtmlCommentsRelativeTop } from '../../schema/docx2html.schema'
import CommentCircleWithLetter from './CommentCircleWithLetter'
import { ChevronDown, PlusAddCircle } from '@/assets/icons'
import { useRef } from 'react'
import { DocumentEditingAnnotationType } from '@/document-editing/schema/document-editing-websocket.schema'
import { createHtmlDiffString } from '@/document-editing/service/document-diffing'
import Docx2HtmlCommentTypeChip from './Docx2HtmlCommentTypeChip'

/**
 * Render Docx2Html Comments Props
 * @param commentGroups is an array of comment arrays. Each comment array represents a top-level comment and its children.
 */
type RenderDocx2HtmlCommentsProps = {
  commentTree: Docx2HtmlCommentTree
  sortedCommentIds: string[]
  commentTopPositions: Docx2HtmlCommentsRelativeTop
  activeCommentId: string | null
  activeCommentVisible: boolean
  annotations: DocumentEditingAnnotationType[]
  onClick: (commentId: string) => void
  onClose: () => void
}

// Configure the HTML parser (for diff string)
const htmlParser = Parser()

/**
 * Render Docx2Html Comments
 * Renders a flattened list of comments grouped with the parent.
 * @param props
 * @returns react component
 */
export default function RenderDocx2HtmlComments(props: RenderDocx2HtmlCommentsProps) {
  const { commentTree, sortedCommentIds, commentTopPositions, activeCommentId, activeCommentVisible, annotations, onClick, onClose } = props

  // Create a copy of the commentTopPositions to dynamically adjusted values (default  to existing values)
  const adjustedTopPositions: Docx2HtmlCommentsRelativeTop = { ...commentTopPositions }

  // Key value pairs of comment references
  const commentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

  // Create an array of absolutely positioned comment elements
  const commentsArray: JSX.Element[] = []

  sortedCommentIds.forEach((commentId, index) => {
    const commentGroup = commentTree[commentId]
    const activeComment = activeCommentId === commentId
    const commentType = commentGroup.commentType
    const originalOffsetTop = commentTopPositions[commentId]

    // IF the comment is a replacement, get the diff string
    function getCommentDiffString(): string | null {
      // Skip if the comment is not a replacement
      if (commentType !== Docx2HtmlCommentType.replacement) return null

      // Get the annotation for the comment, skip if not found
      const annotation = annotations.find((annotation) => annotation.comment_id === commentId) ?? null
      // console.log('Annotation: ', annotation)
      if (!annotation) return null

      // Strip excessive whitespace and newlines from the source and replacement text
      const cleanedSourceText = annotation.source_text.replace(/\s+/g, ' ').trim()
      const cleanedReplacementText = annotation.replacement_text.replace(/\s+/g, ' ').trim()

      // Create a diff between the source_text and replacement_text
      const diffString = createHtmlDiffString(cleanedSourceText, cleanedReplacementText) ?? null

      return diffString
    }
    const commentDiffString = getCommentDiffString()

    // Comment Spacing Buffer
    // Roughly equal to the height of a collapsed comment and some spacing
    const spacingBuffer = 60

    // Get the offset top of the previous comment
    const previousCommentId = sortedCommentIds[index - 1]
    const previousCommentAdjustedOffsetTop = adjustedTopPositions[previousCommentId] ?? null

    // Current min offset top is a multiplier of the index and spacing buffer plus the top position of the first comment
    const defaultMinOffsetTop = index * spacingBuffer + adjustedTopPositions[sortedCommentIds[0]]
    const minOffsetTop = previousCommentAdjustedOffsetTop != null ? previousCommentAdjustedOffsetTop + spacingBuffer : defaultMinOffsetTop

    // Position
    let offsetTop: number | null = adjustedTopPositions[commentId] ?? null

    // Reposition to original height
    const repositionToOriginalHeight = activeComment && activeCommentVisible

    // If the offset top is less than the current min offset top, set the offset top to the current min offset top
    // Do not apply to the active comment unless it's not visible
    if ((!activeComment || (activeComment && !activeCommentVisible)) && offsetTop != null && offsetTop < minOffsetTop) {
      adjustedTopPositions[commentId] = minOffsetTop
      offsetTop = minOffsetTop
    }

    // Reposition to original height if the comment is active and visible
    if (repositionToOriginalHeight) {
      offsetTop = originalOffsetTop
    }

    // console.log(`Comment Id ${commentId} (active? ${activeComment})\nOffset Top: ${offsetTop}, Adjusted Offset Top: ${adjustedTopPositions[commentId]}\n\n`)

    // Create a z-index based on the index in the map
    const zIndex = 1

    // Active Z-Index is greater than the rest
    const activeZIndex = 4

    // Skip rendering if the top position is not found
    if (offsetTop == null) {
      return
    }

    commentsArray.push(
      <div
        ref={(element) => {
          commentRefs.current[commentId] = element
        }}
        role={'button'}
        id={`comment-group-${commentId}`}
        key={`comment-group-${index}`}
        className={`absolute mx-2 shadow-md rounded-md mb-5 flex flex-col border hover:border-[#c6a46f] transition-all ease-in-out duration-300 ${
          activeComment ? `bg-[#fef8e6] border border-[#c6a46f] cursor-default` : `bg-brand-neutral-50 border-brand-neutral-300 cursor-pointer`
        } ${activeComment && activeCommentVisible ? '-ml-4 mr-8 max-h-[1000px] overflow-scroll' : 'max-h-[48px] overflow-hidden'}
        `}
        onClick={() => {
          onClick(commentId)
        }}
        style={{ top: offsetTop + 'px', zIndex: activeComment ? activeZIndex : zIndex }}
      >
        {commentGroup.comments.map((comment, index) => {
          const firstCommentInChain = index === 0

          return (
            <div key={`comment-${comment.id}`} className={'p-3 border-b last-of-type:border-0'}>
              <div className={'flex justify-between'}>
                <div className={'flex items-center gap-x-2 font-bold'}>
                  <CommentCircleWithLetter word={comment.author ?? 'Comment'} /> {comment.author ?? 'Comment'}
                </div>

                <div>
                  {firstCommentInChain &&
                    (activeComment && activeCommentVisible ? (
                      <PlusAddCircle
                        className={'h-6 w-6 cursor-pointer'}
                        onClick={(event) => {
                          onClose()
                          event.stopPropagation()
                        }}
                      />
                    ) : (
                      <ChevronDown className={'h-6 w-6'} />
                    ))}
                </div>
              </div>
              {firstCommentInChain && (
                <div className={'pt-2 pl-[30px]'}>
                  <Docx2HtmlCommentTypeChip commentType={commentType} />
                </div>
              )}
              <div className={'pt-3 pl-[30px] mr-2'}>{comment.text}</div>
              {commentDiffString && (
                <>
                  <div className={'ml-[30px] font-bold mt-3 pl-[1px]'}>Diff:</div>
                  <div className={'px-2 py-1 mt-1 ml-[30px] mb-2 mr-2 bg-brand-neutral-50 rounded-lg shadow'}>
                    <div>{htmlParser.parse(commentDiffString)}</div>
                  </div>
                </>
              )}
            </div>
          )
        })}
      </div>
    )
  })

  // Return the comments array of absolutely positioned elements
  // positioned relative to this container
  return commentsArray
}
