import { FileSummarizer, CreditCard, FileAnalysis, LawsRegs, EnterpriseOrganization } from '@/assets/icons'

import { brandedAIFriendlyName } from '@/util/enterprise'

const examplesList = [
  {
    title: 'Historic state laws and case precedents study',
    description:
      'I need to find information on personal injury laws related to car accidents in New York from the 1980s and 1990s along with the relevant case precedents.',
    icon: FileSummarizer,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Interstate commerce laws exploration',
    description:
      'I need to find interpretations and applications of the Commerce Clause in the U.S. Constitution across different federal and state circuit courts, dating back to the inception of the Clause.',
    icon: CreditCard,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    title: 'International trade law dispute review',
    description:
      'I need to locate case precedents and rulings related to international trade disputes from the 2000s adjudicated by the World Trade Organization and domestic courts, specifically focusing on changes in tariffs and trade agreements.',
    icon: LawsRegs,
    iconForeground: 'text-brand-700',
    iconBackground: 'bg-brand-50',
  },
  {
    title: 'Class action lawsuits examination',
    description:
      'I need to find nationwide case precedents on product liability class action lawsuits, particularly focusing on the nature of damages awarded, class certifications, and variations in law across different jurisdictions.',
    icon: EnterpriseOrganization,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
  {
    title: 'Healthcare law and regulations analysis',
    description:
      'I need to find court decisions interpreting and applying the Health Insurance Portability and Accountability Act (HIPAA) and the Patient Protection and Affordable Care Act (ACA) in the last decade, specifically those related to data privacy and health insurance mandates.',
    icon: FileAnalysis,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
  },
  {
    title: 'Intellectual property rights in digital media',
    description:
      'I need to find case law and statutes related to intellectual property rights, particularly focusing on copyrights and fair use doctrines, in the digital media sector within the United States from 1995 to 2005. These cases should involve music and film industries specifically.',
    icon: FileSummarizer,
    iconForeground: 'text-brand-700',
    iconBackground: 'bg-brand-50',
  },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function ExamplesBlockBooleanBuilder() {
  return (
    <>
      <div className="bg-brand-neutral-50 shadow rounded-lg sm:max-w-screen-lg">
        <div className="mb-5 px-4 py-5 sm:p-6">
          <h1 className={'text-base font-semibold leading-6 text-brand-neutral-900'}>How to compose boolean search strings with {brandedAIFriendlyName}</h1>
          <p className={'my-2 text-sm text-brand-neutral-600'}>
            Ask {brandedAIFriendlyName} to compose boolean search strings compatible with LexisNexis or Westlaw. Here are some examples. Feel free to
            experiment.
          </p>
          <p className={'my-2 text-brand-neutral-600 italic text-xs'}>* {brandedAIFriendlyName} is not affiliated with LexisNexis or Westlaw.</p>
        </div>
      </div>
      <div className="sm:max-w-screen-lg divide-y divide-brand-neutral-200 overflow-hidden rounded-lg bg-brand-neutral-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {examplesList.map((example, exampleIndex) => (
          <div
            key={example.title}
            className={classNames(
              exampleIndex === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              exampleIndex === 1 ? 'sm:rounded-tr-lg' : '',
              exampleIndex === examplesList.length - 2 ? 'sm:rounded-bl-lg' : '',
              exampleIndex === examplesList.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'group relative bg-brand-neutral-50 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-brand-400'
            )}
          >
            <div>
              <span className={classNames(example.iconBackground, example.iconForeground, 'inline-flex rounded-lg p-3 ring-4 ring-white')}>
                <example.icon className="h-6 w-6" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-brand-neutral-900">{example.title}</h3>
              <p className="mt-2 text-sm text-brand-neutral-600">"{example.description}"</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
