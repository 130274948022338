import { kChatHtmlToDocxPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { sanitizeFileName } from '@/routes/dashboard/files/files-utils'
import saveAs from 'file-saver'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { ApiError } from '@/util/errors/api-error'

type SaveHTMLasDocxParams = {
  html: string
  fileName: string
}

export async function saveHTMLasDocx(params: SaveHTMLasDocxParams) {
  const { html, fileName } = params
  const token = await getAuth().currentUser?.getIdToken(true)
  if (!token) throw Error(kErrorCodeAuthError)

  // Create transaction id
  const sentry_transaction_id = nanoid()
  try {
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ html }),
    }

    // Call the backend to convert html to docx file
    const apiUrl = kPaxtonAppApiBaseUrl() + kChatHtmlToDocxPath
    const response = await fetch(apiUrl, requestOptions)

    // Handle non-OK responses.
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, error: ${response.statusText}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            fileName,
          },
        })
      })

      throw new ApiError(response.status, `Fetch error - status: ${response.status}, error: ${response.statusText}`)
    }

    // Save the docx file
    const blob = await response.blob()
    const outputName = sanitizeFileName(fileName) + '.docx'
    saveAs(blob, outputName)
  } catch (error) {
    // Skip api errors already captured above
    if (!(error instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled, fileName },
        })
      })
    }

    throw error
  }
}
