import { ClientAgentReference } from '@/agent/references/store/schemas'
import { ArtifactVersionRead } from '@/artifacts/schemas'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { buildContentViewerContentForAgentReference } from '../util/build-content-viewer-content'

export enum ContentViewerType {
  AGENT_REFERENCE_MD = 'AGENT_REFERENCE_MD',
  AGENT_REFERENCE_PDF = 'AGENT_REFERENCE_PDF',
  ARTIFACT = 'ARTIFACT',
}

export type ContentViewerReferenceData = {
  reference: ClientAgentReference
  citationHighlightIndex?: number
}

// Discriminated Union Type
// When checked for the "type" property, TypeScript will know the "data" type
export type ContentViewerContent =
  | {
      type: ContentViewerType.ARTIFACT
      data: ArtifactVersionRead
    }
  | {
      type: ContentViewerType.AGENT_REFERENCE_MD
      data: ContentViewerReferenceData
    }
  | {
      type: ContentViewerType.AGENT_REFERENCE_PDF
      data: ContentViewerReferenceData
    }

export type ContentViewerState = {
  content: ContentViewerContent | null
}

const initialState: ContentViewerState = {
  content: null,
}

/**
 * Content Viewer Slice
 */
export const contentViewerSlice = createSlice({
  name: 'contentViewerState',
  initialState,
  reducers: {
    // Clear Content Viewer
    clearContentViewer: (state) => {
      state.content = null
    },

    // Set Artifact Version
    setArtifactVersion: (state, action: PayloadAction<{ artifactVersion: ArtifactVersionRead }>) => {
      const { artifactVersion } = action.payload

      const content: ContentViewerContent = {
        type: ContentViewerType.ARTIFACT,
        data: artifactVersion,
      }
      state.content = content
    },

    // Set Agent Reference
    setAgentReference: (state, action: PayloadAction<{ referenceData: ContentViewerReferenceData }>) => {
      const { referenceData } = action.payload

      const content = buildContentViewerContentForAgentReference(referenceData)
      state.content = content
    },

    // Directly sets the content viewer state with a pre-constructed ContentViewerContent object.
    // Use this action when the full content object is already available and does not need transformation.
    setContent: (state, action: PayloadAction<{ content: ContentViewerContent }>) => {
      const { content } = action.payload
      state.content = content
    },
  },
})

// Actions
export const ContentViewerActions = contentViewerSlice.actions

export default contentViewerSlice.reducer
