import { RootState } from '@/store/store'
import { AgentConversation } from './slice'
import { createSelector } from '@reduxjs/toolkit'

/**
 * Select: Agent Conversation From Id
 * Returns the agent conversation from the state if it exists.
 */
export const selectAgentConversationFromId = (state: RootState, props: { conversationId: string | undefined }): AgentConversation | null => {
  const { conversationId } = props
  return state.agentConversationsState.conversations[conversationId ?? ''] ?? null
}

/**
 * Select: Agent Conversation Exists
 * Returns true if the agent conversation exists in the state
 */
export const selectAgentConversationExists = createSelector(selectAgentConversationFromId, (conversation) => conversation !== null)

/**
 * Select: Agent Conversation Title
 * Retrieves the title of an agent conversation.
 */
export const selectAgentConversationTitle = createSelector(selectAgentConversationFromId, (conversation) => conversation?.title ?? null)

/**
 * Select: Agent Conversation Loading
 * Returns true if the agent conversation is loading.
 */
export const selectAgentConversationLoading = createSelector(selectAgentConversationFromId, (conversation) => conversation?.isLoading ?? false)

/**
 * Select: Agent Conversation Load Error
 * Returns the load error for the agent conversation if it exists.
 */
export const selectAgentConversationLoadError = createSelector(selectAgentConversationFromId, (conversation) => conversation?.loadError ?? null)

export const selectAgentConversationActiveContentViewer = (state: RootState, props: { conversationId: string | undefined }) => {
  const { conversationId } = props
  return state.agentConversationsState.activeContentViewer[conversationId ?? ''] ?? null
}

export const selectAgentConversationHasActiveContentViewer = createSelector(
  selectAgentConversationActiveContentViewer,
  (activeContentViewer) => activeContentViewer !== null
)
