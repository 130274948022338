import { BoltIcon } from '@heroicons/react/24/solid'
import * as Popover from '@radix-ui/react-popover'
import { XClear } from '@/assets/icons'
import { Link } from 'react-router-dom'
import { brandedAIFriendlyName } from '@/util/enterprise'
import { PopoverContainerContext } from '@/context/popover-container-context'
import { useContext } from 'react'

/**
 * Agent Prompt Assist Popover
 *
 * This component is used to display a prompt assist popover with content:
 * - A description of the prompt assist feature
 * - A link to the settings page where the feature can be enabled/disabled
 *
 * Note: This is a copy of PromptAssistIndicator (used for legacy conversations) with styling
 * adjustments made to suit the needs of prompt assist events for agent conversations.
 */
export default function AgentPromptAssistPopover() {
  const popoverContainer = useContext(PopoverContainerContext)

  return (
    <div>
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="flex items-center px-2 py-1 gap-x-2 hover:bg-brand-100 rounded-md">
            <div className="flex items-center justify-center w-4 h-4">
              <div className="flex items-center justify-center w-4 h-4 rounded-full bg-brand-500">
                <BoltIcon className="w-3 h-3 text-brand-50" />
              </div>
            </div>
            <span className="text-brand-800 font-bold text-sm">Prompt Assist</span>
          </button>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="rounded-lg bg-white w-[calc(100vw-50px)] lg:w-[calc(100vw-570px)] max-w-[675px] animate-slideDownAndFade [filter:drop-shadow(0_0_10px_rgba(0,0,0,0.04))_drop-shadow(0_25px_25px_rgba(0,0,0,0.10))]"
            align="start"
            sideOffset={5}
            // Collision boundary helps keep it inside the container edges
            collisionBoundary={popoverContainer}
            // Stops the popover from flipping
            avoidCollisions={false}
          >
            <div className="flex justify-between items-start">
              <div className="pl-5 py-5 text-sm text-brand-950">
                <div className="mb-4">Prompt assist is a {brandedAIFriendlyName} feature to aid you when we believe your query could be improved by:</div>
                <ul className="list-disc pl-5 mb-4">
                  <li className="mb-2">using an alternative feature</li>
                  <li className="mb-2">answering a few follow up questions first</li>
                </ul>
                <Link to="/dashboard/settings" className="underline hover:text-brand-blue-500 underline-offset-4">
                  Settings
                </Link>
              </div>
              <Popover.Close
                className="mt-2 mr-2 rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-brand-neutral-500 hover:bg-brand-neutral-100 outline-none cursor-pointer"
                aria-label="Close"
              >
                <XClear />
              </Popover.Close>
            </div>
            <Popover.Arrow className="fill-white translate-x-[53px]" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}
