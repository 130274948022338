import React, { useContext, useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { OrgAdminListUsersUser } from '../schema/org-admin.api.schema'
import { Key, PersonRemove, Warning } from '@mui/icons-material'
import { AuthContext } from '@/context/auth-context'
import OrgAdminConfirmationDialog, { OrgAdminConfirmationDialogProps } from './OrgAdminConfirmationDialog'
import { useChangeRoleToAdminMutation, useChangeRoleToUserMutation, useRemoveUserFromOrgMutation } from '../apis/org-admin.api'
import { kProduction } from '@/constants/constants-ui'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  userListItem: OrgAdminListUsersUser
  refreshData: () => void
}

const UserListItemMenu: React.FC<Props> = (props: Props) => {
  const { userListItem, refreshData } = props

  // Auth Context
  const { user: currentUser } = useContext(AuthContext)

  // Dialog state
  const [openConfirmationDialogProps, setOpenConfirmationDialogProps] = useState<OrgAdminConfirmationDialogProps | null>(null)

  /// RTK-Query Mutations
  const [removeUserFromOrg] = useRemoveUserFromOrgMutation()
  const [changeRoleToAdmin] = useChangeRoleToAdminMutation()
  const [changeRoleToUser] = useChangeRoleToUserMutation()

  // Menu elements
  const menuElements = [
    userListItem.role === 'admin'
      ? {
          name: 'Change role to user',
          icon: Key,
          onClick: () => {
            setOpenConfirmationDialogProps({
              title: 'Change role to user',
              message: `Are you sure you want to change ${userListItem.email}'s role to user?`,
              visible: true,
              onConfirm: async () => {
                await changeRoleToUser(userListItem.id).unwrap() // unwrap to allow popup to catch errors
                await refreshData()
              },
              closeDialog: () => setOpenConfirmationDialogProps(null),
              refreshData: refreshData,
            })
          },
        }
      : {
          name: 'Change role to admin',
          icon: Key,
          onClick: () => {
            setOpenConfirmationDialogProps({
              title: 'Change role to admin',
              message: `Are you sure you want to change ${userListItem.email}'s role to admin?`,
              visible: true,
              onConfirm: async () => {
                await changeRoleToAdmin(userListItem.id).unwrap() // unwrap to allow popup to catch errors
                await refreshData()
              },
              closeDialog: () => setOpenConfirmationDialogProps(null),
              refreshData: refreshData,
            })
          },
        },
    {
      name: 'Remove',
      icon: PersonRemove,
      onClick: () => {
        setOpenConfirmationDialogProps({
          title: 'Remove member',
          message: `Are you sure you want to remove ${userListItem.email}?`,
          visible: true,
          onConfirm: async () => {
            await removeUserFromOrg(userListItem.id).unwrap() // unwrap to allow popup to catch errors
            await refreshData()
          },
          closeDialog: () => setOpenConfirmationDialogProps(null),
          refreshData: refreshData,
        })
      },
    },
  ]

  // Dev menu items
  if (!kProduction) {
    menuElements.push({
      name: 'Dev: Log user',
      icon: Warning,
      onClick: () => {
        console.log('user', userListItem)
      },
    })
  }

  // Do not return this menu for the current user
  if (userListItem.firebase_id === currentUser?.uid) {
    return <></>
  }

  return (
    <>
      {openConfirmationDialogProps && <OrgAdminConfirmationDialog {...openConfirmationDialogProps} />}
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button
            className={`pt-1 rounded-md hover:bg-brand-neutral-200 transition-all duration-200 ease-in-out`}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <EllipsisVerticalIcon
              onClick={() => {
                console.log('clicked')
              }}
              width={22}
              height={22}
            />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="z-50 min-w-[200px] bg-brand-neutral-50 rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
          >
            <ul role="list" className="space-y-1">
              {menuElements.map((item) => (
                <li key={item.name}>
                  <DropdownMenu.Item
                    onClick={(e) => {
                      e.stopPropagation()
                      item.onClick()
                    }}
                    className={classNames(
                      'text-brand-neutral-700 hover:text-brand-500 hover:bg-brand-neutral-50 rounded-md group flex items-center flex-nowrap gap-x-1 py-[4px] text-sm leading-6 cursor-pointer w-full'
                    )}
                  >
                    <item.icon
                      className={classNames('text-brand-neutral-500 group-hover:text-brand-500 h-5 w-5 shrink-0')}
                      aria-hidden="true"
                      viewBox="0 0 28 24"
                    />
                    <div className={`whitespace-nowrap text-sm`}>{item.name}</div>
                  </DropdownMenu.Item>
                </li>
              ))}
            </ul>
            <DropdownMenu.Arrow className="fill-white" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  )
}

export default UserListItemMenu
