import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import AgentResponseEventBubble from './agent-response/AgentResponseEventBubble'
import { AgentEventType } from '@/agent/events/store/schemas'
import AgentUserQueryEventBubble from './UserQueryEventBubble'
import { kErrorDisplayingResponseMessage, kErrorGeneratingResponseMessage } from '@/constants/constants-strings'
import ErrorMessageBubble from './ErrorMessageBubble'
import { selectAgentEventById } from '@/agent/events/store/selectors'
import FollowUpQuestionsEventBubble from './FollowUpQuestionsEventBubble'
import PromptAssistEventBubble from './prompt-assist/PromptAssistEventBubble'
import ResponseCanceledEventBubble from './ResponseCanceledEventBubble'

type EventBubbleConstructorProps = {
  conversationId: string
  eventId: string
}

/**
 * Event Bubble Constructor
 *
 * A component to construct event bubbles based on the AgentEventType.
 */
export default function EventBubbleConstructor(props: EventBubbleConstructorProps) {
  const { conversationId, eventId } = props

  const event = useAppSelector((state: RootState) => selectAgentEventById(state, { conversationId, eventId }))

  if (!event) {
    return <ErrorMessageBubble message={kErrorDisplayingResponseMessage} />
  }

  switch (event.type) {
    case AgentEventType.USER_QUERY:
      return <AgentUserQueryEventBubble conversationId={conversationId} eventId={eventId} />

    case AgentEventType.AGENT_RESPONSE:
      return <AgentResponseEventBubble conversationId={conversationId} eventId={eventId} />

    case AgentEventType.AGENT_RESPONSE_PROMPT_ASSIST:
      return <PromptAssistEventBubble conversationId={conversationId} eventId={eventId} />

    case AgentEventType.FOLLOW_UP_QUESTIONS:
      return <FollowUpQuestionsEventBubble conversationId={conversationId} eventId={eventId} />

    case AgentEventType.CLIENT_ERROR_EVENT: {
      const message = event?.value ?? kErrorGeneratingResponseMessage
      return <ErrorMessageBubble message={message} />
    }
    case AgentEventType.STOP_REQUEST_RECEIVED:
      return <ResponseCanceledEventBubble />

    case AgentEventType.SERVER_ERROR:
      return <ErrorMessageBubble message={kErrorGeneratingResponseMessage} />

    default:
      console.error('Unknown event type: ', event.type)
      return <ErrorMessageBubble message={kErrorDisplayingResponseMessage} />
  }
}
