import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

type ErrorMessageBubbleProps = {
  message: string
}

/**
 * Error Message Bubble
 *
 * A component to display an error message in an event bubble.
 */
export default function ErrorMessageBubble(props: ErrorMessageBubbleProps) {
  const { message } = props

  return (
    <div className="flex items-center p-3 rounded-md text-red-700 bg-red-100 border border-red-200">
      <span className="flex items-center justify-center rounded-lg mr-2">
        <ErrorOutlineIcon fontSize="small" />
      </span>
      <div className="text-sm">{message}</div>
    </div>
  )
}
