import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { MenuOutlined, CloseOutlined, History } from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ChatV2AnonFeatureGuard from '@/components/guards/chat-v2-anon-feature-guard'

import { kDefaultLogoPath } from '@/constants/constants-ui'
import NewChatButton from '@/chat-common/new_chat/components/new-chat-button'
import ChatListSidebar from '@/chat-common/conversation_list/components/chat-list-sidebar'
import DashboardMobileConnectionBanner from '../dashboard-mobile-connection-banner'
import DashboardMobileChatHistory from '../dashboard-mobile-chat-history'
import DashboardSidebarAccountMenu from './dashboard-sidebar-account-menu'

import AiAssistantSidebarGroup from './SidebarGroupAiAssistant'
import ResearchSidebarGroup from './SidebarGroupResearch'
import FilesDriveSidebarItem from './SidebarGroupFiles'
import ToolsSidebarGroup from './SidebarGroupTools'
import SidebarItemSupport from './SidebarItemSupport'
import SidebarItemAnonSignIn from './SidebarItemAnonSignIn'

type DashboardSidebarProps = {
  hideSidebars: boolean
}

export default function DashboardSidebar(props: DashboardSidebarProps) {
  const { hideSidebars } = props
  const { chatFeature } = useParams()
  const [chatHistoryMobileOpen, setChatHistoryMobileOpen] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  // Check if this is a valid chat feature (will be false for non-chat features)
  const validChatFeature: boolean = Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)

  return (
    <>
      {/* Mobile Chat History */}
      <ChatV2AnonFeatureGuard showAuthOnGuard={false}>
        <DashboardMobileChatHistory sidebarOpen={chatHistoryMobileOpen} setSidebarOpen={setChatHistoryMobileOpen} />
      </ChatV2AnonFeatureGuard>

      {/* Mobile Top Bar + Slide-Out Sidebar Nav */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-brand-neutral-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-2xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <CloseOutlined className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 h-full overflow-y-scroll bg-white px-2 pt-4 pb-2">
                  {/* Always use Paxton logo */}
                  <Link to={'/dashboard'} onClick={() => setSidebarOpen(false)} className={'flex items-center'}>
                    <img className="max-w-full max-h-[100px] mx-auto py-1 px-3" src={kDefaultLogoPath} alt="Paxton AI" />
                  </Link>

                  <SidebarItemAnonSignIn setSidebarOpen={setSidebarOpen} />

                  <nav className="flex flex-1 flex-col border-t border-brand-neutral-200">
                    <ul role="list" className="flex flex-1 flex-col gap-y-2 pt-4">
                      <AiAssistantSidebarGroup />
                      <ResearchSidebarGroup />
                      <FilesDriveSidebarItem />
                      <ToolsSidebarGroup />
                    </ul>
                  </nav>

                  <SidebarItemSupport setSidebarOpen={setSidebarOpen} />

                  <DashboardSidebarAccountMenu
                    onSelect={() => {
                      setSidebarOpen(false)
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className={'hidden lg:flex h-full '}>
        <div className={`inset-y-0 z-50 flex flex-col overflow-hidden transition-all ease-in-out duration-500 ${hideSidebars ? 'w-0' : 'w-66'}`}>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="h-full w-[254px] flex flex-col pt-5 px-2 gap-y-5 overflow-y-auto overflow-x-clip border-r border-[#F0EBE2] bg-white">
            <div className="w-full flex items-center justify-center">
              {/* Always use Paxton logo */}
              <Link to={'/dashboard'}>
                <img className="max-w-full max-h-[52px]" src={kDefaultLogoPath} alt="Paxton AI" />
              </Link>
            </div>

            <SidebarItemAnonSignIn setSidebarOpen={setSidebarOpen} />

            <nav className="flex flex-1 flex-col border-t border-brand-neutral-200">
              <ul role="list" className="flex flex-1 flex-col gap-y-2 pt-4">
                <AiAssistantSidebarGroup />
                <ResearchSidebarGroup />
                <FilesDriveSidebarItem />
                <ToolsSidebarGroup />
              </ul>
            </nav>

            <SidebarItemSupport setSidebarOpen={setSidebarOpen} />

            <DashboardSidebarAccountMenu
              onSelect={() => {
                setSidebarOpen(false)
              }}
            />
          </div>
        </div>

        {/* Desktop: Chat List Sidebar */}
        {/* Only display for valid features */}
        {validChatFeature && (
          <ChatV2AnonFeatureGuard showAuthOnGuard={false}>
            <div className={`overflow-y-scroll transition-all ease-in-out duration-500 ${hideSidebars ? 'w-0' : 'w-64 3xl:w-80'}`}>
              <div className={`px-2 py-2 w-full`}>
                <NewChatButton />
              </div>
              <ChatListSidebar />
            </div>
          </ChatV2AnonFeatureGuard>
        )}
      </div>

      {/* Mobile Top Bar */}
      <div className="h-12 w-full top-0 z-40 flex items-center bg-white pr-3 shadow-sm lg:hidden">
        <button type="button" className="px-3 pt-1 pb-1.5 text-[#121212]" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <MenuOutlined className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="w-full flex items-center justify-between text-sm font-medium text-[#121212]">
          <div className="flex gap-x-3 items-center">
            <div className="text-base font-extrabold tracking-tighter">
              {/* Always use Paxton logo on mobile header */}
              <img className="w-20" src={kDefaultLogoPath} alt="PAXTON" />
            </div>
          </div>

          <div className="flex">
            <DashboardMobileConnectionBanner />

            {/* Only show if we're on a valid feature */}
            {validChatFeature && (
              <ChatV2AnonFeatureGuard showAuthOnGuard={false}>
                <button
                  type="button"
                  className="ml-3 p-2 text-[#76736A] hover:text-[#121212]"
                  onClick={() => setChatHistoryMobileOpen(true)}
                  aria-label="Open chat history"
                  title="History"
                >
                  <History style={{ fontSize: '28px' }} />
                </button>
                <NewChatButton isCompact />
              </ChatV2AnonFeatureGuard>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
