import { kPaymentsPreAuthorizationPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/react'
import { paymentErrorResponseSchema, UserPaymentError, SystemPaymentError } from '../types/payment-errors'
import { store } from '@/store/store'
import { setLegacyStripeCheckoutMessage, setLegacyStripeCheckoutVisible } from '@/store/slices/ui-state.slice'

export type createHoldPaymentRequest = {
  setupIntentID: string
  userAgent: string
}

export type createHoldPaymentResponse = {
  payment_intent_id: string
  success: boolean
}

function isUserPaymentError(error: any): boolean {
  // Check for card_error type
  if (error?.type === 'card_error') return true

  // Check for specific error codes that indicate user payment issues
  if (error?.code && ['card_declined', 'insufficient_funds', 'expired_card'].includes(error.code)) return true

  return false
}

function handleSystemError(error: any, message: string, sentryMessage: string, sentryTransactionId: string, extraData?: object) {
  Sentry.withScope((scope) => {
    scope.setTags({ transaction_id: sentryTransactionId })
    Sentry.captureException(new Error(sentryMessage), {
      extra: { error, ...extraData },
    })
  })

  store.dispatch(setLegacyStripeCheckoutMessage(message))
  store.dispatch(setLegacyStripeCheckoutVisible(true))

  return new SystemPaymentError(message)
}

function handleUserPaymentError(error: any) {
  const userMessage = error?.user_message || 'Your payment method was declined. Please try again with a different payment method.'

  return new UserPaymentError(userMessage)
}

/**
 * Creates a hold payment using Stripe
 * @throws {UserPaymentError} When a payment is declined by the user's bank
 * @throws {SystemPaymentError} When a system error occurs
 */
export default async function createHoldPayment({
  setupIntentID: setup_intent_id,
  userAgent: user_agent,
}: createHoldPaymentRequest): Promise<createHoldPaymentResponse> {
  const sentryTransactionId = nanoid()
  const apiUrl = kPaxtonAppApiBaseUrl() + `/${kPaymentsPreAuthorizationPath}`

  try {
    // Get authentication token
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) {
      throw handleSystemError(
        { errorCode: kErrorCodeAuthError },
        'Authentication failed. Please sign in again.',
        'Hold payment: Authentication error',
        sentryTransactionId
      )
    }

    // Construct request
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentryTransactionId,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        setup_intent_id,
        user_agent,
      }),
    }

    // Make API request
    const response = await fetch(apiUrl, requestOptions)

    // Parse response data
    let responseData: any
    try {
      responseData = await response.json()
    } catch (parseError) {
      throw handleSystemError(
        parseError,
        'Payment system error. Please try again by refreshing the page or use the Stripe portal by clicking the button below.',
        'Hold payment: JSON parse error',
        sentryTransactionId,
        { response }
      )
    }

    // Handle error responses
    if (!response.ok || responseData.success === false || responseData.error) {
      try {
        const parsedError = paymentErrorResponseSchema.parse(responseData)

        // Determine if this is a user payment error or system error
        if (isUserPaymentError(parsedError.error)) {
          throw handleUserPaymentError(parsedError.error)
        } else {
          throw handleSystemError(
            parsedError.error,
            'Payment system error. Please try again or use the legacy checkout.',
            'Hold payment: API error',
            sentryTransactionId,
            { response: responseData }
          )
        }
      } catch (zodError) {
        // Re-throw our custom errors, but handle Zod validation errors as system errors
        if (zodError instanceof UserPaymentError || zodError instanceof SystemPaymentError) {
          throw zodError
        }

        throw handleSystemError(
          zodError,
          'Unable to process payment. Please try again or use the legacy checkout.',
          'Hold payment: Error parsing failed',
          sentryTransactionId,
          { responseData }
        )
      }
    }

    // Success case
    return responseData
  } catch (e: any) {
    // If it's already one of our error types, just rethrow it
    if (e instanceof UserPaymentError || e instanceof SystemPaymentError) {
      throw e
    }

    // Generic error handling for all other types of errors
    throw handleSystemError(
      e,
      e?.message || 'An unexpected error occurred. Please try again or use the legacy checkout.',
      'Hold payment: Unexpected error',
      sentryTransactionId
    )
  }
}
