import { XClear, TrashDelete } from '@/assets/icons'
import { FilesDriveDialogType } from '@/store/slices/ui-state.slice'

type FilesDriveToolbarProps = {
  handleDialogOpen: (dialogType: FilesDriveDialogType) => void
  selectedFilesAndFoldersTotal: number
  clearSelectedItems: () => void
}

export default function FilesDriveToolbar({ handleDialogOpen, selectedFilesAndFoldersTotal, clearSelectedItems }: FilesDriveToolbarProps) {
  const toolbarDisabled = selectedFilesAndFoldersTotal === 0

  return (
    <div className={`px-2 flex gap-x-2 ${!toolbarDisabled ? 'opacity-100' : 'opacity-50'} transition-all duration-200 ease-in-out`}>
      <button
        onClick={clearSelectedItems}
        disabled={toolbarDisabled}
        className={`flex gap-x-1 px-2 py-1 pr-3 items-center rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${
          toolbarDisabled ? 'cursor-default' : 'cursor-pointer'
        }`}
      >
        <XClear width={20} height={20} />
        <div>{selectedFilesAndFoldersTotal} selected</div>
      </button>

      <button
        onClick={() => handleDialogOpen(FilesDriveDialogType.DELETE)}
        disabled={toolbarDisabled}
        className={`flex gap-x-2 px-2 py-1 pr-3 rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${
          toolbarDisabled ? 'cursor-default' : 'cursor-pointer'
        }`}
      >
        <TrashDelete width={20} height={20} />
        <div>Delete</div>
      </button>
    </div>
  )
}
