import { downloadArtifactAsDocX } from '@/artifacts/fetch/get-download-artifact-docx'
import { GlobalToastType } from '@/constants/constants-ui'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { store } from '@/store/store'

/**
 * Download Artifact
 *
 * This function can be provided to the ContentViewerDownloadButton to enable it.
 * @param artifactVersionId
 * @param onLoading
 * @param onError
 */
export default async function downloadArtifactButtonFunction(
  artifactVersionId: string,
  onLoading: (isLoading: boolean) => void,
  onError: (error: Error) => void
) {
  onLoading(true)
  try {
    await downloadArtifactAsDocX(artifactVersionId)
    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `Download ready.`, durationMs: 2000 }))
  } catch (error) {
    onError(new Error('Download failed'))
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'File download failed.', durationMs: 3000 }))
  } finally {
    onLoading(false)
  }
}
