import { z } from 'zod'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { parseTimestamp } from '@/util/validators'
import { ClientAgentEventReadSchema } from '@/agent/events/store/schemas'
import { ArtifactVersionReadSchema } from '@/artifacts/schemas'
import { AgentResponseMetadataReadSchema } from '@/agent/response_metadata/store/schemas'
import { AgentAssistantRequestParamsSchema } from '@/agent/chat_form/store/schemas'
import { ClientAgentReferenceSchema } from '@/agent/references/store/schemas'

// Based on the backend AgentConversationRead model
export const AgentConversationReadSchema = z.object({
  id: z.string().uuid(),
  user_id: z.string(),
  title: z.string(),
  feature: z.nativeEnum(ChatV2Feature).default(ChatV2Feature.agent), // default to "agent" for agent conversations (not provided by server)
  is_hidden: z.boolean(),
  updated_at: z.preprocess((val, ctx) => parseTimestamp(val, ctx), z.number()),
})
export type AgentConversationRead = z.infer<typeof AgentConversationReadSchema>

// Zod schema for GetUserConversationsResponse
export const GetAgentConversationsResponseSchema = z.object({
  conversations: z.array(AgentConversationReadSchema),
})
export type GetAgentConversationsResponse = z.infer<typeof GetAgentConversationsResponseSchema>

// Based on the backend GetConversationDataResponse model
export const GetAgentConversationDataResponseSchema = z.object({
  conversation: AgentConversationReadSchema,
  events: z.array(ClientAgentEventReadSchema),
  artifact_versions: z.array(ArtifactVersionReadSchema),
  response_metadata: z.array(AgentResponseMetadataReadSchema),
  references: z.array(ClientAgentReferenceSchema).nullable(),
  latest_request_params: AgentAssistantRequestParamsSchema.nullable(),
})

export type GetAgentConversationDataResponse = z.infer<typeof GetAgentConversationDataResponseSchema>

// Based on the backend CreateFollowUpQuestionsResponse model
export const CreateFollowUpQuestionsResponseSchema = z.object({
  questions: z.array(z.string()),
})

export type CreateFollowUpQuestionsResponse = z.infer<typeof CreateFollowUpQuestionsResponseSchema>
