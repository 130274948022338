import { useState } from 'react'
import { useAppSelector, useAppDispatch } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectVisibleReference, selectConversationFeature } from '@/chat-common/store/chat-v2.selectors'
import referenceMetadataToTitle from '@/chat-common/util/reference-metadata-to-title'
import { OpenNew } from '@/assets/icons'
import { Link } from 'react-router-dom'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { downloadReferenceAsDocX } from '@/chat-common/util/download-conversation'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { isCitatorEnabled } from './reference-view-utils'
import ReferenceViewerConstructor from './reference-viewer-constructor'
import { CloseRounded } from '@mui/icons-material'
import { ChatV2Actions } from '@/chat-common/store/chat-v2.slice'

type ReferenceViewerProps = {
  conversationId: string
}

/**
 * Legacy Reference Viewer
 *
 * Handles the rendering of a reference in the legacy chat viewer.
 * This reference viewer is highly coupled to the legacy chat interface and is replaced by the new Content Viewer feature.
 *
 * Renders the core content for a Reference:
 * - Reference number
 * - Reference title
 * - Reference content via ReferenceViewerConstructor, which determines the correct reference sub-viewer component.
 */
export default function LegacyReferenceViewer(props: ReferenceViewerProps) {
  const { conversationId } = props
  const { trackEvent } = useAnalytics()
  const dispatch = useAppDispatch()

  // Redux state
  const visibleReference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))

  // Local state
  const [referenceHtml, setReferenceHtml] = useState('')
  const [downloadLoading, setDownloadLoading] = useState(false)

  if (!visibleReference) return null

  const rawTitle = visibleReference.metadata?.title
  const parentId = visibleReference.metadata?.parent_id
  const title = referenceMetadataToTitle(visibleReference)

  return (
    <div className="flex flex-col h-full w-full px-4 py-2">
      {/* Header Row */}
      <div className="flex items-end pb-2 border-b border-brand-neutral-200 bg-brand-neutral-50 sticky top-0 z-10">
        {/* Reference number */}
        <span className="text-normal font-bold">[{visibleReference.reference_number}]</span>

        {/* Link to open in new tab (if parentId) */}
        {parentId && (
          <Link
            to={`/dashboard/reference-viewer/caselaw/${parentId}`}
            target="_blank"
            className="flex items-center hover:text-brand-700 active:bg-brand-100 ml-4"
          >
            <span className="text-sm font-medium leading-tight">New tab</span>
            <OpenNew className="w-4 h-4 pl-1" />
          </Link>
        )}

        {/* .docx download button (only visible when referenceHtml is ready) */}
        <button
          className={`flex items-center hover:text-brand-700 active:bg-brand-100 ml-4 ${referenceHtml ? '' : 'hidden'}`}
          onClick={() => {
            trackEvent(AnalyticsEvent.DownloadChatSourceToDocx)
            downloadReferenceAsDocX(referenceHtml, rawTitle ?? 'source', setDownloadLoading)
          }}
        >
          <span className="text-sm font-medium leading-tight">.docx</span>
          <ArrowDownTrayIcon className="h-4 pl-1 pb-[2px]" />
          {downloadLoading && (
            <span className="pl-2 pb-[1px] h-5">
              <CircularProgressContinuousSized size={12} thickness={7} />
            </span>
          )}
        </button>

        {/* Close Button */}
        <button className="ml-auto flex items-center justify-center" onClick={() => dispatch(ChatV2Actions.removeVisibleReference({ conversationId }))}>
          <CloseRounded fontSize="small" />
        </button>
      </div>

      {/* Title line (and optional citator border) */}
      <div className={`pt-2 text-sm whitespace-pre-wrap compatible-word-break font-bold ${isCitatorEnabled(feature, visibleReference) ? '' : 'pb-2 border-b'}`}>
        {title}
      </div>

      {/* Main reference content */}
      <ReferenceViewerConstructor conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtml} />
    </div>
  )
}
