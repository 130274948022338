import { useCallback, useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { kAcceptedFilesList } from '@/constants/constants-gcs'
import { nanoid } from 'nanoid'
import { UploadFile } from '@mui/icons-material'
import { UploadDisclaimer } from '@/constants/constants-components'
import { useAppDispatch } from '@/store/store-hooks'
import { DriveType, FolderNode, NodeType } from '../schemas/files-drive-schema'
import uploadDriveFileHandler from '../helpers/uploadHandler'
import { FileDriveUploadStatusPopover } from './FileDriveUploadStatusPopover'
import { addDriveFileUploadTask, updateDriveFileUploadTask } from '@/store/slices/file-drive-upload-tasks.slice'
import { kSegmentTrackFileUploadStarted } from '@/constants/constants-segment'
import { FilesDriveActions } from '../store/files-drive.slice'
import { getAuth } from 'firebase/auth'
import { AuthContext } from '@/context/auth-context'

export type UploadTaskStatusDrive = {
  progress: number
  complete: boolean
  errorMessage: string
  name: string
  uploadableFileId: string
}

export type FilesDropZoneProps = {
  currentFolderId: string
  currentFolder: FolderNode | null
  gcsConfirmOverwrite?: boolean
  singleFileLimit?: boolean
  showFolderName?: boolean
  driveType: DriveType

  hideUploadProgressOverlay?: boolean
}

export interface FilesDropZoneRef {
  openSystemFileSelector: () => void
}

export const FileDriveDropZone = function FileDriveDropZone(props: FilesDropZoneProps) {
  const { currentFolderId, currentFolder, singleFileLimit, hideUploadProgressOverlay = false, driveType = DriveType.USER } = props

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [dropError, setDropError] = useState<string | null>(null)


  const dispatch = useAppDispatch()
  const currentTasks: UploadTaskStatusDrive[] = []

  const { userAccountData } = useContext(AuthContext)
  const organizationId = userAccountData?.legacyDriveCompatibleOrganizationId


  const onTaskComplete = useCallback((files_ids: string[]) => {
    // Update the files list by making a new request after an upload is complete
    dispatch(FilesDriveActions.uploadFiles({ files_ids }))
  }, [dispatch]);

  const auth = getAuth()
  const user = auth.currentUser?.uid

  //TODO: To be implemented in next PRs
  // const { showFileOverwriteConfirmation } = useFileOverwriteModal()//Comes from path and will be a folder id.


  //Files On Drop
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setDropError(null)

      if (acceptedFiles.length === 0) {
        console.warn('No accepted files to upload.')
        setDropError('No accepted files to upload.')
        return
      }

      setDrawerOpen(true)

      analytics.track(kSegmentTrackFileUploadStarted, {
        fileCount: acceptedFiles.length,
      })

      const filesToUpload = acceptedFiles.map((file) => {
        const fileId = nanoid()
        return {
          uploadableFile: {
            id: fileId,
            name: file.name,
            type: NodeType.FILE,
            folder_path: currentFolder ? `${currentFolder.folder_path}${currentFolder.name}` : '',
            parent_id: currentFolderId,
            user_id: user, file: file,
          },
          task: {
            progress: 0,
            complete: false,
            errorMessage: '',
            uploadableFileId: fileId,
            name: file.name
          },
        }
      })

      dispatch(addDriveFileUploadTask(filesToUpload.map((item) => item.task)))

      const files_ids = filesToUpload.map(({ uploadableFile }) => uploadableFile.id)
      //Handle uploads concurrently with proper promise handling 
      try {
        await Promise.all(
          filesToUpload.map(({ uploadableFile }) =>
            uploadDriveFileHandler({
              uploadableFile: {
                ...uploadableFile,
                user_id: user || '', // Ensure user_id is always a string
              },
              currentFolder: currentFolderId,
              driveType: driveType,
              orgId: organizationId ?? null,
              onProgress: (progress: number) => {
                dispatch(
                  updateDriveFileUploadTask({ progress, id: uploadableFile.id }))
              },
              onError: (error: Error) => {
                dispatch(updateDriveFileUploadTask({ id: uploadableFile.id, progress: 0, errorMessage: error.message, }))
              },
              onComplete: () => {
                dispatch(updateDriveFileUploadTask({ progress: 100, id: uploadableFile.id, complete: true, }))
                onTaskComplete(files_ids)
              },
            })))
      } catch (error) {
        console.error('Failed to upload files:', error)
      }
    }, [dispatch, currentFolder, currentFolderId, user, onTaskComplete])


  const onDropRejected = (fileRejections: any) => {
    console.warn('Rejected Files: ', fileRejections)
    setDropError('Invalid file format')
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: kAcceptedFilesList,
    maxFiles: singleFileLimit ? 1 : undefined,
  })

  return (
    <>
      <div
        className={`mx-2 p-4 rounded-lg border-2 border-brand-500 transition-all duration-300 ${isDragActive ? 'bg-brand-50' : 'border-opacity-20 '} border-dashed`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <div className={'text-center'}>
          <div className={'mb-3'}>
            <UploadFile />
          </div>
          <p>
            Drag & Drop or <span className={'text-brand-500 underline cursor-pointer'}>Choose{singleFileLimit ? ' a File' : ' Files'}</span>
          </p>
          <p className={'mt-1 text-sm text-brand-neutral-600'}>Supported formats {Object.values(kAcceptedFilesList).flat().join(', ')}</p>
          <UploadDisclaimer />
          {dropError && <p className={'mt-1 text-sm text-red-700'}>{dropError}</p>}
        </div>
      </div>
      {!hideUploadProgressOverlay && (
        <FileDriveUploadStatusPopover expanded={drawerOpen} setExpanded={(newValue: boolean) => setDrawerOpen(newValue)} tasks={currentTasks} />
      )}
    </>
  )
}

