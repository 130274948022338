import * as Popover from '@radix-ui/react-popover'
import { XClear } from '@/assets/icons'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { agentReferenceMetadataToTitle } from '@/agent/references/util/agent-reference-utils'
import { ContentViewerActions } from '@/content-viewer/store/slice'
import { useContext } from 'react'
import { PopoverContainerContext } from '@/context/popover-container-context'
import { selectIdentifierMatchesActiveContentViewer } from '@/content-viewer/store/selectors'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import AgentResponseReferencesListItemPages from './AgentResponseReferencesListItemPages'

type AgentResponseCitationPopoverProps = {
  citation_id: string
  citation_id_index: number
  turn_id: string
}

export default function AgentResponseCitationPopover(props: AgentResponseCitationPopoverProps) {
  const { citation_id, citation_id_index, turn_id } = props
  const dispatch = useAppDispatch()
  const popoverContainer = useContext(PopoverContainerContext)

  // Get this reference data based on the citation_id
  const reference = useAppSelector((state: RootState) => state.agentReferencesState.references[citation_id]) ?? null

  // Watch whether this is the active reference
  const isActive = useAppSelector((state: RootState) =>
    selectIdentifierMatchesActiveContentViewer(state, { id: citation_id, highlightIndex: citation_id_index })
  )

  // Get the turn's unique citation_ids -> highlight count object
  const turnUniqueCitationAndHighlightCount =
    useAppSelector((state: RootState) => state.agentReferencesState.turnUniqueCitationAndHighlightCount[turn_id]) ?? {}
  const turnUniqueCitationIds = Object.keys(turnUniqueCitationAndHighlightCount)
  const index = turnUniqueCitationIds.indexOf(citation_id)

  // Hide the popover if the reference or citation_id index is not found
  // (indexOf returns -1 if the citation_id is not found)
  if (!reference || index < 0) {
    return null
  }

  // Construct the ContentViewerReferenceData needed by the content viewer
  const referenceData = { reference, citationHighlightIndex: citation_id_index }

  // Get the conversation id from the reference
  const conversationId = reference.conversation_id

  // Create the label
  // If not found, use the citation_id
  // If found, add 1 to the index to make it human readable
  const label = citation_id_index > 0 ? `${index + 1}.${citation_id_index}` : index + 1

  // Parse the reference title
  const title = agentReferenceMetadataToTitle(reference)

  const handleViewReference = () => {
    // Set the content viewer state with the reference
    dispatch(ContentViewerActions.setAgentReference({ referenceData }))

    // Set the active content viewer for the conversation
    dispatch(AgentConversationsActions.setConversationActiveContentViewerReference({ conversationId, referenceData }))
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={`text-brand-500 px-1 hover:text-brand-600 ${isActive ? 'text-brand-600 bg-brand-400 bg-opacity-20 rounded-md' : 'text-brand-500'}`}
          aria-label={`citation_id ${citation_id}`}
        >
          [{label}]
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="rounded pt-2 px-3 pb-2 max-w-xs xs:max-w-screen-xs sm:max-w-screen-sm bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.50),0_10px_20px_-15px_hsla(206,22%,7%,.50)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
          // Collision boundary helps keep it inside the container edges
          collisionBoundary={popoverContainer}
          collisionPadding={25}
        >
          <div className="flex justify-between items-center mb-2">
            <div className="font-bold text-xs text-black p-[1px] pb-[3px]">[{label}]</div>
            <Popover.Close
              className="rounded-full inline-flex items-center justify-center text-brand-neutral-500 hover:bg-brand-neutral-200 outline-none cursor-pointer"
              aria-label="Close"
            >
              <XClear />
            </Popover.Close>
          </div>

          <div className={'pr-1 pb-1'}>
            <div className={'compatible-word-break font-bold text-xs text-black whitespace-pre-wrap mb-[2px]'}>{title}</div>
            <div className="text-brand-neutral-600 text-xs">
              <AgentResponseReferencesListItemPages reference={reference} />
            </div>

            <button
              onClick={handleViewReference}
              className={`font-bold text-xs text-brand-500 underline cursor-pointer hover:underline hover:text-brand-600 inline-block`}
            >
              View source
            </button>
          </div>

          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
