import { selectVisibleReference } from '@/chat-common/store/chat-v2.selectors'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { OpenNew } from '@/assets/icons'

type ReferenceViewWebProps = {
  conversationId: string
}

export default function ReferenceViewContentWeb(props: ReferenceViewWebProps) {
  const { conversationId } = props

  const reference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))

  // If no reference
  if (!reference) return <div>No reference selected</div>

  const published_at = reference.metadata.published_at
  const title = reference.metadata.title
  const url = reference.metadata.url
  const snippet_text = reference.metadata.snippet_text

  return (
    <div className="reference-view-html overflow-y-scroll py-2">
      <div id="web-reference" className={'text-sm'}>
        <div className={'font-bold mb-1'}>{title}</div>
        <div className={'text-brand-neutral-500 mb-5'}>{published_at}</div>
        <div className={''}>{snippet_text}</div>
        <a target={'_blank'} href={url} className={'pt-2 flex gap-x-2 items-center font-bold underline text-brand-800 hover:text-brand-500'}>
          <div className={'inline-block'}>Visit source</div>
          <OpenNew />
        </a>
      </div>
    </div>
  )
}
