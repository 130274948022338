import { useAppSelector } from '@/store/store-hooks'
import { useMatch } from 'react-router'
import { selectLastViewedConversationIdRecord } from '@/chat-common/store/chat-v2-ux.selectors'
import { AiChat, Drafting, FileAnalysis } from '@/assets/icons'
import DashboardSidebarGroup from './components/dashboard-sidebar-group'
import { DashboardSidebarItemType } from './components/dashboard-sidebar-item'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'

export default function SidebarGroupAiAssistant() {
  const lastViewedConversationIdRecord = useAppSelector(selectLastViewedConversationIdRecord)

  const lastViewedAssistantId = lastViewedConversationIdRecord[ChatV2Feature.assistant]
  const lastViewedDocumentQueryId = lastViewedConversationIdRecord[ChatV2Feature.documentquery]
  const lastViewedDocumentDraftingId = lastViewedConversationIdRecord[ChatV2Feature.drafting]
  const featureAssistant = useMatch(`/dashboard/chat/${ChatV2Feature.assistant}/*`)
  const aiAssistantItems: DashboardSidebarItemType[] = [
    {
      name: 'AI Assistant',
      href: lastViewedAssistantId ? `/dashboard/chat/${ChatV2Feature.assistant}/${lastViewedAssistantId}` : `/dashboard/chat/${ChatV2Feature.assistant}`,
      icon: AiChat,
      current: featureAssistant ?? false,
      allowAnon: false,
    },
    {
      name: 'Drafting',
      href: lastViewedDocumentDraftingId ? `/dashboard/chat/drafting/${lastViewedDocumentDraftingId}` : '/dashboard/chat/drafting',
      icon: Drafting,
      current: useMatch('/dashboard/chat/drafting/*') ?? false,
      allowAnon: false,
      subItem: true,
    },
    {
      name: 'File Analysis',
      href: lastViewedDocumentQueryId
        ? `/dashboard/chat/${ChatV2Feature.documentquery}/${lastViewedDocumentQueryId}`
        : `/dashboard/chat/${ChatV2Feature.documentquery}`,
      icon: FileAnalysis,
      current: useMatch(`/dashboard/chat/${ChatV2Feature.documentquery}/*`) ?? false,
      allowAnon: false,
      subItem: true,
    },
  ]

  return <DashboardSidebarGroup items={aiAssistantItems} />
}
