import { Fragment, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import LrrSourceDialogPathDrillDown from './lrr-source-dialog-path-drilldown'
import { chatV2CurrentSourceLrrAddSource, chatV2CurrentSourceLrrRemoveSource, chatV2CurrentSourceLrrRemoveAll } from '@/chat-common/store/chat-v2.slice'
import { LrrSourceSource, LrrSourcesResponse } from '@/store/apis/hosted-filters-api'
import { ChatV2QueryMetadataLrrV2 } from '@/chat-common/schemas/chat-query-metadata-schema'
import LRRSourceDialogSourceDrillDown from './lrr-source-dialog-source-drilldown'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'
import { RootState } from '@/store/store'

type LrrSourceDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
  lrrSourcesResponse: LrrSourcesResponse
}

export default function LrrSourceDialog(props: LrrSourceDialogProps) {
  const { open, onClose, conversationId, lrrSourcesResponse } = props
  const dispatch = useAppDispatch()
  const sentry_transaction_id = nanoid()

  // Redux state selectors
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataLrrV2 | null

  // Local state
  const [query, setQuery] = useState('')
  const [stateSelected, setStateSourceSelected] = useState<string | null>(null)

  // Isolate the current conversation selections (currentSource)
  const currentSourceSelections = currentSource?.lrr_selections ?? []

  // Isolate the top level keys
  const topLevelKeys = Object.keys(lrrSourcesResponse)

  // Query Filtered Keys (top level search)
  const filteredKeys = topLevelKeys.filter((key) => key.toLowerCase().includes(query.toLowerCase()))
  //Check if given source is federal
  const isFederal = (source: string) => source === 'Federal'

  //Source handler methods
  const removePreviousSourceSelectionDispatcher = (prevSelection: string, conversationId: string) => {
    lrrSourcesResponse[prevSelection].sources.forEach((source) => dispatch(chatV2CurrentSourceLrrRemoveSource({ conversationId, source_name: source.value })))
    dispatch(chatV2CurrentSourceLrrRemoveSource({ conversationId, source_name: prevSelection }))
  }

  const addingSourcesDispatcher = (source: LrrSourceSource, conversationId: string): void => {
    try {
      dispatch(chatV2CurrentSourceLrrAddSource({ conversationId, source_name: source.value, path: source.path }))
      // Check for children sources to also be selected (e.g. Federal Rules)
      if (source.sources && source.sources.length > 0) {
        source.sources.forEach((s) => {
          dispatch(chatV2CurrentSourceLrrAddSource({ conversationId, source_name: s.value, path: s.path }))
        })
      }
    } catch (error) {
      console.error('An error occurred while removing the source from currentSource:', error)
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(error, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'An error occurred while managing the source', durationMs: 2000 }))
    }
  }

  const removingSourcesDispatcher = (source: LrrSourceSource, conversationId: string) => {
    try {
      dispatch(chatV2CurrentSourceLrrRemoveSource({ conversationId, source_name: source.value }))
      if (source.sources && source.sources.length > 0)
        source.sources.forEach((s) => dispatch(chatV2CurrentSourceLrrRemoveSource({ conversationId, source_name: s.value })))
    } catch (error) {
      console.error('An error occurred while removing the source from currentSource:', error)
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(error, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'An error occurred while managing the source', durationMs: 2000 }))
    }
  }

  const handleInputChange = (key: string, conversationId: string): void => {
    //handle the state selection
    handleStateSelection(key, conversationId)

    const parentSources = lrrSourcesResponse[key].sources

    const checked: boolean = parentSources.every((source) => {
      const sourceEntry = currentSourceSelections.find((entry) => entry.source_name === source.value)
      return sourceEntry != null
    })

    // Allow to remove the top level category by removing all of its sources
    if (checked) {
      parentSources.forEach((source) => {
        removingSourcesDispatcher(source, conversationId)
      })
    } else {
      //In case checked is false, add all the sources
      parentSources.forEach((source) => {
        addingSourcesDispatcher(source, conversationId)
      })
    }
  }

  //Prevent more than one state to be selected
  const handleStateSelection = (key: string, conversationId: string) => {
    if (!isFederal(key)) {
      if (stateSelected) {
        removePreviousSourceSelectionDispatcher(stateSelected, conversationId)
      }
      setStateSourceSelected(key)
      return
    }
  }

  const handleClearAll = (conversationId: string) => {
    dispatch(chatV2CurrentSourceLrrRemoveAll({ conversationId }))
    setStateSourceSelected(null)
  }

  // If not open, return null
  if (!open) return null

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-neutral-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto px-4 py-20 lg:ml-[300px]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl transform rounded-xl bg-brand-neutral-50 p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox value={query}>
                <input
                  className="w-full rounded-md border-0 bg-brand-neutral-100 px-4 py-2.5 text-brand-neutral-900 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                />

                <div className={'overflow-y-scroll'}>
                  {/* List Sources */}
                  <Combobox.Options static className="-mb-2 max-h-96 sm:max-h-[600px] scroll-py-2 py-2 text-sm text-brand-neutral-800">
                    {/* TOP LEVEL KEY LISTING */}
                    {filteredKeys.map((key) => {
                      // Get this parent level key's sources
                      const parentSources = lrrSourcesResponse[key].sources

                      // CHECKED: Determine if this should be checked (all  of its sources are in the currentSources list)
                      const checked: boolean = parentSources.every((source) => {
                        const sourceEntry = currentSourceSelections.find((entry) => entry.source_name === source.value)
                        return sourceEntry != null
                      })

                      // Continue to show the source drilldown if any of the children are checked
                      const someChildrenChecked: boolean = parentSources.some((source) => {
                        const sourceEntry = currentSourceSelections.find((entry) => entry.source_name === source.value)
                        return sourceEntry != null
                      })

                      // Whether to show source drilldown (for parents with multiple sources)
                      const showSourceDrillDown = parentSources.length > 1

                      // Whether this parent has children that can be drilled down (Only single source CFR and US Code)
                      const parentSource = parentSources[0]
                      const showPathChildren = parentSources.length == 1 && parentSource.children && parentSource.children.length > 0

                      return (
                        <Combobox.Option key={key} value={key}>
                          <div className={`cursor-default select-none px-4 py-2 grid grid-cols-[24px_auto] items-center border-t-[1px]`}>
                            <input
                              id={`parent-item-${key}`}
                              type="checkbox"
                              checked={checked}
                              onChange={() => handleInputChange(key, conversationId)}
                              className="h-4 w-4 rounded border-brand-neutral-300 text-brand-500 focus:ring-brand-500"
                            />

                            <label htmlFor={`parent-item-${key}`} className={'pl-1 pr-2 items-center text-sm font-bold'}>
                              {key}
                            </label>
                          </div>

                          {showSourceDrillDown && someChildrenChecked && (
                            <LRRSourceDialogSourceDrillDown
                              conversationId={conversationId}
                              sources={parentSources}
                              sourceHandlers={{ addingSourcesDispatcher, removingSourcesDispatcher }}
                            />
                          )}
                          {checked && showPathChildren && <LrrSourceDialogPathDrillDown source={parentSource} conversationId={conversationId} />}
                        </Combobox.Option>
                      )
                    })}
                  </Combobox.Options>
                </div>

                <div className={'flex'}>
                  <button
                    type={'button'}
                    onClick={() => handleClearAll(conversationId)}
                    className={
                      'flex-auto items-center rounded-md bg-brand-neutral-50 border-[1px] border-brand-500 px-3 py-2 m-2 text-sm font-semibold text-brand-500 shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
                    }
                  >
                    Clear
                  </button>
                  <button
                    type={'button'}
                    onClick={() => onClose(false)}
                    className={
                      'flex-auto items-center rounded-md bg-brand-500 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
                    }
                  >
                    Done
                  </button>
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
