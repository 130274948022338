import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { TrashDelete, OverflowMenu, FileDownload, Drafting } from '@/assets/icons'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  onRenameClick: () => void
  onDeleteClick: () => void
  onDownloadClick: () => void
  onOpen: () => void
  isDisabled: boolean
}

const FileActionsMenu: React.FC<Props> = (props: Props) => {
  const { onRenameClick, onDownloadClick, onDeleteClick, onOpen, isDisabled } = props

  const menuElements = [
    {
      name: 'Rename',
      icon: Drafting,
      current: false,
      onClick: () => onRenameClick(), //Will open the rename modal
    },
    {
      name: 'Delete',
      icon: TrashDelete,
      current: false,
      onClick: () => onDeleteClick(),
    },
    {
      name: 'Download',
      icon: FileDownload,
      current: false,
      onClick: () => onDownloadClick(),
    },
    //TODO: Uncomment this when moving item functionality is enable
    // {
    //   name: 'Move item',
    //   icon: EnterIcon,
    //   current: false,
    //   onClick: () => {},
    // }
  ]

  return (
    <DropdownMenu.Root onOpenChange={onOpen}>
      <DropdownMenu.Trigger asChild disabled={isDisabled}>
        <button className={`p-2`}>
          <OverflowMenu
            width={18}
            height={18}
            className={`hover:text-brand-500 ${isDisabled ? 'text-brand-neutral-300 hover:text-brand-neutral-300 cursor-not-allowed' : ''}`}
          />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[200px] bg-brand-neutral-50 rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <ul role="list" className="space-y-1.5">
            {menuElements.map((item) => (
              <li key={item.name} onMouseEnter={() => (item.current = true)}>
                <DropdownMenu.Item
                  onClick={() => item.onClick()}
                  className={classNames(
                    item.current
                      ? 'bg-brand-neutral-50 text-brand-500'
                      : 'text-brand-neutral-700 hover:text-brand-500 hover:bg-brand-neutral-100 cursor-pointer',
                    'rounded-md group flex items-center flex-nowrap gap-x-1 py-[2px] text-sm leading-6 w-full'
                  )}
                >
                  <item.icon className={classNames(item.current ? 'text-brand-500' : 'h-5 w-5 shrink-0')} aria-hidden="true" />
                  <div className={`whitespace-nowrap`}>{item.name}</div>
                </DropdownMenu.Item>
              </li>
            ))}
          </ul>

          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default FileActionsMenu
