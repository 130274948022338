import { paxtonHighlightAreaSchema } from '@/components/react-pdf-viewer/schema/paxton-highlight-area'
import { z } from 'zod'

// AgentReferenceType enum
export enum AgentReferenceType {
  FILE_UPLOAD_CHUNK = 'file_upload_chunk',
  FILE_UPLOAD_FULL = 'file_upload_full',
}
export const AgentReferenceTypeSchema = z.nativeEnum(AgentReferenceType)

// Base Reference Metadata Schema
export const BaseReferenceMetadataSchema = z.object({
  reference_id: z.string().uuid(),
  user_id: z.string(),
  file_path: z.string(),
})

// ReferenceMetadataFileUploadChunkRead
export const ReferenceMetadataFileUploadChunkReadSchema = BaseReferenceMetadataSchema.extend({
  chunk_id: z.string(),
  page_nums: z.array(z.number()),
})
export type ReferenceMetadataFileUploadChunkRead = z.infer<typeof ReferenceMetadataFileUploadChunkReadSchema>

// ReferenceMetadataFileUploadFullRead
export const ReferenceMetadataFileUploadFullReadSchema = BaseReferenceMetadataSchema.extend({
  // No extensions
})
export type ReferenceMetadataFileUploadFullRead = z.infer<typeof ReferenceMetadataFileUploadFullReadSchema>

// AgentReferenceHighlights
export const AgentHighlightedCitationSchema = z.object({
  reference_id: z.string().uuid(),
  event_id: z.string().uuid(),
  index: z.number(),
  sentences: z.array(z.string()).nullable(),
  highlights: z.array(paxtonHighlightAreaSchema).nullable(),
})
export type AgentHighlightedCitation = z.infer<typeof AgentHighlightedCitationSchema>

// AgentReferenceRead
export const AgentReferenceReadSchema = z.object({
  id: z.string().uuid(),
  turn_id: z.string().uuid(),
  type: AgentReferenceTypeSchema,
  citation_id: z.string(),
  created_at: z.string().datetime(),
})
export type AgentReferenceRead = z.infer<typeof AgentReferenceReadSchema>

/**
 * Client Agent Reference
 * - the full reference object
 * - discriminated union of the two types of references
 * - Docs https://zod.dev/?id=discriminated-unions
 *
 * Discriminated unions allow us to access the correct type of metadata based on
 * the "type" field of the reference object.
 *
 * @example
 * switch (reference.type) {
 *  case AgentReferenceType.FILE_UPLOAD_CHUNK: {
 *    const pageNumbers = reference.metadata.page_nums // IDE property access of unique metadata fields works correctly.
 *    break
 *  }
 */
// ClientAgentReference - the full reference object
// This is a discriminated union of the two types of references
export const ClientAgentReferenceSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(AgentReferenceType.FILE_UPLOAD_CHUNK),
    conversation_id: z.string().uuid(),
    reference: AgentReferenceReadSchema,
    metadata: ReferenceMetadataFileUploadChunkReadSchema,
    highlighted_citations: z.array(AgentHighlightedCitationSchema),
  }),
  z.object({
    type: z.literal(AgentReferenceType.FILE_UPLOAD_FULL),
    conversation_id: z.string().uuid(),
    reference: AgentReferenceReadSchema,
    metadata: ReferenceMetadataFileUploadFullReadSchema,
    highlighted_citations: z.array(AgentHighlightedCitationSchema),
  }),
])
export type ClientAgentReference = z.infer<typeof ClientAgentReferenceSchema>
