import { createListenerMiddleware } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '@/store/store'
import { AgentConversationFormActions } from './slice'
import { WS2Actions } from '@/websocket_v2/ws2.slice'
import { WS2SendPayloadType } from '@/websocket_v2/ws2.schemas'

export const agentFormListenerMiddleware = createListenerMiddleware()
const startListener = agentFormListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()

/**
 * Submit the agent chat form
 */
startListener({
  actionCreator: AgentConversationFormActions.submitForm,
  effect: async (action, listenerApi) => {
    const { conversationId } = action.payload
    // Get the form data for this conversation
    const formData = listenerApi.getState().agentConversationFormState.forms[conversationId]
    if (!formData) {
      console.error('Form data not found.')
      return
    }

    // Mark the form as submitting
    listenerApi.dispatch(AgentConversationFormActions.setQueryIsSubmitting({ conversationId, isSubmitting: true }))

    // Websocket: Connect and send
    listenerApi.dispatch(
      WS2Actions.connectAndSend({
        type: WS2SendPayloadType.CONVERSATION_MESSAGE,
        conversationId: conversationId,
        payload: formData.requestParams,
      })
    )
  },
})

/**
 * Stop the agent chat process
 */
startListener({
  actionCreator: AgentConversationFormActions.sendStop,
  effect: async (action, listenerApi) => {
    const { conversationId } = action.payload

    // Websocket: Connect and send
    listenerApi.dispatch(WS2Actions.stopTask({ conversationId }))
  },
})
