import {
  chatV2CurrentSourceAssistantAddFile,
  chatV2CurrentSourceAssistantClearAllFiles,
  chatV2CurrentSourceAssistantToggleSelectedFile,
} from '@/chat-common/store/chat-v2.slice'
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { getTokenLimit } from '../repository/get-token-limit'
import { SelectedFilesActions } from './slice'
import { defaultMaxTokenResponse, getSelectedFilesEmbeddingSum } from '@/routes/dashboard/files/files-utils'
import { AppDispatch, RootState } from '@/store/store'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { selectConversationSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import * as Sentry from '@sentry/react'

export const selectedFilesListenerMiddleware = createListenerMiddleware()
const startListener = selectedFilesListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()

/**
 * Listen to
 * 1. Selected File Changes
 * 2. Selected File Processing Status Changes
 *
 * Execute the following actions:
 * 1. Check for max tokens allowed (if null)
 * 2. Calculate the tokens of the selected processed files
 * 3. Update the selectedFiles state slice
 */
startListener({
  // Matcher: Runs AFTER the root reducer has completed
  // Actions must have conversationId as a payload property
  matcher: isAnyOf(
    SelectedFilesActions.checkLimits,
    chatV2CurrentSourceAssistantToggleSelectedFile,
    chatV2CurrentSourceAssistantAddFile,
    chatV2CurrentSourceAssistantClearAllFiles,
    AgentConversationFormActions.addFile,
    AgentConversationFormActions.removeFile,
    AgentConversationFormActions.clearFiles
  ),
  effect: async (action, listenerApi) => {
    // Destructure common action payload property
    const { conversationId } = action.payload as { conversationId: string }

    // Validate the payload type to avoid runtime errors
    if (typeof conversationId !== 'string') {
      Sentry.captureException(new Error(`Invalid payload while checking token limits in selectedFilesListenerMiddleware: "conversationId" must be a string.`), {
        extra: {
          actionType: action.type,
          payload: JSON.stringify(action.payload),
        },
      })
      return
    }

    // Get the current state
    const state = listenerApi.getState()

    // Selected Files
    const selectedFiles = selectConversationSelectedFiles(state, { conversationId })

    // Set the used tokens to 0 and exit early if there are no selected files
    if (selectedFiles.length === 0) {
      listenerApi.dispatch(SelectedFilesActions.setUsedTokens({ chatId: conversationId, usedTokens: 0 }))
      return
    }

    // Token limit state
    const tokenLimitLoading = state.selectedFilesState.tokenLimit.loading
    const tokenLimitResponse = state.selectedFilesState.tokenLimit.response

    // Update the token limit from backend
    // Skip if it's loading or if it's not the default
    try {
      // Get the token limit if it's not loading and if it's still the default
      if (!tokenLimitLoading && tokenLimitResponse === defaultMaxTokenResponse) {
        // Dispatch loading status
        listenerApi.dispatch(SelectedFilesActions.setTokenLimitLoading(true))

        // Get the token limit
        const response = await getTokenLimit()

        // Dispatch the state update
        listenerApi.dispatch(SelectedFilesActions.setTokenLimitResponse(response))
      }
    } catch (e) {
      // Do nothing (sentry error captured in fetch function)
    } finally {
      // Dispatch the loading status
      listenerApi.dispatch(SelectedFilesActions.setTokenLimitLoading(false))
    }

    // Update the tokens used based on the processed file metadata
    try {
      // Dispatch loading status
      listenerApi.dispatch(SelectedFilesActions.setUsedTokensLoading({ chatId: conversationId, loading: true }))

      // Fetch the used tokens
      const total = await getSelectedFilesEmbeddingSum(selectedFiles)

      // Dispatch the used tokens
      listenerApi.dispatch(SelectedFilesActions.setUsedTokens({ chatId: conversationId, usedTokens: total }))
    } catch (e) {
      // Do nothing (sentry error captured in fetch function)
    } finally {
      // Dispatch the loading status
      listenerApi.dispatch(SelectedFilesActions.setUsedTokensLoading({ chatId: conversationId, loading: false }))
    }
  },
})
