import React, { ReactElement } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rehypeWrap from 'rehype-wrap-all'
import rehypeTagNameChange from '@/util/rehype-custom-plugins/rehype-tag-swap'
import { ClientAgentEventRead } from '../../store/schemas'
import AgentResponseCitationPrintable from './AgentResponseCitationPrintable'
import { rehypeAgentCitations } from '@/util/rehype-custom-plugins/rehype-agent-citations'
import { CitationIdHighlightCount } from '@/agent/references/store/slice'
interface EventTextMarkdownPrintableProps {
  event: ClientAgentEventRead
  turnUniqueCitationAndHighlightCount?: CitationIdHighlightCount
}

/**
 * Event Text Markdown Printable
 *
 * Renders the event’s value (text response) as Markdown suitable for printing or copying to the clipboard.
 * Note: Functionality copied over from chatV2FormatResponseStringPrintable for consistency between agent and legacy conversations.
 */
const EventTextMarkdownPrintable: React.FC<EventTextMarkdownPrintableProps> = (props: EventTextMarkdownPrintableProps): ReactElement | null => {
  const { event, turnUniqueCitationAndHighlightCount } = props

  if (!event || !event.value) {
    console.error('EventTextMarkdownPrintable: Event or event value is missing.')
    return null
  }

  // Trim whitespace from the event's text
  const trimmedText = event.value.trim()

  return (
    <Markdown
      className="markdown-bubble-container max-w-3xl"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[
        rehypeRaw,
        [rehypeWrap, { selector: 'table', wrapper: 'div.source-html-table-wrapper' }],
        rehypeAgentCitations,
        [
          // Swap <pre> and <code> tags with <div> tags for consistent formatting in printable output
          rehypeTagNameChange,
          [
            { tagName: 'pre', newTagName: 'div' },
            { tagName: 'code', newTagName: 'div' },
          ],
        ],
      ]}
      components={{
        // Handle divs
        div: ({ children, ...props }) => {
          // map the children and if there is a child string that contains any more than 2 '/n' for example
          // "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" then repalce it with a max of a 2 new line '\n\n'
          const newLineRegex = /\n{2,}/g
          const filteredChildren = Array.isArray(children)
            ? children.map((child) => {
                if (typeof child === 'string') {
                  return child.replace(newLineRegex, '\n\n')
                }
                return child
              })
            : children

          return <div {...props}>{filteredChildren}</div>
        },

        // Handle list items
        li: ({ children, ...props }) => {
          // filter the children and if there is a child string of '\n' then remove it
          const filteredChildren = Array.isArray(children) ? children.filter((child) => child !== '\n') : children

          return <li {...props}>{filteredChildren}</li>
        },

        // Handle citation spans
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        span: ({ node, ...props }) => {
          if ('data-agent-citation_id' in props && 'data-agent-citation_id_index' in props) {
            if (!turnUniqueCitationAndHighlightCount) {
              console.error('EventTextMarkdownPrintable: citation span found but turnUniqueCitationAndHighlightCount is missing.')
              // Return a default span if the citation data is missing
              return <span {...props}>{props.children}</span>
            }

            const citation_id = props['data-agent-citation_id'] as string
            const citation_id_index = props['data-agent-citation_id_index'] as number

            const turnUniqueCitationIds = Object.keys(turnUniqueCitationAndHighlightCount)
            const index = turnUniqueCitationIds.indexOf(citation_id)

            // Create the label for the citation using:
            // - the index of the citation_id in turnUniqueCitationIds, plus 1 to make it human readable
            // - the citation_id_index if it exists

            // For example:
            // - if the index is "0" and the citation_id_index is 0, the label will be "1"
            // - if the index is "3" and the citation_id_index is 2, the label will be "4.2"
            const label = citation_id_index > 0 ? `${index + 1}.${citation_id_index}` : index + 1

            return <AgentResponseCitationPrintable citationLabel={label} />
          }
          // Handle all other spans: render a default span
          return <span {...props}>{props.children}</span>
        },
      }}
    >
      {trimmedText}
    </Markdown>
  )
}

export default EventTextMarkdownPrintable
