import { LockClosedIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { PathMatch } from 'react-router-dom'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export type DashboardSidebarItemType = {
  name: string
  href: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  current: boolean | PathMatch<string>
  new?: boolean
  subItem?: boolean
  organizationRequired?: boolean
  allowAnon: boolean
  excludedBrands?: BrandEnum[]
  onClick?: () => void
}

export type DashboardSidebarItemProps = {
  item: DashboardSidebarItemType
  index: number
  isFirstSubItem?: boolean
}

export default function DashboardSidebarItem({ item, index, isFirstSubItem }: DashboardSidebarItemProps) {
  const authContext = useContext(AuthContext)
  const { user } = authContext
  const userIsAnon = user?.isAnonymous

  return (
    <div className={classNames(item.subItem ? 'border-l-[2px] border-l-brand-neutral-200 ml-5 pl-3 pb-1 last:pb-0' : 'w-full', isFirstSubItem ? 'mt-1' : '')}>
      {item.onClick ? (
        <button
          onClick={item.onClick}
          key={`${index}-${item.name}`}
          className={classNames(
            item.current ? 'text-brand-500 bg-brand-neutral-100' : 'text-brand-neutral-950 hover:bg-brand-neutral-100',
            'group flex items-center p-2 rounded-md w-full',
            item.subItem ? 'text-[14px]' : 'text-[16px]'
          )}
        >
          <item.icon
            className={classNames(
              item.current ? 'text-brand-500' : 'text-brand-neutral-600 group-hover:text-brand-neutral-700',
              item.subItem ? 'h-5 w-5' : 'h-6 w-6',
              'mr-2'
            )}
            aria-hidden="true"
          />
          <span className="text-md leading-6">{item.name}</span>
          {userIsAnon && !item.allowAnon && <LockClosedIcon className="h-4 ml-auto text-brand-neutral-500" />}
          {!userIsAnon && item.new && (
            <div className="text-2xs font-medium leading-tight uppercase ml-auto py-[1px] px-[4px] rounded-[4px] bg-black text-white">beta</div>
          )}
        </button>
      ) : (
        <Link
          to={item.href}
          key={`${index}-${item.name}`}
          className={classNames(
            item.current ? 'text-brand-500 bg-brand-neutral-100' : 'text-brand-neutral-950 hover:bg-brand-neutral-100',
            'group flex items-center p-2 rounded-md w-full',
            item.subItem ? 'text-[14px]' : 'text-[16px]'
          )}
        >
          <item.icon
            className={classNames(
              item.current ? 'text-brand-500' : 'text-brand-neutral-600 group-hover:text-brand-neutral-700',
              item.subItem ? 'h-5 w-5' : 'h-6 w-6',
              'mr-2'
            )}
            aria-hidden="true"
          />
          <span className="text-md leading-6">{item.name}</span>
          {userIsAnon && !item.allowAnon && <LockClosedIcon className="h-4 ml-auto text-brand-neutral-500" />}
          {!userIsAnon && item.new && (
            <div className="text-2xs font-medium leading-tight uppercase ml-auto py-[1px] px-[4px] rounded-[4px] bg-black text-white">beta</div>
          )}
        </Link>
      )}
    </div>
  )
}
