import { kErrorDisplayingResponseMessage } from '@/constants/constants-strings'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import ErrorMessageBubble from '../../events/components/ErrorMessageBubble'

type AgentUserQueryEventBubbleProps = {
  conversationId: string
  eventId: string
}

/**
 * Agent User Query Event Bubble
 *
 * A component to display events of type USER_QUERY.
 */
export default function AgentUserQueryEventBubble(props: AgentUserQueryEventBubbleProps) {
  const { conversationId, eventId } = props

  const event = useAppSelector((state: RootState) => state.agentEventsState.events[conversationId]?.[eventId])

  // TODO: User query bubble expand/collapse for when contentExceedsOriginalHeight
  // See ChatUserQueryBubble for reference

  if (!event) {
    console.error('Event not found')
    return <ErrorMessageBubble message={kErrorDisplayingResponseMessage} />
  }

  const trimmedText = event.value.trim() || ''

  const messageHeader = 'You'

  return (
    <div className="px-4 rounded-lg text-base whitespace-pre-wrap compatible-word-break bg-transparent">
      <div className="font-bold text-xl mb-1">{messageHeader}</div>
      <div className="text-sm leading-6">{trimmedText}</div>
    </div>
  )
}
