import { nanoid } from 'nanoid'
import { DriveFileSchema, DriveFolderSchema, DriveType, FilesDriveResponse, FolderDriveResponse } from '../schemas/files-drive-schema'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import * as Sentry from '@sentry/browser'
import { kFileDriveApiPath, kFolderDriveApiPath } from '@/constants/constants-api-paths'
import { ApiError } from '@/util/errors/api-error'

/**
 * Creates request options for authenticated GET requests to the API
 *
 * @param sentry_transaction_id - Unique ID for request tracing in Sentry
 * @returns Request options object with auth token and headers
 * @throws Error if user is not authenticated
 */
async function getRequestGETOptions(sentry_transaction_id: string): Promise<RequestInit> {
  const token = await getAuth().currentUser?.getIdToken(true)

  if (!token) throw Error(kErrorCodeAuthError)

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Transaction-Id': sentry_transaction_id,
    },
    method: 'GET',
  }
}

/**
 * Return the folder id of the root folder for the given drive type
 *
 * @param driveType - The type of drive to fetch the folder id from
 * @returns A promise that resolves to the folder id
 */
export async function fetchRootFolderId(driveType: DriveType): Promise<string> {
  const sentry_transaction_id = nanoid()

  const apiUrl = kPaxtonAppApiBaseUrl() + kFolderDriveApiPath + `?folder_id=null` + (driveType === DriveType.ORG ? `&owner_type=organization` : '')

  try {
    const requestOptions = await getRequestGETOptions(sentry_transaction_id)
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (response.status !== 200 || !response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(new Error(`Failed to fetch root folder id: ${response.statusText}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            driveType,
          },
        })
      })
      throw new ApiError(response.status, `Failed to fetch root folder id: ${response.statusText}`)
    }

    if (!data.length) {
      throw new Error('No folder found', { cause: data })
    }

    // Should be an array of folders with only the folder matching the folder id null
    const validatedData = DriveFolderSchema.array().safeParse(data)

    if (!validatedData.success) {
      throw new Error(`Failed to validate response: ${validatedData.error.message}`)
    }

    const rootFolderId = validatedData.data.find((folder) => folder.folder_id === null)?.id

    if (!rootFolderId) {
      throw new Error('No root folder found', { cause: data })
    }

    return rootFolderId
  } catch (error) {
    // Skip api errors already captured above
    if (!(error instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })
    }
    throw error
  }
}

/**
 * Get all files for the authenticated user or organization by folder id
 * Sends a GET request to the backend to retrieve the user's files
 * @returns A promise that resolves to an array of files and folders
 * @throws An error if the response status is not OK
 */
export async function fetchAllItemsByFolder(folderId: string): Promise<FolderDriveResponse> {
  const sentry_transaction_id = nanoid()
  const apiUrl = kPaxtonAppApiBaseUrl() + kFolderDriveApiPath + `${folderId}`

  try {
    const requestOptions = await getRequestGETOptions(sentry_transaction_id)

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (response.status !== 200 || !response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(new Error(`Failed to fetch items: ${response.statusText}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            folderId,
          },
        })
      })
      throw new ApiError(response.status, `Failed to fetch items: ${response.statusText}`)
    }

    if (!data) {
      throw new Error('No data returned from fetch', { cause: data })
    }

    const validatedData = DriveFolderSchema.safeParse(data)

    if (!validatedData.success) {
      throw new Error(`Failed to validate response: ${validatedData.error.message}`)
    }

    return validatedData.data
  } catch (e) {
    // Skip api errors already captured above
    if (!(e instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(e, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled, folderId: folderId },
        })
      })
    }
    throw e
  }
}

/**
 * Get a specific file by ID for the authenticated user
 * Sends a GET request to the backend to retrieve the specified file
 * @param fileId The ID of the file to retrieve
 * @returns A promise that resolves to the file data
 * @throws An error if the response status is not OK
 */
export async function fetchFileById(fileId: string): Promise<FilesDriveResponse> {
  const sentry_transaction_id = nanoid()
  const apiUrl = kPaxtonAppApiBaseUrl() + kFileDriveApiPath + `${fileId}`

  try {
    const requestOptions = await getRequestGETOptions(sentry_transaction_id)

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (response.status !== 200 || !response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(new Error(`Failed to fetch file: ${response.statusText}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            fileId,
          },
        })
      })
      throw new ApiError(response.status, `Failed to fetch file: ${response.statusText}`)
    }

    if (!data) {
      throw new Error('No data returned from fetch', { cause: data })
    }

    const validatedData = DriveFileSchema.safeParse(data)
    if (!validatedData.success) {
      throw new Error(`Failed to validate response: ${validatedData.error.message}`)
    }

    return validatedData.data
  } catch (e) {
    // Skip api errors already captured above
    if (!(e instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(e, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled, fileId: fileId },
        })
      })
    }
    throw e
  }
}
