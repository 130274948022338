import { store } from '@/store/store'
import { ClientAgentReference } from '../store/schemas'

/**
 * Determines the title for a given agent reference based on its metadata.
 *
 * @param reference - The agent reference object.
 * @returns The title string to display.
 */
export function agentReferenceMetadataToTitle(reference: ClientAgentReference) {
  const title = reference.metadata.file_path.split('/').pop() ?? 'Unnamed Reference'
  return title
}

/**
 * Get the footnote number for a given agent reference.
 * @param reference - The agent reference object.
 * @returns The footnote number as a string, or null if the reference is not found.
 */
export function agentReferenceFootnoteNumber(reference: ClientAgentReference): string | null {
  // Get the turn's unique citation_ids -> highlight count object
  const turnUniqueCitationAndHighlightCount = store.getState().agentReferencesState.turnUniqueCitationAndHighlightCount[reference.reference.turn_id] ?? {}
  const turnUniqueCitationIds = Object.keys(turnUniqueCitationAndHighlightCount)
  const index = turnUniqueCitationIds.indexOf(reference.reference.citation_id) ?? -1

  return index >= 0 ? `${index + 1}` : null
}
