import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { ContentViewerActions } from '@/content-viewer/store/slice'
import { useAppDispatch } from '@/store/store-hooks'
import { CloseRounded, TextSnippet } from '@mui/icons-material'

export type ContentViewerHeaderProps = {
  conversationId: string
  title: string
  loading: boolean
}

export default function ContentViewerHeader(props: ContentViewerHeaderProps) {
  const { conversationId, title, loading } = props
  const dispatch = useAppDispatch()

  const handleClose = () => {
    // Clear the content viewer
    dispatch(ContentViewerActions.clearContentViewer())

    // Remove the active content viewer for the conversation
    dispatch(AgentConversationsActions.RemoveConversationActiveContentViewer({ conversationId }))
  }

  return (
    <div className="grid grid-cols-[47px_auto_47px] items-center h-14 py-2 border-b border-brand-neutral-200 overflow-y-hidden">
      <div className={'w-full text-center'}>
        {loading ? <CircularProgressContinuousSized size={16} thickness={7} /> : <TextSnippet className="text-gray-400 -mt-[2px]" />}
      </div>
      <div className={'compatible-word-break font-bold leading-[17px] max-h-full text-sm overflow-hidden'} title={title}>
        {title}
      </div>
      <div className={'text-center'}>
        <button onClick={handleClose} aria-label="Close Artifact Viewer">
          <CloseRounded fontSize="small" />
        </button>
      </div>
    </div>
  )
}
