import { kFileDriveApiPath, kFolderDriveApiPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
/**
 * Delete a file by file id
 * Sends a DELETE request to the backend to delete the file
 * @returns A promise that resolves to an object with a success/failure message
 * @throws An error if the response status is not OK
 */
export async function deleteFileById(fileId: string) {
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken(true)

    if (!token) throw Error(kErrorCodeAuthError)

    const apiUrl = kPaxtonAppApiBaseUrl() + kFileDriveApiPath + `${fileId}`

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Transaction-Id': sentry_transaction_id,
      },
      method: 'DELETE',
    }

    const response = await fetch(apiUrl, requestOptions)

    if (response.status !== 200) {
      throw new Error(`Failed to delete file: ${response.statusText}`)
    }

    return response
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled, fileId: fileId },
      })
    })
    throw e
  }
}

/**
 * Delete multiple files by their file ids
 * Sends DELETE requests to the backend to delete the files
 * @param fileIds Array of file IDs to delete
 * @returns A promise that resolves when all files are deleted
 * @throws An error if any of the delete operations fail
 */
export async function deleteFilesByIds(fileIds: string[]) {
  const sentry_transaction_id = nanoid()

  if (fileIds.length === 0) {
    throw new Error('No file IDs provided')
  }

  try {
    const token = await getAuth().currentUser?.getIdToken(true)

    if (!token) throw Error(kErrorCodeAuthError)

    const apiUrl = kPaxtonAppApiBaseUrl() + kFileDriveApiPath

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Transaction-Id': sentry_transaction_id,
      },
      method: 'DELETE',
      body: JSON.stringify({ file_ids: fileIds }),
    }

    const response = await fetch(apiUrl, requestOptions)

    if (response.status !== 200) {
      throw new Error(`Failed to delete files: ${response.statusText}`)
    }

    const body = await response.json()

    if (body.not_deleted_files?.length) {
      throw new Error(`Failed to delete files: ${body.not_deleted_files.join(', ')}`)
    }

    return response
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: {
          onLine: navigator.onLine,
          cookieEnabled: navigator.cookieEnabled,
          fileIds,
        },
      })
    })
    throw e
  }
}

/**
 * Delete multiple folders by their folder ids
 * Sends DELETE requests to the backend to delete the folders
 * @param folderIds Array of folder IDs to delete
 * @returns A promise that resolves when all folders are deleted
 * @throws An error if any of the delete operations fail
 */
export async function deleteFoldersByIds(folderIds: string[]) {
  const sentry_transaction_id = nanoid()

  if (folderIds.length === 0) {
    throw new Error('No folder IDs provided')
  }

  try {
    const token = await getAuth().currentUser?.getIdToken(true)

    if (!token) throw Error(kErrorCodeAuthError)

    const apiUrl = kPaxtonAppApiBaseUrl() + kFolderDriveApiPath

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Transaction-Id': sentry_transaction_id,
      },
      method: 'DELETE',
      body: JSON.stringify({ folder_ids: folderIds }),
    }

    const response = await fetch(apiUrl, requestOptions)
    if (response.status !== 200) {
      throw new Error(`Failed to delete folders: ${response.statusText}`)
    }

    const body = await response.json()
    if (body.not_deleted_folders && body.not_deleted_folders?.length) {
      throw new Error(`Failed to delete folders: ${body.not_deleted_folders.join(', ')}`)
    }

    return response
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: {
          onLine: navigator.onLine,
          cookieEnabled: navigator.cookieEnabled,
          folderIds,
        },
      })
    })
    throw e
  }
}
