import { useState, useEffect, useContext, useRef, createContext } from 'react'
import StripePortalDialog from '@/routes/dashboard/subscribe/stripe-portal-dialog.tsx'
import { setStripePortalDialogVisible } from '@/store/slices/ui-state.slice'
import PartnerCodeDialog from '@/routes/dashboard/subscribe/PartnerCode.tsx'
import signOutOfApp from '@/firebase/auth/sign-out'
import { useNavigate } from 'react-router'
import CheckoutContainer from '@/components/stripe/CheckoutContainer'
import { useHubspotConversations } from '@/context/hubspot-conversations-provider'
import createStripeSetupIntent from '@/payments/fetch/post-stripe-setup-intent.ts'
import type { CreateStripeSetupIntentResponse } from '@/payments/fetch/post-stripe-setup-intent.ts'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { ArrowUpRight, AlertErrorCircle } from '@/assets/icons'
import { setLegacyStripeCheckoutVisible } from '@/store/slices/ui-state.slice'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import { annualBillingMessage, monthlyBillingMessage } from '@/routes/dashboard/subscribe/subscription-utils'
import { BillingCycle } from '@/firebase/auth/auth-jwt-schema'
import BillingCycleToggle from '@/routes/dashboard/subscribe/BillingCycleToggle'
import { setBillingCycle } from '@/routes/dashboard/subscribe/store/subscription.slice'
import { AuthContext } from '@/context/auth-context'
import { SubscriptionType } from '@/firebase/auth/auth-jwt-schema'
import DueSection from './DueSection'
import {
  getMonthlyCost,
  getAnnualCostOfMonthlyPlan,
  getAnnualPlanDiscountPercentage,
  getAmountDue,
} from '@/routes/dashboard/subscribe/store/subscription.slice'

// Create a simpler context that only exposes the scroll function
export interface CheckoutScrollContextType {
  scrollToTop: () => void
}

export const CheckoutScrollContext = createContext<CheckoutScrollContextType>({
  // No-op function as default value
  scrollToTop: () => {
    /* Default empty implementation */
  },
})

export default function TestimonialPlusSignup() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const state = useAppSelector((state: RootState) => state.subscriptionState)
  const trialDays = state.trialDays
  const [partnerCodeDialogVisible, setPartnerCodeDialogVisible] = useState(false)
  const { toggleWidget } = useHubspotConversations()
  const [stripeSetupIntent, setStripeSetupIntent] = useState<string | null>(null)
  const [setupIntentLoading, setSetupIntentLoading] = useState(false)
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const showStripePortalButton = uiState.showLegacyStripeCheckout
  const legacyStripeCheckoutMessage = uiState.legacyStripeCheckoutMessage
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptionState)
  const billingCycle = subscriptionState.billingCycle
  const promotion = subscriptionState.promotion
  const { userAccountData } = useContext(AuthContext)

  // Create the ref inside the component
  const checkoutScrollContainerRef = useRef<HTMLDivElement>(null)

  // Function to scroll to the top of the container
  const scrollToTop = () => {
    if (checkoutScrollContainerRef.current) {
      checkoutScrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (stripeSetupIntent) return
    fetchStripeSetupIntent()
  }, [stripeSetupIntent])

  const fetchStripeSetupIntent = async () => {
    setSetupIntentLoading(true)
    console.log('fetchStripeSetupIntent', stripeSetupIntent)
    try {
      const response: CreateStripeSetupIntentResponse = await createStripeSetupIntent()
      setStripeSetupIntent(response.client_secret ?? null)
      setSetupIntentLoading(false)
    } catch (error) {
      dispatch(setLegacyStripeCheckoutVisible(true))
    }
  }

  const handleBillingCycleSelect = (cycle: BillingCycle) => {
    dispatch(setBillingCycle(cycle))
  }

  const subscriptionType = userAccountData?.subscriptionType ?? SubscriptionType.STANDARD

  const monthlyCost = getMonthlyCost(state, billingCycle, subscriptionType)
  const amountDue = getAmountDue(state, billingCycle, subscriptionType, promotion)

  const originalPrice = getAnnualCostOfMonthlyPlan(state, subscriptionType)
  const discountPercentage = getAnnualPlanDiscountPercentage(state, subscriptionType)

  const paymentHeader = `Start your ${trialDays} day free trial`

  const paymentSubHeader = `Then just $${monthlyCost} / month, ${(billingCycle === BillingCycle.ANNUAL ? annualBillingMessage : monthlyBillingMessage).replace(
    '{discountPercentage}',
    discountPercentage.toString()
  )} Cancel anytime.`

  return (
    <CheckoutScrollContext.Provider value={{ scrollToTop }}>
      <div className="flex h-full flex-col md:flex-row">
        <div className="flex md:w-1/2 bg-brand-neutral-50">
          <div ref={checkoutScrollContainerRef} className="flex flex-col space-y-4 justify-center w-full overflow-y-auto">
            <PartnerCodeDialog visible={partnerCodeDialogVisible} onClose={() => setPartnerCodeDialogVisible(false)} />
            <StripePortalDialog />
            <div className="sm:mx-auto w-full h-full sm:max-w-xl flex justify-center">
              <div className="bg-brand-neutral-50 p-8 max-w-md sm:rounded-lg">
                <div className="flex items-center justify-between">
                  <h1 className="text-[23px] md:text-[27px] text-start font-bold tracking-tight text-brand-neutral-900">{paymentHeader}</h1>
                  {subscriptionType === SubscriptionType.EDUCATION && (
                    <span className="text-[8px] md:text-2xs font-semibold inline-block py-1 px-2 uppercase rounded text-brand-500 bg-brand-200 uppercase last:mr-0 mr-1">
                      student
                    </span>
                  )}
                </div>
                <h2 className="text-l mb-3 text-start font-semibold text-brand-neutral-800">{paymentSubHeader}</h2>
                <img src="/images/logos/stripe-logo-svg.svg" alt="Logo 1" className="mb-4 h-7 flex" />
                <DueSection amountDue={amountDue} originalPrice={billingCycle === BillingCycle.ANNUAL ? originalPrice : null} promotion={promotion} />
                <BillingCycleToggle billingCycle={billingCycle} handleBillingCycleSelect={handleBillingCycleSelect} />
                {setupIntentLoading ? (
                  <div className="mt-5 text-center">
                    <CircularProgressContinuous />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col justify-between text-center text-sm text-brand-neutral-500">
                      {legacyStripeCheckoutMessage && (
                        <div className="mt-3 p-3 bg-white border border-red-400 rounded-md shadow-sm mb-4">
                          <p className="text-red-600 flex items-start text-left">
                            <AlertErrorCircle className="h-5 w-5 mr-1" />
                            {legacyStripeCheckoutMessage}
                          </p>
                        </div>
                      )}
                      {showStripePortalButton ? (
                        <>
                          <button
                            type="submit"
                            className="w-full rounded-md bg-brand-700 px-2 py-1 mt-4 text-lg  text-white shadow-sm hover:bg-brand-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-900 "
                            onClick={() => dispatch(setStripePortalDialogVisible(true))}
                          >
                            Add Payment Method on Stripe <ArrowUpRight className={'inline-block mb-1'} />
                          </button>
                        </>
                      ) : (
                        <>
                          <CheckoutContainer clientSecret={stripeSetupIntent} supportTrial={true} />
                        </>
                      )}
                    </div>

                    <div className="flex justify-between gap-x-4 mt-5 text-center text-sm text-brand-neutral-500">
                      <button
                        onClick={() => {
                          signOutOfApp(navigate)
                        }}
                        className="font-semibold leading-6 text-brand-500 hover:text-brand-400"
                      >
                        Sign out
                      </button>
                      <button
                        onClick={() => {
                          toggleWidget()
                        }}
                        className="font-semibold leading-6 text-brand-500 hover:text-brand-400"
                      >
                        Chat with support
                      </button>
                      <button
                        onClick={() => {
                          setPartnerCodeDialogVisible(true)
                        }}
                        className="font-semibold leading-6 text-brand-500 hover:text-brand-400"
                      >
                        Partner code
                      </button>
                    </div>
                    <div className="h-4" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:w-1/2">
          <div className="flex flex-col py-8 justify-center w-full bg-brand-neutral-100 px-6">
            <div className="flex justify-center w-full">
              <ul role="list" className="-mb-8">
                <li>
                  <div className="flex items-center gap-x-6">
                    <img className="h-16 w-16 rounded-full" src="/images/testimonial-headshot-web.jpeg" alt="" />
                    <div>
                      <h3 className="text-base font-semibold leading-7 tracking-tight text-brand-neutral-900">Emily L.</h3>
                      <p className="mt-1 italic">"I use Paxton AI to get up to speed on issues and generate first drafts in minutes instead of hours."</p>
                    </div>
                  </div>
                </li>

                <li className="mt-8">
                  <div className="h-5" />
                  <h3 className="font-bold mb-2">Featured In</h3>
                  <div className="mt-4 flex flex-wrap gap-x-4 gap-y-2 items-center">
                    <img src="/images/logos/reuters-logo-svg.svg" alt="Logo 1" className="h-8 pr-2" />
                    <img src="/images/logos/fortune-logo-svg.svg" alt="Logo 2" className="h-9 p-2" />
                    <img src="/images/logos/forbes-logo-svg.svg" alt="Logo 2" className="h-11" />
                    <img src="/images/logos/axios-logo-svg.svg" alt="Logo 2" className="h-5" />
                    <div className={'basis-full h-0'}></div>
                    <img src="/images/logos/wsj-logo-svg.svg" alt="Logo 2" className="h-5" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </CheckoutScrollContext.Provider>
  )
}
