import { CircularProgress, ThemeProvider, createTheme } from '@mui/material'
import { CheckIcon } from '@radix-ui/react-icons'
import { XClear } from '@/assets/icons'

// A spinning loader designed to fit into a tailwind ui button
interface CircularProgressDeterminateOption {
  value: number
  error: boolean
}

export const CircularProgressDeterminateTheme = createTheme({
  palette: {
    primary: {
      main: '#2A49FF', // brand-500
    },
  },
})

export const CircularProgressDeterminate = ({ value, error }: CircularProgressDeterminateOption) => {
  return (
    <>
      <div className={''}>
        <div role="status" className="relative block h-[25px] pl-[5px]">
          <div className={'absolute z-0'}>
            <ThemeProvider theme={CircularProgressDeterminateTheme}>
              <CircularProgress size={25} thickness={5} variant="determinate" value={value} />
            </ThemeProvider>
          </div>
          <div className={`absolute z-10 transition-all ${value == 100 ? 'opacity-100' : 'opacity-0'}`}>
            <CheckIcon className={'self-center bg-green-600 text-white rounded-full p-[1px] h-[25px] w-[25px]'} />
          </div>
          <div className={`absolute z-10 transition-all ${error ? 'opacity-100' : 'opacity-0'}`}>
            <XClear className={'self-center bg-red-600 text-white rounded-full p-[1px] h-[25px] w-[25px]'} />
          </div>
        </div>
      </div>
    </>
  )
}
