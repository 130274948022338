import { RootState } from '@/store/store'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FolderNode } from '../schemas/files-drive-schema'
import FolderListItem from './FolderListItem'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { FilesDriveActions } from '../store/files-drive.slice'

type FolderListProps = {
  folders: FolderNode[]
  selectedFolders: string[]
  isItemSelected: (itemId: string, selectedFiles: string[], selectedFolders: string[]) => boolean
}

export default function FolderList({ folders, isItemSelected }: FolderListProps) {

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { pathname } = location
  const baseDriveLocationPath = pathname.split('/').slice(0, 5).join('/')

  const { folders_ids, files_ids } = useAppSelector((state: RootState) => state.filesDrive.selectedFilesAndFolders)

  const handleSelectFolder = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const { checked, value: itemId } = e.currentTarget // value = id of the item. 

    let selectedFolders = folders_ids

    if (checked) {
      selectedFolders = [...selectedFolders, itemId]
    } else {
      selectedFolders = selectedFolders.filter(id => id !== itemId)
    }

    dispatch(FilesDriveActions.setSelectedFilesAndFolders({
      files_ids, //remains the same
      folders_ids: selectedFolders
    }))
  }

  function clearSelectedItems() {
    dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files_ids: [], folders_ids: [] }))
  }

  function handleFolderClick(folder: FolderNode) {
    navigate(`${baseDriveLocationPath}/${encodeURI(folder.id)}`)
    clearSelectedItems()
  }

  return (
    <>
      {folders.map((folder) => (
        <FolderListItem
          key={folder.id}
          folder={folder}
          isSelected={isItemSelected(folder.id, files_ids, folders_ids)}
          onSelect={handleSelectFolder}
          onClick={() => handleFolderClick(folder)}
        />
      ))}
    </>
  )
}
