import React from 'react'
import { ClientAgentEventRead } from '@/agent/events/store/schemas'
import EventTextMarkdownPrintable from './EventTextMarkdownPrintable'

interface PromptAssistEventPrintableBubbleProps {
  event: ClientAgentEventRead
}

/**
 * Prompt Assist Event Printable Bubble
 *
 * A printable version of the PromptAssistEventBubble.
 */
const PromptAssistEventPrintableBubble: React.FC<PromptAssistEventPrintableBubbleProps> = (props: PromptAssistEventPrintableBubbleProps) => {
  const { event } = props

  // If the event is missing or the value is empty, don't render the bubble
  if (!event || !event.value.trim()) {
    return null
  }

  return (
    <div>
      {/* Header */}
      <h2>
        <i>Prompt Assist</i>
      </h2>

      {/* Event text rendered as printable Markdown */}
      <EventTextMarkdownPrintable event={event} />
    </div>
  )
}

export default PromptAssistEventPrintableBubble
