import { useAppSelector } from '@/store/store-hooks'
import { useMatch } from 'react-router'
import { selectLastViewedConversationIdRecord } from '@/chat-common/store/chat-v2-ux.selectors'
import { CombinedResearch, LawsRegs, CaseLawGavel } from '@/assets/icons'
import DashboardSidebarGroup from './components/dashboard-sidebar-group'
import { DashboardSidebarItemType } from './components/dashboard-sidebar-item'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { isPaxtonBrand } from '@/util/enterprise'

export default function SidebarGroupResearch() {
  const lastViewedConversationIdRecord = useAppSelector(selectLastViewedConversationIdRecord)

  const lastViewedResearchId = lastViewedConversationIdRecord[ChatV2Feature.research]
  const lastViewedLrrV2Id = lastViewedConversationIdRecord[ChatV2Feature.lrr_v2]
  const lastViewedCaseLawId = lastViewedConversationIdRecord[ChatV2Feature.caselaw]

  const isPaxtonHost = isPaxtonBrand()

  const researchItems: DashboardSidebarItemType[] = [
    {
      name: 'Combined Research',
      href: lastViewedResearchId ? `/dashboard/chat/${ChatV2Feature.research}/${lastViewedResearchId}` : `/dashboard/chat/${ChatV2Feature.research}`,
      icon: CombinedResearch,
      current: useMatch(`/dashboard/chat/${ChatV2Feature.research}/*`) ?? false,
      allowAnon: false,
    },
    {
      name: 'Laws & Regulations',
      href: lastViewedLrrV2Id ? `/dashboard/chat/lrr_v2/${lastViewedLrrV2Id}` : '/dashboard/chat/lrr_v2',
      icon: LawsRegs,
      current: useMatch('/dashboard/chat/lrr_v2/*') ?? false,
      allowAnon: isPaxtonHost ? true : false,
      subItem: true,
    },
    {
      name: 'Case Law',
      href: lastViewedCaseLawId ? `/dashboard/chat/caselaw/${lastViewedCaseLawId}` : '/dashboard/chat/caselaw',
      icon: CaseLawGavel,
      current: useMatch('/dashboard/chat/caselaw/*') ?? false,
      allowAnon: false,
      subItem: true,
    },
  ]

  return <DashboardSidebarGroup items={researchItems} />
}
