import React from 'react'

/**
 * Context for providing a reference to a container element.
 *
 * This context allows components, such as Radix Popovers, to access a
 * specific container element where they should be positioned or constrained.
 * It can be used to:
 *
 * - Define a custom **collision boundary** for popovers to prevent overflow.
 * - Specify a **portal container** where popovers should be rendered.
 *
 * Usage:
 * - Provide the container in a parent component:
 *   `<PopoverContainerContext.Provider value={containerRef.current}>`
 * - Consume it inside child components to use for collision boundaries, portals, etc.:
 *   `<Popover.Content collisionBoundary={container} />`
 *   `<Popover.Portal container={container} />`
 *
 * For more details on how Radix handles popover positioning and collision detection,
 * see the official documentation:
 * [Radix Popover Docs](https://www.radix-ui.com/docs/primitives/components/popover)
 */
export const PopoverContainerContext = React.createContext<HTMLElement | null>(null)
