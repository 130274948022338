import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useState } from 'react'
import { getBrandName } from '@/util/enterprise'
import { ArtifactVersionRead, ArtifactVersionStatusType } from '@/artifacts/schemas'
import { selectArtifactById } from '@/artifacts/store/selectors'
import ArtifactButton from '@/artifacts/components/artifact-button'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { kErrorDisplayingResponseMessage } from '@/constants/constants-strings'
import ErrorMessageBubble from '../ErrorMessageBubble'
import EventTextMarkdown from '../EventTextMarkdown'
import { selectConversationWebSocketConnected } from '@/websocket_v2/ws2.selectors'
import { selectMostRecentConversationEventId } from '@/agent/events/store/selectors'
import BlinkingCursor from '@/components/loaders/BlinkingCursor'
import AgentResponseActions from './AgentResponseActions'
import AgentResponseReferencesSection from './AgentResponseReferencesSection'

type AgentResponseEventBubbleProps = {
  conversationId: string
  eventId: string
}

/**
 * Agent Response Event Bubble
 *
 * A component to display events of type AGENT_RESPONSE.
 */
export default function AgentResponseEventBubble(props: AgentResponseEventBubbleProps) {
  const { conversationId, eventId } = props
  const dispatch = useAppDispatch()

  // Redux state selectors
  const event = useAppSelector((state: RootState) => state.agentEventsState.events[conversationId]?.[eventId])
  const artifactVersion: ArtifactVersionRead | null = useAppSelector((state: RootState) =>
    selectArtifactById(state, { artifactVersionId: event?.artifact_version_id })
  )
  const isWebSocketConnected = useAppSelector((state: RootState) => selectConversationWebSocketConnected(state, { conversationId }))

  const mostRecentEventId = useAppSelector((state: RootState) => selectMostRecentConversationEventId(state, { conversationId }))
  const isMostRecentEvent = mostRecentEventId === eventId
  const showBlinkingCursor = isMostRecentEvent && isWebSocketConnected && !artifactVersion

  // Blink state - blink is false until 500ms after render
  const [blink, setBlink] = useState(false)
  setTimeout(() => {
    setBlink(true)
  }, 500)

  const text = event?.value

  // Trim the text
  const trimmedText = text?.trim() || ''

  // Message header
  const messageHeader = getBrandName()

  if (!event) {
    console.error('Event not found')
    return <ErrorMessageBubble message={kErrorDisplayingResponseMessage} />
  }

  // If the text is empty, don't render the bubble yet
  if (!trimmedText) {
    return null
  }

  return (
    <div className="py-2 px-4 rounded-lg text-base whitespace-pre-wrap compatible-word-break bg-white">
      <div className="font-bold text-xl mb-1">{messageHeader}</div>
      <div className="text-sm leading-6 mb-2">
        <div id="chat-response-bubble">
          <EventTextMarkdown conversationId={conversationId} eventId={eventId} />
        </div>
        {showBlinkingCursor && <BlinkingCursor blink={blink} />}
      </div>

      {/* Message Action Icons */}
      {/* Don't show the action icons if the websocket is connected as the response may not be complete yet */}
      {!isWebSocketConnected && !artifactVersion && <AgentResponseActions conversationId={conversationId} event={event} />}

      {/* Artifact */}
      {artifactVersion && (
        <div className={`space-y-3 ${artifactVersion.status === ArtifactVersionStatusType.COMPLETED ? 'mb-1' : 'mb-3'}`}>
          <ArtifactButton
            conversationId={conversationId}
            artifactVersion={artifactVersion}
            onCancelGenerating={() => {
              dispatch(AgentConversationFormActions.sendStop({ conversationId }))
            }}
          />
          {artifactVersion.status === ArtifactVersionStatusType.COMPLETED && (
            <div className="text-sm leading-6">
              Your draft is ready for review and may be accessed via the artifact above. Is there anything you would like clarified or modified?
            </div>
          )}
        </div>
      )}

      {/* References */}
      <AgentResponseReferencesSection turn_id={event.turn_id} response_message={event.value} />
    </div>
  )
}
