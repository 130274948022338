type AgentResponseCitationPrintableProps = {
  citationLabel: string | number
}

/**
 * Agent Response Citation Printable
 *
 * A printable version of the AgentResponseCitationPopover component.
 * This is used to display citations in the printable version of the agent response text.
 */
export default function AgentResponseCitationPrintable(props: AgentResponseCitationPrintableProps) {
  const { citationLabel } = props

  return (
    <span className="text-brand-800 px-1" aria-label={`Citation ${citationLabel}`}>
      [{citationLabel}]
    </span>
  )
}
