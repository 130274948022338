import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@/store/store'
import { AgentResponseMetadataRead } from './schemas'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'

/**
 * Select: Agent Response Metadata by Event ID
 *
 * Returns the response metadata for the given event ID, or null if it does not exist.
 */
export const selectAgentResponseMetadataByEventId = (state: RootState, props: { eventId: string | undefined | null }): AgentResponseMetadataRead | null => {
  const { eventId } = props
  if (!eventId) return null

  return state.agentResponseMetadataState.metadata[eventId] ?? null
}

/**
 * Select: Agent Response Thumb Rating for a given Event ID
 *
 * Returns the thumb rating or null if it does not exist.
 */
export const selectAgentResponseThumbRating = createSelector(
  selectAgentResponseMetadataByEventId,
  (metadata): FeedbackThumb | null => metadata?.thumb_rating ?? null
)

/**
 * Select: Agent Response Feedback Dialog
 *
 * Returns the feedback dialog state from the store.
 */
export const selectAgentResponseFeedbackDialog = (state: RootState) => state.agentResponseMetadataState.feedbackDialog
