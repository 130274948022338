import { useContext } from 'react'
import { useMatch } from 'react-router'
import { useAppSelector } from '@/store/store-hooks'
import { AuthContext } from '@/context/auth-context'
import { selectLastViewedConversationIdRecord } from '@/chat-common/store/chat-v2-ux.selectors'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { SidebarItemId } from '@/store/slices/ui-state.slice'
import { FileSummarizer, ContractAnalysis, ContractSearch, WebCompass, Boolean, CompareDocuments, Tools } from '@/assets/icons'
import DashboardSidebarCollapsibleGroup from './components/dashboard-sidebar-collapsible-group'
import { DashboardSidebarItemType } from './components/dashboard-sidebar-item'

export default function SidebarGroupTools() {
  // Auth Context
  const authContext = useContext(AuthContext)
  const { userAccountData } = authContext
  const brand = userAccountData?.brand ?? BrandEnum.NONE

  // Get the last viewed conversations record
  const lastViewedConversationIdRecord = useAppSelector(selectLastViewedConversationIdRecord)
  const lastViewedWebSearchId = lastViewedConversationIdRecord[ChatV2Feature.websearch]
  const lastViewedContractSearchId = lastViewedConversationIdRecord[ChatV2Feature.contractsearch]
  const lastViewedDocumentSummarizationId = lastViewedConversationIdRecord[ChatV2Feature.documentSummarization]

  // Create the tools items
  const toolsItems: DashboardSidebarItemType[] = [
    {
      name: 'File Summarizer',
      href: lastViewedDocumentSummarizationId
        ? `/dashboard/chat/${ChatV2Feature.documentSummarization}/${lastViewedDocumentSummarizationId}`
        : `/dashboard/chat/${ChatV2Feature.documentSummarization}`,
      icon: FileSummarizer,
      new: true,
      current: useMatch(`/dashboard/chat/${ChatV2Feature.documentSummarization}/*`) ?? false,
      allowAnon: false,
      subItem: true,
    },
    {
      name: 'Contract Analysis',
      href: '/dashboard/document-editing/list',
      icon: ContractAnalysis,
      new: true,
      current: useMatch('/dashboard/document-editing/*') ?? false,
      allowAnon: false,
      subItem: true,
    },
    {
      name: 'Contract Search',
      href: lastViewedContractSearchId
        ? `/dashboard/chat/${ChatV2Feature.contractsearch}/${lastViewedContractSearchId}`
        : `/dashboard/chat/${ChatV2Feature.contractsearch}`,
      icon: ContractSearch,
      new: true,
      current: useMatch(`/dashboard/chat/${ChatV2Feature.contractsearch}/*`) ?? false,
      allowAnon: false,
      excludedBrands: [BrandEnum.HAIKU],
      subItem: true,
    },
    {
      name: 'Web & News',
      href: lastViewedWebSearchId ? `/dashboard/chat/websearch/${lastViewedWebSearchId}` : '/dashboard/chat/websearch',
      icon: WebCompass,
      current: useMatch('/dashboard/chat/websearch/*') ?? false,
      allowAnon: false,
      subItem: true,
    },
    {
      name: 'Boolean Composer',
      href: '/dashboard/boolean-composer',
      icon: Boolean,
      current: useMatch('/dashboard/boolean-composer') ?? false,
      allowAnon: false,
      excludedBrands: [BrandEnum.HAIKU],
      subItem: true,
    },
    {
      name: 'Compare Documents',
      href: '/dashboard/audit',
      icon: CompareDocuments,
      current: useMatch('/dashboard/audit') ?? false,
      allowAnon: false,
      excludedBrands: [BrandEnum.HAIKU],
      subItem: true,
    },
  ]

  // Filter out items excluded for the current brand
  const filteredItems = toolsItems.filter((item) => !item.excludedBrands?.includes(brand))

  // Only render if there are items to show
  if (filteredItems.length === 0) {
    return null
  }

  return <DashboardSidebarCollapsibleGroup title="Tools" itemId={SidebarItemId.Tools} icon={Tools} items={filteredItems} />
}
