import { ChevronDown, ChevronRight } from '@/assets/icons'
import AgentResponseReferencesListItem from './AgentResponseReferencesListItem'
import { CitationIdHighlightCount } from '@/agent/references/store/slice'

type AgentResponseReferencesGroupProps = {
  title: string
  citationIdHighlightCount: CitationIdHighlightCount
  numOfCitations: number
  groupCitationIds: string[]
  turnUniqueCitationIds: string[]
  isExpanded: boolean
  toggleExpand: () => void
}

export default function AgentResponseReferencesGroup({
  title,
  citationIdHighlightCount,
  numOfCitations,
  groupCitationIds,
  turnUniqueCitationIds,
  isExpanded,
  toggleExpand,
}: AgentResponseReferencesGroupProps) {
  return (
    <>
      <div
        className={`px-1 text-sm text-brand-neutral-600 flex items-center gap-x-2 cursor-pointer rounded-md hover:bg-brand-neutral-100`}
        onClick={toggleExpand}
      >
        {numOfCitations} {title}
        <div>{isExpanded ? <ChevronDown width="16" height="16" /> : <ChevronRight width="16" height="16" />}</div>
      </div>
      {isExpanded && (
        <div className={`text-sm mt-2 px-1`}>
          {groupCitationIds.map((citation_id) => {
            const highlightCount = citationIdHighlightCount[citation_id] || 0

            // Get the index of the citation_id in the turnUniqueCitationIds array (not just this group)
            const citationIdIndex = turnUniqueCitationIds.indexOf(citation_id)

            return (
              <AgentResponseReferencesListItem
                key={`${title}_${citation_id}`}
                citation_id={citation_id}
                citationIdIndex={citationIdIndex}
                citationIdHighlightCount={highlightCount}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
