import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { AlertErrorCircle, ChevronDown, ChevronUp } from '@/assets/icons'
import { selectConversationCurrentSource, selectConversationIsLoading, selectConversationSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2QueryMetadataAssistant, ModeChoice } from '@/chat-common/schemas/chat-query-metadata-schema'
import { toggleSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.slice'
import { selectFileProcessingStatusesWithKeys } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { FileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { selectConversationSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.selectors'
import { AgentFocusMode } from '@/agent/chat_form/store/schemas'

type SelectedFilesProps = {
  chatId: string
  isOverLimit: boolean
  someProcessingOrPending: boolean
}

function SelectedFilesControl(props: SelectedFilesProps) {
  const { chatId, isOverLimit, someProcessingOrPending } = props
  const dispatch = useAppDispatch()

  // Check if agent conversation
  const agentConversationForm = useAppSelector((state: RootState) => state.agentConversationFormState.forms[chatId])

  // Conversation State
  const currentSource = useAppSelector((state: RootState) => selectConversationCurrentSource(state, { chatId: chatId })) as ChatV2QueryMetadataAssistant | null
  const isLoading = useAppSelector((state: RootState) => selectConversationIsLoading(state, { chatId }))

  // Selected Files State
  const selectedFilePaths = useAppSelector((state: RootState) => selectConversationSelectedFiles(state, { conversationId: chatId }))
  const selectedFileProcessingStatuses = useAppSelector((state: RootState) => selectFileProcessingStatusesWithKeys(state, { keys: selectedFilePaths }))
  const someErrors = Object.values(selectedFileProcessingStatuses).some((status) => status === FileProcessingStatus.ERROR)
  const selectedFilesCount = selectedFilePaths.length
  const hasSelectedFiles = selectedFilesCount > 0

  // Focus mode
  const focusMode = agentConversationForm ? agentConversationForm.requestParams.focus_mode : currentSource?.focus_mode

  // Whether to show the file required error
  const showFileRequiredError = !hasSelectedFiles && (focusMode === ModeChoice.DOCUMENTQUERY || focusMode === AgentFocusMode.ANALYSIS)

  // Inferred version of isExpanded
  const globalIsExpanded = useAppSelector((state: RootState) => selectConversationSelectedFilesContentExpanded(state, { chatId }))
  const isExpanded = globalIsExpanded || someErrors

  const toggleSelectedFilesBox = () => {
    dispatch(toggleSelectedFilesContentExpanded({ conversationId: chatId }))
  }

  if (!hasSelectedFiles && !showFileRequiredError) return null

  return (
    <div>
      {hasSelectedFiles && !someErrors && (
        // Control for collapsing the selected files box and user feedback
        <button
          className={`flex text-sm text-brand-neutral-500 gap-x-1 ${isLoading ? 'cursor-default opacity-50' : 'cursor-pointer'}`}
          onClick={toggleSelectedFilesBox}
          disabled={isLoading}
        >
          {someProcessingOrPending ? (
            <div className={'flex gap-x-2 items-center'}>
              <CircularProgressContinuousSized size={11} thickness={7} /> <div>Processing files...</div>
            </div>
          ) : (
            <div className={'flex gap-x-[5px] items-end'}>
              {/* Warning icon if files are over limit */}
              {isOverLimit && <AlertErrorCircle className="text-yellow-600 self-center size-[15px] mt-[2px]" />}

              {/* Selected files count display */}
              <div>
                {selectedFilesCount}
                {selectedFilesCount > 1 ? ' Files' : ' File'}
              </div>
            </div>
          )}
          {isExpanded ? (
            <ChevronDown className={`self-center h-4 w-4 ${isLoading ? 'text-brand-neutral-500' : 'hover:text-brand-500'}`} />
          ) : (
            <ChevronUp className={`self-center h-4 w-4 ${isLoading ? 'text-brand-neutral-500' : 'hover:text-brand-500'}`} />
          )}
        </button>
      )}
      {(someErrors || showFileRequiredError) && (
        <span className={`flex xs:justify-end m-1 text-red-700 text-xs`}>
          {someErrors && 'Error processing file(s). Please try again.'}
          {showFileRequiredError && 'Add a file to continue in analysis mode'}
        </span>
      )}
    </div>
  )
}

export default SelectedFilesControl
