import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { selectAgentEventFollowUpQuestions } from '@/agent/events/store/selectors'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useState } from 'react'
import { selectAgentConversationFormSubmitBlockedReason } from '@/agent/chat_form/store/selectors'
import { selectConversationSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'

type FollowUpQuestionsEventBubbleProps = {
  conversationId: string
  eventId: string
}

/**
 * Follow Up Questions Event Bubble
 *
 * A component to display events of type FOLLOW_UP_QUESTIONS, containing follow up questions
 * generated using the most recent agent response from the conversation.
 */
export default function FollowUpQuestionsEventBubble(props: FollowUpQuestionsEventBubbleProps) {
  const { conversationId, eventId } = props
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  // Redux State Selectors
  const selectedFilePaths = useAppSelector((state: RootState) => selectConversationSelectedFiles(state, { conversationId }))
  const submitBlockedReason = useAppSelector((state: RootState) =>
    selectAgentConversationFormSubmitBlockedReason(state, {
      conversationId,
      keys: selectedFilePaths,
      // Flag to bypass the empty input check, since the follow up question selection
      // will replace the input text in the chat form with the selected question
      allowEmptyInput: true,
    })
  )
  const followUpQuestionsState = useAppSelector((state: RootState) => selectAgentEventFollowUpQuestions(state, { conversationId, eventId }))

  const submitBlocked = !!submitBlockedReason

  // Local state
  const [clickedSuggestion, setClickedSuggestion] = useState<boolean>(false)

  if (!followUpQuestionsState) {
    return null
  }

  const { loading, isError, questions } = followUpQuestionsState

  // Trim to a max of 3 suggestions (if there are more)
  const trimmedQuestions = questions.length > 3 ? questions.slice(0, 3) : questions

  return (
    <div className="pr-2 py-0 mt-0 pb-5 whitespace-pre-wrap">
      <div className="border-l border-brand-neutral-300 ml-5 pl-5 py-1">
        <div className="font-bold mb-1 text-xs text-brand-neutral-800">Follow up questions</div>

        {loading && (
          <div className="flex items-center gap-x-2">
            <CircularProgressContinuousSized size={14} thickness={7} hexColor="#2A49FF" />
            <p className="inline text-xs">Generating follow up questions...</p>
          </div>
        )}

        {isError && <div className="text-red-700 text-xs pb-1">Error generating follow up questions.</div>}

        {/* User has tried clicking a suggested question but it is blocked */}
        {clickedSuggestion && submitBlocked && (
          <div className="text-red-700 text-xs pb-1">Please correct any form errors before selecting a follow up question.</div>
        )}

        <ol className="list-disc pl-4">
          {trimmedQuestions.map((suggestion, index) => (
            <li key={`${eventId}_${index}`} className={`mb-1 last-of-type:mb-0 leading-none`}>
              <button
                onClick={() => {
                  setClickedSuggestion(true)

                  // We allow click even if submit is blocked to show error message via
                  // setClickedSuggestion(), but the form will not be submitted
                  if (!submitBlocked) {
                    // Submit the form with the selected question
                    dispatch(AgentConversationFormActions.setInputText({ conversationId, text: suggestion }))
                    dispatch(AgentConversationFormActions.submitForm({ conversationId }))

                    // Reset the clicked suggestion flag
                    setClickedSuggestion(false)

                    // Track the new message
                    trackEvent(AnalyticsEvent.NewChatMessage, { feature: ChatV2Feature.agent })
                  }
                }}
                className={`text-xs text-left underline ${
                  submitBlocked
                    ? 'text-brand-neutral-500 hover:text-brand-neutral-500 no-underline cursor-default'
                    : 'cursor-pointer text-brand-700 hover:text-brand-500'
                }`}
              >
                {suggestion}
              </button>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}
