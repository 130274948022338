import { RootState } from '@/store/store'
import { createSelector, createSelectorCreator, lruMemoize } from '@reduxjs/toolkit'
import { ChatV2Conversation, ChatV2Feature, ChatV2MessageType } from './chat-v2.slice'
import { ModeChoice } from '../schemas/chat-query-metadata-schema'
import { selectFileProcessingStatusesWithKeys } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { FileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'
import { kNewConversationTitle } from '../conversation_list/constants'

/**
 * Select Conversation
 * Can be used as an input selector that retrieves a conversation with a given 'chatId' from the 'conversations' object.
 * Returns null if the conversation does not exist.
 * Selectors can use a function like this as the first input for convenience.
 */
export const selectConversationFromId = (state: RootState, props: { chatId: string | undefined }): ChatV2Conversation | null => {
  return state.chatV2State.conversations[props.chatId ?? ''] ?? null
}

/**
 * Selector: Conversation Exists
 * A child selector that checks if a conversation with a given 'chatId' exists based on
 * the selectConversationFromId parent selector.
 *
 * Reduces re-renders: The Input 1 selector is the output of selectConversationFromId rather than the root state.
 * This means that the selector will only recompute when the conversation with the given 'chatId' changes,
 * and not the rest of the state.
 */
export const selectConversationExists = createSelector(
  // Input 1: The output of the 'selectorInputConversationFromId' selector.
  selectConversationFromId,

  // Input 2: No additional props needed for this computation
  // Do not need to define here

  // Output selector/result function:
  // Returns 'true' if a conversation exists, 'false' otherwise.
  // The double negation (!!) ensures a boolean result.
  (conversation) => !!conversation
)

/**
 * Selector: Visible Reference Exists
 * A child selector that checks if a visible reference exists in a conversation.
 */
export const selectLegacyConversationVisibleReferenceExists = createSelector(selectConversationFromId, (conversation) => !!conversation?.visibleReference)

/**
 * Selector: Visible Reference
 * A selector that retrieves the visible reference from a conversation.
 */
export const selectVisibleReference = (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.visibleReference ?? null

/**
 * Selector: Visible Reference Needs PDF Renderer
 * Determines if the visible reference requires the PDF renderer.
 */
export const selectVisibleReferenceUsesPDFRenderer = createSelector(
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.visibleReference ?? null,
  (visibleReference) => visibleReference?.metadata?.process_version === 'V2'
)

/**
 * Selector Visible Reference Render Trigger
 * A selector that retrieves the render trigger for the visible reference from a conversation.
 */
export const selectVisibleReferenceRenderTrigger = (state: RootState, props: { chatId: string }) =>
  state.chatV2State.conversations[props.chatId]?.visibleReferenceRenderTrigger ?? ''

/**
 * Selector: Conversation Is Loading
 */
export const selectConversationIsLoading = (state: RootState, props: { chatId: string | undefined }) =>
  state.chatV2State.conversations[props.chatId ?? '']?.isLoading ?? false

/**
 * Selector: Conversation Feature
 */
export const selectConversationFeature = (state: RootState, props: { chatId: string | undefined }) =>
  state.chatV2State.conversations[props.chatId ?? '']?.feature ?? null

/**
 * Select: isDiscontinuedFeature
 */
export const selectIsDiscontinuedFeature = createSelector(
  // Input selector: Extract 'feature' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.feature ?? null,
  // Result function: Check if the feature is discontinued
  (feature) => {
    return feature === ChatV2Feature.lrr
  }
)

/**
 * Selector: Conversation Messages
 */
export const selectConversationMessages = (state: RootState, props: { chatId: string | undefined }) =>
  state.chatV2State.conversations[props.chatId ?? '']?.messages ?? {}

/**
 * Selector: Conversation current source
 */
export const selectConversationCurrentSource = (state: RootState, props: { chatId: string | undefined }) =>
  state.chatV2State.conversations[props.chatId ?? '']?.currentSource ?? null

/**
 * Selector: Conversation Title
 */
export const selectConversationTitle = (state: RootState, props: { chatId: string | undefined }) =>
  state.chatV2State.conversations[props.chatId ?? '']?.title ?? null

/**
 * Selector: Current Source
 */
export const selectCurrentSource = (state: RootState, props: { chatId: string | undefined }) =>
  state.chatV2State.conversations[props.chatId ?? '']?.currentSource

/**
 * Selector: Conversation currently selected files
 *
 * Agent Conversations: from the form state
 * Legacy Conversations: from the current source
 *
 * Normalizes all selected files to an array of strings.
 */
export const selectConversationSelectedFiles = createSelector(
  (state: RootState, props: { conversationId: string }) => state.chatV2State.conversations[props.conversationId ?? ''],
  (state: RootState, props: { conversationId: string }) => state.agentConversationFormState.forms[props.conversationId ?? ''],
  (legacyConversation, agentConversationForm): string[] => {
    if (!legacyConversation && !agentConversationForm) return []

    // Handle agent conversations
    if (agentConversationForm) {
      return agentConversationForm.requestParams.file_paths ?? []
    }

    // Get Feature
    const legacyFeature = legacyConversation.feature

    // Handle legacy conversation selections based on feature
    switch (legacyFeature) {
      case ChatV2Feature.contractanalysis: {
        const path = legacyConversation.currentSource?.file_path
        return path ? [path] : []
      }
      case ChatV2Feature.documentSummarization: {
        const path = legacyConversation.currentSource?.selected_file
        return path ? [path] : []
      }
      case ChatV2Feature.lrr_v2:
        return legacyConversation.currentSource?.lrr_selections ?? []
      case ChatV2Feature.documentquery:
      case ChatV2Feature.assistant:
        return legacyConversation.currentSource?.selected_files ?? []
      default:
        return []
    }
  }
)

/**
 * Message Keys Equality Check
 * Custom equality check to compare the previous and next message keys
 * and ignore other changes in the objects
 *
 * Use this equality function with its createMessageIdsSelector in order to only
 * recompute the selector when the message keys change.
 * @param prevMessages
 * @param nextMessages
 * @returns
 */
const equalityCheckMessageKeys = (prevMessages: Record<string, any>, nextMessages: Record<string, any>) => {
  const prevKeys = prevMessages ? Object.keys(prevMessages) : []
  const nextKeys = nextMessages ? Object.keys(nextMessages) : []
  if (prevKeys.length !== nextKeys.length) return false

  // Return true if every key at each index is the same
  return prevKeys.every((key, index) => key === nextKeys[index])
}

// Create a custom selector with the custom equality function, for creating a selector that only recomputes when the message keys change
const createMessageIdsEqualitySelector = createSelectorCreator(lruMemoize, equalityCheckMessageKeys)

/**
 * Selector for conversation message ids
 * - Reduces re-renders since it's inputs won't change as frequently as if we were watching the root state object
 *
 * - Custom input equality check to compare the previous and next message keys
 * - Inputs will not be considered different if the keys are the same, even if the message objects are different
 * - Default behavior would recompute the selector (and re-render) every time the 'messages' objects change
 */
export const selectConversationMessageIds = createMessageIdsEqualitySelector(
  // INPUT 1
  selectConversationMessages,
  (messages) => {
    return Object.keys(messages)
  },
  {
    memoizeOptions: {
      // Custom equality check evaluates INPUT 1 to determine whether it has changed
      equalityCheck: equalityCheckMessageKeys,
    },
  }
)

/**
 * Selector to get a message by id
 */
export const selectMessageFromId = (state: RootState, props: { chatId: string; messageId: string }) => {
  const conversation = state.chatV2State.conversations[props.chatId]
  return conversation?.messages[props.messageId] ?? null
}

/**
 * Child selector: message type
 */
export const selectMessageType = (state: RootState, props: { chatId: string; messageId: string }) => {
  const conversation = state.chatV2State.conversations[props.chatId]
  return conversation?.messages[props.messageId]?.metadata?.message_type ?? null
}

/**
 * Selector to see if the messageId is the most recent message
 */
export const selectIsMostRecentMessage = createSelector(
  selectConversationMessages,
  (_: RootState, props: { messageId: string }) => props,
  (messages, props) => {
    // Get most recent message
    const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

    // The provided messageId matches the most recent message
    return mostRecentMessage?.metadata?.message_id === props.messageId
  }
)

/**
 * Selector for whether submission in this conversation is blocked
 * Returns true if the conversation is loading, the form has an error, the current source is null, the selected files are empty or any of the selected files are processing.
 *
 * Does not check for an empty text input.
 */
export const selectConversationSubmitBlocked = createSelector(
  // Input 1: Get the conversation based on chatId
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? ''] ?? null,

  // Input 2: Provide the processing statuses of the selected files
  (state: RootState, props: { keys: string[] }) => selectFileProcessingStatusesWithKeys(state, props),

  (conversation, processingStatuses) => {
    if (!conversation) return true

    // Infer values
    const feature = conversation.feature as ChatV2Feature
    const isLoading = conversation.isLoading
    const messages = conversation.messages ?? {}
    const currentSource = conversation.currentSource ?? null
    const formValidationError = conversation.formValidationError ?? false

    // If the feature is not the assistant, reset processingStatuses to an empty record
    // Currently just for the assistant
    if (feature !== ChatV2Feature.assistant) {
      processingStatuses = {}
    }

    // Global settings for all features
    if (isLoading || formValidationError || !currentSource) return true

    // Feature-specific conditions
    switch (feature) {
      // Features that require selected files
      case ChatV2Feature.documentquery:
        // If there are no selected files
        if (currentSource.selected_files.length === 0) return true
        break

      case ChatV2Feature.contractanalysis:
        // If the file path is empty or there are already messages (only 1 message allowed)
        if (currentSource.file_path === '' || Object.keys(messages).length > 0) return true
        break

      case ChatV2Feature.documentSummarization:
        // If selected file is empty
        if (currentSource.selected_file === '' || currentSource.selected_file == undefined) return true
        break

      case ChatV2Feature.lrr_v2: {
        // If file path is empty
        const lrrSelections = currentSource.lrr_selections ?? []
        if (lrrSelections.length === 0) return true
        break
      }

      case ChatV2Feature.research:
        // If neither federal or state sources are selected
        if (currentSource.federal_court_ids == null && currentSource.state_court_ids == null) return true
        break

      case ChatV2Feature.agent:
      case ChatV2Feature.assistant: {
        // If any files in the conversation are pending, processing or has errors
        const hasUnprocessedFiles = Object.values(processingStatuses).some((status) =>
          [FileProcessingStatus.PENDING, FileProcessingStatus.PROCESSING, FileProcessingStatus.ERROR].includes(status as FileProcessingStatus)
        )
        if (hasUnprocessedFiles) return true

        // If no files are selected in document query mode
        if (currentSource?.focus_mode === ModeChoice.DOCUMENTQUERY && currentSource?.selected_files.length === 0) return true
        break
      }
    }

    return false
  }
)

/**
 * Selector: The current text input in the conversation
 */
export const selectConversationCurrentTextInput = (state: RootState, props: { chatId: string }) =>
  state.chatV2State.conversations[props.chatId]?.currentTextInput ?? ''

/**
 * Selector for whether to show the loading bubble
 * If
 * - The conversation is loading
 * - The most recent message is a user_query
 */
export const selectShowMessageLoadingBubble = createSelector(selectConversationFromId, (conversation) => {
  if (!conversation) return false
  const messages = conversation.messages
  if (!messages) return false

  // Get most recent message
  const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

  // Is the most recent message a user_query?
  const mostRecentMessageIsUserQuery = mostRecentMessage?.metadata?.message_type === ChatV2MessageType.user_query

  return conversation.isLoading && mostRecentMessageIsUserQuery
})

/**
 * COMBINED Selector to get the processing statuses for the selected files.
 *
 * This selector takes the selected files and their corresponding processing statuses,
 * and maps the processing statuses to the selected files. If a selected file does not
 * have a processing status, it defaults to `FileProcessingStatus.SUCCESS`.
 *
 * @param state - The root state of the application.
 * @param props - The properties containing the chat ID or keys.
 * @returns A record where the keys are the selected file keys and the values are their processing statuses.
 */
export const selectProcessingStatusesForSelectedFiles = createSelector(
  // Input selectors
  (state: RootState, props: { chatId: string | undefined }) => selectConversationSelectedFiles(state, { conversationId: props.chatId ?? '' }),
  (state: RootState, props: { keys: string[] }) => selectFileProcessingStatusesWithKeys(state, props),
  // Result function
  (selectedFiles, processingStatuses) => {
    // Map processing statuses to selected files
    return selectedFiles.reduce((acc: Record<string, string>, key: string) => {
      if (processingStatuses[key]) {
        acc[key] = processingStatuses[key]
      } else {
        acc[key] = FileProcessingStatus.SUCCESS
      }
      return acc
    }, {})
  }
)

/**
 * Select: Feature's Most Recent New Chat
 * A selector that retrieves the most recent "New Chat" conversation for a given feature.
 *
 * Agent and Assistant are both treated as Agent, to find the most recent Agent new chat.
 */
export const selectFeatureMostRecentNewChat = createSelector(
  (state: RootState) => state.chatV2State.conversations,
  (state: RootState) => state.agentConversationsState.conversations,
  (_: RootState, props: { chatFeature: ChatV2Feature }) => props.chatFeature,
  (legacyConversations, agentConversations, chatFeature) => {
    // Handle agent conversations (assistant and agent are both now agent for new chats)
    if (chatFeature === ChatV2Feature.assistant || chatFeature === ChatV2Feature.agent) {
      if (!agentConversations) return null

      const agentConversationsArray = Object.values(agentConversations)
      return agentConversationsArray.find((conversation) => conversation?.title === kNewConversationTitle) ?? null
    } else {
      if (!legacyConversations) return null

      // Convert the conversations object to an array
      const conversationsArray = Object.values(legacyConversations)

      // Sort the conversations by modified_on, most recent first
      conversationsArray.sort((a, b) => b.modified_on - a.modified_on)

      // Isolate feature conversations
      const featureConversations = conversationsArray.filter((conversation) => conversation.feature === chatFeature)

      // Find the most recent "New Chat" title from kNewConversationTitle
      return featureConversations.find((conversation) => conversation.title === kNewConversationTitle) ?? null
    }
  }
)

/**
 * Selector to get references by id
 */
export const selectReferencesFromId = (state: RootState, props: { chatId: string; messageId: string }) => {
  const conversation = state.chatV2State.conversations[props.chatId]
  return conversation?.references[props.messageId] ?? {}
}
