import FileActionMenu from '@/chat-common/components/form-source-controls/assistant/file-action-menu'
import { selectConversationSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { ArrowUpIcon } from '@radix-ui/react-icons'
import { XClear } from '@/assets/icons'

import { useEffect, useRef } from 'react'
import { selectAgentConversationFormSubmitBlockedReason } from '../store/selectors'
import { AgentConversationFormActions } from '../store/slice'
import { AgentFocusMode } from '../store/schemas'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'

type AgentChatTextAreaProps = {
  conversationId: string
}

/**
 * Agent Chat Text Area
 * The text area for the agent chat window.
 * Isolated to avoid re-renders from the chat window.
 * @param props
 * @returns
 */
export default function AgentChatTextArea(props: AgentChatTextAreaProps) {
  const { conversationId } = props
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  // Detect mobile/tablet devices using the user agent string
  const userAgent = navigator.userAgent
  const isMobileOrTablet = /Mobi|Android|iPad|Tablet|PlayBook/i.test(userAgent)

  // Conversation values
  const formData = useAppSelector((state: RootState) => state.agentConversationFormState.forms[conversationId])
  const input_text = formData?.requestParams.input_text ?? ''
  const selectedFilePaths = useAppSelector((state: RootState) => selectConversationSelectedFiles(state, { conversationId }))
  const submitBlockedReason = useAppSelector((state: RootState) =>
    selectAgentConversationFormSubmitBlockedReason(state, {
      conversationId,
      keys: selectedFilePaths,
    })
  )

  const submitBlocked = !!submitBlockedReason
  const isSubmitting = !!formData?.queryIsSubmitting

  // Use a ref flag to track if a submission was initiated and we are waiting for confirmation
  const isAwaitingSubmissionConfirmation = useRef(false)

  // Websocket connection for this conversation
  const ws2Connection = useAppSelector((state: RootState) => state.ws2State.connections[conversationId])
  const isConnected = ws2Connection?.connected ?? false

  // Handle Send Message on the Chat Window Form
  const handleSendMessage = () => {
    if (submitBlocked) return
    if (!formData) return

    isAwaitingSubmissionConfirmation.current = true
    dispatch(AgentConversationFormActions.submitForm({ conversationId }))
    trackEvent(AnalyticsEvent.NewChatMessage, { feature: ChatV2Feature.agent })
  }

  // Create a new form if it doesn't exist
  useEffect(() => {
    if (!formData) {
      dispatch(AgentConversationFormActions.createForm({ conversationId, focus_mode: AgentFocusMode.INTELLIGENT }))
    }
  }, [formData, dispatch, conversationId])

  // Update text area height when input_text changes
  useEffect(() => {
    if (textAreaRef.current) {
      const target = textAreaRef.current
      target.style.height = 'auto'
      target.style.height = `${target.scrollHeight}px`
    }
  }, [input_text])

  /**
   * Re-focus on text area after submission
   *
   * Skips the behaviour for mobile/tablet devices as some mobile/tablet devices
   * trigger the keyboard to appear when the textarea is focused.
   */
  useEffect(() => {
    // Submission has finished and we were waiting for confirmation
    if (!isSubmitting && isAwaitingSubmissionConfirmation.current) {
      // Not a mobile/tablet device and text area exists
      if (!isMobileOrTablet && textAreaRef.current) {
        textAreaRef.current.focus()
      }

      // Reset the submission flag
      isAwaitingSubmissionConfirmation.current = false
    }
  }, [isSubmitting, isMobileOrTablet])

  return (
    <div>
      <div
        className={`relative w-full border border-brand-neutral-400 rounded-lg ${isSubmitting ? 'bg-brand-neutral-100 text-gray-400' : 'bg-brand-neutral-50'}`}
      >
        <form>
          <div className={'grid grid-cols-[auto_35px_48px] gap-x-2 items-end'}>
            <div className={'pl-3 py-1 self-center'}>
              <textarea
                ref={textAreaRef}
                disabled={isSubmitting}
                className={`p-0 m-0 pt-1 w-full resize-none self-center border-none focus:ring-0 bg-transparent border-0`}
                value={input_text}
                onChange={(e) => {
                  dispatch(AgentConversationFormActions.setInputText({ conversationId, text: e.target.value }))
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault() // Prevent default to avoid line break in textarea
                    handleSendMessage()
                  }
                }}
                placeholder={'Enter a prompt here'}
                rows={1}
                style={{ lineHeight: '1.5', maxHeight: '12rem' }}
              />
            </div>

            <div className={'mb-[10px]'}>
              <FileActionMenu disabled={isConnected} conversationId={conversationId} />
            </div>

            {/* Form initializing */}
            {!formData && (
              <div className={'w-10 h-10 grid place-items-center rounded-md bg-brand-500 bg-opacity-50'}>
                <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
              </div>
            )}

            {/* Not connected: send button */}
            {!isConnected && (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  handleSendMessage()
                }}
                disabled={submitBlocked}
                className={`w-10 h-10 mb-2 mt-2 bg-brand-500 text-white rounded-md grid place-items-center ${submitBlocked ? 'opacity-50' : 'opacity-100'}`}
              >
                <ArrowUpIcon width="20" height="20" />
              </button>
            )}

            {/* Connected: stop button */}
            {isConnected && (
              <button
                className={'w-10 h-10 mb-2 mt-2 bg-red-700 text-white rounded-md grid place-items-center ring-0 border-none outline-none focus:outline-none'}
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(AgentConversationFormActions.sendStop({ conversationId }))
                }}
              >
                <XClear width="20" height="20" />
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Below form */}
      <div className={'px-2'}>
        <p className={'text-xs text-brand-neutral-600 text-left mt-1 hidden sm:block'}>new line: shift + enter</p>
      </div>
    </div>
  )
}
