import { getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import * as Sentry from '@sentry/browser'
import { getAuth } from 'firebase/auth'
import { kGcsUserFileDriveUploadsBucketRef } from '@/constants/constants-gcs'
import { NodeType, DriveType } from '../schemas/files-drive-schema'

// Types
type UploadableDriveFile = {
  id: string
  file: File | Blob
  name: string
  user_id: string
  folder_path: string
  type: NodeType
}

type UploadHandlerCallbacks = {
  onProgress: (progress: number) => void
  onError: (error: Error) => void
  onComplete: () => void
}

type UploadDriveFileHandlerArgs = UploadHandlerCallbacks & {
  uploadableFile: UploadableDriveFile
  currentFolder: string | null
  driveType: DriveType
  orgId: string | null
}

/**
 * Handles file uploads to Firebase Storage for both personal and organization drives.
 * 
 * @param {UploadDriveFileHandlerArgs} args - Upload configuration and callbacks
 * @throws {Error} If user is not authenticated or if blob file is missing a name
 * 
 * @example
 * ```typescript
 * uploadDriveFileHandler({
 *   uploadableFile: { id: '123', file: fileBlob, name: 'document.pdf', ... },
 *   currentFolder: 'reports',
 *   driveType: DriveType.PERSONAL,
 *   orgId: null,
 *   onProgress: (progress) => console.log(`Upload progress: ${progress}%`),
 *   onError: (error) => console.error(error),
 *   onComplete: () => console.log('Upload complete'),
 * })
 * ```
 */
export default function uploadDriveFileHandler({
  uploadableFile,
  currentFolder,
  onProgress,
  onError,
  onComplete,
  driveType,
  orgId
}: UploadDriveFileHandlerArgs) {
  // Validate user authentication
  const userId = getAuth().currentUser?.uid
  if (!userId) {
    throw new Error('User must be authenticated to upload files')
  }

  // Validate blob files have names
  if (uploadableFile.file instanceof Blob && !uploadableFile.name) {
    throw new Error('Blob files must have a name')
  }

  // Setup storage reference
  const storage = getStorage(undefined, kGcsUserFileDriveUploadsBucketRef)
  const directoryPath = getDirectoryPath(driveType, orgId, userId, currentFolder)
  const fileRef = ref(storage, `${directoryPath}/${uploadableFile.id}`)

  // Start upload with metadata
  const uploadTask = uploadBytesResumable(fileRef, uploadableFile.file, {
    customMetadata: {
      file_name: uploadableFile.name,
      // TODO: Changes on the backend will allow us to remove user_id from metadata in the future - see ticket https://linear.app/paxtonai/issue/ENG-5535/user-id-in-the-path-not-the-metadata
      user_id: driveType === DriveType.ORG ? orgId ?? '' : userId,
      folder_path: uploadableFile.folder_path ?? '',
    }
  })

  // Attach upload observers
  uploadTask.on(
    'state_changed',
    (snapshot) => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
    (error) => {
      Sentry.captureException(error, {
        extra: {
          onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled,
          driveType,
          userId,
          orgId,
          uploadableFile,
        },
      })
      onError(error)
    },
    onComplete
  )
}

/**
 * Generates the storage directory path based on drive type and user context
 */
function getDirectoryPath(
  driveType: DriveType,
  orgId: string | null,
  userId: string,
  currentFolder: string | null
): string {
  const baseDir = driveType === DriveType.ORG && orgId
    ? `tenants/${orgId}`
    : `users/${userId}`
  return `${baseDir}/${currentFolder ?? ''}`
}