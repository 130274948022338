import { ChatV2Feature, chatV2SetConversationCurrentTextInput } from '@/chat-common/store/chat-v2.slice'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { getAuth } from 'firebase/auth'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'
import { chatV2HandleSendMessage } from '@/chat-common/util/chat-v2-handle-send-message'
import {
  selectConversationCurrentTextInput,
  selectConversationFeature,
  selectConversationIsLoading,
  selectConversationSelectedFiles,
  selectConversationSubmitBlocked,
} from '@/chat-common/store/chat-v2.selectors'
import { useEffect, useRef } from 'react'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { ArrowUpIcon } from '@radix-ui/react-icons'
import FileActionMenu from './assistant/file-action-menu'

type ChatFormInputProps = {
  conversationId: string
}

/**
 * The Chat Form component.
 *
 * Contains the chat form source controls, and the text area input.
 * Has additional logic for single message features, anonymous users.
 * @returns
 */
export default function ChatFormInput(props: ChatFormInputProps) {
  const { conversationId } = props
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  // Auth
  const auth = getAuth()
  const isAnonymous = auth.currentUser?.isAnonymous ?? false

  // Conversation values
  const selectedFilePaths = useAppSelector((state: RootState) => selectConversationSelectedFiles(state, { conversationId }))
  const currentTextInput = useAppSelector((state: RootState) => selectConversationCurrentTextInput(state, { chatId: conversationId }))
  const isLoading = useAppSelector((state: RootState) => selectConversationIsLoading(state, { chatId: conversationId }))
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))

  const isAssistantFeature = feature === ChatV2Feature.assistant

  // Form submit disabled
  const submitBlocked = useAppSelector((state: RootState) =>
    selectConversationSubmitBlocked(state, {
      chatId: conversationId,
      keys: selectedFilePaths,
    })
  )

  const submitDisabled = submitBlocked || currentTextInput.trim() == ''

  /**
   * Handle Send Message on the Chat Window Form
   */
  const handleSendMessage = () => {
    chatV2HandleSendMessage({
      chatId: conversationId,
      onComplete: () => {
        // Update the height of the text area
        if (textAreaRef.current) {
          textAreaRef.current.style.height = '40px' // starting height of area
        }

        // Track the new message
        trackEvent(AnalyticsEvent.NewChatMessage, {
          feature: feature
        })
      },
    })
  }

  // Update text area height when input_text changes
  useEffect(() => {
    if (textAreaRef.current) {
      const target = textAreaRef.current
      target.style.height = 'auto'
      target.style.height = `${target.scrollHeight}px`
    }
  }, [currentTextInput])

  return (
    <div>
      {/* Anon user - Save Your Work Buttons */}
      {isAnonymous && (
        <div className={'flex justify-center w-full text-xs text-center'}>
          <div className={'flex gap-x-2 items-end bg-brand-800 bg-opacity-80 py-1 px-4 rounded-t-md'}>
            <div className={'text-white'}>Save your work:</div>
            <button
              className={'text-white underline px-1 hover:text-opacity-80'}
              onClick={() => {
                dispatch(
                  openAuthDialog({
                    authDialogType: AuthDialogType.SIGN_UP,
                  })
                )
              }}
            >
              Sign up
            </button>
            <button
              className={'text-white underline hover:text-opacity-80'}
              onClick={() => {
                dispatch(
                  openAuthDialog({
                    authDialogType: AuthDialogType.SIGN_IN,
                  })
                )
              }}
            >
              Sign in
            </button>
          </div>
        </div>
      )}

      {/* Chat Form - Text Input */}
      {/* Legacy Form */}
      {!isAssistantFeature && (
        <div>
          <div className={`z-10 relative w-full border border-brand-neutral-400 rounded-lg bg-brand-neutral-50`}>
            <form>
              <div className={'grid grid-cols-[auto_48px] gap-x-2 items-end'}>
                <div className={'pl-3 py-1 self-center'}>
                  <textarea
                    ref={textAreaRef}
                    className="p-0 m-0 pt-1 w-full resize-none self-center border-none focus:ring-0 bg-transparent border-0"
                    value={currentTextInput}
                    onChange={(e) => {
                      dispatch(chatV2SetConversationCurrentTextInput({ conversationId: conversationId, text: e.target.value }))
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault() // Prevent default to avoid line break in textarea

                        if (!submitDisabled) {
                          handleSendMessage() // Call your form's submit handler
                        }
                      }
                    }}
                    placeholder={feature === ChatV2Feature.contractanalysis ? 'Provide context here' : 'Enter a prompt here'}
                    rows={1}
                    style={{ lineHeight: '1.5', maxHeight: '12rem' }}
                  />
                </div>

                {isLoading ? (
                  <div className={'w-10 h-10 mt-2 mb-2 mr-2 grid place-items-center rounded-md bg-brand-500 bg-opacity-50'}>
                    <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      handleSendMessage()
                    }}
                    disabled={submitDisabled}
                    className={`w-10 h-10 mt-2 mb-2 mr-2 bg-brand-500 text-white rounded-md grid place-items-center ${
                      submitDisabled ? 'opacity-50' : 'opacity-100'
                    }`}
                  >
                    <ArrowUpIcon width="20" height="20" />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Assistant Form */}
      {isAssistantFeature && (
        <div>
          <div className={`z-10 relative w-full border border-brand-neutral-400 rounded-lg bg-brand-neutral-50`}>
            <form>
              <div className={'grid grid-cols-[auto_35px_48px] gap-x-2 items-end'}>
                <div className={'pl-3 py-1 self-center'}>
                  <textarea
                    ref={textAreaRef}
                    className="p-0 m-0 pt-1 w-full resize-none self-center border-none focus:ring-0 bg-transparent border-0"
                    value={currentTextInput}
                    onChange={(e) => {
                      dispatch(chatV2SetConversationCurrentTextInput({ conversationId: conversationId, text: e.target.value }))
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault() // Prevent default to avoid line break in textarea
                        if (!submitDisabled) {
                          handleSendMessage()
                        }
                      }
                    }}
                    placeholder={'Enter a prompt here'}
                    rows={1}
                    style={{ lineHeight: '1.5', maxHeight: '12rem' }}
                  />
                </div>

                <div className="mb-[10px]">
                  <FileActionMenu disabled={isLoading} conversationId={conversationId} />
                </div>

                {isLoading ? (
                  <div className={'w-10 h-10 mt-2 mb-2 mr-2 grid place-items-center rounded-md bg-brand-500 bg-opacity-50'}>
                    <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      handleSendMessage()
                    }}
                    disabled={submitDisabled}
                    className={`w-10 h-10 mt-2 mb-2 mr-2 bg-brand-500 text-white rounded-md grid place-items-center ${
                      submitDisabled ? 'opacity-50' : 'opacity-100'
                    }`}
                  >
                    <ArrowUpIcon width="20" height="20" />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Below form */}
      <div className={'px-2'}>
        <p className={'text-xs text-brand-neutral-600 text-left mt-1 hidden sm:block'}>new line: shift + enter</p>
      </div>
    </div>
  )
}
