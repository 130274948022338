import ReactDOMServer from 'react-dom/server'
import { ChatV2MessageType, ChatV2Conversation, ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { nanoid } from 'nanoid'
import ChatResponsePrintableBubble from '@/chat-common/components/message-bubbles/chat-response-printable-bubble'
import ChatUserQueryPrintableBubble from '@/chat-common/components/message-bubbles/chat-user-query-printable-bubble'
import * as Sentry from '@sentry/react'
import { store } from '@/store/store'
import { ChatV2Message } from '../schemas/chat-v2.schemas'
import { saveHTMLasDocx } from '../fetch/post-download-html-docx'
import refreshFullConversation from '../fetch/get-full-conversation'
import { selectConversationFromId } from '../store/chat-v2.selectors'

function getFilteredMessagesArray(conversation: ChatV2Conversation) {
  const messages = conversation?.messages ?? {}
  const messagesArray: ChatV2Message[] = Object.values(messages) ?? []
  return messagesArray.filter(
    (message) => message.metadata.message_type !== ChatV2MessageType.suggested_questions && message.metadata.message_type !== ChatV2MessageType.client_error
  )
}

function buildMessageComponents(messages: ChatV2Message[], conversation: ChatV2Conversation) {
  const messageComponents = messages.map((message) => {
    switch (message.metadata.message_type) {
      case ChatV2MessageType.response: {
        // Get references for the message
        const references = conversation.references[message.metadata.message_id] ?? {}
        return <ChatResponsePrintableBubble key={`response-print-${message.metadata.message_id}`} message={message} references={references} />
      }
      case ChatV2MessageType.user_query:
        return <ChatUserQueryPrintableBubble key={`query-print-${message.metadata.message_id}`} message={message} conversation={conversation} />
      default:
        return null
    }
  })
  return <>{messageComponents}</>
}

export async function buildDocumentHTML(content: JSX.Element, title: string) {
  const contentHTML = ReactDOMServer.renderToStaticMarkup(content)

  const document = `
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${title}</title>
    </head>
    <body>
      ${contentHTML}
    </body>
  </html>
  `

  return document
}

export async function copyResponseToClipboard(message: ChatV2Message, references: Record<string, ChatV2MessageReferenceType>) {
  const printableMessage = <ChatResponsePrintableBubble message={message} references={references} />
  const messageHTML = await buildDocumentHTML(printableMessage, '')
  const outputHtml = formatHtmlForDocXTransformation(messageHTML)

  const tempElement = document.createElement('div')
  tempElement.innerHTML = outputHtml
  document.body.appendChild(tempElement)

  const range = document.createRange()
  range.selectNode(tempElement)

  const selection = window.getSelection()
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }

  try {
    const clipboardItem = new ClipboardItem({
      'text/plain': new Blob([tempElement.innerText], { type: 'text/plain' }),
      'text/html': new Blob([tempElement.innerHTML], { type: 'text/html' }),
    })

    navigator.clipboard.write([clipboardItem])
    console.log('Content copied to clipboard!')
  } catch (err) {
    console.error('Failed to copy content:', err)
    document.body.removeChild(tempElement)
  }

  if (selection) {
    selection.removeAllRanges()
  }
  document.body.removeChild(tempElement)
}

export async function downloadResponseAsDocxRemote(fileName: string, message: ChatV2Message, references: Record<string, ChatV2MessageReferenceType>) {
  try {
    const printableElement = <ChatResponsePrintableBubble message={message} references={references} />
    const messageHTML = await buildDocumentHTML(printableElement, fileName)
    const outputHtml = formatHtmlForDocXTransformation(messageHTML)
    await saveHTMLasDocx({ html: outputHtml, fileName })
    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: fileName + ' downloaded', durationMs: 2000 }))
  } catch (error) {
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Response download failed', durationMs: 2000 }))
  }
}

export async function downloadConversationAsDocxRemote(conversation: ChatV2Conversation | null) {
  if (!conversation) {
    handleConversationDownloadError('Conversation is null for downloadConversationAsDocxRemote()')
    return
  }

  const conversationId = conversation.id

  // If lastRefresh is null, refresh the conversation as it has not been loaded yet
  if (conversation.lastRefresh === null) {
    await refreshFullConversation({
      conversationId: conversationId,
      onError: () => handleConversationDownloadError(),
      onLoadingStatus: () => null,
      forceRefresh: true,
    })
    // After the refresh, get the updated conversation from the store
    conversation = selectConversationFromId(store.getState(), { chatId: conversationId })
  }

  if (!conversation) {
    handleConversationDownloadError('Conversation is null for downloadConversationAsDocxRemote() after refresh', conversationId)
    return
  }

  store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `Preparing download...`, durationMs: 3000 }))

  try {
    // Get or construct fileName
    const fileName = conversation.title ?? `${nanoid()}-download`

    const filteredMessagesArray = getFilteredMessagesArray(conversation)
    const messageComponents = buildMessageComponents(filteredMessagesArray, conversation)
    const messagesHTML = await buildDocumentHTML(messageComponents, fileName)
    const outputHtml = formatHtmlForDocXTransformation(messagesHTML)
    await saveHTMLasDocx({ html: outputHtml, fileName })
    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: fileName + ' downloaded', durationMs: 2000 }))
  } catch (error) {
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Conversation download failed', durationMs: 2000 }))
  }
}

/**
 * Handles an error that occurs during a conversation download.
 *
 * @param errorMessage - The error message to capture to sentry. (optional)
 * @param conversationId - The ID of the conversation that is being downloaded, if available.
 */
export function handleConversationDownloadError(errorMessage?: string, conversationId?: string) {
  if (errorMessage) {
    Sentry.captureException(new Error(errorMessage), {
      extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled, conversationId: conversationId ?? 'unknown' },
    })
  }
  store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: `Error preparing the download.`, durationMs: 3000 }))
}

function indentNestedElements(element: HTMLElement | Element, depth: number, lastTag = '') {
  let currentDepth = depth
  let incrementDepth = false
  Array.from(element.children).forEach((child, index) => {
    const childTagName = child.tagName.toLowerCase()

    // If the first child is not a section, but the last child was, increment the depth
    if (index === 0 && childTagName !== 'section' && lastTag === 'section') {
      // Only increment the depth once per section, prevents siblings from accidentally incrementing the depth
      if (!incrementDepth) {
        currentDepth = currentDepth + 1
        incrementDepth = true
      }

      // Indent the child element with four non-breaking spaces times the depth
      const indentation = '&nbsp;'.repeat(4 * depth)
      child.innerHTML = indentation + child.innerHTML
    }

    // Recursively indent the child's children, increasing the depth
    indentNestedElements(child, currentDepth, childTagName)
  })
}

export function indentHtmlString(htmlString: string) {
  try {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const body = doc.body

    // Start the indentation process with the root element and an initial depth of 0
    indentNestedElements(body, 0)

    // Serialize the document body back to an HTML string

    return body.innerHTML
  } catch (error) {
    console.error('Error indenting html string:', error)
  }

  return htmlString
}

export function formatHtmlForDocXTransformation(sourceHTML: string) {
  // Regex for cleaning the HTML
  const highlightRegex = /<span class="highlighted-snippet bg-yellow-300">(.*?)<\/span>/g
  const h1ToBRegex = /<h\d>(.*?)<\/h\d>/g

  let outputHtml = sourceHTML
  try {
    // Clean the HTML to fit display format
    outputHtml = outputHtml.replace(highlightRegex, '<mark>$1</mark>')
    outputHtml = outputHtml.replace(h1ToBRegex, '<b>$1</b>')

    // // Only intend for specific features
    // if ([ChatV2Feature.lrr.toString(), ChatV2Feature.lrr_v2.toString()].includes(feature)) {
    //   outputHtml = indentHtmlString(outputHtml)
    // }
  } catch (error) {
    console.error('Error processing html string:', error)
  }
  return outputHtml
}

export async function downloadReferenceAsDocX(sourceHTML: string, fileName: string, onLoading: (loading: boolean) => void) {
  onLoading(true)
  try {
    const outputHtml = formatHtmlForDocXTransformation(sourceHTML)
    await saveHTMLasDocx({ html: outputHtml, fileName })
    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `${fileName} downloaded`, durationMs: 2000 }))
  } catch (error) {
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Reference download failed', durationMs: 2000 }))
    throw error
  } finally {
    onLoading(false)
  }
}
