import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { kResponseCanceledMessage } from '@/constants/constants-strings'

/**
 * Response Canceled Event Bubble
 *
 * A component to display an event of type STOP_REQUEST_RECEIVED, indicating that the response was
 * canceled by the user before completion.
 */
export default function ResponseCanceledEventBubble() {
  /**
   * TODO: The colours used below are colours in the new updated neutral ramp, to be added in ENG-5284.
   * Replace the arbitrary hex codes with the new variables once they have been added.
   */
  return (
    <div className="flex items-center p-3 rounded-md text-[#5F5C55] bg-[#F6F3ED] border border-[#DFD7CB]">
      <span className="flex items-center justify-center rounded-lg mr-2">
        <HighlightOffOutlinedIcon fontSize="small" />
      </span>
      <div className="text-sm">{kResponseCanceledMessage}</div>
    </div>
  )
}
