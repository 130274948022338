import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgentResponseMetadataRead, getDefaultAgentResponseMetadata } from './schemas'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'

export type FeedbackDialogState = {
  visible: boolean
  eventId: string | null
  positive: boolean | null
}

const initialFeedbackDialogState: FeedbackDialogState = {
  visible: false,
  eventId: null,
  positive: null,
}

export type AgentResponseMetadataState = {
  metadata: Partial<{ [eventId: string]: AgentResponseMetadataRead }>
  feedbackDialog: FeedbackDialogState
}

const initialState: AgentResponseMetadataState = {
  metadata: {},
  feedbackDialog: initialFeedbackDialogState,
}

/**
 * AgentResponseMetadata Slice
 * Holds response metadata for agent events of type AGENT_RESPONSE.
 */
export const agentResponseMetadataSlice = createSlice({
  name: 'agentResponseMetadataState',
  initialState,
  reducers: {
    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Upsert a single response metadata
    upsertResponseMetadata: (state, action: PayloadAction<AgentResponseMetadataRead>) => {
      state.metadata[action.payload.event_id] = action.payload
    },

    // Upsert a list of response metadata
    upsertResponseMetadataList: (state, action: PayloadAction<AgentResponseMetadataRead[]>) => {
      action.payload.forEach((meta) => {
        state.metadata[meta.event_id] = meta
      })
    },

    // Set the thumb rating for a given agent response event
    setThumbRating: (state, action: PayloadAction<{ eventId: string; thumb: FeedbackThumb }>) => {
      const { eventId, thumb } = action.payload

      let metadata = state.metadata[eventId]
      if (!metadata) {
        // Use schema-generated default if metadata doesn't exist yet
        metadata = getDefaultAgentResponseMetadata(eventId)
        state.metadata[eventId] = metadata
      }

      metadata.thumb_rating = thumb
    },

    // Open the feedback dialog for a given event
    openFeedbackDialog: (state, action: PayloadAction<{ eventId: string; positive: boolean }>) => {
      state.feedbackDialog = {
        visible: true,
        eventId: action.payload.eventId,
        positive: action.payload.positive,
      }
    },

    // Close the feedback dialog
    closeFeedbackDialog: (state) => {
      state.feedbackDialog = initialFeedbackDialogState
    },
  },
})

// Actions
export const AgentResponseMetadataActions = agentResponseMetadataSlice.actions

export default agentResponseMetadataSlice.reducer
