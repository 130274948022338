import { useState, useContext } from 'react'
import { useElements, PaymentElement, useStripe, AddressElement } from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import { kLinkTermsOfServiceUrl, kPaxtonAppBaseDomain } from '@/constants/constants-links'
import PromoCode from '@/routes/dashboard/subscribe/PromoCode'
import * as Sentry from '@sentry/browser'
import { setLegacyStripeCheckoutVisible, setLegacyStripeCheckoutMessage } from '@/store/slices/ui-state.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useNavigate } from 'react-router'
import { RootState } from '@/store/store'
import { AuthContext } from '@/context/auth-context'
import { SubscriptionType } from '@/firebase/auth/auth-jwt-schema'
import { UserPaymentError, SystemPaymentError } from '@/payments/types/payment-errors'
import { CheckoutScrollContext } from '@/routes/dashboard/subscribe/TestimonialPlusSignup'
import { ResignupScrollContext } from '@/routes/dashboard/subscribe/TestimonialPlusResignup'

type CheckoutFormProps = {
  checkoutButtonCopy?: string
  supportTrial?: boolean
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { checkoutButtonCopy, supportTrial } = props
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptionState)
  const billingCycle = subscriptionState.billingCycle
  const promotion = subscriptionState.promotion
  const { userAccountData } = useContext(AuthContext)

  // Try to get the scroll context from either component
  const checkoutContext = useContext(CheckoutScrollContext)
  const resignupContext = useContext(ResignupScrollContext)

  const [isLoading, setIsLoading] = useState(false)

  const subscriptionType = userAccountData?.subscriptionType ?? SubscriptionType.STANDARD

  // Function to scroll to the top of the checkout container
  const scrollCheckoutToTop = () => {
    // Use whichever context is available
    if (checkoutContext.scrollToTop) {
      checkoutContext.scrollToTop()
    } else if (resignupContext.scrollToTop) {
      resignupContext.scrollToTop()
    }
  }

  function handlePaymentError(error: StripeError | UserPaymentError | SystemPaymentError | unknown) {
    const isStripeError = (err: any): err is StripeError => err && typeof err === 'object' && 'type' in err && typeof err.type === 'string'

    if (isStripeError(error)) {
      // the stripe checkout component automatically adds a card_error to the error object
      if (error.type === 'card_error') {
        return
      }
      // the stripe checkout component automatically adds a validation_error to the error object
      if (error.type === 'validation_error') {
        return
      }

      if (error.type === 'invalid_request_error') {
        scrollCheckoutToTop()
        dispatch(
          setLegacyStripeCheckoutMessage(
            error.message ?? 'We are unable to authenticate your payment method. Please choose a different payment method and try again.'
          )
        )
        return
      }
    }

    setIsLoading(false)
    Sentry.captureException(new Error('Customer checkout: Error during payment processing.'), {
      extra: { error },
    })

    // Redirect for system errors only
    dispatch(
      setLegacyStripeCheckoutMessage(
        `We apologize, but there was an issue processing your payment.\nPlease add your payment method on Stripe's payment portal.`
      )
    )
    dispatch(setLegacyStripeCheckoutVisible(true))
    navigate('/dashboard')
  }

  function handleStripeUnavailable() {
    // Scroll to top to show error message
    scrollCheckoutToTop()

    Sentry.captureException(new Error('Customer checkout: Stripe.js has not loaded yet.'))
    dispatch(
      setLegacyStripeCheckoutMessage(
        "We're sorry, there was an issue connecting to Stripe.\n Please click the button to submit your payment method on Stripe's payment portal."
      )
    )
    dispatch(setLegacyStripeCheckoutVisible(true))
    navigate('/dashboard')
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (!stripe || !elements) {
      handleStripeUnavailable()
      return
    }
    setIsLoading(true)
    try {
      const { error }: { error?: StripeError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${kPaxtonAppBaseDomain()}/pending-subscription?billingCycle=${billingCycle}${
            promotion?.coupon ? `&promotion=${promotion.coupon.id}` : ''
          }`,
        },
      })

      if (error) {
        handlePaymentError(error)
      }
    } catch (error) {
      handlePaymentError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadingOrIncompleteCardStyles = 'bg-brand-neutral-300 hover:bg-brand-neutral-400'
  const doneLoadingAndCompleteCardStyles = 'cursor-pointer bg-brand-500 hover:bg-brand-400'
  const ctaCopy = checkoutButtonCopy ?? 'Start Trial'

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="flex flex-col w-full">
      <AddressElement options={{ mode: 'billing' }} />
      <div className="h-4" />
      <PaymentElement options={{ terms: { card: 'never' } }} />

      <PromoCode />

      <div className="text-xs text-brand-neutral-600 mt-2 text-justify max-w-sm">
        We place a ${subscriptionType === SubscriptionType.EDUCATION ? '29' : '199'} preauthorization hold on your credit card to verify payment. This is not a
        charge. The hold is removed immediately. {supportTrial ? 'You will not be charged until your trial ends.' : ''} See our
        <a href="https://www.paxton.ai/pricing" target="_blank" className="text-brand-500 underline ml-1">
          FAQ
        </a>{' '}
        for details.
      </div>

      <div className="text-xs text-brand-neutral-600 mt-4 text-justify max-w-sm">
        By providing your card information, you allow Paxton AI, Inc. to charge your card for future payments in accordance with
        <a href={kLinkTermsOfServiceUrl} target="_blank" rel="noopener noreferrer" className="text-brand-500 underline ml-1">
          our terms
        </a>
        .
      </div>

      <button
        type="submit"
        className={` ${isLoading ? loadingOrIncompleteCardStyles : doneLoadingAndCompleteCardStyles} ${
          isLoading && 'cursor-wait'
        } w-full rounded-md  px-2 py-1 mt-4 text-lg  text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-900 `}
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text" className="flex items-center justify-center">
          {isLoading ? (
            <div className="mr-5">
              <CircularProgressContinuous />
            </div>
          ) : (
            ctaCopy
          )}
        </span>
      </button>
    </form>
  )
}
