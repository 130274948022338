import { useMatch } from 'react-router'
import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { Drive, SharedDrive } from '@/assets/icons'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import DashboardSidebarGroup from './components/dashboard-sidebar-group'
import { DashboardSidebarItemType } from './components/dashboard-sidebar-item'

export default function SidebarGroupFiles() {
  // Auth Context to check if user has an organization account
  const authContext = useContext(AuthContext)
  const { userAccountData } = authContext
  const brand = userAccountData?.brand ?? BrandEnum.NONE

  // Route matches
  const orgDriveMatch = useMatch('/dashboard/drives/org-drive') ?? false

  // Create items array with My Drive and conditionally add Shared Drive
  const driveItems: DashboardSidebarItemType[] = [
    {
      name: 'My Drive',
      href: '/dashboard/drives/my-drive',
      icon: Drive,
      current: useMatch('/dashboard/drives/my-drive') ?? false,
      allowAnon: false,
    },
  ]

  // Add Shared Drive only if user has an organization account
  if (userAccountData?.isOrgAccount) {
    driveItems.push({
      name: 'Shared Drive',
      href: '/dashboard/drives/org-drive',
      icon: SharedDrive,
      current: orgDriveMatch,
      allowAnon: false,
      organizationRequired: true,
      excludedBrands: [BrandEnum.HAIKU],
    })
  }

  // Filter out items excluded for the current brand
  const filteredItems = driveItems.filter((item) => !item.excludedBrands?.includes(brand))

  // Only render if there are items to show
  if (filteredItems.length === 0) {
    return null
  }

  return <DashboardSidebarGroup items={filteredItems} />
}
