import { useState, useEffect, useCallback } from 'react'
import { convertMarkdownToHtml } from '../util/markdown-to-html'
import { getMarkdownFromGCS } from '../fetch/get-artifact-markdown-gcs'
import highlightMarkdownHtmlString from '../util/markdown-reference-highlighter'
import { wrapTablesWithDiv } from '@/chat-common/util/source-html-editing-functions'

interface GcsMarkdownLoaderOptions {
  gcsBucketName: string
  filePath: string
  highlights?: string[] | null
}

interface GcsMarkdownLoaderResult {
  htmlString: string | null // Or any, if the GcsMarkdown type vary.  Use a more specific type if possible.
  isLoading: boolean
  hasError: boolean
  retry: () => void
}

/**
 * useGcsMarkdownLoader
 *
 * Custom hook to fetch and process markdown GcsMarkdown from
 *
 * @param filePath - The path to the markdown file.
 * @returns An object containing the processed HTML string, loading state, error flag, and a retry function.
 */
const useGcsMarkdownLoader = (props: GcsMarkdownLoaderOptions): GcsMarkdownLoaderResult => {
  const { filePath, gcsBucketName, highlights } = props
  const [htmlString, setHtmlString] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  const fetchAndProcess = useCallback(async () => {
    setIsLoading(true)
    setHasError(false)
    setHtmlString(null)

    try {
      // Fetch markdown from GCS
      const markdown = await getMarkdownFromGCS(gcsBucketName, filePath)

      // Convert markdown to HTML
      let html = await convertMarkdownToHtml(markdown)

      // Highlight text if necessary
      if (highlights) {
        html = await highlightMarkdownHtmlString(html, highlights)
      }

      // Wrap tables to allow horizontal scrolling and nice table formatting
      html = wrapTablesWithDiv(html)

      // Update local state with HTML
      setHtmlString(html)
    } catch (err) {
      console.error('Error in useGcsMarkdown:', err)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }, [filePath, gcsBucketName, highlights])

  // Initial fetch and process on mount or when filePath changes
  useEffect(() => {
    fetchAndProcess()
  }, [fetchAndProcess])

  return { htmlString, isLoading, hasError, retry: fetchAndProcess }
}

export default useGcsMarkdownLoader
