/**
 * Represents an error that occurs during the fetching of an Markdown.
 */
export class MarkdownFetchError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'MarkdownFetchError'
  }
}

/**
 * Represents an error that occurs during the processing of an Markdown.
 */
export class MarkdownProcessingError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'MarkdownProcessingError'
  }
}
