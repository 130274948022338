export const kDocumentInterfaceApiQueryPath = '/api/v1/query/expanded'
export const kDocumentInterfaceApiFilesQueryPath = '/api/v1/query/document'
export const kDocumentAuditApiQueryPath = '/api/v1/audit/' // needs trailing slash
export const kBooleanBuilderApiQueryPath = '/api/v1/casefinder/' // needs trailing slash
export const kSourceApiQueryPath = '/api/v1/query/source'
export const kTranscriberApiPath = '/api/v1/transcribe/' // needs trailing slash
export const kCaseLawQueryApiPath = '/api/v1/query/caselaw'
export const kSecScanApiPath = '/api/v1/sec/scan'
export const kSecQueryApiPath = '/api/v1/sec/query'
export const kSecCompanyNameQueryApiPath = '/api/v1/sec/query_company_name'
export const kSecCompanyTickerQueryApiPath = '/api/v1/sec/query_company_ticker'
export const kWebSearchQueryApiPath = '/api/v1/websearch/query'
export const kChatV2ApiSuggestionsBasePath = '/api/v1/suggestion'
export const kMessageFeedbackApiPath = '/api/v1/chat/message_feedback'
export const kPaymentsSignupPath = 'api/v1/payments/checkout'
export const kPaymentsPartnerPath = 'api/v1/payments/partner'
export const kPaymentsSurveyPath = 'api/v1/payments/survey'
export const kPaymentsSurveyPathV2 = 'api/v1/payments/survey-v2'
export const kPaymentsPortalPath = 'api/v1/payments/portal'
export const kPaymentsSubscriptionPath = 'api/v1/payments/subscription'
export const kPaymentsPreAuthorizationPath = 'api/v1/payments/pre-authorization'
export const kPaymentsSetupIntentPath = 'api/v1/payments/setup_intent'
export const kHighlightUserReferencePath = '/api/v1/sourceviewer/highlight_user_reference'
export const kChatHtmlToDocxPath = '/api/v1/sourceviewer/html_to_docx'

// Assistant (Agent)
export const kAgentAssistantRouterApiBasePath = '/api/v1/assistant'
export const kAgentAssistantChatBasePath = '/api/v1/assistant/chat'

// Artifact
export const kArtifactApiBasePath = '/api/v1/artifact'
export const kArtifactVersionsBasePath = kArtifactApiBasePath + '/versions'
export const kArtifactDownloadDocxApiPath = kArtifactVersionsBasePath + '/{artifactVersionId}/docx'

// Chat
export const kChatV2ApiConversationsBasePath = '/api/v1/chat/conversations'

// Source view paths
export const kSourceApiLrr = '/api/v1/lrr/reference'
export const kSourceApiLrrV2 = '/api/v1/lrr_v2/reference'
export const kSourceApiContracts = '/api/v1/contract_retrieval/get_contract?s3_path='

// HTML source paths
export const kSourceCaseLawHtml = '/api/v1/caselaw/get_html'

// Public hosted file paths
export const kCaseLawCourtsFilePath = '/filters/case_law_courts.min.json'
export const kLrrSourcesFilePath = '/filters/lrr_sources.min.json'

// Organizations
export const kGetUserOrgData = '/api/v1/organization'
export const kCreateOrganization = '/api/v1/organization/v2'

// Organizations - Admin
export const kOrgBasePath = '/api/v1/organization'
export const kOrgUserOAuthBrandOnboardPath = '/api/v1/organization/user/brand/onboard'

// Document Editing - Contract Analysis
export const kDocumentEditingContractAnalysesApiPath = 'api/v1/contract_analysis/analyses'
export const kDocumentEditingContractAnalysisApiPath = 'api/v1/contract_analysis/analysis'

// Citator
export const kCitatorGetCaseStatusApiPath = 'api/v1/citator/get-or-enqueue-case-status'
export const kCitatorGetCaseCitationsApiPath = 'api/v1/citator/case-citations'
export const kCitatorGetCitingCasesApiPath = 'api/v1/citator/citing-cases'
export const kCitatorGetRelatedCasesApiPath = 'api/v1/citator/related-cases'

// Caselaw Reference Viewer
export const kCaselawReferenceViewerApiPath = '/api/v1/caselaw/get_case_metadata'

// Subscriptions
export const kSubscriptionValidatePromoCodeApiPath = '/api/v1/payments/promotion'
export const kSubscriptionCurrentPriceApiPath = '/api/v1/payments/current-price'
export const kSubscriptionCheckoutPricesApiPath = '/api/v1/payments/checkout-prices'
export const kSubscriptionTrialDaysApiPath = '/api/v1/payments/trial-days'

// Referral
export const kReferralActivatePath = '/api/v1/referral/activate'
export const kReferralCodePath = '/api/v1/referral/code'

// Limit
export const kTokenLimitApiPath = '/api/v1/limit/tokens'

// User Settings
export const kSettingsBasePath = '/api/v1/settings'
export const kUserSettingsApiPath = '/api/v1/settings/user'

// Onboarding
export const kOnboardingPath = '/api/v1/onboarding/' // needs trailing slash

// File Drive
export const kFileDriveApiPath = '/api/v1/files/'
export const kFolderDriveApiPath = '/api/v1/folders/'
