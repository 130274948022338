import React, { useContext } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { AuthContext } from '@/context/auth-context'
import { Api } from '@mui/icons-material'
import AccountIcon from '@/assets/icons/user-account.svg?react'
import CreditCardIcon from '@/assets/icons/credit-card.svg?react'
import SettingsIcon from '@/assets/icons/settings.svg?react'
import MessageDotsIcon from '@/assets/icons/message-dots.svg?react'
import LogOutIcon from '@/assets/icons/log-out.svg?react'
import EnterpriseIcon from '@/assets/icons/-enterprise-organization.svg?react'
import { ArrowUpRight, Diamond } from '@/assets/icons'
import { kProduction } from '@/constants/constants-ui'
import { useNavigate, useMatch } from 'react-router-dom'
import { kSegmentTrackOpenSupportChatClicked, kSegmentTrackSignOut } from '@/constants/constants-segment'
import { useHubspotConversations } from '@/context/hubspot-conversations-provider'
import signOutOfApp from '@/firebase/auth/sign-out'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { getHelpCenterUrl } from '@/util/enterprise'
import { kLinkPrivacyPolicyUrl, kLinkTermsOfServiceUrl } from '@/constants/constants-links'
import { ClockStopwatch } from '@/assets/icons'
import { SubscriptionStatus, SubscriptionType } from '@/firebase/auth/auth-jwt-schema'
import { useAppDispatch } from '@/store/store-hooks'
import { setReferralDialogVisible } from '@/store/slices/ui-state.slice'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type Props = {
  onSelect: () => void
}

const DashboardSidebarAccountMenu: React.FC<Props> = (props: Props) => {
  const { onSelect } = props
  const navigate = useNavigate()
  const { toggleWidget } = useHubspotConversations()
  const { user, userAccountData } = useContext(AuthContext)
  const dispatch = useAppDispatch()

  // DEV ONLY: Testing variables - set to true to test different states
  const devOverrides = {
    forceTrial: false, // Set to true to force trial state
    forceBrand: null as BrandEnum | null, // Set to BrandEnum.HAIKU to test enterprise brand
    forceStudent: false, // Set to true to test student account
    forceReferral: false, // Set to true to force show referral dialog
  }

  // Override values for testing if in development
  const subscriptionStatus = !kProduction && devOverrides.forceTrial ? SubscriptionStatus.TRIAL : userAccountData?.subscriptionStatus
  const brand = !kProduction && devOverrides.forceBrand ? devOverrides.forceBrand : userAccountData?.brand ?? BrandEnum.NONE
  const subscriptionType =
    !kProduction && devOverrides.forceStudent ? SubscriptionType.EDUCATION : userAccountData?.subscriptionType ?? SubscriptionType.STANDARD
  const showReferral = !kProduction && devOverrides.forceReferral ? true : userAccountData?.validSubscription && !userAccountData?.isOrgAccount

  const legacyCompatibleOrgId = userAccountData?.legacyDriveCompatibleOrganizationId
  const userIsAnon = user?.isAnonymous
  const isOrgAdmin = userAccountData?.isOrgAdmin

  // Add route matching
  const accountMatch = useMatch('/dashboard/account')
  const adminMatch = useMatch('/dashboard/admin')
  const settingsMatch = useMatch('/dashboard/settings')

  // This is for logged in users only
  if (!user || userIsAnon) {
    return null
  }

  // Menu elements
  const menuElements = [
    legacyCompatibleOrgId
      ? {
          hideFromAnon: true,
          name: 'Enterprise Account',
          icon: EnterpriseIcon,
          current: false,
          noClick: true,
          onClick: () => {
            return
          },
        }
      : {
          hideFromAnon: true,
          name: 'Manage subscription',
          icon: CreditCardIcon,
          current: accountMatch !== null,
          onClick: () => {
            navigate('/dashboard/account')
          },
        },

    ...(legacyCompatibleOrgId && isOrgAdmin
      ? [
          {
            hideFromAnon: true,
            name: 'Admin Dashboard',
            icon: SettingsIcon,
            current: adminMatch !== null,
            onClick: () => {
              navigate('/dashboard/admin')
            },
          },
        ]
      : []),
    {
      name: 'Settings',
      icon: SettingsIcon,
      current: settingsMatch !== null,
      onClick: () => {
        navigate('/dashboard/settings')
      },
    },
    {
      hideFromAnon: false,
      name: 'Chat with support',
      icon: MessageDotsIcon,
      current: false,
      onClick: () => {
        // Track
        analytics.track(kSegmentTrackOpenSupportChatClicked)

        toggleWidget()
      },
      excludedBrands: [BrandEnum.HAIKU],
    },
    {
      hideFromAnon: true,
      name: 'Sign Out',
      icon: LogOutIcon,
      current: false,
      onClick: () => {
        // Track
        analytics.track(kSegmentTrackSignOut)

        signOutOfApp(navigate)
      },
    },
    ...(!kProduction
      ? [
          {
            name: 'Log Token (dev only)',
            icon: Api,
            current: false,
            onClick: async () => {
              const token = await user.getIdToken(true) // force refresh true
              console.log(token)
            },
          },
        ]
      : []),
  ].filter((item) => ((userIsAnon && !item.hideFromAnon) || !userIsAnon) && !item.excludedBrands?.includes(brand))

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button
          className="w-[239px] group flex items-center gap-x-2.5 pl-1 pr-3 py-2 rounded-full hover:bg-brand-neutral-100 focus:outline-none"
          aria-label="Account Menu"
        >
          <div className="flex-none flex justify-center items-center w-8 h-8 rounded-full bg-brand-neutral-200 group-hover:bg-brand-neutral-300">
            {user.displayName ? (
              <span className="text-brand-neutral-700 font-medium text-base">
                {user.displayName
                  .split(' ')
                  .map((name) => name[0])
                  .join('')
                  .toUpperCase()
                  .slice(0, 2)}
              </span>
            ) : (
              <AccountIcon className="w-5 h-5 text-brand-neutral-700" />
            )}
          </div>
          <span className={`flex-grow text-left text-sm leading-5 font-medium text-brand-neutral-950 truncate`}>{user.displayName ?? user.email}</span>
          <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-brand-neutral-950">
            <path d="M0 0L5 5L10 0H0Z" fill="currentColor" />
          </svg>
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 w-[239px] bg-white rounded-[5px] shadow-[0px_0px_6px_-2px_rgba(0,_0,_0,_0.05),_0px_0px_15px_-3px_rgba(0,_0,_0,_0.1)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={-4}
          align="end"
          alignOffset={-8}
        >
          <div className="border border-brand-neutral-300 rounded-[5px]">
            {subscriptionStatus === SubscriptionStatus.TRIAL && (
              <div className="flex items-center gap-1.5 px-4 py-2 bg-brand-50 border-b border-brand-100 rounded-t-[5px]">
                <ClockStopwatch className="w-5 h-5 text-brand-500" />
                <span className="text-[14px] font-semibold leading-[1.429em] tracking-[-1.07%] text-brand-500">Free Trial</span>
              </div>
            )}
            <div className="py-4 px-4">
              <div className="flex flex-col gap-4">
                <span className="text-[14px] leading-5 font-medium text-brand-neutral-600 truncate">{user.email}</span>
                {brand !== BrandEnum.NONE && brand !== BrandEnum.PAXTON ? (
                  <>
                    <span className="text-xs leading-4 font-medium text-brand-neutral-700 uppercase">
                      {brand === BrandEnum.HAIKU ? 'Holland & Knight' : brand}
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="px-1 py-[1px] bg-brand-neutral-950 rounded text-[12px] leading-4 text-white font-medium uppercase">Enterprise</span>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center gap-2">
                    <span className="px-1 py-[1px] bg-brand-neutral-950 rounded text-[12px] leading-4 text-white font-medium uppercase">
                      {subscriptionType === SubscriptionType.EDUCATION ? 'Student' : 'Professional'}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <ul role="list" className="border-t border-brand-neutral-200 p-2 space-y-0.5">
              {menuElements.map((item) => (
                <li key={item.name}>
                  <DropdownMenu.Item
                    onClick={() => {
                      item.onClick()
                      onSelect()
                    }}
                    className={classNames(
                      item.current
                        ? 'bg-brand-neutral-200 text-brand-500'
                        : `text-brand-neutral-700 ${
                            item.noClick ? 'outline-none ring-0 cursor-default' : 'hover:bg-brand-neutral-50 hover:text-brand-500 cursor-pointer'
                          }`,
                      'group flex items-center gap-x-2.5 p-2 text-[14px] leading-[1.429em] tracking-[-1.07%] font-medium outline-none rounded-[4px]'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-brand-500' : `${item.noClick ? '' : 'text-brand-neutral-600 group-hover:text-brand-500'}`,
                        'h-5 w-5 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                  </DropdownMenu.Item>
                </li>
              ))}
            </ul>

            <ul role="list" className="border-t border-brand-neutral-200 p-2 space-y-0.5">
              <li>
                <DropdownMenu.Item
                  onClick={() => {
                    window.open(getHelpCenterUrl(), '_blank')
                    onSelect()
                  }}
                  className="group flex items-center gap-x-2.5 p-2 text-[14px] leading-[1.429em] tracking-[-1.07%] font-medium text-brand-neutral-700 hover:bg-brand-neutral-50 hover:text-brand-500 cursor-pointer outline-none rounded-[4px] w-full"
                >
                  <span className="flex-1">Help Center</span>
                  <ArrowUpRight className="w-4 h-4 text-brand-neutral-600 group-hover:text-brand-500" />
                </DropdownMenu.Item>
              </li>
              <li>
                <DropdownMenu.Item
                  onClick={() => {
                    window.open(kLinkPrivacyPolicyUrl, '_blank')
                    onSelect()
                  }}
                  className="group flex items-center gap-x-2.5 p-2 text-[14px] leading-[1.429em] tracking-[-1.07%] font-medium text-brand-neutral-700 hover:bg-brand-neutral-50 hover:text-brand-500 cursor-pointer outline-none rounded-[4px] w-full"
                >
                  <span className="flex-1">Privacy Policy</span>
                  <ArrowUpRight className="w-4 h-4 text-brand-neutral-600 group-hover:text-brand-500" />
                </DropdownMenu.Item>
              </li>
              <li>
                <DropdownMenu.Item
                  onClick={() => {
                    window.open(kLinkTermsOfServiceUrl, '_blank')
                    onSelect()
                  }}
                  className="group flex items-center gap-x-2.5 p-2 text-[14px] leading-[1.429em] tracking-[-1.07%] font-medium text-brand-neutral-700 hover:bg-brand-neutral-50 hover:text-brand-500 cursor-pointer outline-none rounded-[4px] w-full"
                >
                  <span className="flex-1">Terms of Service</span>
                  <ArrowUpRight className="w-4 h-4 text-brand-neutral-600 group-hover:text-brand-500" />
                </DropdownMenu.Item>
              </li>
            </ul>

            <div className="border-t border-brand-neutral-200 bg-brand-neutral-50 rounded-b-[5px]">
              {showReferral && (
                <div className="p-4" onClick={(e) => e.stopPropagation()}>
                  <div className="w-full bg-brand-50 border border-brand-100 rounded-[4px] p-3">
                    <div className="flex flex-col gap-2">
                      <p className="text-brand-neutral-700 text-xs leading-[1.333em] font-normal">
                        Invite others to join Paxton and earn 1 free month per referral.
                      </p>
                      <div className="flex justify-center items-center gap-1">
                        <Diamond className="w-4 h-4 text-brand-500" />
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            dispatch(setReferralDialogVisible(true))
                          }}
                          className="text-brand-500 text-xs leading-[1.333em] font-semibold hover:opacity-80"
                        >
                          Get 1 Month Free
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="px-4 py-2 bg-brand-neutral-100">
                <span className="block text-[10.4px] leading-[1.077em] text-brand-neutral-700 font-normal">v{APP_VERSION}</span>
              </div>
            </div>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default DashboardSidebarAccountMenu
