import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'
import { ContentViewerType } from './slice'

/**
 * Selector: IdentifierMatchesActiveContentViewer
 *
 * The provided identifier can be whatever is appropriate for the ContentViewerType that
 * it would naturally check against.
 *
 * @returns {boolean} true if the provided id is active in the content viewer given the content viewer's type.
 */
export const selectIdentifierMatchesActiveContentViewer = createSelector(
  (state: RootState) => state.contentViewerState.content,
  (_: RootState, props: { id: string; highlightIndex?: number }) => props,
  (content, props) => {
    if (!content || !props) return false

    const { id, highlightIndex } = props

    switch (content.type) {
      case ContentViewerType.ARTIFACT:
        return content.data.id === id

      case ContentViewerType.AGENT_REFERENCE_MD:
        return highlightIndex === undefined
          ? content.data.reference.reference.citation_id === id
          : content.data.reference.reference.citation_id === id && content.data.citationHighlightIndex === highlightIndex

      case ContentViewerType.AGENT_REFERENCE_PDF:
        return highlightIndex === undefined
          ? content.data.reference.reference.citation_id === id
          : content.data.reference.reference.citation_id === id && content.data.citationHighlightIndex === highlightIndex
      default:
        return false
    }
  }
)
