import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { useAppDispatch } from '@/store/store-hooks'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'
import { isPaxtonBrand } from '@/util/enterprise'

type SidebarItemAnonSignInProps = {
  setSidebarOpen: (open: boolean) => void
}

export default function SidebarItemAnonSignIn({ setSidebarOpen }: SidebarItemAnonSignInProps) {
  const dispatch = useAppDispatch()
  const isPaxtonHost = isPaxtonBrand()

  // Auth Context
  const authContext = useContext(AuthContext)
  const { user } = authContext
  const userIsAnon = user?.isAnonymous

  // Only render for anonymous users
  if (!userIsAnon) {
    return null
  }

  return (
    <div className="px-2 flex flex-row gap-2">
      {isPaxtonHost && (
        <button
          onClick={() => {
            dispatch(openAuthDialog({ authDialogType: AuthDialogType.SIGN_UP }))
            setSidebarOpen(false)
          }}
          className="flex items-center justify-center gap-x-2 w-full px-4 py-2 text-sm font-medium text-white bg-brand-500 hover:bg-brand-400 rounded-md"
        >
          Sign up
        </button>
      )}
      <button
        onClick={() => {
          dispatch(openAuthDialog({ authDialogType: AuthDialogType.SIGN_IN }))
          setSidebarOpen(false)
        }}
        className="flex items-center justify-center gap-x-2 w-full px-4 py-2 text-sm font-medium text-brand-500 bg-brand-neutral-50 hover:bg-brand-neutral-100 rounded-md"
      >
        Sign in
      </button>
    </div>
  )
}
