/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileNode, FileStatus, FolderNode, DriveType } from '../schemas/files-drive-schema'

export type FilesDriveState = {
  /** Array of folder nodes in the current directory */
  folders: FolderNode[]
  /** Array of file nodes in the current directory */
  files: FileNode[]
  /** Loading state for fetching current directory contents */
  loading: boolean
  /** Error message if current directory contents fetch fails */
  error: string | null
  /** ID of the currently viewed folder */
  currentFolderId: string | null
  /** Node for the currently viewed folder */
  currentFolder: FolderNode | null
  /** Whether file status polling is active */
  isPollingFiles: boolean
  /** Whether file/folder deletion operations are active */
  isDeletingItems: boolean
  /** Whether file/folder creation operations are active */
  isCreatingFolder: boolean
  /** Whether file/folder renaming operations are active */
  isRenamingItems: boolean
  /** IDs of currently selected files and folders */
  selectedFilesAndFolders: {
    /** Array of selected file IDs */
    files_ids: string[]
    /** Array of selected folder IDs */
    folders_ids: string[]
  }
  /** Currently active item for single-item operations like rename/delete */
  activeItem: FileNode | FolderNode | null
  /** Drive type */
  driveType: DriveType
}

const initialState: FilesDriveState = {
  folders: [],
  files: [],
  loading: false,
  error: null,
  currentFolderId: null,
  currentFolder: null,
  isPollingFiles: false,
  isDeletingItems: false,
  isCreatingFolder: false,
  isRenamingItems: false,
  selectedFilesAndFolders: {
    files_ids: [],
    folders_ids: [],
  },
  activeItem: null,
  driveType: DriveType.USER,
}

export const filesDriveSlice = createSlice({
  name: 'filesDrive',
  initialState,
  reducers: {
    /**
     * Triggers fetching of files and folders for a specific folder ID.
     * Handled by listener middleware which makes the API call.
     * @example dispatch(FilesDriveActions.fetchItems({ folderId: "123" }))
     */
    fetchItems: (_state, _action: PayloadAction<{ folderId: string }>) => {
      // handled by the listener middleware
    },

    /**
     * Triggers fetching of files and folders for the root folder of an specific org ID.
     * Handled by listener middleware which makes the API call.
     * @example dispatch(FilesDriveActions.fetchOrgItems({ organizationId: "123" }))
     */
    fetchOrgItems: (_state, _action: PayloadAction<{ organizationId: string }>) => {
      // handled by the listener middleware
    },
    /**
     * Triggers fetching of status for a specific file.
     * Handled by listener middleware which makes the API call.
     * @example dispatch(FilesDriveActions.fetchFileStatusById({ fileId: "456" }))
     */
    fetchFileStatusById: (_state, _action: PayloadAction<{ fileId: string }>) => {
      // handled by the listener middleware
    },

    /**
     * Triggers creation of a new folder.
     * Handled by listener middleware which makes the API call.
     * @example dispatch(FilesDriveActions.createFolder({ folderName: "New Folder" }))
     */
    createFolder: (_state, _action: PayloadAction<{ folderName: string; parentFolderId: string }>) => {
      // handled by the listener middleware
    },
    /**
     * Triggers upload of files.
     * Handled by listener middleware which makes the API call.
     * @example dispatch(FilesDriveActions.uploadFiles({ files_ids: ["123"] }))
     */
    uploadFiles: (_state, _action: PayloadAction<{ files_ids: string[] }>) => {
      // handled by the listener middleware
    },
    /**
     * Updates the current folder being viewed.
     * @example dispatch(FilesDriveActions.setCurrentFolderId({ folderId: "789" }))
     */
    setCurrentFolderId: (state, action: PayloadAction<{ folderId: string }>) => {
      state.currentFolderId = action.payload.folderId
    },

    /**
     * Set the current folder node
     * Use to set the current folder when the user navigates to a new folder
     * @example dispatch(FilesDriveActions.setCurrentFolder({ folder: folderData }))
     */
    setCurrentFolder: (state, action: PayloadAction<{ folder: FolderNode | null }>) => {
      state.currentFolder = action.payload.folder ?? null
    },

    /**
     * Triggers deletion of specified files and folders.
     * Handled by listener middleware which makes the API call.
     * @example dispatch(FilesDriveActions.removeItems({ fileIds: ["123"], folderIds: ["456"] }))
     */
    removeItems: (_state, _action: PayloadAction<{ fileIds: string[]; folderIds: string[] }>) => {
      // handled by the listener middleware
    },

    /**
     * Sets loading state during item deletion.
     * @example dispatch(FilesDriveActions.setDeletingItems(true))
     */
    setDeletingItems: (state, action: PayloadAction<boolean>) => {
      state.isDeletingItems = action.payload
    },

    /**
     * Sets the creating folder active state.
     * @example dispatch(FilesDriveActions.setCreatingFolder(true))
     */
    setCreatingFolder: (state, action: PayloadAction<boolean>) => {
      state.isCreatingFolder = action.payload
    },

    /**
     * Sets the currently active file/folder for single-item operations.
     * @example dispatch(FilesDriveActions.setActiveFile(fileData))
     */
    setActiveFile: (state, action: PayloadAction<FileNode | FolderNode | null>) => {
      state.activeItem = action.payload
    },

    /**
     * Clears the currently active file/folder.
     * @example dispatch(FilesDriveActions.clearActiveFile())
     */
    clearActiveFile: (state) => {
      state.activeItem = null
    },

    /**
     * Updates processing status of a specific file.
     * @example dispatch(FilesDriveActions.updateFileStatus({ fileId: "123", status: FileStatus.COMPLETE }))
     */
    updateFileStatus: (state, action: PayloadAction<{ fileId: string; status: FileStatus }>) => {
      const { fileId, status } = action.payload
      const file = state.files.find((f) => f.id === fileId)
      if (file) {
        file.status = status
      }
    },

    /**
     * Updates the name of a specific file.
     * @example dispatch(FilesDriveActions.renameItem({ itemNewName: "New Name", itemId: "123" }))
     */
    renameItem: (_state, _action: PayloadAction<{ proposedItemName: string; itemId: string }>) => {
      // handled by the listener middleware
    },

    /**
     * Sets the renaming items active state.
     * @example dispatch(FilesDriveActions.setRenamingItems(true))
     */
    setRenamingItems: (state, action: PayloadAction<boolean>) => {
      state.isRenamingItems = action.payload
    },

    /**
     * Updates the list of files and folders in the current directory.
     * @example dispatch(FilesDriveActions.setFolderItems({ folders: folderList, files: fileList }))
     */
    setFolderItems: (state, action: PayloadAction<{ folders: FolderNode[]; files: FileNode[] }>) => {
      const { folders, files } = action.payload

      if (!folders && !files) {
        state.error = 'Failed to fetch items.'
        state.loading = false
        return
      }
      const rootFolder = folders.find((f) => !f.folder_id)

      if (rootFolder) {
        state.currentFolderId = rootFolder.id
      }

      // Filter out the root folder from the folders array.
      state.folders = folders.filter((f) => f.folder_id !== null)
      // Ensure that state.files is always an array to prevent potential runtime issues.
      state.files = Array.isArray(files) ? files : []
    },

    /**
     * Sets error state when operations fail.
     * @example dispatch(FilesDriveActions.setError("Failed to load files"))
     */
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
      state.loading = false
    },

    /**
     * Sets loading state during operations.
     * @example dispatch(FilesDriveActions.setLoading(true))
     */
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    /**
     * Controls whether file status polling is active.
     * @example dispatch(FilesDriveActions.setPollingFiles({ isActive: true }))
     */
    setPollingFiles: (state, action: PayloadAction<{ isActive: boolean }>) => {
      state.isPollingFiles = action.payload.isActive
    },
    /**
     * Toggles selection state for specified files.
     * If files are already selected, they will be deselected.
     * If files are not selected, they will be added to selection.
     * @example dispatch(FilesDriveActions.setSelectedFiles({ files_ids: ["123"] }))
     */
    setSelectedFiles: (state, action: PayloadAction<{ files_ids: string[] }>) => {
      const { files_ids } = action.payload

      // Check if the file is already selected
      const isFileSelected = files_ids.some((id) => state.selectedFilesAndFolders.files_ids.includes(id))

      if (isFileSelected) {
        state.selectedFilesAndFolders.files_ids = state.selectedFilesAndFolders.files_ids.filter((id) => !files_ids.includes(id))
      } else {
        state.selectedFilesAndFolders.files_ids = [...state.selectedFilesAndFolders.files_ids, ...files_ids]
      }
    },
    /**
     * Toggles selection state for specified folders.
     * If folders are already selected, they will be deselected.
     * If folders are not selected, they will be added to selection.
     * @example dispatch(FilesDriveActions.setSelectedFolders({ folders_ids: ["123"] }))
     */
    setSelectedFolders: (state, action: PayloadAction<{ folders_ids: string[] }>) => {
      const { folders_ids } = action.payload

      // Check if the file is already selected
      const isFolderSelected = folders_ids.some((id) => state.selectedFilesAndFolders.folders_ids.includes(id))

      if (isFolderSelected) {
        state.selectedFilesAndFolders.folders_ids = state.selectedFilesAndFolders.folders_ids.filter((id) => !folders_ids.includes(id))
      } else {
        state.selectedFilesAndFolders.folders_ids = [...state.selectedFilesAndFolders.folders_ids, ...folders_ids]
      }
    },

    /**
     * Updates the list of selected files and folders.
     * @example dispatch(FilesDriveActions.setSelectedFilesAndFolders({ files: ["123"], folders: ["456"] }))
     */
    setSelectedFilesAndFolders: (state, action: PayloadAction<{ files_ids: string[]; folders_ids: string[] }>) => {
      state.selectedFilesAndFolders = action.payload
    },

    /**
     * Sets the drive type.
     * @example dispatch(FilesDriveActions.setDriveType(DriveType.ORG))
     */
    setDriveType: (state, action: PayloadAction<DriveType>) => {
      state.driveType = action.payload
    },
  },
})

export const FilesDriveActions = filesDriveSlice.actions
export default filesDriveSlice.reducer
