import { buildAgentResponseEventPrintableBubble } from '@/agent/conversations/util/download-agent-conversation'
import { ClientAgentEventRead } from '../store/schemas'
import { buildDocumentHTML, formatHtmlForDocXTransformation } from '@/chat-common/util/download-conversation'

/**
 * Copy Agent Response to Clipboard
 *
 * Copies the contents of an agent response event to the clipboard.
 * This includes the header, the event text, and references.
 *
 * @param event - The event to copy to the clipboard.
 */
export async function copyAgentResponseToClipboard(event: ClientAgentEventRead) {
  // Build the printable version of the agent response event
  const printableElement = buildAgentResponseEventPrintableBubble(event)

  // Render the printable bubble component to static HTML
  const eventHTML = await buildDocumentHTML(printableElement, '')
  const formattedHTML = formatHtmlForDocXTransformation(eventHTML)

  // Create a temporary container element and append the formatted HTML.
  const tempElement = document.createElement('div')
  tempElement.innerHTML = formattedHTML
  document.body.appendChild(tempElement)

  // Create a selection range for copying the content
  const range = document.createRange()
  range.selectNode(tempElement)

  const selection = window.getSelection()
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }

  try {
    // Prepare a ClipboardItem with both plain text and HTML versions
    const clipboardItem = new ClipboardItem({
      'text/plain': new Blob([tempElement.innerText], { type: 'text/plain' }),
      'text/html': new Blob([tempElement.innerHTML], { type: 'text/html' }),
    })

    // Write to the clipboard
    await navigator.clipboard.write([clipboardItem])
  } catch (err) {
    console.error('Failed to copy content:', err)
    document.body.removeChild(tempElement)
  }

  // Clean up: Remove the selection and the temporary element
  if (selection) {
    selection.removeAllRanges()
  }
  document.body.removeChild(tempElement)
}
