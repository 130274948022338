

export function formatDate(date: string) {
  const dateObj: Date = new Date(date)
  return Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(dateObj)
}

export function formatSize(size: number) {
  if (size < 1000000) return `${Math.round(size / 1000)} KB`
  return `${(size / 1000000).toFixed(1)} MB`
}