import React, { useEffect, useRef } from 'react'
import { Parser } from 'html-to-react'

type ContentViewerHTMLContainerProps = {
  htmlString: string
}

/**
 * Content Viewer: HTML
 *
 * Parses and renders the provided HTML string in a document-looking container.
 */
const ContentViewerHTMLContainer: React.FC<ContentViewerHTMLContainerProps> = (props: ContentViewerHTMLContainerProps) => {
  const { htmlString } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const htmlParser = Parser()
  const parsedHtml = htmlParser.parse(htmlString)

  useEffect(() => {
    // Use setTimeout to ensure DOM is fully rendered
    const timer = setTimeout(() => {
      if (containerRef.current) {
        const firstHighlight = containerRef.current.querySelector('.ref-highlight')
        if (firstHighlight) {
          firstHighlight.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    }, 250)

    return () => clearTimeout(timer)
  }, [htmlString])

  // Render HTML
  return (
    <div className="relative h-full flex flex-col items-center p-3 animate-fadeIn">
      <div ref={containerRef} className="artifact-view-container gcs-md-highlights flex-grow border bg-white">
        {parsedHtml}
      </div>
      <div>&nbsp;</div>
    </div>
  )
}

export default ContentViewerHTMLContainer
