import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { saveAs } from 'file-saver'

export async function downloadDriveFile(filePath: string, fileName: string) {
  if (!filePath) {
    return
  }

  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const fileRef = ref(storage, filePath)
  const blob = await getBlob(fileRef)
  saveAs(blob, fileName)
  return true
}
