import { saveHTMLasDocx } from '@/chat-common/fetch/post-download-html-docx'
import AgentResponseEventPrintableBubble from '@/agent/events/components/printable/AgentResponseEventPrintableBubble'
import { buildDocumentHTML, formatHtmlForDocXTransformation } from '@/chat-common/util/download-conversation'
import { store } from '@/store/store'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { AgentEventType, ClientAgentEventRead } from '@/agent/events/store/schemas'
import { AgentConversation, AgentConversationsActions } from '../store/slice'
import { handleConversationDownloadError } from '@/chat-common/util/download-conversation'
import { selectAgentConversationFromId, selectAgentConversationTitle } from '../store/selectors'
import { selectSortedConversationEvents } from '@/agent/events/store/selectors'
import GeneralEventPrintableBubble from '@/agent/events/components/printable/GeneralEventPrintableBubble'
import { selectArtifactById } from '@/artifacts/store/selectors'
import { ClientAgentReference } from '@/agent/references/store/schemas'
import PromptAssistEventPrintableBubble from '@/agent/events/components/printable/PromptAssistEventPrintableBubble'

const kConversationDataLoadTimeoutMs = 10000

/**
 * Downloads an agent response as a DOCX file.
 *
 * @param fileName - The name of the file to save.
 * @param event - The agent response event to download.
 */
export async function downloadAgentResponseAsDocx(conversationId: string, event: ClientAgentEventRead | null | undefined) {
  if (!event) {
    handleConversationDownloadError('ClientAgentEventRead is null for downloadAgentResponseAsDocx()')
    return
  }

  try {
    // Construct the fileName from the conversation title or the event id
    const conversationTitle = selectAgentConversationTitle(store.getState(), { conversationId })
    const fileName = conversationTitle ?? `agent-response-${event.id}`

    // Build the printable version of the agent response event
    const printableElement = buildAgentResponseEventPrintableBubble(event)

    // Render the printable element to an HTML string
    const eventHtml = await buildDocumentHTML(printableElement, fileName)
    const outputHtml = formatHtmlForDocXTransformation(eventHtml)

    // Call the BE to save the HTML as a DOCX file
    await saveHTMLasDocx({ html: outputHtml, fileName })

    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: fileName + ' downloaded', durationMs: 2000 }))
  } catch (error) {
    console.error('Error downloading agent response as DOCX:', error)
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Response download failed', durationMs: 2000 }))
  }
}

/**
 * Downloads an agent conversation as a DOCX file.
 *
 * @param conversation - The conversation to download.
 */
export async function downloadAgentConversationAsDocx(conversation: AgentConversation | null) {
  if (!conversation) {
    handleConversationDownloadError('Conversation is null for downloadAgentConversationAsDocx()')
    return
  }

  const conversationId = conversation.id

  // If lastRefresh is null, refresh the conversation as it has not been loaded yet
  if (conversation.lastRefresh === null) {
    store.dispatch(AgentConversationsActions.fetchConversationData({ conversationId, forceRefresh: true }))

    // Wait until the conversation data is loaded
    try {
      await waitForConversationData(conversationId, kConversationDataLoadTimeoutMs)
    } catch (error) {
      handleConversationDownloadError(`Error loading conversation data: ${error}`, conversationId)
      return
    }

    // After the refresh, get the updated conversation from the store
    conversation = selectAgentConversationFromId(store.getState(), { conversationId })
  }

  if (!conversation) {
    handleConversationDownloadError('Conversation is null for downloadAgentConversationAsDocx() after refresh', conversationId)
    return
  }

  store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `Preparing download...`, durationMs: 3000 }))

  try {
    // Get or construct fileName
    const fileName = conversation.title ?? `agent-conversation-${conversationId}`

    // Get the events for the conversation
    const events = selectSortedConversationEvents(store.getState(), { conversationId })

    // Build the event components
    const eventComponents = buildPrintableEventComponents(events)

    // Render the event components to an HTML string
    const documentHTML = await buildDocumentHTML(eventComponents, fileName)
    const outputHtml = formatHtmlForDocXTransformation(documentHTML)

    // Call the BE to save the HTML as a DOCX file
    await saveHTMLasDocx({ html: outputHtml, fileName })

    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: fileName + ' downloaded', durationMs: 2000 }))
  } catch (error) {
    console.error('Error downloading agent conversation as DOCX:', error)
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Conversation download failed', durationMs: 2000 }))
  }
}

/**
 * Waits for the conversation data to be loaded by monitoring the Redux store.
 *
 * @param conversationId - The ID of the conversation.
 * @param timeoutMs - Maximum wait time in milliseconds.
 */
function waitForConversationData(conversationId: string, timeoutMs: number): Promise<void> {
  return new Promise((resolve, reject) => {
    const startTime = Date.now()

    const unsubscribe = store.subscribe(() => {
      const updatedConversation = selectAgentConversationFromId(store.getState(), { conversationId })

      // If there's an error, reject immediately
      if (updatedConversation && updatedConversation.loadError) {
        unsubscribe()
        reject(new Error(`${updatedConversation.loadError.message}`))
      }
      // If data loaded successfully, resolve
      else if (updatedConversation && updatedConversation.lastRefresh !== null && !updatedConversation.isLoading) {
        unsubscribe()
        resolve()
      }
      // If we've waited too long, reject
      else if (Date.now() - startTime > timeoutMs) {
        unsubscribe()
        reject(new Error('Timeout waiting for conversation data'))
      }
    })
  })
}

/**
 * Builds an AgentResponseEventPrintableBubble component from an agent response event.
 *
 * @param event - The agent response event.
 * @returns A JSX.Element representing the printable bubble.
 */
export function buildAgentResponseEventPrintableBubble(event: ClientAgentEventRead): JSX.Element {
  const state = store.getState()

  // Get the artifact version for the event
  const artifactVersion = selectArtifactById(state, { artifactVersionId: event.artifact_version_id })

  // Get the turn unique citation ids and highlight count for the event
  const turnUniqueCitationAndHighlightCount = state.agentReferencesState.turnUniqueCitationAndHighlightCount[event.turn_id] ?? {}
  const turnUniqueCitationIds = Object.keys(turnUniqueCitationAndHighlightCount)

  // Get the references for the event based on the turnUniqueCitationIds
  const references: ClientAgentReference[] = turnUniqueCitationIds
    .map((citationId) => state.agentReferencesState.references[citationId])
    .filter((ref): ref is ClientAgentReference => Boolean(ref))

  return (
    <AgentResponseEventPrintableBubble
      key={`event-print-${event.id}`}
      event={event}
      references={references}
      turnUniqueCitationAndHighlightCount={turnUniqueCitationAndHighlightCount}
      artifactVersion={artifactVersion}
    />
  )
}

/**
 * Builds the printable event components for the conversation, intended for downloading as a DOCX file.
 *
 * @param events - The list of events to build the printable components for.
 * @returns A list of printable event components.
 */
function buildPrintableEventComponents(events: ClientAgentEventRead[]) {
  const eventComponents = events.map((event) => {
    // Any events types not handled below will be skipped in the output DOCX file
    switch (event.type) {
      case AgentEventType.AGENT_RESPONSE:
        return buildAgentResponseEventPrintableBubble(event)
      case AgentEventType.USER_QUERY:
      case AgentEventType.STOP_REQUEST_RECEIVED:
      case AgentEventType.SERVER_ERROR:
        return <GeneralEventPrintableBubble key={`event-print-${event.id}`} event={event} />
      case AgentEventType.AGENT_RESPONSE_PROMPT_ASSIST:
        return <PromptAssistEventPrintableBubble key={`event-print-${event.id}`} event={event} />
      default:
        return null
    }
  })
  return <>{eventComponents}</>
}
