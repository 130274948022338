import FolderFilled from '@/assets/icons/folder-filled.svg?react'
import { FolderNode } from '../schemas/files-drive-schema'

interface FolderListItemProps {
  folder: FolderNode
  isSelected: boolean
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick: () => void
}

export default function FolderListItem({ folder, isSelected, onSelect, onClick }: FolderListItemProps) {
  return (
    <div
      className="border-t-[1px] py-2 grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden cursor-pointer hover:bg-brand-100"
      onClick={onClick}
    >
      <div className="px-2 grid grid-cols-[22px_32px_auto] items-center">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={onSelect}
          value={folder.id}
          className={'w-4 h-4 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm'}
          onClick={(e) => e.stopPropagation()}
        />
        <FolderFilled />
        <p className="text-sm overflow-hidden">{folder.name}</p>
      </div>
    </div>
  )
}
