// EventBubbleActions.tsx
import { ClipboardIcon, DocumentArrowDownIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import { copyAgentResponseToClipboard } from '@/agent/events/util/copy-agent-response-to-clipboard'
import { downloadAgentResponseAsDocx } from '@/agent/conversations/util/download-agent-conversation'
import { putResponseFeedback } from '@/agent/response_metadata/fetch/put-response-feedback'
import { AgentResponseMetadataActions } from '@/agent/response_metadata/store/slice'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectAgentResponseThumbRating } from '@/agent/response_metadata/store/selectors'
import { ClientAgentEventRead } from '@/agent/events/store/schemas'

type AgentResponseActionsProps = {
  conversationId: string
  event: ClientAgentEventRead
}

/**
 * Agent Response Actions
 *
 * Actions for agent responses such as:
 * - Thumb Rating (up/down) to submit feedback
 * - Copy response to clipboard
 * - Download response as DOCX
 */
export default function AgentResponseActions(props: AgentResponseActionsProps) {
  const { conversationId, event } = props
  const { trackEvent } = useAnalytics()
  const dispatch = useAppDispatch()

  const eventId = event.id
  const responseThumbRating = useAppSelector((state: RootState) => selectAgentResponseThumbRating(state, { eventId: event.id }))

  const handleThumbClick = (positive: boolean) => {
    const thumbRating = positive ? FeedbackThumb.UP : FeedbackThumb.DOWN

    // Submit initial feedback with empty text
    putResponseFeedback({ eventId, thumb: thumbRating, thumbText: '' })

    // Open dialog for text feedback
    dispatch(AgentResponseMetadataActions.openFeedbackDialog({ eventId, positive }))

    // Set the thumb rating in state
    dispatch(AgentResponseMetadataActions.setThumbRating({ eventId, thumb: thumbRating }))
  }

  const handleCopyAgentResponse = async () => {
    try {
      await copyAgentResponseToClipboard(event)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: 'Text copied to clipboard', durationMs: 2000 }))
    } catch (error) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Failed to copy text', durationMs: 2000 }))
      console.error('Error copying text:', error)
    }
  }

  return (
    <div className="flex gap-x-2 items-center text-brand-neutral-500 text-xs">
      <button
        title="This response was helpful"
        className={`p-1 rounded-full hover:text-brand-700 active:bg-brand-100 ${responseThumbRating === FeedbackThumb.UP ? 'bg-brand-100' : ''}`}
        onClick={() => handleThumbClick(true)}
      >
        <HandThumbUpIcon className="h-5" />
      </button>
      <button
        title="This response was not helpful"
        className={`p-1 rounded-full hover:text-brand-700 active:bg-brand-100 ${responseThumbRating === FeedbackThumb.DOWN ? 'bg-brand-100' : ''}`}
        onClick={() => handleThumbClick(false)}
      >
        <HandThumbDownIcon className="h-5" />
      </button>
      <button
        title="Copy response to clipboard"
        className="p-1 rounded-full hover:text-brand-700 active:bg-brand-100"
        onClick={() => {
          trackEvent(AnalyticsEvent.CopyChatResponse)
          handleCopyAgentResponse()
        }}
      >
        <ClipboardIcon className="h-5" />
      </button>
      <button
        title="Download response as a DOCX file"
        className="p-1 rounded-full hover:text-brand-700 active:bg-brand-100"
        onClick={async () => {
          trackEvent(AnalyticsEvent.DownloadChatResponseToDocx)
          await downloadAgentResponseAsDocx(conversationId, event)
        }}
      >
        <DocumentArrowDownIcon className="h-5" />
      </button>
    </div>
  )
}
