import { RootState } from '@/store/store'

/**
 * Selector: Conversation Selected Files Content Expanded
 * A selector that retrieves the expanded state of the selected files content for a given conversation.
 */
export const selectConversationSelectedFilesContentExpanded = (state: RootState, props: { chatId: string | undefined }) =>
  state.chatV2UxState.selectedFilesContentExpanded[props.chatId ?? ''] ?? false

/**
 * Selector: Last Viewed Conversation By Feature
 */
export const selectLastViewedConversationIdRecord = (state: RootState) => state.chatV2UxState.lastViewedConvoIdByFeature ?? null

/**
 * Selector: Active Conversation Id
 */
export const selectActiveConversationId = (state: RootState) => state.chatV2UxState.activeConversationId

/**
 * Selector: Prompt Assist Enabled
 */
export const selectPromptAssistEnabled = (state: RootState) => state.chatV2UxState.promptAssistEnabled
export const selectPromptAssistLastFetched = (state: RootState) => state.chatV2UxState.promptAssistLastFetched
export const selectPromptAssistFetchIsLoading = (state: RootState) => state.chatV2UxState.promptAssistFetchIsLoading
