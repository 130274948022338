import { nanoid } from 'nanoid'
import { FilesDriveResponse } from '../schemas/files-drive-schema'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import * as Sentry from '@sentry/browser'
import { kFileDriveApiPath } from '@/constants/constants-api-paths'
import { ApiError } from '@/util/errors/api-error'

/**
 * Updates the name of a specific file.
 * @param itemName - The new name of the file.
 * @param itemId - The id of the file to update.
 * @returns A promise that resolves to the updated file.
 * @throws An error if the response status is not OK
 */
export async function putItemName({ itemName, itemId }: { itemName: string; itemId: string }): Promise<FilesDriveResponse> {
  const sentry_transaction_id = nanoid()

  try {
    //Maybe abstract this authentication methods.
    const token = await getAuth().currentUser?.getIdToken(true)

    if (!token) throw Error(kErrorCodeAuthError)

    const apiUrl = kPaxtonAppApiBaseUrl() + kFileDriveApiPath + itemId

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Transaction-Id': sentry_transaction_id,
      },
      method: 'PUT',
      body: JSON.stringify({ name: itemName }),
    }

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok || response.status !== 200) {
      const error = new ApiError(response.status, `Failed to update item name - status: ${response.status}, detail: ${data.detail}`)

      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            itemId: itemId,
          },
        })
      })
      throw new ApiError(response.status, `Failed to update item name - status: ${response.status}, detail: ${data.detail}`)
    }

    // When details is provided, it means there is an error from the backend
    if (!data.detail) {
      return data
    } else {
      throw new Error('Failed to update item name', { cause: data })
    }
  } catch (e) {
    // Skip api errors already captured above
    if (!(e instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(e, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })
    }
    throw e
  }
}
