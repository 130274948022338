import { useEffect, useRef } from 'react'
import { useAppSelector } from '@/store/store-hooks'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import ErrorComponent from '@/components/error/error-component'
import NewChatIntroAssistant from '@/chat-common/components/new-chat-intro-assistant'
import ChatWindowDropZoneMultifile, { ChatWindowDropZoneMultifileRef } from '@/chat-common/components/chat-window-dropzone-multifile'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import {
  selectAgentConversationExists,
  selectAgentConversationLoading,
  selectAgentConversationLoadError,
} from '@/agent/conversations/store/selectors'
import AgentChatFormWrapper from '@/agent/chat_form/components/AgentChatFormWrapper'
import { FileUploadContext } from '@/context/file-upload-context'
import { useDispatch } from 'react-redux'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import EventBubbleList from '../events/components/EventBubbleList'
import ScrollToBottomIcon from './components/ScrollToBottomIcon'
import { selectAgentConversationHasEvents } from '../events/store/selectors'
import { setLastViewedConvoIdByFeature } from '@/chat-common/store/chat-v2-ux.slice'
import MessageFeedbackDialog from '@/chat-common/components/dialogs/message-feedback-dialog'
import { AgentResponseMetadataActions } from '../response_metadata/store/slice'
import { selectAgentResponseFeedbackDialog } from '../response_metadata/store/selectors'
import { PopoverContainerContext } from '@/context/popover-container-context'

type AgentChatWindowProps = {
  conversationId: string
}

/**
 * Chat Window
 *
 * This component is mainly for templating the Chat Window area.
 * - Scrollable messages area
 * - Chat form at the bottom
 * @param props
 * @returns
 */
export default function AgentChatWindow(props: AgentChatWindowProps) {
  const { conversationId } = props

  const dispatch = useDispatch()

  // Redux State Selectors
  const conversationExists = useAppSelector((state: RootState) => selectAgentConversationExists(state, { conversationId: conversationId }))
  const conversationLoading = useAppSelector((state: RootState) => selectAgentConversationLoading(state, { conversationId: conversationId }))
  const conversationLoadError = useAppSelector((state: RootState) => selectAgentConversationLoadError(state, { conversationId: conversationId }))

  const conversationHasEvents = useAppSelector((state: RootState) => selectAgentConversationHasEvents(state, { conversationId: conversationId }))
  const feedbackDialog = useAppSelector((state: RootState) => selectAgentResponseFeedbackDialog(state))

  // Whether to show the loader
  const showLoader = conversationLoading && !conversationHasEvents

  // Refs
  const scrollingChatWindowRef = useRef<HTMLDivElement | null>(null)
  const chatWindowDropZoneMultifileRef = useRef<ChatWindowDropZoneMultifileRef | null>(null)

  // Allows interaction with the child component's openSystemFileSelector method
  const openSystemFileSelector = () => {
    chatWindowDropZoneMultifileRef?.current?.openSystemFileSelector()
  }

  /**
   * Load the conversation data for this chat
   */
  useEffect(() => {
    dispatch(AgentConversationsActions.fetchConversationData({ conversationId, forceRefresh: true }))
  }, [conversationId, dispatch])

  /**
   * Set as the last viewed conversation
   */
  useEffect(() => {
    if (conversationExists) {
      // Visually and in terms of UX, the "agent" feature is the assistant.
      dispatch(setLastViewedConvoIdByFeature({ feature: ChatV2Feature.assistant, conversationId }))
    }
  }, [conversationExists, conversationId, dispatch])

  // If there was an error loading the conversation, show the error component
  if (conversationLoadError) {
    return (
      <div className={'p-5'}>
        <ErrorComponent {...conversationLoadError} />
      </div>
    )
  }

  return (
    <>
      {feedbackDialog.visible && feedbackDialog.eventId && feedbackDialog.positive !== null && (
        <MessageFeedbackDialog
          conversationId={conversationId}
          eventId={feedbackDialog.eventId}
          positive={feedbackDialog.positive}
          visible={feedbackDialog.visible}
          onClose={() => dispatch(AgentResponseMetadataActions.closeFeedbackDialog())}
        />
      )}

      {/* Provide the container element to the popover context, used for popover collision boundaries */}
      <PopoverContainerContext.Provider value={scrollingChatWindowRef.current}>
        <div className="flex flex-grow flex-col items-center px-2">
          <div ref={scrollingChatWindowRef} className="flex-1 flex-grow overflow-y-scroll w-full">
            {/* This div will contain the conversation and will be scrollable */}
            <div className="max-w-3xl mx-auto h-full">
              {/* Render circular progress indicator if we're loading the messages and there are no messages */}
              {showLoader && (
                <div className={'w-full h-full flex justify-center items-center'}>
                  <CircularProgressContinuousSized size={30} thickness={7} hexColor={'#2A49FF'} />
                </div>
              )}

              {/* Render the new chat intro if we are on the new chat screen and have not sent an initial message */}
              {!showLoader && !conversationHasEvents && (
                <div className="flex flex-col justify-start pt-2 xs:pt-8 pb-5">
                  <NewChatIntroAssistant />
                  <ChatWindowDropZoneMultifile
                    ref={chatWindowDropZoneMultifileRef}
                    hideDropZoneUI={conversationHasEvents}
                    chatId={conversationId}
                    chatFeature={ChatV2Feature.agent}
                  />
                </div>
              )}

              {conversationExists && conversationHasEvents && (
                <>
                  {/* Render ChatWindowDropZoneMultifile with the UI hidden once the conversation has started */}
                  <ChatWindowDropZoneMultifile
                    ref={chatWindowDropZoneMultifileRef}
                    hideDropZoneUI={conversationHasEvents}
                    chatId={conversationId}
                    chatFeature={ChatV2Feature.agent}
                  />
                </>
              )}

              <EventBubbleList conversationId={conversationId} />
            </div>
          </div>

          {/* This form will stay at the bottom of the screen */}
          {conversationExists && (
            <div className={'w-full pb-4 z-10 bg-brand-neutral-100'}>
              {/* Floating go to bottom button */}
              <ScrollToBottomIcon conversationId={conversationId} scrollingDivRef={scrollingChatWindowRef} />

              <FileUploadContext.Provider value={{ openSystemFileSelector: openSystemFileSelector }}>
                <AgentChatFormWrapper conversationId={conversationId} />
              </FileUploadContext.Provider>
            </div>
          )}
        </div>
      </PopoverContainerContext.Provider>
    </>
  )
}
