import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import { z } from 'zod'

// AgentResponseMetadataRead Schema
export const AgentResponseMetadataReadSchema = z.object({
  event_id: z.string().uuid(),
  time_to_first_token: z.number().default(0),
  thumb_rating: z.nativeEnum(FeedbackThumb).nullable().default(null),
  thumb_text: z.string().nullable().default(null),
})

export type AgentResponseMetadataRead = z.infer<typeof AgentResponseMetadataReadSchema>

/**
 * Generates a default agent response metadata object for a given event ID.
 */
export const getDefaultAgentResponseMetadata = (eventId: string): AgentResponseMetadataRead => {
  return AgentResponseMetadataReadSchema.parse({ event_id: eventId })
}
